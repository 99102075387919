import { ContactDto, ContactView } from "@/core/models/master-data/Contact.model";
import { ClientView, ClientDto } from "@/core/models/master-data/Client.model";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import GetConfig, { applyGetConfig } from "../base/get.config";
import { MasterdataView } from "@/core/models/master-data/MasterData.model";
import { RecipientDto, RecipientView } from "@/core/models/master-data/Recipient.model";
import { BidderDto, BidderView } from "@/core/models/master-data/Bidder.model";
import { PlannerDto, PlannerView } from "@/core/models/master-data/Planner.mode";
import { OpportunityDto, OpportunityView } from "@/core/models/master-data/Opportunity.model";
import { SalesView, SalesDto } from "@/core/models/master-data/Sales.model";
import store from "@/core/store";
import { i18nGlobalTc } from "@/i18n";

export function loadMasterdata(quotationId: number): Promise<MasterdataView> {
  store.commit("setLoading", true);
  return axios
    .get(`quotation/${quotationId}/stammdaten`)
    .then((response) => {
      const result: MasterdataView = new MasterdataView(response.data);
      return result;
    })
    .finally(() => store.commit("setLoading", false));
}

export function updateMasterdata(quotationId: number, masterdata: MasterdataView): Promise<any> {
  return axios
    .put(`quotation/${quotationId}/stammdaten`, masterdata)
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        store.commit(
          "activateAlert",
          {
            type: "success",
            message: i18nGlobalTc("CORE.SERVICES.MASTER_DATA.MASTER_DATA.UPDATE_SUCCESS"),
          },
          { root: true }
        );
      } else {
        store.commit(
          "activateAlert",
          {
            type: "error",
            message: i18nGlobalTc("CORE.SERVICES.MASTER_DATA.MASTER_DATA.UPDATE_ERROR"),
          },
          { root: true }
        );
      }
      return response;
    });
}

export function loadClientList(getConfig?: GetConfig): Promise<ClientView[]> {
  store.commit("setLoading", true);
  let config: AxiosRequestConfig = {};
  if (getConfig) {
    config = applyGetConfig(getConfig, config);
  }

  return axios
    .get(`customers/clients`, config)
    .then((response) => {
      const result: ClientView[] = response.data.map((dto: ClientDto) => new ClientView(dto));
      return result;
    })
    .finally(() => store.commit("setLoading", false));
}

export function loadRecipientList(getConfig?: GetConfig): Promise<RecipientView[]> {
  store.commit("setLoading", true);
  let config: AxiosRequestConfig = {};
  if (getConfig) {
    config = applyGetConfig(getConfig, config);
  }

  return axios
    .get(`customers/recipients`, config)
    .then((response) => {
      const result: RecipientView[] = response.data.map(
        (dto: RecipientDto) => new RecipientView(dto)
      );
      return result;
    })
    .finally(() => store.commit("setLoading", false));
}

export function loadSalesAreaList(getConfig?: GetConfig): Promise<SalesView[]> {
  store.commit("setLoading", true);
  let config: AxiosRequestConfig = {};
  let clientId = "";
  if (getConfig) {
    config = applyGetConfig(getConfig, config);
    clientId = config.params.kundennummer;
  }

  return axios
    .get(`customerSaleAreas`, config)
    .then((response) => {
      const result: SalesView[] = response.data.map((dto: SalesDto) => {
        dto.kundennummer = clientId;
        return new SalesView(dto);
      });
      return result;
    })
    .finally(() => store.commit("setLoading", false));
}

export function loadContactList(kundennummer: number): Promise<ContactView[]> {
  store.commit("setLoading", true);
  const config: AxiosRequestConfig = {};
  config.params = {
    kundennummer: kundennummer,
  };

  return axios
    .get(`contacts/list`, config)
    .then((response) => {
      const result: ContactView[] = response.data.map((dto: ContactDto) => new ContactView(dto));
      return result;
    })
    .finally(() => store.commit("setLoading", false));
}

export function loadBidderList(getConfig?: GetConfig): Promise<BidderView[]> {
  store.commit("setLoading", true);
  let config: AxiosRequestConfig = {};
  if (getConfig) {
    config = applyGetConfig(getConfig, config);
  }

  return axios
    .get(`customers/bidders`, config)
    .then((response) => {
      const result: BidderView[] = response.data.map((dto: BidderDto) => new BidderView(dto));
      return result;
    })
    .finally(() => store.commit("setLoading", false));
}

export function loadPlannerList(getConfig?: GetConfig): Promise<PlannerView[]> {
  store.commit("setLoading", true);
  let config: AxiosRequestConfig = {};
  if (getConfig) {
    config = applyGetConfig(getConfig, config);
  }

  return axios
    .get(`customers/planners`, config)
    .then((response) => {
      const result: PlannerView[] = response.data.map((dto: PlannerDto) => new PlannerView(dto));
      return result;
    })
    .finally(() => store.commit("setLoading", false));
}

export function loadOpportunityList(getConfig?: GetConfig): Promise<OpportunityView[]> {
  store.commit("setLoading", true);
  let config: AxiosRequestConfig = {};
  if (getConfig) {
    config = applyGetConfig(getConfig, config);
  }

  return axios
    .get(`opportunities/list`, config)
    .then((response) => {
      const result: OpportunityView[] = response.data.map(
        (dto: OpportunityDto) => new OpportunityView(dto)
      );
      return result;
    })
    .finally(() => store.commit("setLoading", false));
}
