<template>
  <v-container class="full-height" fluid>
    <v-row>
      <v-col>
        <h1>{{ "Angebote" + (dataBaseComment ? " - " + dataBaseComment : "") }}</h1>
      </v-col>
      <v-col align-self="end" align="right">
        <v-btn
          :disabled="disableCreateButton"
          :class="{ 'dark-btn': !disableCreateButton }"
          @click="dialog = true"
        >
          <v-icon color="white" start>
            mdi-pencil
          </v-icon>
          {{ $tc("COMPONENTS.QUOTATION.QUOTATIONLIST.EDIT_COMMENT") }}
        </v-btn>

        <v-dialog v-model="dialog" width="500" @click:outside="formComment = dataBaseComment">
          <v-form v-if="dialog" ref="myForm" @submit="submit($event)">
            <v-card v-bind="{ loading: submitted, disabled: submitted }">
              <v-card-title>
                {{
                  $tc("COMPONENTS.QUOTATION.QUOTATIONLIST.EDIT_COMMENT")
                }}
              </v-card-title>

              <v-card-text>
                <v-textarea
                  ref="formComment"
                  v-model="formComment"
                  :label="$tc('BOQ.ADDBOQDIALOG.COMMENT')"
                  variant="outlined"
                />
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-spacer />
                <v-btn variant="outlined" @click="(dialog = false), (formComment = dataBaseComment)">
                  {{ $t("BOQ.ADDBOQDIALOG.CANCEL") }}
                </v-btn>
                <v-btn class="dark-btn" elevation="0" type="submit">
                  {{ $t("BOQ.ADDBOQDIALOG.SAVE") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>

        <v-btn
          class="ml-2"
          :disabled="disableCreateButton"
          :class="{ 'dark-btn': !disableCreateButton }"
          @click="createQuotation()"
        >
          <v-icon color="white" start>
            mdi-plus
          </v-icon>
          {{ $tc("COMPONENTS.QUOTATION.QUOTATIONLIST.NEW_QUOTATION") }}
        </v-btn>
        <ImportQuotationDialog v-if="boqId" :boq-id="+boqId" @success="listQuotations()" />
        <v-btn class="dark-btn ml-2" @click="navigateToBoqOverview()">
          <v-icon color="white" start>
            mdi-arrow-left
          </v-icon>
          {{ $tc("COMPONENTS.QUOTATION.QUOTATIONLIST.BACK") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-divider class="mb-3" />
    <v-container class="pa-0 table-container elevation-5 rounded-lg" fluid>
      <BoqDataTable
        v-model:get-config="getConfig"
        :items="quotationList"
        :headers="headers"
        :loading="loading"
        @click:row="navigateToDetailView"
      >
        <template #[`item.boqId`]="">
          {{ boqId }}
        </template>

        <!-- tooltip for product hierarchy -->
        <template #[`item.productHierarchy`]="{ item }">
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <div v-bind="props">
                {{ item.productHierarchyFirst }}
              </div>
            </template>
            {{ item.productHierarchy }}
          </v-tooltip>
        </template>

        <template #[`item.status`]="{ item }">
          <div
            :class="{
              'status-cancelled': ['Abgebrochen', 'Fehlgeschlagen'].includes(item.status),
              'quotation-disabled': ['Konvertierung', 'Wartet', 'Abgebrochen'].includes(
                item.status
              ),
            }"
          >
            {{ item.status }}
          </div>
        </template>

        <template #[`item.actions`]="{ item }">
          <ActionButton
            tooltip="Löschen"
            icon="mdi-delete"
            @button-clicked="deleteQuotation(item.id)"
          />
        </template>
      </BoqDataTable>
    </v-container>
  </v-container>
</template>

<script lang="ts">
import BoqDataTable from "@/shared/components/data-table.component.vue";
import axios from "axios";
import { getQuotationListHeaders } from "./quotation-list-headers";
import { BoqTableHeader } from "@/core/interfaces/boq-table-header.interface";
import ActionButton from "@/shared/components/action-button.component.vue";
import Quotation from "@/core/models/quotation/quotation.model";
import GetConfig from "@/core/services/base/get.config";
import ImportQuotationDialog from "@/components/quotation/ImportQuotationDialog.vue";
import { defineComponent } from "vue";
import type { VDataTableRowClickData } from '@/core/models/vuetify/vuetify.model';

export default defineComponent({
  components: {
    BoqDataTable,
    ActionButton,
    ImportQuotationDialog,
  },
    props: {
        boqId: {
            type: String,
            required: true,
        }
    },
    data() {
        const headers: BoqTableHeader[] = [];
        const successAlert: any = {
                type: "success",
                message: this.$tc("COMPONENTS.QUOTATION.QUOTATIONLIST.MESSAGE.QUOTATION_SUCCESS"),
              };
        const errorAlert: any = {
                type: "error",
                message: this.$tc("COMPONENTS.QUOTATION.QUOTATIONLIST.MESSAGE.QUOTATION_ERROR"),
              };
        const timer: any = undefined;
        const getConfig: GetConfig = GetConfig.default();

        return {
            getConfig,
            timer,
            dialog: false,
            submitted: false,
            errorAlert,
            successAlert,
            dataBaseComment: "",
            formComment: "",
            headers,
            disableCreateButton: false
        };
    },
    computed: {
        quotationList(): Quotation[] {
            return this.$store.getters["quotation/quotationList"];
        },
        loading(): boolean {
            return this.$store.getters["loading"];
        }
    },
    watch: {
        "getConfig": [{
            handler: "getConfigChanged"
        }],
        "quotationList": [{
            handler: "quotationListChanged"
        }]
    },
    async mounted() {
        this.listQuotations();
            this.headers = getQuotationListHeaders();
            this.startTimer();

            axios({
              method: `get`,
              url: `/boq/${this.boqId}`,
            })
              .then((response: any) => {
                if (response.status === 200) {
                  this.dataBaseComment = response.data ? response.data.comment : "";
                  this.formComment = this.dataBaseComment;
                }
              })
              .catch((err) => {
                console.error(err);
              });
    },
    methods: {
        startTimer() {
            this.timer = setInterval(() => this.listQuotations(), 5000);
        },
        beforeUnmount() {
            this.stopTimer();
        },
        stopTimer() {
            clearInterval(this.timer);
        },
        async submit(event: any) {
            event.preventDefault();
                this.submitted = true;

                await axios({
                  method: "post",
                  url: `/boq/${this.boqId}/editComment`,
                  params: {
                    comment: this.formComment,
                  },
                })
                  .then((response) => {
                    if ((response.status = 200)) {
                      this.dataBaseComment = this.formComment;
                      this.submitted = false;
                      this.dialog = false;
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
        },
        listQuotations() {
            this.$store.dispatch("quotation/loadQuotationList", {
              boqId: +this.boqId,
              getConfig: this.getConfig,
            });
        },
        createQuotation() {
            this.disableCreateButton = true;
            axios({
              method: "post",
              url: "/quotation/create",
              params: {
                boqId: this.boqId,
              },
            }).then((response) => {
              this.disableCreateButton = false;
              if (response.status === 201) {
                this.$store.commit("activateAlert", this.successAlert);
                this.startTimer();
                this.listQuotations();
              } else {
                this.$store.commit("activateAlert", this.errorAlert);
              }
            });
        },
        deleteQuotation(id: number) {
            console.log(id);
        },
        navigateToDetailView(clickEvent: MouseEvent, data: VDataTableRowClickData<any>) {
            if (["Verfügbar", "Exportiert"].includes(data.item.status)) {
                  axios.post(`/quotation/${data.item.id}/lock`).then((response) => {
                    if (response.status === 200) {
                      if (response.data.canBeLocked) {
                        this.$router.push({
                          name: "QuotationDetail",
                          params: { boqId: `${this.boqId}`, quotationId: `${data.item.id}` },
                        });
                      } else {
                        this.$store.commit("activateAlert", {
                          type: "error",
                          message:
                            this.$tc("COMPONENTS.QUOTATION.QUOTATIONLIST.MESSAGE.QUOTATION_EDITEDBY") +
                            ` ${response.data.lockedBy}`,
                        });
                      }
                    }
                  });

                  return true;
                }
        },
        navigateToBoqOverview() {
            this.$router.push({
              name: "BoqOverview",
            });
        },
        getConfigChanged() {
            this.listQuotations();
        },
        quotationListChanged() {
            if (!this.quotationList.find((e) => ["Konvertierung", "Wartet"].includes(e.status))) {
              this.stopTimer();
            }
        }
    }
})

</script>

<style lang="scss" scoped>
@use "@/style/styles";

.table-container {
  height: styles.$table-container-height;
}

.quotation-disabled {
  color: styles.$secondary;
}

.status-cancelled {
  color: red;
}
</style>
