import { ActionTree } from "vuex";
import * as MasterdataService from "@/core/services/master-data/master-data.service";
import MasterdataState from "./state";
import { RootState } from "@/core/store/state";
import GetConfig from "@/core/services/base/get.config";
import { ClientView } from "@/core/models/master-data/Client.model";
import { RecipientView } from "@/core/models/master-data/Recipient.model";
import { ContactView } from "@/core/models/master-data/Contact.model";
import { BidderView } from "@/core/models/master-data/Bidder.model";
import { PlannerView } from "@/core/models/master-data/Planner.mode";
import { OpportunityView } from "@/core/models/master-data/Opportunity.model";
import { MasterdataView } from "@/core/models/master-data/MasterData.model";
import { BillOfQuantityView } from "@/core/models/master-data/BillOfQuantity.model";
import { SalesView } from "@/core/models/master-data/Sales.model";

export const actions: ActionTree<MasterdataState, RootState> = {
  //Masterdata

  loadMasterdata(context, quotationId: number) {
    MasterdataService.loadMasterdata(quotationId).then((response) => {
      context.commit("setMasterdata", response);
    });
  },

  updateMasterdata(context, { quotationId, masterdata }) {
    MasterdataService.updateMasterdata(quotationId, masterdata).then((response) => {
      console.log(response);
    });
  },

  setMasterdataChanged(context, masterdataChanged: boolean) {
    context.commit("setMasterdataChanged", masterdataChanged);
  },

  clearMasterdata(context) {
    context.commit("setMasterdata", new MasterdataView());
  },

  //Client

  loadClientList(context, config?: GetConfig) {
    MasterdataService.loadClientList(config).then((response) => {
      context.commit("setClientList", response);
    });
  },

  clearClientList(context) {
    context.commit("setClientList", []);
  },

  setClient(context, client: ClientView) {
    context.commit("setClient", client);
    context.commit("setMasterdataChanged", true);
  },

  clearClient(context) {
    context.commit("setClient", new ClientView());
  },

  // Recipient

  loadRecipientList(context, config?: GetConfig) {
    MasterdataService.loadRecipientList(config).then((response) => {
      context.commit("setRecipientList", response);
    });
  },

  clearRecipientList(context) {
    context.commit("setRecipientList", []);
  },

  setRecipient(context, recipient: RecipientView) {
    context.commit("setRecipient", recipient);
    context.commit("setMasterdataChanged", true);
  },
  clearRecipient(context) {
    context.commit("setRecipient", new RecipientView());
  },

  // Sales Area

  loadSalesAreaList(context, config?: GetConfig) {
    MasterdataService.loadSalesAreaList(config)
      .then((response) => {
        context.commit("setSalesAreaList", response);
      })
      .catch((err) => {
        console.error(err);
      });
  },

  clearSalesAreaList(context) {
    context.commit("setSalesAreaList", []);
  },

  setSalesArea(context, salesArea: SalesView) {
    context.commit("setSalesArea", salesArea);
    context.commit("setMasterdataChanged", true);
  },
  clearSalesArea(context) {
    context.commit("setSalesArea", new SalesView());
  },

  //Contact

  loadContactList(context, kundennummer: number) {
    MasterdataService.loadContactList(kundennummer).then((response) => {
      context.commit("setContactList", response);
    });
  },

  loadBoomiContactList(context, boomiContacts: ContactView[]) {
    context.commit("setContactList", boomiContacts);
  },

  clearContactList(context) {
    context.commit("setContactList", []);
  },

  setContact(context, contact: ContactView) {
    context.commit("setContact", contact);
    context.commit("setMasterdataChanged", true);
  },

  clearContact(context) {
    context.commit("setContact", new ContactView());
  },

  //Planner

  loadPlannerList(context, config?: GetConfig) {
    MasterdataService.loadPlannerList(config).then((response) => {
      context.commit("setPlannerList", response);
    });
  },

  clearPlannerList(context) {
    context.commit("setPlannerList", []);
  },

  setPlanner(context, planner: PlannerView) {
    context.commit("setPlanner", planner);
    context.commit("setMasterdataChanged", true);
  },

  clearPlanner(context) {
    context.commit("setPlanner", new PlannerView());
  },

  //Bidder

  loadBidderList(context, config?: GetConfig) {
    MasterdataService.loadBidderList(config).then((response) => {
      context.commit("setBidderList", response);
    });
  },

  clearBidderList(context) {
    context.commit("setBidderList", []);
  },

  setBidder(context, bidder: BidderView[]) {
    context.commit("setBidder", bidder);
    context.commit("setMasterdataChanged", true);
  },

  clearBidder(context) {
    context.commit("setBidder", new BidderView());
  },

  //Oppportunity

  loadOpportunityList(context, config?: GetConfig) {
    MasterdataService.loadOpportunityList(config).then((response) => {
      context.commit("setOpportunityList", response);
    });
  },

  clearOpportunityList(context) {
    context.commit("setOpportunityList", []);
  },

  setOpportunity(context, opportunity: OpportunityView) {
    context.commit("setOpportunity", opportunity);
    context.commit("setMasterdataChanged", true);
  },

  clearOpportunity(context) {
    context.commit("setOpportunity", new OpportunityView());
  },

  //BillOfQuantity

  setBillOfQuantity(context, billOfQuantity: BillOfQuantityView) {
    context.commit("setBillOfQuantity", billOfQuantity);
    context.commit("setMasterdataChanged", true);
  },

  clearBillOfQuantity(context) {
    context.commit("setBillOfQuantity", new BillOfQuantityView());
  },
};
