import store from "../store";
import { AxiosError } from "axios";
import router from "@/router";
import { i18nGlobalTc } from "@/i18n";

export function handleAxiosErrorResponse(error: AxiosError<{ error?: string }>) {
  console.error(`handleAxiosErrorResponse: ` + error);
  // handle error
  if (error.response) {
    switch (error.response.status) {
      case 400:
      case 404:
        let details: string;
        console.log(error.response);
        if (error.response.data.error) {
          console.log(error.response.data.error);
          details = error.response.data.error;
          store.dispatch("triggerAlert", {
            type: "error",
            message: `${error.response.status}: ${details}`,
          });
        } else {
          console.log(error.response.data);
          store.dispatch("triggerAlert", {
            type: "error",
            message: error.response.data
              ? error.response.data
              : i18nGlobalTc("CORE.ERROR_HANDLER.UNKNOWN_ERROR"),
          });
        }
        break;
      case 401:
        router.push({ name: "Login" });
        break;
      case 500:
        store.dispatch("triggerAlert", {
          type: "error",
          message: "Server Error",
        });
        break;
    }
    throw error;
  } else {
    store.dispatch("triggerAlert", {
      type: "error",
      message: i18nGlobalTc("CORE.ERROR_HANDLER.UNKNOWN_ERROR"),
    });
    throw error;
  }
}
