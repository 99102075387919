import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-37ac58f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.dialog,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dialog) = $event)),
    width: "400px"
  }, {
    activator: _withCtx(({ props }) => [
      _createVNode(_component_v_btn, _mergeProps(props, { class: "dark-btn" }), {
        default: _withCtx(() => [
          _createTextVNode(" Export ")
        ]),
        _: 2
      }, 1040)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_form, {
        ref: "myForm",
        onSubmit: _cache[2] || (_cache[2] = ($event: any) => (_ctx.submit($event)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, _mergeProps({ loading: _ctx.submitted, disabled: _ctx.submitted }, { class: "text-center" }), {
            default: _withCtx(() => [
              (_ctx.QuotationExported)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_v_card_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.EXPORTQUOTATIONDIALOG.SUCCESS.EXPORTED_QUOTATION")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString("Export-ID: " + _ctx.exportId), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_divider),
                    _createVNode(_component_v_card_actions, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_v_btn, {
                          variant: "outlined",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialog = false))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.EXPORTQUOTATIONDIALOG.CLOSE")), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_v_card_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.exportId
                ? _ctx.$tc("COMPONENTS.EXPORTQUOTATIONDIALOG.ERROR.ALREADY_EXPORTED")
                : _ctx.$tc("COMPONENTS.EXPORTQUOTATIONDIALOG.WANT_TO_EXPORT")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_card_subtitle, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, {
                          size: "100",
                          color: "error"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" mdi-help-circle ")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.exportId
                    ? "Export-ID: " + _ctx.exportId
                    : _ctx.$tc("COMPONENTS.EXPORTQUOTATIONDIALOG.ERROR.ONLY_ONCE")), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.EXPORTQUOTATIONDIALOG.PROCESS_QUOTATION_DOCUMENT")), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_divider),
                    _createVNode(_component_v_card_actions, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_v_btn, {
                          variant: "outlined",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dialog = false))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.exportId
                  ? _ctx.$tc("COMPONENTS.EXPORTQUOTATIONDIALOG.CLOSE")
                  : _ctx.$tc("COMPONENTS.EXPORTQUOTATIONDIALOG.CANCEL")), 1)
                          ]),
                          _: 1
                        }),
                        (!_ctx.exportId)
                          ? (_openBlock(), _createBlock(_component_v_btn, {
                              key: 0,
                              class: "dark-btn",
                              elevation: "0",
                              type: "submit"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(" Exportieren ")
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]))
            ]),
            _: 1
          }, 16)
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}