<template>
  <v-dialog v-if="article != null" v-model="dialog">
    <template #activator="slotData">
      <slot name="activator" v-bind="slotData" :article="article" />
    </template>
    <v-card>
      <v-card-title>
        {{ article.bezeichnungProduktname }} Modell {{ article.modellnummer }}
      </v-card-title>
      <v-card-text>
        <div class="content-title">
          {{ $tc("COMPONENTS.ARTICLE.ARTICLE_DETAIL.ARTICLENUMBER") }}.
        </div>
        <div class="content-message">
          {{ article.artikelnummer }}
        </div>
        <br>
        <div class="content-title">
          {{ $tc("COMPONENTS.ARTICLE.ARTICLE_DETAIL.TITLE") }}
        </div>
        <div class="content-message">
          {{ article.bezeichnungProduktname }}
        </div>
        <br>
        <div class="content-title">
          {{ $tc("COMPONENTS.ARTICLE.ARTICLE_DETAIL.QUOTATION_TEXT") }}
        </div>
        <div class="content-message">
          {{ article.angebotstext }}
        </div>
        <br>
        <div class="content-title">
          {{ $tc("COMPONENTS.ARTICLE.ARTICLE_DETAIL.OTHER") }}
        </div>
        <div v-for="key of Object.keys(article)" :key="key" class="content-message">
          <ul>
            <li
              v-if="
                article[key] !== '' &&
                  key !== 'modellnummer' &&
                  key !== 'artikelnummer' &&
                  key !== 'bezeichnungProduktname' &&
                  key !== 'angebotstext'
              "
            >
              {{ key }}: {{ article[key] }}
            </li>
          </ul>
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn class="dark-btn" @click="dialog = false">
          {{ $tc("COMPONENTS.ARTICLE.ARTICLE_DETAIL.CLOSE") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        article: {
            type: Object as PropType<any>
        }
    },
    data() {
        return {
            dialog: false
        };
    },
    methods: {
        clickArticle() {
            this.dialog = true;
        }
    }
})

</script>
