<template>
  <div class="d-flex align-center">
    <svg
      version="1.1"
      id="icon--viega-logo"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 841.9 595.3"
      style="enable-background:new 0 0 841.9 595.3;"
      xml:space="preserve"
    >
      <path class="st0" d="M74.7,1.9h692.5v591.4H74.7V1.9z"/>
      <path class="st1" d="M98.8,332.9h644.2v236.4H98.8V332.9z"/>
      <path d="M98.8,25.9h644.2v283H98.8V25.9z"/>
      <path
        class="st2"
        d="M268.8,125.7h-32.3l-16.8,52.6c-2.8,8.9-5.9,19.3-7.2,24.9c-1.3-5.4-4.4-16.2-7.2-24.9l-16.8-52.6h-33.1
        l41.2,113.6h31C227.6,239.3,268.8,125.7,268.8,125.7z M439.1,182.7c0,4.3-0.2,7.4-0.4,9.5h-68.5c5.6,35.5,45.8,22.9,55.1,15.2
        l0.5,0.1l4.6,22.8c-7.3,5.6-20.2,11.4-36.7,11.4c-32.9,0-53.9-23.2-53.9-59.1c0-34.8,22-59.3,51-59.3
        C421.5,123.4,439.1,150.2,439.1,182.7z M409.9,169.7c-0.9-10.2-7-20.3-20.5-20.3c-10.9,0-17.7,8.9-18.8,20.3H409.9L409.9,169.7z
        M287.5,125.7h31v113.6h-31V125.7z M329.1,82.5c0-14.1-11.9-26-26.2-26c-14.3,0-26.2,11.8-26.2,26c0,14.1,11.9,26,26.2,26
        C317.2,108.5,329.1,96.7,329.1,82.5z M523.9,204.1v-42.8c-4.6-6.3-11.6-9.7-20.3-9.7c-11.6,0-17.9,10-17.9,29
        c0,23.4,7.9,33.1,19.6,33.1C514.7,213.6,519.5,209.7,523.9,204.1L523.9,204.1z M554.4,219.9c1.7,54.8-31.7,64.8-72.2,64.1l-4.7-24.9
        l0.3-0.3c23.1,0,48.3,0.7,46.6-26.8c-5.7,5-13.3,9.5-24.2,9.5c-24.4,0-44.5-18.2-44.5-60.6c0-34,16.1-57.8,44.5-57.8
        c10,0,18.8,3.9,24.2,8.9l0.1-6.5h30L554.4,219.9L554.4,219.9z M642.3,206v-14.3c-5.2-3.2-10.3-4.8-20.9-4.8
        c-11.3,0-17.4,5.2-17.4,14.3c0,8,4.6,15.1,17.4,15.1C631.2,216.4,639.1,211.4,642.3,206L642.3,206z M672.6,239.7h-29.8l-0.3-7.6
        c-5.5,5.6-15,10-25.7,10c-21.4,0-42.7-13-42.7-40.5c0-25.1,20.5-37.7,46.4-37.7c10.5,0,17.2,2.2,22,4.5v-5.6
        c0-13-17.1-23.9-50.9-6.5l-0.4-0.1l-4.3-22.8c19-12.3,85.7-22.7,85.7,31.6L672.6,239.7L672.6,239.7z"
      />
    </svg>
  </div>
</template>

<script setup lang="ts">

</script>

<style lang="scss" scoped>
.st0{fill:#FFFFFF;}
.st1{fill:#FFED00;}
.st2{fill-rule:evenodd;clip-rule:evenodd;fill:#FFED00;}
</style>
