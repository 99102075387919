import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_ctx.userDetail)
    ? (_openBlock(), _createBlock(_component_v_container, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createElementVNode("h1", null, _toDisplayString(_ctx.userDetail && _ctx.userDetail.firstname
              ? _ctx.$tc("COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_DETAILS.DETAILS")
              : _ctx.$tc("COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_DETAILS.CREATE")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_divider, { class: "mb-3" }),
          _createVNode(_component_v_container, { class: "elevation-5 rounded-lg" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_form, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            label: _ctx.$tc('COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.FIRST_NAME'),
                            type: "text",
                            modelValue: _ctx.userDetail.firstname,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userDetail.firstname) = $event))
                          }, null, 8, ["label", "modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            label: _ctx.$tc('COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.LAST_NAME'),
                            type: "text",
                            modelValue: _ctx.userDetail.lastname,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.userDetail.lastname) = $event))
                          }, null, 8, ["label", "modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            label: _ctx.$tc('COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.E_MAIL_ADDRESS'),
                            type: "email",
                            modelValue: _ctx.userDetail.email,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.userDetail.email) = $event))
                          }, null, 8, ["label", "modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_select, {
                            label: _ctx.$tc('COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.ROLE'),
                            type: "text",
                            modelValue: _ctx.userDetail.role,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.userDetail.role) = $event)),
                            items: _ctx.roles
                          }, null, 8, ["label", "modelValue", "items"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_select, {
                            label: _ctx.$tc('COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.COUNTRY'),
                            type: "text",
                            items: _ctx.marketList,
                            "item-title": "name",
                            "item-value": "id",
                            "onUpdate:modelValue": [
                              _ctx.onChange,
                              _cache[4] || (_cache[4] = ($event: any) => ((_ctx.userDetail.marketId) = $event))
                            ],
                            modelValue: _ctx.userDetail.marketId
                          }, null, 8, ["label", "items", "onUpdate:modelValue", "modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_select, {
                            label: _ctx.$tc('COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.SALES_TEAM'),
                            type: "text",
                            modelValue: _ctx.userDetail.teamId,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.userDetail.teamId) = $event)),
                            items: _ctx.teams,
                            "item-value": "id",
                            "item-title": "name",
                            rules: [
                (v) =>
                  v != -1 ||
                  !!!v ||
                  _ctx.$tc('COMPONENTS.POSITION.POSITIONARTICLEDIALOG.RULES.NOT_EMPTY'),
              ]
                          }, null, 8, ["label", "modelValue", "items", "rules"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            label: _ctx.$tc('COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.USERNAME_SAP'),
                            type: "text",
                            modelValue: _ctx.userDetail.sapNumber,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.userDetail.sapNumber) = $event))
                          }, null, 8, ["label", "modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_btn, {
                            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.navigateBack())),
                            class: "dark-btn"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.CANCEL")), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_btn, {
                            class: "dark-btn ml-2",
                            onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.submitUser()))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.SAVE")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}