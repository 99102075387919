import { Module } from 'vuex';
import { actions } from './actions';
import mutations from './mutations';
import { getters } from './getters';
import { RootState } from '~core/store/state';
import ArticleState, { state } from './state';

export const articleModule: Module<ArticleState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
