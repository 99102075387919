<template>
  <v-card v-bind="{ loading: submitted, disabled: submitted }" class="text-center">
    <v-card-title text-align-self="center"> Warnung entfernen </v-card-title>

    <v-card-subtitle>
      <v-icon size="100" color="error">mdi-help-circle</v-icon>
    </v-card-subtitle>

    <v-card-text v-if="validationErrorType !== 'error'">
      Möchten Sie die Warnung wirklich entfernen?
    </v-card-text>
    <v-card-text v-else>
      <p>Rote Warnungen können nicht per Klick entfernt werden.</p>
      <p>
        Stattdessen muss die Position so angepasst werden, dass das zu Grunde liegende Problem
        gelöst wird.
      </p>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions v-if="validationErrorType !== 'error'">
      <v-spacer></v-spacer>
      <v-btn variant="outlined" @click="cancel()"> Abbrechen </v-btn>
      <v-btn class="dark-btn" elevation="0" @click="submit()"> Entfernen </v-btn>
    </v-card-actions>
    <v-card-actions v-else>
      <v-spacer></v-spacer>
      <v-btn variant="outlined" @click="cancel()"> OK </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    data() {
        const successAlert: any = {
                type: "success",
                message: "Die Warnung wurde erfolgreich entfernt!",
              };

        return {
            submitted: false,
            successAlert
        };
    },
    methods: {
        submit() {
            this.submitted = true;

                console.log(this.validationError);

                const params = {
                  validationError: this.validationError,
                };
                axios
                  .delete(`/position/${this.positionId}/removeValidationError`, { params })
                  .then((response) => {
                    switch (response.status) {
                      case 200:
                        this.$store.commit("activateAlert", this.successAlert);
                        this.clearDialog();
                        this.$emit("validationErrorRemoved", response.data);
                        break;
                      default:
                        this.handleError();
                        break;
                    }
                  })
                  .catch(() => {
                    this.handleError();
                  });
        },
        handleError() {
            this.$store.commit("activateAlert", this.$store.state.errorAlert);
            this.submitted = false;
        },
        clearDialog() {
            this.submitted = false;
        },
        cancel() {
            this.$emit("close");
        }
    },
    props: {
        positionId: {
            type: Object as PropType<any>
        },
        validationError: {
            type: Object as PropType<any>
        },
        validationErrorType: {
            type: Object as PropType<any>
        }
    }
})

</script>

<style lang="scss" scoped>
.v-card-title {
  display: block; //weil der Card-Title sonst nicht centered wird
}
</style>
