import { RootState } from "~core/store/state";

export default {
  setMessage(state: RootState, value: string) {
    state.setMessage(value);
  },

  activateAlert(state: RootState, value: any) {
    state.activateAlert(value);
    state.alert.active = true;
    setTimeout(() => {
      state.alert.active = false;
    }, 5000);
  },

  setLoading: (state: RootState, value: boolean) => {
    state.loading = value;
  },
};
