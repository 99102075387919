import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigationDrawer = _resolveComponent("NavigationDrawer")!
  const _component_NavigationBar = _resolveComponent("NavigationBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_MySnackbar = _resolveComponent("MySnackbar")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_NavigationDrawer, {
        modelValue: _ctx.showDrawer,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showDrawer) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_NavigationBar, {
        "onClick:drawerIcon": _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDrawer = !_ctx.showDrawer))
      }),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }),
      _createVNode(_component_MySnackbar)
    ]),
    _: 1
  }))
}