import { RootState } from '@/core/store/state';
import { Module } from 'vuex';
import QuotationState from './state';
import { actions } from './actions';
import { mutations } from './mutations';
import { state } from './state';
import { getters } from './getters';

export const quotationModule: Module<QuotationState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
} 
