import BoqState from './state';
import { Boq } from '@/core/models/boq/Boq.model';

export default {
  setBoqList: (
    state: BoqState,
    value: Boq[]
  ) => {
    state.boqList = value;
  }
};
