<template>
  <v-form ref="myForm" @submit="submit($event)" :key="key">
    <v-card v-bind="{ loading: submitted, disabled: submitted }" class="text-center">
      <v-card-title>
        {{ $tc("COMPONENTS.POSITION.CHANGEPOSITIONDIALOG.UPDATE_POSITION") }}
      </v-card-title>
      <v-card-text>
        <v-select
          v-model="form.positionType"
          :label="$tc('COMPONENTS.POSITION.CHANGEPOSITIONDIALOG.TYPE')"
          :items="positionTypes"
        ></v-select>
        <v-textarea
          auto-grow
          v-if="form.positionType != 'POSITION'"
          v-model="form.positionLabel"
          :label="$tc('COMPONENTS.POSITION.CHANGEPOSITIONDIALOG.DESCRIPTION')"
        ></v-textarea>
        <v-text-field
          v-model="form.ownRef"
          :label="$tc('COMPONENTS.POSITION.CHANGEPOSITIONDIALOG.NUMBER')"
        ></v-text-field>
        <v-text-field
          v-if="checkIfGroup(form)"
          v-model="form.groupQuantity"
          :label="$tc('COMPONENTS.POSITION.CHANGEPOSITIONDIALOG.QUANTITY')"
          type="number"
        ></v-text-field>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn variant="outlined" @click="cancel()">
          {{ $tc("COMPONENTS.POSITION.CHANGEPOSITIONDIALOG.CANCEL") }}
        </v-btn>
        <v-btn class="dark-btn" elevation="0" type="submit">
          {{ $tc("COMPONENTS.POSITION.CHANGEPOSITIONDIALOG.SAVE") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    data() {
        const positionTypes: any = [
                {
                  value: "LOS",
                  title: this.$tc("COMPONENTS.POSITION.ADDPOSITIONDIALOG.POSITIONTYPES.GO"),
                },
                {
                  value: "GEWERK",
                  title: this.$tc("COMPONENTS.POSITION.ADDPOSITIONDIALOG.POSITIONTYPES.CRAFT"),
                },
                {
                  value: "TITEL",
                  title: this.$tc("COMPONENTS.POSITION.ADDPOSITIONDIALOG.POSITIONTYPES.TITLE"),
                },
                {
                  value: "GRUPPE",
                  title: this.$tc("COMPONENTS.POSITION.ADDPOSITIONDIALOG.POSITIONTYPES.GROUP"),
                },
                {
                  value: "GRUPPE_ALT",
                  title: this.$tc("COMPONENTS.POSITION.ADDPOSITIONDIALOG.POSITIONTYPES.ALT_GR"),
                },
                {
                  value: "GRUPPE_EVT",
                  title: this.$tc("COMPONENTS.POSITION.ADDPOSITIONDIALOG.POSITIONTYPES.EV_GR"),
                },
                {
                  value: "GRUPPE_BED",
                  title: this.$tc("COMPONENTS.POSITION.ADDPOSITIONDIALOG.POSITIONTYPES.BED_GR"),
                },
                {
                  value: "POSITION",
                  title: this.$tc("COMPONENTS.POSITION.ADDPOSITIONDIALOG.POSITIONTYPES.STANDARDPOSITION"),
                },
                {
                  value: "TEXT",
                  title: this.$tc("COMPONENTS.POSITION.ADDPOSITIONDIALOG.POSITIONTYPES.TEXT_POSITION"),
                },
              ];
        const form: {
                positionType: string | null;
                positionLabel: string | null;
                ownRef: string | null;
                groupQuantity: number | null;
              } = {
                positionType: null,
                positionLabel: null,
                ownRef: null,
                groupQuantity: null,
              };
        const negativeValueAlert: any = {
                type: "error",
                message: this.$tc("COMPONENTS.POSITION.CHANGEPOSITIONDIALOG.NEGATIVE_VALUE_ERROR"),
              };
        const currentPosition: any = undefined;
        const key: any = 0;

        return {
            key,
            currentPosition,
            negativeValueAlert,
            submitted: false,
            form,
            positionTypes
        };
    },
    mounted() {
        this.currentPosition = this.position;
        this.initFormData();
    },
    methods: {
        checkIfGroup(form: any): boolean {
            const groupValues = ["GRUPPE", "GRUPPE_EVT", "GRUPPE_BED", "GRUPPE_ALT"];
            return groupValues.includes(form.positionType);
        },
        handleNegativeValueError() {
            this.submitted = false;
            this.$store.commit("activateAlert", this.negativeValueAlert);
        },
        initFormData() {
            if (this.currentPosition) {
              this.form.positionType = this.currentPosition.positionType || "POSITION";
              this.form.ownRef = this.currentPosition.ownRef;
              this.form.positionLabel = this.currentPosition.label;
              this.form.groupQuantity = this.currentPosition.groupQuantity || null;
            }
            this.key++;
        },
        submit(event: any) {
            event.preventDefault();
                this.submitted = true;

                if (this.form.groupQuantity && this.form.groupQuantity < 1) {
                  this.handleNegativeValueError();
                  return;
                }

                const params = {
                  positionNumber: this.form.ownRef,
                  positionType: this.form.positionType,
                  label: this.form.positionLabel,
                  groupQuantity: this.checkIfGroup(this.form) ? this.form.groupQuantity : null,
                };
                this.form.groupQuantity = params.groupQuantity;
                axios
                  .post(`/position/${this.position.id}/modify`, params)
                  .then((response) => this.handleResponse(response))
                  .catch(() => this.handleError());
        },
        handleResponse(response: any) {
            this.$emit("onPositionChanged", response.data);
                this.submitted = false;

                this.currentPosition.positionType = this.form.positionType;
                this.currentPosition.positionNumber = this.form.ownRef;
                this.currentPosition.label = this.form.positionLabel;
                this.currentPosition.groupQuantity = this.form.groupQuantity;
        },
        handleError() {
            this.submitted = false;
        },
        cancel() {
            this.$emit("close");
            this.clearDialog();
        },
        clearDialog() {
            this.submitted = false;
            this.myForm.reset();
            this.initFormData();
        },
        onPositionChanged() {
            this.initFormData();
        }
    },
    props: {
        position: {
            type: Object as PropType<any>
        }
    },
    computed: {
        myForm() {
            return this.$refs["myForm"] as HTMLFormElement;
        }
    },
    watch: {
        "position": [{
            handler: "onPositionChanged"
        }]
    }
})

</script>

<style lang="scss" scoped>
.v-card-title {
  display: block; //weil der Card-Title sonst nicht centered wird
}
</style>
