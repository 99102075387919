<template>
  <v-form ref="myForm" @submit="submit($event)">
    <v-card height="70vh">
      <v-card-title>Artikel ändern</v-card-title>
      <v-card-text class="card1">
        <ArticleOverview
          :defaultValue="position.positionText"
          :preselected="selectedManual"
          @articleSelected="articleSelectedManual($event)"
        />
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn variant="outlined" @click="close"> Abbrechen </v-btn>
        <v-btn
          elevation="0"
          type="submit"
          :disabled="selected.length === 0"
          :class="{ 'dark-btn': selected.length !== 0 }"
        >
          Übernehmen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script lang="ts">
import ArticleOverview from "@/components/article/ArticleOverview.vue";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  components: {
    ArticleOverview,
  },
    data() {
        const selectedManual: any[] = [];
        const selectedAutomatic: any[] = [];

        return {
            selectedAutomatic,
            selectedManual,
            showAutomatic: false
        };
    },
    computed: {
        selected() {
            return this.showAutomatic ? this.selectedAutomatic : this.selectedManual;
        }
    },
    methods: {
        submit(event: any) {
            event.preventDefault();
            this.$emit("change", this.selected[0]);
            this.close();
        },
        articleSelectedAutomatic(selected: any) {
            this.selectedAutomatic = selected;
        },
        articleSelectedManual(selected: any) {
            this.selectedManual = selected;
        },
        emitResponse(response: any) {
            this.$emit("success", response);
        },
        close() {
            this.$emit("close");
        }
    },
    props: {
        position: {
            type: Object as PropType<any>
        },
        articleId: {
            type: Number
        }
    }
})

</script>

<style lang="scss" scoped>
.card1 {
  flex-grow: 1;
}

.button-container {
  flex-grow: 0;
}
</style>
