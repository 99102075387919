import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_file_input = _resolveComponent("v-file-input")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, {
    ref: "importForm",
    onSubmit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.submit($event)))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { loading: _ctx.loading }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_file_input, {
                label: _ctx.$tc('COMPONENTS.POSITION.IMPORTPOSITION.UPLOAD_FILE'),
                variant: "outlined",
                accept: ".xls,.xlsx,.csv",
                "show-size": "",
                multiple: false,
                density: "compact",
                "onUpdate:modelValue": _ctx.onFileSelected,
                rules: [(v) => !!v || _ctx.$tc('COMPONENTS.POSITION.POSITIONARTICLEDIALOG.RULES.NOT_EMPTY')]
              }, null, 8, ["label", "onUpdate:modelValue", "rules"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_divider),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                variant: "outlined",
                onClick: _ctx.closeImportDialog
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.POSITION.IMPORTPOSITION.CANCEL")), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_btn, {
                class: "dark-btn",
                elevation: "0",
                type: "submit"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.POSITION.IMPORTPOSITION.SAVE")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["loading"])
    ]),
    _: 1
  }, 512))
}