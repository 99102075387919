<template>
  <v-container>
    <v-row class="mb-1">
      <v-col>
        <h1>{{ $tc("COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.USER_ADMIN") }}</h1>
      </v-col>
      <v-col align-self="end" align="right">
        <v-btn @click="createNewUser" class="dark-btn">
          <v-icon start>mdi-plus</v-icon>
          {{ $t("COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.CREATE_USER") }}</v-btn
        >
      </v-col>
    </v-row>
    <v-container class="pa-0 elevation-5 rounded-lg">
      <v-data-table
        class="user-table"
        :headers="headers"
        :items="userList"
        @click:row="showUserDetails"
      >
        <template v-slot:[`item.role`]="{ item }">
          {{ getRoleText(item.role) }}
        </template>

        <template v-slot:[`item.marketId`]="{ item }">
          {{ getCountryName(item.marketId, marketList) }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <!-- Delete -->
          <ActionButton tooltip="Löschen" icon="mdi-delete" @buttonClicked="deleteUser(item)" />
        </template>
      </v-data-table>
    </v-container>
  </v-container>
</template>

<script lang="ts">
import { User } from "~core/models/user/User.model";
import { BoqTableHeader } from "@/core/interfaces/boq-table-header.interface";
import { getRoleText } from "@/components/user-administration/mappings/roles.mapping";
import ActionButton from "@/shared/components/action-button.component.vue";
import { Market } from "@/core/models/market/market.model";
import * as MarketService from "@/core/services/market/market.service";
import { defineComponent } from "vue";
import type { VDataTableRowClickData } from '@/core/models/vuetify/vuetify.model';

export default defineComponent({
  components: {
    ActionButton,
  },
    data() {
        const headers: BoqTableHeader[] = [
                {
                  title: "ID",
                  value: "id",
                },
                {
                  title: this.$tc("COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.FIRST_NAME"),
                  value: "firstname",
                },
                {
                  title: this.$tc("COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.LAST_NAME"),
                  value: "lastname",
                },
                {
                  title: this.$tc("COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.E_MAIL_ADDRESS"),
                  value: "email",
                },
                {
                  title: this.$tc("COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.ROLE"),
                  value: "role",
                },
                {
                  title: this.$tc("COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.COUNTRY"),
                  value: "marketId",
                },
                {
                  title: this.$tc("COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.SALES_TEAM"),
                  value: "teamName",
                },
                {
                  title: this.$tc("COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.USERNAME_SAP"),
                  value: "sapNumber",
                },
                {
                  title: this.$tc("COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.ACTIONS"),
                  value: "actions",
                  width: 110,
                },
              ];

        return {
            headers
        };
    },
    mounted() {
        this.loadUsers();
        this.loadMarkets();
    },
    methods: {
        getCountryName(marketId: number, marketList: Market[]) {
            return MarketService.getCountryName(marketId, marketList);
        },
        getRoleText(role: string) {
            return getRoleText(role);
        },
        showUserDetails(clickEvent: MouseEvent, data: VDataTableRowClickData<User>) {
            this.$router.push(`/user-administration/${data.item.id}`);
        },
        createNewUser() {
            this.$router.push(`/user-administration/-1`);
        },
        loadUsers() {
            return this.$store.dispatch("user/loadUserList");
        },
        async loadMarkets() {
            await this.$store.dispatch("market/loadMarketList");
        },
        async deleteUser(user: User) {
            await this.$store.dispatch("user/deleteUser", user.id);
            await this.loadUsers();
        }
    },
    computed: {
        userList(): User[] {
            return this.$store.getters["user/userList"];
        },
        marketList(): Market[] {
            return this.$store.getters["market/market"];
        }
    }
})

</script>

<style lang="scss">
@use "@/style/styles";

.user-table {
  thead {
    background: styles.$primary;
    span {
      color: white;
    }
  }
}
</style>
