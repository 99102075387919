<template>
  <v-card>
    <v-card-title v-if="addMode">{{
      $tc("COMPONENTS.SETTINGS.KEYWORDDIALOG.ADD_KEYWORD")
    }}</v-card-title>
    <v-card-title v-else>{{
      $tc("COMPONENTS.SETTINGS.KEYWORDDIALOG.CHANGE_KEYWORD")
    }}</v-card-title>

    <v-card-text>
      <v-row align="center">
        <v-text-field
          ref="keyword-input"
          class="mr-4"
          v-model="internalKeyword.value"
          :label="$tc('COMPONENTS.SETTINGS.KEYWORDDIALOG.KEYWORD')"
          @keydown.enter="$emit('buttonClicked', internalKeyword)"
        >
        </v-text-field>

        <v-select
          v-if="currentUser.role == 'ROLE_ADMIN'"
          ref="keyword-input"
          v-model="internalKeyword.marketId"
          :items="marketList"
          :label="$tc('COMPONENTS.SETTINGS.KEYWORDDIALOG.COUNTRY')"
          item-value="id"
          item-title="name"
          @keydown.enter="$emit('buttonClicked', internalKeyword)"
        >
        </v-select>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn @click="$emit('buttonClicked', internalKeyword)" class="dark-btn">
        <div v-if="addMode">
          <v-icon start>mdi-plus</v-icon>
          {{ $tc("COMPONENTS.SETTINGS.KEYWORDDIALOG.ADD_KEYWORD") }}
        </div>

        <div v-else>
          <v-icon start>mdi-pencil</v-icon>
          {{ $tc("COMPONENTS.SETTINGS.KEYWORDDIALOG.CHANGE") }}
        </div>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { Keyword } from "@/core/models/keyword/Keyword.model";
import { Market } from "@/core/models/market/market.model";
import { User } from "@/core/models/user/User.model";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    data() {
        const internalKeyword: Keyword = { ...this.keyword };
        const countryObject: { marketId: number; language: string }[] = [];

        return {
            isAdmin: false,
            countryObject,
            internalKeyword
        };
    },
    async mounted() {
        setTimeout(() => this.input?.focus(), 0);
    },
    props: {
        addMode: { default: true,
            type: Boolean
        },
        keyword: { default: () => new Keyword(),
            type: Object as PropType<Keyword>
        }
    },
    computed: {
        input() {
            return this.$refs["keyword-input"] as HTMLElement;
        },
        currentUser(): User {
            return this.$store.getters["user/currentUser"];
        },
        marketList(): Market[] {
            return this.$store.getters["market/market"];
        }
    }
})

</script>
