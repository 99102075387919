export class SalesView implements SalesDto {
  salesOrganization = "";
  distributionChannel = "";
  division = "";
  kundennummer = "";

  constructor(dto?: SalesDto) {
    if (dto) {
      this.salesOrganization = dto.salesOrganization;
      this.distributionChannel = dto.distributionChannel;
      this.division = dto.division;
      this.kundennummer = dto.kundennummer;
    }
  }
}

export interface SalesDto {
  salesOrganization: string;
  distributionChannel: string;
  division: string;
  kundennummer: string;
}
