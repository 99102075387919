import { ClientDto } from "../master-data/Client.model";
import { ContactDto } from "../master-data/Contact.model";

export default class Quotation {
  id = "";
  exportId = "";
  exportUri = "";
  clientId = "";
  clientName = "";
  contactName = "";
  lastEditor = "";
  productHierarchy = "";
  productHierarchyFirst = "";
  status = "";
  version = "";

  constructor(quotationDto?: QuotationDto) {
    if (quotationDto) {
      this.id = quotationDto.id;
      this.exportId = quotationDto.exportId;
      this.exportUri = quotationDto.exportUri;
      if (quotationDto.client) {
        this.clientId = quotationDto.client.kundennummer;
        this.clientName = quotationDto.client.name;
      }
      if (quotationDto.contact) {
        this.contactName = quotationDto.contact.businessPartnerFullName;
      }
      this.lastEditor = quotationDto.lastEditor;
      if (quotationDto.productHierarchy) {
        this.productHierarchy = quotationDto.productHierarchy;
        const productHierarchy = quotationDto.productHierarchy.split(", ");
        this.productHierarchyFirst = productHierarchy.length > 0 ? productHierarchy[0] : "";
      }
      this.status = Quotation.getStatus(quotationDto.status);
      this.version = quotationDto.version;
    }
  }

  static getStatus(status: string): string {
    switch (status) {
      case "INIT":
        return "Initialisiert";
      case "AVAILABLE":
        return "Verfügbar";
      case "QUEUED":
        return "Wartet";
      case "CONVERTING":
        return "Konvertierung";
      case "FAILED":
        return "Fehlgeschlagen";
      case "CANCELLED":
        return "Abgebrochen";
      case "EXPORTED":
        return "Exportiert";
      default:
        return status;
    }
  }
}

export interface QuotationDto {
  id: string;
  exportId: string;
  exportUri: string;
  client: ClientDto;
  contact: ContactDto;
  lastEditor: string;
  productHierarchy: string;
  status: string;
  version: string;
}
