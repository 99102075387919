import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createElementVNode("h1", null, _toDisplayString(_ctx.$tc("COMPONENTS.USER_ADMIN.MAPPINGS.USER_PROFILE.TITLE")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_divider, { class: "mb-3" }),
      _createVNode(_component_v_container, { class: "elevation-5 rounded-lg" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_form, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        label: _ctx.$tc('COMPONENTS.USER_ADMIN.MAPPINGS.USER_PROFILE.FIRSTNAME'),
                        type: "text",
                        disabled: "",
                        modelValue: _ctx.currentUser.firstname,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentUser.firstname) = $event))
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        label: _ctx.$tc('COMPONENTS.USER_ADMIN.MAPPINGS.USER_PROFILE.LASTNAME'),
                        type: "text",
                        disabled: "",
                        modelValue: _ctx.currentUser.lastname,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentUser.lastname) = $event))
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        label: _ctx.$tc('COMPONENTS.USER_ADMIN.MAPPINGS.USER_PROFILE.E-MAIL'),
                        type: "text",
                        disabled: "",
                        modelValue: _ctx.currentUser.email,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentUser.email) = $event))
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_select, {
                        label: _ctx.$tc('COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.ROLE'),
                        type: "text",
                        disabled: "",
                        modelValue: _ctx.currentUser.role,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.currentUser.role) = $event)),
                        items: _ctx.roles
                      }, null, 8, ["label", "modelValue", "items"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_select, {
                        label: _ctx.$tc('COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.COUNTRY'),
                        type: "text",
                        items: _ctx.marketList,
                        "item-title": "name",
                        "item-value": "id",
                        disabled: "",
                        modelValue: _ctx.currentUser.marketId,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.currentUser.marketId) = $event))
                      }, null, 8, ["label", "items", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_select, {
                        label: _ctx.$tc('COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.SALES_TEAM'),
                        type: "text",
                        modelValue: _ctx.currentUser.teamId,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.currentUser.teamId) = $event)),
                        items: _ctx.teams,
                        disabled: "",
                        "item-value": "id",
                        "item-title": "name"
                      }, null, 8, ["label", "modelValue", "items"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        label: _ctx.$tc('COMPONENTS.USER_ADMIN.MAPPINGS.USER_PROFILE.USERNAME_SAP'),
                        type: "text",
                        modelValue: _ctx.currentUser.sapNumber,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.currentUser.sapNumber) = $event))
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.updateUserProfile())),
                        class: "dark-btn"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.USER_ADMIN.MAPPINGS.USER_PROFILE.SAVE")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}