import { Keyword } from "@/core/models/keyword/Keyword.model";
import SettingsState from "./state";

export const mutations = {
  setKeywords: (state: SettingsState, value: Keyword[]) => {
    state.keywords = value;
  },
  setUnits: (state: SettingsState, value: Keyword[]) => {
    state.units = value;
  },
  setProductHierarchy: (state: SettingsState, value: any) => {
    state.productHierarchy = value;
  },
};
