import { i18nGlobalTc } from "@/i18n";
export class Market implements MarketDTO {
  id = -1;
  language = "";
  name = "";

  translatedValue(language: string) {
    return i18nGlobalTc("LANGUAGEKEYS." + language);
  }

  constructor(dto?: MarketDTO) {
    if (dto) {
      this.id = dto.id;
      this.language = dto.language;
      this.name = this.translatedValue(dto.language);
    }
  }
}

export interface MarketDTO {
  id: number;
  language: string;
  name: string;
}
