<template>
  <v-container class="customer-selection">
    <v-alert
      v-if="Object.keys({ ...getConfig.filter }).length === 0"
      class="mt-3"
      density="compact"
      variant="text"
      type="info">
      {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.OPPORTUNITY_SELECTION.USE_FILTER") }}
    </v-alert>
    <div class="table-container">
      <BoqDataTable
        ref="dataTable"
        :headers="headers"
        :items="opportunityList"
        :singleSelect="true"
        :showSelect="true"
        itemKey="number"
        :loading="loading"
        v-model:get-config="getConfig"
        :debounceTime="0"
        @itemSelected="onItemSelected"
        @enterPressed="loadOpportunities"
      >
      </BoqDataTable>
    </div>
    <v-row class="flex-grow-0" justify="start">
      <v-col cols="auto">
        <v-btn
          variant="outlined"
          prepend-icon="mdi-magnify"
          :disabled="loading"
          @click="loadOpportunities()"
        >
          {{ $tc('COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.OPPORTUNITY_SELECTION.SEARCH_AGAIN') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import BoqDataTable from "@/shared/components/data-table.component.vue";
import GetConfig from "@/core/services/base/get.config";
import { OpportunityView } from "@/core/models/master-data/Opportunity.model";
import { BoqTableHeader } from "@/core/interfaces/boq-table-header.interface";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    BoqDataTable,
  },
    data() {
        const errorAlert: any = {
                type: "error",
                message: this.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.OPPORTUNITY_SELECTION.ERROR"),
              };
        const noContentAlert: any = {
                type: "info",
                message: this.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.OPPORTUNITY_SELECTION.NO_CONTENT"),
              };
        const headers: BoqTableHeader[] = [
                {
                  value: "number",
                  title: this.$tc(
                    "COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.HEADERS.OPPORTUNITY_SELECTION.NUMBER"
                  ),
                  filterable: true,
                },
                {
                  value: "name",
                  title: this.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.HEADERS.OPPORTUNITY_SELECTION.NAME"),
                  filterable: true,
                },
                {
                  value: "street",
                  title: this.$tc(
                    "COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.HEADERS.OPPORTUNITY_SELECTION.STREET"
                  ),
                  filterable: true,
                },
                {
                  value: "zip",
                  title: this.$tc(
                    "COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.HEADERS.OPPORTUNITY_SELECTION.POSTAL_CODE"
                  ),
                  filterable: true,
                },
                {
                  value: "city",
                  title: this.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.HEADERS.OPPORTUNITY_SELECTION.CITY"),
                  filterable: true,
                },
                {
                  value: "country",
                  title: this.$tc(
                    "COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.HEADERS.OPPORTUNITY_SELECTION.COUNTRY"
                  ),
                  filterable: true,
                },
                // {
                //   value: "description",
                //   title: this.$tc(
                //     "COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.HEADERS.OPPORTUNITY_SELECTION.DECRIPTION"
                //   ),
                //   filterable: false,
                // },
                {
                  value: "date",
                  title: this.$tc(
                    "COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.HEADERS.OPPORTUNITY_SELECTION.DATE_OF_CREATION"
                  ),
                  filterable: false,
                  type: "date",
                },
                // {
                //   value: "inactive",
                //   title: this.$tc(
                //     "COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.HEADERS.OPPORTUNITY_SELECTION.INACTIVE"
                //   ),
                //   filterable: false,
                // },
              ];
        const getConfig: GetConfig = GetConfig.default();

        return {
            getConfig,
            headers,
            noContentAlert,
            errorAlert
        };
    },
    mounted() {
        this.getConfig.filter = {
          number: this.initialValue,
        };
        (this.$refs.dataTable as InstanceType<typeof BoqDataTable>).initFiltersFromGetConfig(this.getConfig);
        if (this.initialValue) this.loadOpportunities();
    },
    unmounted() {
        this.$store.dispatch("masterdata/clearOpportunityList");
    },
    methods: {
        loadOpportunities() {
            if (this.loading) return;
            this.$store.dispatch("masterdata/loadOpportunityList", this.getConfig);
        },
        onItemSelected(item: OpportunityView[]) {
            this.$emit("itemSelected", item[0]);
        },
        opportunityListChanged(opportunityList: OpportunityView[]) {
            if (opportunityList.length !== 0) {
              const opportunityListItem = opportunityList.find((e) => e.number === this.initialValue);
              if (opportunityListItem) {
                (this.$refs.dataTable as InstanceType<typeof BoqDataTable>).selectPreselected(opportunityListItem);
                this.onItemSelected([opportunityListItem])
              }
            }
        }
    },
    props: {
        initialValue: { default: "",
            type: String
        }
    },
    watch: {
        "opportunityList": [{
            handler: "opportunityListChanged"
        }]
    },
    computed: {
        opportunityList(): OpportunityView[] {
            return this.$store.getters["masterdata/opportunityList"];
        },
        loading(): boolean {
            return this.$store.getters["loading"];
        }
    }
})

</script>

<style lang="scss" scoped>
.customer-selection {
  flex-grow: 1;
  height: 1%;
  display: flex;
  flex-direction: column;
}

.table-container {
  overflow-y: auto;
  flex-grow: 1;
  flex-shrink: 1;
}
</style>
