import { ActionTree } from 'vuex';
import { RootState } from '@/core/store/state';
import PositionState from './state';
import * as PositionService from '@/core/services/position/position.service';


export const actions: ActionTree<PositionState, RootState> = {
  loadAllPositions(context, quotationId) {
    PositionService.loadAllPositions(quotationId).then(response => {
      context.commit('setPositions', response.items);
    });
  },

  loadPositionsToplevel(context, quotationId) {
    PositionService.loadPositionsToplevel(quotationId).then(response => {
      context.commit('setPositions', response);
    });
  },

  loadPositionChildren(context, {quotationId, positionId}) {
    return PositionService.loadPositionChildren(quotationId, positionId).then(response => {
      context.commit('setPositionChildren', response);
    });
  },

  clearPositions(context) {
    context.commit('setPositions', null);
  },

  filterPositions(context, { quotationId, pageFilter }) {
    PositionService.filterPositions(quotationId, pageFilter);
  },
};
