<template>
  <v-card height="90vh">
    <v-card-title class="d-flex align-center">
      <div>{{ $tc("COMPONENTS.POSITION.POSITION_FILTER.PDF_VIEW") }}</div>
      <v-btn class="dark-btn ml-auto" @click="close">{{
        $tc("COMPONENTS.POSITION.POSITION_FILTER.BACK")
      }}</v-btn>
    </v-card-title>
    <v-card-text class="dialog-content">
      <v-row class="fill-height">
        <v-col cols="3" class="col-left fill-height">
          <v-card class="main-card keywords overflow-y-auto">
            <v-card-text>{{ $tc("COMPONENTS.POSITION.POSITION_FILTER.KEYWORDS") }}</v-card-text>
            <v-divider></v-divider>
            <div
              class="keyword__item d-flex align-center"
              v-for="(keyword, index) in keywords"
              :key="index"
            >
              <v-checkbox v-model="selectedKeywords" :value="keyword.value" hide-details />
              <span class="ml-3">{{ keyword.value }}</span>
              <span class="ml-1">{{
                `(${
                  keywordCount.get(keyword.value.toLowerCase())
                    ? keywordCount.get(keyword.value.toLowerCase())
                    : 0
                })`
              }}</span>
              <v-icon
                class="ml-auto"
                @click.stop="scrollToKeyword = { positionNumber: keyword.value.toLowerCase() }"
                >mdi-target</v-icon
              >
            </div>
          </v-card>
          <v-spacer></v-spacer>
          <v-card class="main-card mt-3 page-selection">
            <v-card-text>{{
              $tc("COMPONENTS.POSITION.POSITION_FILTER.PAGE_SELECTION")
            }}</v-card-text>
            <v-divider></v-divider>
            <v-card-text>
              <v-radio-group v-model="selectedOption" density="compact">
                <v-radio
                  v-for="option in radioOptions"
                  :key="option.value"
                  :label="option.text"
                  :value="option.value"
                ></v-radio>
              </v-radio-group>
              <v-form>
                <v-text-field
                  v-model="internalPageFilter"
                  label=""
                  placeholder="1, 10-20, 99"
                  variant="outlined"
                  density="compact"
                  @click="selectedOption = 'Relevante Seiten'"
                ></v-text-field>
              </v-form>
              <v-btn class="dark-btn ml-auto" @click="save">{{
                $tc("COMPONENTS.POSITION.POSITION_FILTER.APPLY")
              }}</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="9" class="fill-height">
          <PdfView
            :boqId="boqId"
            :quotationId="quotationId"
            :filterView="true"
            :showOverlay="true"
            v-model:scrollToElement="scrollToKeyword"
            :keywordFilter="selectedKeywords"
            @keywordCount="keywordCount = $event"
          >
            <template v-slot:scrolling-overlay="{ box, makePositionId, transform }">
              <v-sheet
                class="id-overlay-sheet"
                :id="makePositionId(box.value)"
                :width="box.xend - box.xstart"
                :height="box.yend - box.ystart"
                :data-translate-y="box.ystart"
                :data-page="box.page"
                :data-line="box.line"
                :data-word="box.word"
                :style="transform(box)"
                color="yellow-accent-2"
              >
              </v-sheet>
            </template>
          </PdfView>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import PdfView from "@/components/pdfviewer/PdfView.vue";
import { Keyword } from "@/core/models/keyword/Keyword.model";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    PdfView,
  },
    data() {
        const scrollToKeyword: any = null;
        const keywordCount: Map<string, number> = new Map();
        const selectedKeywords: string[] = [];
        const radioOptions: { text: string; value: string }[] = [
                { text: this.$tc("COMPONENTS.POSITION.POSITION_FILTER.ALL_PAGES"), value: "Alle Seiten" },
                {
                  text: this.$tc("COMPONENTS.POSITION.POSITION_FILTER.RELEVANT_PAGES"),
                  value: "Relevante Seiten",
                },
              ];

        return {
            radioOptions,
            selectedOption: "Alle Seiten",
            selectedKeywords,
            internalPageFilter: "",
            keywordCount,
            scrollToKeyword
        };
    },
    mounted() {
        this.loadKeywords();

            if (this.pageFilter) {
              this.internalPageFilter = this.pageFilter;
              this.selectedOption = "Relevante Seiten";
            }
    },
    unmounted() {
        this.$store.dispatch("settings/clearKeywords");
    },
    methods: {
        async loadKeywords() {
            if (this.$store.state.settings.keywords.length === 0) {
              await this.$store.dispatch("settings/loadKeywords");
            }
            this.selectedKeywords = this.keywords.map((element) => element.value);
        },
        close() {
            this.$emit("close");
        },
        save() {
            this.selectedOption === "Alle Seiten"
              ? this.$emit("save", "")
              : this.$emit("save", this.internalPageFilter);
            //Test
            this.$emit("close");
        }
    },
    props: {
        boqId: {
            type: Number
        },
        quotationId: {
            type: Number
        },
        pageFilter: {
            type: String
        }
    },
    computed: {
        keywords(): Keyword[] {
            return this.$store.getters["settings/keywords"];
        }
    }
})

</script>

<style lang="scss" scoped>
@use "@/style/styles.scss";

.keywords {
  height: 59%;
}

.keyword__item {
  height: 50px;
  padding: 0 12px;
}

.col-left {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
}

.col-right {
  display: flex;
  flex-direction: column;
}

.dialog-content {
  height: calc(100% - 58px);
}

.page-selection {
  height: auto;
}

::v-deep .v-input {
  font-size: 0.875rem !important;
}

::v-deep .v-input .v-label {
  font-size: 0.875rem !important;
}

.id-overlay-sheet {
  opacity: 0.25;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
