<template>
  <v-card>
    <v-card-title v-if="addMode">{{ $tc("COMPONENTS.SETTINGS.UNITDIALOG.ADD_UNIT") }}</v-card-title>
    <v-card-title v-else>{{ $tc("COMPONENTS.SETTINGS.UNITDIALOG.CHANGE_UNIT") }}</v-card-title>

    <v-card-text>
      <v-row align="center">
        <v-text-field
          ref="unit-input"
          class="mr-4"
          v-model="internalUnit.value"
          :label="$tc('COMPONENTS.SETTINGS.UNITDIALOG.UNIT')"
          @keydown.enter="$emit('buttonClicked', internalUnit)"
        >
        </v-text-field>

        <v-select
          v-if="currentUser.role == 'ROLE_ADMIN'"
          ref="unit-input"
          v-model="internalUnit.marketId"
          :items="marketList"
          :label="$tc('COMPONENTS.SETTINGS.UNITDIALOG.COUNTRY')"
          item-value="id"
          item-title="name"
          @keydown.enter="$emit('buttonClicked', internalUnit)"
        >
        </v-select>
      </v-row>
    </v-card-text>

    <v-card-actions class="justify-center">
      <v-btn @click="$emit('buttonClicked', internalUnit)" class="dark-btn">
          <div v-if="addMode">
            <v-icon start>mdi-plus</v-icon>
            {{ $tc("COMPONENTS.SETTINGS.UNITDIALOG.ADD_UNIT") }}
          </div>

          <div v-else>
            <v-icon start>mdi-pencil</v-icon>
            {{ $tc("COMPONENTS.SETTINGS.UNITDIALOG.CHANGE") }}
          </div>
        </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { Keyword } from "@/core/models/keyword/Keyword.model";
import { Market } from "@/core/models/market/market.model";
import { User } from "@/core/models/user/User.model";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    data() {
        const internalUnit: Keyword = { ...this.unit };

        return {
            isAdmin: false,
            internalUnit
        };
    },
    async mounted() {
        setTimeout(() => this.input?.focus(), 0);
    },
    props: {
        addMode: { default: true,
            type: Boolean
        },
        unit: { default: () => new Keyword(),
            type: Object as PropType<Keyword>
        }
    },
    computed: {
        input() {
            return this.$refs["unit-input"] as HTMLElement;
        },
        marketList(): Market[] {
            return this.$store.getters["market/market"];
        },
        currentUser(): User {
            return this.$store.getters["user/currentUser"];
        }
    }
})

</script>
