import { ActionTree } from "vuex";
import * as MarketService from "@/core/services/market/market.service";
import { RootState } from "@/core/store/state";
import GetConfig from "@/core/services/base/get.config";
import MarketState from "./state";

export const actions: ActionTree<MarketState, RootState> = {
  loadMarketList(context, config?: GetConfig) {
    MarketService.loadMarketList(config).then((response) => {
      context.commit("setMarketList", response);
    });
  },
};
