
import store from "@/core/store";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import GetConfig, { applyGetConfig } from "../base/get.config";
import { Article, ArticleDto }  from "@/core/models/article/Article.model";

const resource = "article"

export function loadArticleList(getConfig?: GetConfig): Promise<any> {
  store.commit('setLoading', true);
  let config: AxiosRequestConfig = {};
  if (getConfig) {
    config = applyGetConfig(getConfig, config);
    config.params.page = getConfig.page;
    config.params.itemsPerPage = getConfig.perPage;
  }

  return axios
    .get(`/${resource}/find`, config)
    .then((response: AxiosResponse<ArticleDto[]>) => {
      return (response && response.data) ? response.data : new Article();
      // return (response && response.data) ? response.data.map(e => new Article(e)) : new Article();
    })
    .finally(() => store.commit('setLoading', false))
}
