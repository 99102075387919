<template>
  <v-container fluid class="h-100">
    <v-row dense>
      <div class="d-flex quotation__header">
        <v-col v-for="item in header" :key="item.id" class="mr-5" cols="auto">
          <b>{{ item.title }}</b>
          <br>
          {{ item.value }}

          <v-tooltip v-if="item.id === 1" location="bottom">
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                icon
                class="pa-0 ma-0"
                width="19"
                height="19"
                @click.stop="restartConversion()"
              >
                <v-icon color="green" size="19">
                  mdi-restart
                </v-icon>
              </v-btn>
            </template>
            {{ $tc("COMPONENTS.QUOTATION.QUOTATIONDETAIL.RESTART_DETECTION") }}
          </v-tooltip>
        </v-col>
        <v-col cols="auto">
          <b>{{ $tc("COMPONENTS.QUOTATION.QUOTATIONDETAIL.PROCESSING_PROGRESS") }}</b>
          <br>
          {{ validationValue ? (validationValue * 100).toFixed(2) : "0" }}%
        </v-col>
      </div>
      <v-col class="ml-auto" align-self="center" align="right" cols="auto">
        <ExportQuotationDialog
          :boq-id="boqId ? +boqId : undefined"
          :quotation-id="quotationId ? +quotationId : undefined"
          :export-id="quotationDetail ? quotationDetail.exportId : undefined"
          @exported="loadQuotationDetail"
          @update:quotation-id="$emit('update:quotationId', $event)"
        />
        <v-btn class="dark-btn ml-2" @click="navigateToQuotationList">
          <v-icon color="white" start>
            mdi-arrow-left
          </v-icon>
          {{ $tc("COMPONENTS.QUOTATION.QUOTATIONDETAIL.BACK") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-divider />
    <v-row class="quotation__detail">
      <v-col class="fill-height" cols="6">
        <div class="quotation__masterdata-wrapper px-6 fill-height">
          <v-row align-content="center" class="px-3 py-4 sticky">
            <v-col>
              <v-btn
                style="border-radius: 5px 0px 0px 5px"
                :class="{ 'dark-btn': !showPositions }"
                :variant="showPositions ? 'outlined' : undefined"
                elevation="0"
                :disabled="hideMode"
                @click="showPositions = false"
              >
                {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.MASTERDATA.MASTER_DATA") }}
              </v-btn>

              <v-btn
                style="border-radius: 0px 5px 5px 0px"
                :variant="!showPositions ? 'outlined' : undefined"
                :class="{ 'dark-btn': showPositions }"
                elevation="0"
                @click="showPositions = true"
              >
                {{ $tc("COMPONENTS.POSITION.POSITION.POSITION_DETECTION") }}
              </v-btn>
            </v-col>
            <!-- <v-spacer></v-spacer> -->
            <v-col v-if="showPositions && !hideMode" cols="auto">
              <v-btn variant="text"
                icon
                height="36"
                :disabled="actionLog?.undoActions.length == 0"
                @click="undo(0)"
              >
                <v-icon>mdi-undo</v-icon>
              </v-btn>
              <v-menu>
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    variant="text"
                    icon
                    height="36"
                    :disabled="actionLog?.undoActions.length == 0"
                  >
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in actionLog?.undoActions.slice().reverse()"
                    :key="index"
                    :class="{
                      actionLogHover: actionLogHoverIndex != null && index <= actionLogHoverIndex,
                    }"
                    :title="item.message.key
                      ? $tc('COMPONENTS.QUOTATION.QUOTATIONDETAIL.' + item.message.key,
                            item.message.quantity,
                            item.message.objects
                      )
                      : item.message.message"
                    @mouseover="onActionLogMouseOver(index)"
                    @mouseleave="onActionLogMouseLeave()"
                    @click="undo(index)"
                  />
                </v-list>
              </v-menu>
              <v-menu>
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    variant="text"
                    icon
                    height="36"
                    :disabled="actionLog?.redoActions.length == 0"
                  >
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in actionLog?.redoActions.slice().reverse()"
                    :key="index"
                    :class="{
                      actionLogHover: actionLogHoverIndex != null && index <= actionLogHoverIndex,
                    }"
                    @mouseover="onActionLogMouseOver(index)"
                    @mouseleave="onActionLogMouseLeave()"
                  >
                    <v-list-item-title @click="redo(index)">
                      {{
                        item.message.key
                          ? $tc(
                            "COMPONENTS.QUOTATION.QUOTATIONDETAIL." + item.message.key,
                            item.message.quantity,
                            item.message.objects
                          )
                          : item.message.message
                      }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn variant="text"
                icon
                height="36"
                :disabled="actionLog?.redoActions.length == 0"
                @click="redo(0)"
              >
                <v-icon>mdi-redo</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="quotation__masterdata">
            <v-col>
              <div v-if="!showPositions" class="overflow-y-auto fill-height">
                <Masterdata :quotation="quotationDetail" />
              </div>
              <div v-else>
                <Position
                  :boq-id="boqId ? +boqId : undefined"
                  :quotation-id="quotationId ? +quotationId : undefined"
                  :page-filter="quotationDetail.pageFilter"
                  :position-ids-to-open="positionIdsToOpen"
                  :position-from-overlay="positionFromOverlay"
                  @scroll-in-pdf-clicked="onScrollInPdfClicked"
                  @progress-updated="handleProgressUpdated()"
                  @reload-pdf="reloadTriggered = true"
                  @change-show-position="changeShowPosition"
                  @change-hide-mode="changeHideMode"
                />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="6" class="fill-height">
        <PdfView
          v-model:scroll-to-element="scrollToPositionNumber"
          v-model:reload-triggered="reloadTriggered"
          :boq-id="boqId ? +boqId : undefined"
          :quotation-id="quotationId ? +quotationId : undefined"
          class="pt-4"
          @position-clicked="positionClicked"
          @add-from-overlay="openAddFromOverlayPopup($event)"
        >
          <template #scrolling-overlay="{ box, makePositionId }">
            <v-sheet
              :id="makePositionId(box.value)"
              class="id-overlay-sheet"
              :width="box.xend - box.xstart"
              :height="box.yend - box.ystart"
              :data-translate-y="box.ystart"
              :data-page="box.page"
              :data-line="box.line"
              :data-word="box.word"
            />
          </template>
        </PdfView>
      </v-col>
    </v-row>
    <v-dialog v-model="showDialogAddFromOverlay" width="400px">
      <AddPositionFromOverlayDialog
        v-if="showDialogAddFromOverlay"
        :clicked-box="clickedBox"
        :quotation-id="quotationId ? +quotationId : undefined"
        @close="showDialogAddFromOverlay = false"
        @position-added-from-overlay="addPositionToTreeview($event)"
      />
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import Masterdata from "@/components/master-data/MasterData.vue";
import Position from "@/components/position/PositionView.vue";
import ExportQuotationDialog from "@/components/quotation/ExportQuotationDialog.vue";
import AddPositionFromOverlayDialog from "@/components/quotation/AddPositionFromOverlayDialog.vue";
import PdfView from "@/components/pdfviewer/PdfView.vue";
import { formatDate } from "@/core/helper/date-time";
import axios from "axios";
import { ActionLogDto } from "@/core/models/quotation/quotation-detail.model";
import { defineComponent, ref } from "vue";

export default defineComponent({
  components: {
    Masterdata,
    Position,
    ExportQuotationDialog,
    AddPositionFromOverlayDialog,
    PdfView,
  },
    props: {
        boqId: {
            type: String
        },
        quotationId: {
            type: String
        }
    },
    data() {
        const timer: any = undefined;
        const clickedBox: any = {};
        const scrollToPositionNumber: any = null;
        const positionIdsToOpen: number[] = [];
        const actionLogHoverIndex = ref<number | null>(null);

        return {
            actionLogHoverIndex,
            hideMode: false,
            showPositions: false,
            positionIdsToOpen,
            scrollToPositionNumber,
            clickedBox,
            showDialogAddFromOverlay: false,
            reloadTriggered: false,
            positionFromOverlay: null,
            timer
        };
    },
    computed: {
        header() {
            return [
              {
                id: 1,
                title: this.$tc("COMPONENTS.QUOTATION.QUOTATIONDETAIL.BOQ_NUMBER"),
                value: this.boqId,
              },
              {
                id: 2,
                title: this.$tc("COMPONENTS.QUOTATION.QUOTATIONDETAIL.QUOTATION_NR"),
                value: this.quotationDetail ? this.quotationDetail.id : "-",
              },
              {
                id: 3,
                title: this.$tc("COMPONENTS.QUOTATION.QUOTATIONDETAIL.SOURCE"),
                value:
                  this.quotationDetail && this.quotationDetail.billOfQuantityDto
                    ? this.quotationDetail.billOfQuantityDto.source
                    : "-",
              },
              {
                id: 4,
                title: this.$tc("COMPONENTS.QUOTATION.QUOTATIONDETAIL.SALES_TEAM"),
                value:
                  this.quotationDetail && this.quotationDetail.billOfQuantityDto
                    ? this.quotationDetail.billOfQuantityDto.editingGroup
                    : "-",
              },
              {
                id: 5,
                title: this.$tc("COMPONENTS.QUOTATION.QUOTATIONDETAIL.RECEIVE_DATE"),
                value:
                  this.quotationDetail && this.quotationDetail.billOfQuantityDto
                    ? this.transformToDateString(this.quotationDetail.billOfQuantityDto.date)
                    : "-",
              },
              {
                id: 6,
                title: this.$tc("COMPONENTS.QUOTATION.QUOTATIONDETAIL.AUTHOR"),
                value: this.quotationDetail && this.quotationDetail ? this.quotationDetail.lastEditor : "-",
              },
              {
                id: 7,
                title: "Export-ID",
                value:
                  this.quotationDetail && this.quotationDetail.exportId
                    ? this.quotationDetail.exportId
                    : "-",
              },
            ];
        },
        quotationDetail(): any {
            return this.$store.getters["quotation/quotationDetail"];
        },
        actionLog(): ActionLogDto | null {
            return this.$store.getters["quotation/actionLog"];
        },
        validationValue(): number {
            return this.$store.getters["quotation/validationValue"];
        }
    },
    mounted() {
        this.loadQuotationDetail();
        this.updateValidationValue();
        this.loadActionLog();
        if (this.quotationId) {
          this.loadMasterdata(+this.quotationId);
        }
        this.startTimer();
    },
    unmounted() {
      this.stopTimer();
      this.clearQuotationDetail();
      this.clearMasterdata();
    },
    methods: {
        onActionLogMouseOver(index: number) {
            this.actionLogHoverIndex = index;
        },
        onActionLogMouseLeave() {
            this.actionLogHoverIndex = null;
        },
        changeShowPosition() {
            this.showPositions = !this.showPositions;
        },
        changeHideMode() {
            this.hideMode = !this.hideMode;
        },
        transformToDateString(filterValue: string | Date): string {
            if (filterValue) {
              return formatDate(filterValue);
            }
            return filterValue;
        },
        startTimer() {
            this.timer = setInterval(() => this.sendLockConfirmation(), 1000 * 30);
        },
        sendLockConfirmation() {
            axios.post(`/quotation/${this.quotationId}/confirmLock`);
        },
        stopTimer() {
            clearInterval(this.timer);
            axios.post(`/quotation/${this.quotationId}/unlock`);
        },
        loadQuotationDetail() {
            this.$store.dispatch("quotation/loadQuotationDetail", this.quotationId);
        },
        updateValidationValue() {
            this.$store.dispatch("quotation/updateValidationValue", this.quotationId);
        },
        loadActionLog() {
            this.$store.dispatch("quotation/loadActionLog", this.quotationId);
        },
        loadMasterdata(quotationId: number) {
            this.$store.dispatch("masterdata/loadMasterdata", quotationId);
        },
        clearQuotationDetail() {
            console.warn("clear quotation detail");
        },
        clearMasterdata() {
            console.warn("clear masterdata");
        },
        openAddFromOverlayPopup(box: any) {
            if (box.type === "POSITION") {
              this.clickedBox = box;
              this.showDialogAddFromOverlay = true;
            }
        },
        positionClicked(positionIdsToOpen: number[]) {
            this.positionIdsToOpen = positionIdsToOpen;
        },
        onScrollInPdfClicked(item: any) {
            this.scrollToPositionNumber = item;
        },
        handleProgressUpdated() {
            this.updateValidationValue()
        },
        addPositionToTreeview(positionFromOverlay: any) {
            this.positionFromOverlay = positionFromOverlay;
            this.reloadTriggered = !this.reloadTriggered;
        },
        navigateToQuotationList() {
            this.$router.push({
              name: "QuotationList",
              params: { boqId: `${this.boqId}`, quotationId: `${this.quotationId}` },
            });
        },
        restartConversion() {
            this.$router.push(`/boq/${this.boqId}/modify`);
        },
        undo(index: number) {
            axios({
              method: "post",
              url: `/quotation/${this.quotationId}/undo`,
              params: {
                steps: index + 1,
              },
            }).then((response: any) => {
              this.$store.commit("quotation/setActionLog", response.data);
              // this.$store.dispatch("position/loadOpenPositions", {quotationId: this.quotationId, openPositions: this.open});
              this.$store.dispatch("position/loadAllPositions", this.quotationId);
            });
        },
        redo(index: number) {
            axios({
              method: "post",
              url: `/quotation/${this.quotationId}/redo`,
              params: {
                steps: index + 1,
              },
            }).then((response: any) => {
              this.$store.commit("quotation/setActionLog", response.data);
              this.$store.dispatch("position/loadAllPositions", this.quotationId);
              // todo: load open positions
            });
        }
    }
})

</script>

<style lang="scss" scoped>
@use "@/style/styles";
$header-height: 72px;

.quotation__header {
  height: $header-height;
}

.quotation__detail {
  height: calc(100% - #{$header-height});
}

.quotation__masterdata-wrapper {
  background: styles.$container-background;
  border-radius: 10px;
  overflow: auto;
}

.quotation_masterdata {
  height: 100%;
}

.id-overlay-sheet {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
}
.actionLogHover {
  background: rgba($color: #000000, $alpha: 0.1);
}
</style>
