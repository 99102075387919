function convertStringToDate(stringDate: string | null): string {
  if (stringDate) {
    const year = parseInt(stringDate.substring(0, 4));
    const month = parseInt(stringDate.substring(4, 6)) - 1;
    const day = parseInt(stringDate.substring(6));
    return new Date(year, month, day).toLocaleDateString();
  }
  return "";
}

export class OpportunityView {
  number = "";
  name = "";
  city = "";
  zip = "";
  street = "";
  date = "";
  country = "";

  constructor(dto?: OpportunityDto) {
    if (dto) {
      this.number = dto.number;
      this.name = dto.name;
      this.city = dto.city;
      this.zip = dto.zip;
      this.street = dto.street;
      this.date = convertStringToDate(dto.date);
      this.country = dto.country;
    }
  }
}

export interface OpportunityDto {
  number: string;
  name: string;
  city: string;
  zip: string;
  street: string;
  date: string;
  country: string;
}
