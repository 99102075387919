import { User } from "@/core/models/user/User.model";

export default class UserState {
  userList: User[]  = [];
  userDetail: User | null = null;
  userChanged = false;
  currentUser: User | null = null;
}

export const state = new UserState();
