import { RootState } from "@/core/store/state";
import { GetterTree } from "vuex";
import SettingsState from "./state";

export const getters: GetterTree<SettingsState, RootState> = {
  keywords: (state) => {
    return state.keywords;
  },
  units: (state) => {
    return state.units;
  },
  productHierarchy: (state) => state.productHierarchy,
};
