<template>
  <v-form ref="myForm" @submit="submit($event)" :key="key">
    <v-card v-bind="{ loading: submitted, disabled: submitted }" class="text-center">
      <v-card-title> {{ $tc("COMPONENTS.POSITION.ADDPOSITIONDIALOG.ADD_ELEMENT") }} </v-card-title>

      <v-card-text>
        <v-select v-model="form.positionType" label="Typ" :items="positionTypes"></v-select>
        <v-text-field
          v-if="checkPosition(form)"
          v-model="form.positionLabel"
          :label="$tc('COMPONENTS.POSITION.ADDPOSITIONDIALOG.LABEL')"
        ></v-text-field>
        <v-textarea
          v-if="form.positionType == 'TEXT'"
          v-model="form.positionLabel"
          :label="$tc('COMPONENTS.POSITION.ADDPOSITIONDIALOG.LABEL')"
        ></v-textarea>
        <v-text-field
          v-model="form.positionNumber"
          :label="$tc('COMPONENTS.POSITION.ADDPOSITIONDIALOG.NUMBER')"
          :rules="[(v) => !!v || $tc('COMPONENTS.POSITION.ADDPOSITIONDIALOG.EMPTY_ERROR')]"
        ></v-text-field>
        <v-text-field
          v-if="checkIfGroup(form)"
          v-model="form.groupQuantity"
          :label="$tc('COMPONENTS.POSITION.ADDPOSITIONDIALOG.QUANTITY')"
          type="number"
          :rules="[(v) => !!v || $tc('COMPONENTS.POSITION.ADDPOSITIONDIALOG.EMPTY_ERROR')]"
        ></v-text-field>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn variant="outlined" @click="cancel()">
          {{ $tc("COMPONENTS.POSITION.ADDPOSITIONDIALOG.CANCEL") }}
        </v-btn>
        <v-btn
          class="dark-btn"
          elevation="0"
          type="submit"
        >
          {{ $tc("COMPONENTS.POSITION.ADDPOSITIONDIALOG.ADD") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    data() {
        const negativeValueAlert: any = {
                type: "error",
                message: this.$tc("COMPONENTS.POSITION.CHANGEPOSITIONDIALOG.NEGATIVE_VALUE_ERROR"),
              };
        const positionTypes: any = [
                {
                  value: "LOS",
                  title: this.$tc("COMPONENTS.POSITION.ADDPOSITIONDIALOG.POSITIONTYPES.GO"),
                },
                {
                  value: "GEWERK",
                  title: this.$tc("COMPONENTS.POSITION.ADDPOSITIONDIALOG.POSITIONTYPES.CRAFT"),
                },
                {
                  value: "TITEL",
                  title: this.$tc("COMPONENTS.POSITION.ADDPOSITIONDIALOG.POSITIONTYPES.TITLE"),
                },
                {
                  value: "GRUPPE",
                  title: this.$tc("COMPONENTS.POSITION.ADDPOSITIONDIALOG.POSITIONTYPES.GROUP"),
                },
                {
                  value: "GRUPPE_ALT",
                  title: this.$tc("COMPONENTS.POSITION.ADDPOSITIONDIALOG.POSITIONTYPES.ALT_GR"),
                },
                {
                  value: "GRUPPE_EVT",
                  title: this.$tc("COMPONENTS.POSITION.ADDPOSITIONDIALOG.POSITIONTYPES.EV_GR"),
                },
                {
                  value: "GRUPPE_BED",
                  title: this.$tc("COMPONENTS.POSITION.ADDPOSITIONDIALOG.POSITIONTYPES.BED_GR"),
                },
                {
                  value: "POSITION",
                  title: this.$tc("COMPONENTS.POSITION.ADDPOSITIONDIALOG.POSITIONTYPES.STANDARDPOSITION"),
                },
                {
                  value: "TEXT",
                  title: this.$tc("COMPONENTS.POSITION.ADDPOSITIONDIALOG.POSITIONTYPES.TEXT_POSITION"),
                },
              ];
        const form: any = {
                quotationId: undefined,
                parentPositionId: undefined,
                positionType: undefined,
                positionLabel: undefined,
                positionNumber: undefined,
              };
        const key: any = 0;

        return {
            submitted: false,
            key,
            form,
            positionTypes,
            negativeValueAlert
        };
    },
    mounted() {
        this.initFormData();
    },
    methods: {
        checkPosition(form: any) {
            return form.positionType != "POSITION" && form.positionType != "TEXT";
        },
        handleNegativeValueError() {
            this.submitted = false;
            this.$store.commit("activateAlert", this.negativeValueAlert);
        },
        checkIfGroup(form: any): boolean {
            const groupValues = ["GRUPPE", "GRUPPE_EVT", "GRUPPE_BED", "GRUPPE_ALT"];

                return groupValues.includes(form.positionType);
        },
        initFormData() {
            this.form.quotationId = this.quotationId;
            this.form.positionType = "POSITION";
            if (this.positionId) {
              this.form.parentPositionId = this.positionId;
            }
            this.key++;
        },
        submit(event: any) {
            event.preventDefault();

                if (this.form.groupQuantity && this.form.groupQuantity < 1) {
                  this.handleNegativeValueError();
                  return;
                }

                const form = this.$refs.myForm as any;

                if (!form.validate()) return;
                this.submitted = true;
                axios
                  .post(`/position/create`, this.form)
                  .then((response) => this.handleResponse(response))
                  .catch((error) => {
                    console.error(error);
                    this.handleError();
                  });
        },
        handleResponse(response: any) {
            this.$emit("onPositionAdded", response.data);
            this.cancel();
        },
        handleError() {
            // this.$store.commit("activateAlert", this.$store.state.errorAlert);
            this.submitted = false;
        },
        cancel() {
            this.clearDialog();
            this.$emit("close");
        },
        clearDialog() {
            this.submitted = false;
            this.myForm.reset();
            this.initFormData();
        },
        onPositionIdChanged() {
            this.initFormData();
        }
    },
    props: {
        quotationId: {
            type: Object as PropType<any>
        },
        positionId: {
            type: Number
        }
    },
    computed: {
        myForm() {
            return this.$refs["myForm"] as HTMLFormElement;
        }
    },
    watch: {
        "positionId": [{
            handler: "onPositionIdChanged"
        }]
    }
})

</script>

<style lang="scss" scoped>
.v-card-title {
  display: block; //weil der Card-Title sonst nicht centered wird
}
</style>
