export class ContactView {
  contactId = "";
  firstName = "";
  lastName = "";
  businessPartnerFullName = "";
  phoneNumber = "";
  emailAddress = "";
  partnerNumber = "";

  constructor(dto?: ContactDto) {
    if (dto) {
      this.contactId = dto.contactId;
      this.firstName = dto.firstName;
      this.lastName = dto.lastName;
      this.businessPartnerFullName = dto.businessPartnerFullName;
      this.phoneNumber = dto.phoneNumber;
      this.emailAddress = dto.emailAddress;
    }
  }
}

export interface ContactDto {
  contactId: string;
  firstName: string;
  lastName: string;
  businessPartnerFullName: string;
  phoneNumber: string;
  emailAddress: string;
}
