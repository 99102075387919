<template>
  <div class="settings-container">
    <v-container class="keyword-manager-container ml-0 mr-3">
      <v-row class="align-center mb-2">
        <v-col>
          <h2>{{ $tc("COMPONENTS.SETTINGS.UNITMANAGER.MANAGE_UNITS") }}</h2>
        </v-col>
        <v-col cols="auto">
          <v-btn
            @click="onAddUnitClicked()"
            class="dark-btn"
          >
            <v-icon start>mdi-plus</v-icon>
            {{ $tc(`COMPONENTS.SETTINGS.UNITMANAGER.ADD`) }}
          </v-btn>
        </v-col>
      </v-row>

      <BoqDataTable
        class="table-container overflow-y-auto"
        :headers="headers"
        :items="units"
        v-model:get-config="getConfig"
        key="unit"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex">
            <ActionButton
              :tooltip="$tc('COMPONENTS.SETTINGS.UNITMANAGER.EDIT')"
              icon="mdi-pencil"
              @buttonClicked="
                dialog = true;
                addMode = false;
                unit = item;
              "
            />

            <ActionButton
              :tooltip="$tc('COMPONENTS.SETTINGS.UNITMANAGER.DELETE')"
              icon="mdi-delete"
              @buttonClicked="deleteUnit(item)"
            />
          </div>
        </template>
        <template v-slot:[`item.marketId`]="{ item }">
          {{ getCountryName(item.marketId, marketList) }}
        </template>
      </BoqDataTable>

      <v-dialog v-model="dialog" max-width="400px">
        <UnitDialog
          v-if="dialog"
          :addMode="addMode"
          :unit="unit"
          @buttonClicked="onButtonClicked"
        />
      </v-dialog>
    </v-container>
  </div>
</template>

<script lang="ts">
import { BoqTableHeader } from "@/core/interfaces/boq-table-header.interface";
import { Keyword } from "@/core/models/keyword/Keyword.model";
import { Market } from "@/core/models/market/market.model";
import { User } from "@/core/models/user/User.model";
import GetConfig from "@/core/services/base/get.config";
import ActionButton from "@/shared/components/action-button.component.vue";
import BoqDataTable from "@/shared/components/data-table.component.vue";
import UnitDialog from "./unit-dialog.component.vue";
import * as MarketService from "@/core/services/market/market.service";
import { defineComponent, ref } from "vue";

export default defineComponent({
  components: {
    BoqDataTable,
    ActionButton,
    UnitDialog,
  },
    data() {
        const headers: BoqTableHeader[] = [
                {
                  title: this.$tc("COMPONENTS.SETTINGS.UNITMANAGER.UNIT"),
                  value: "value",
                  filterable: true,
                },
                {
                  title: this.$tc("COMPONENTS.SETTINGS.UNITMANAGER.COUNTRY"),
                  value: "marketId",
                  filterable: false,
                  type: "select",
                },
                {
                  title: this.$tc("COMPONENTS.SETTINGS.UNITMANAGER.ACTIONS"),
                  value: "actions",
                  filterable: false,
                  width: 110,
                },
              ];
        const value = ref<number | null>(null);
        const unit: Keyword = new Keyword();
        const getConfig: GetConfig = GetConfig.default();

        return {
            getConfig,
            dialog: false,
            addMode: true,
            unit,
            value,
            headers
        };
    },
    async mounted() {
        if (this.$store.state.settings.units.length === 0) {
              await this.loadUnits();
            }

            this.value = this.productHierarchy;

            this.headers[1].filterable = this.currentUser && this.currentUser.role == "ROLE_ADMIN";
    },
    unmounted() {
        this.$store.dispatch("settings/clearUnits");
    },
    methods: {
        getCountryName(marketId: number, marketList: Market[]) {
            return MarketService.getCountryName(marketId, marketList);
        },
        loadUnits() {
            this.$store.dispatch("settings/loadUnits");
        },
        async filterUnits() {
            const response = await this.$store.dispatch("settings/filterUnits", this.getConfig);
            return response;
        },
        async addUnit(newUnit: { value: string; marketId?: number }) {
            await this.$store.dispatch("settings/addUnit", newUnit);
            await this.filterUnits();
        },
        async updateUnit(unit: Keyword) {
            await this.$store.dispatch("settings/updateUnit", unit);
            await this.filterUnits();
        },
        async deleteUnit(unit: Keyword) {
            await this.$store.dispatch("settings/deleteUnit", unit);
            await this.filterUnits();
        },
        onButtonClicked(unit: Keyword) {
            this.addMode ? this.addUnit(unit) : this.updateUnit(unit);

                this.addMode = true;

                this.clearUnit();
                this.close();
        },
        clearUnit() {
            this.unit = new Keyword();
        },
        close() {
            this.dialog = false;
        },
        async getConfigChanged() {
            try {
              await this.filterUnits();
            } catch (err) {
              console.error(err);
            }
        },
        onAddUnitClicked() {
          this.dialog = true;
          this.addMode = true;
          this.unit = new Keyword();
        }
    },
    watch: {
        "getConfig": [{
            handler: "getConfigChanged"
        }]
    },
    computed: {
        units(): Keyword[] {
            return this.$store.getters["settings/units"];
        },
        productHierarchy(): number | null {
            return this.$store.getters["settings/productHierarchy"];
        },
        currentUser(): User {
            return this.$store.getters["user/currentUser"];
        },
        marketList(): Market[] {
            return this.$store.getters["market/market"];
        }
    }
})

</script>

<style lang="scss" scoped>
.settings-container {
  width: 40%;
  height: 100%;
  display: flex;
}

.keyword-manager-container {
  width: 100%;
}
.table-container {
  max-height: 75vh;
}
</style>
