import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a5fd0034"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "settings-container" }
const _hoisted_2 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_ActionButton = _resolveComponent("ActionButton")!
  const _component_BoqDataTable = _resolveComponent("BoqDataTable")!
  const _component_KeywordDialog = _resolveComponent("KeywordDialog")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_container, { class: "keyword-manager-container ml-0 mr-3" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { class: "align-center mb-2" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => [
                _createElementVNode("h2", null, _toDisplayString(_ctx.$tc("COMPONENTS.SETTINGS.KEYWORDMANAGER.MANAGE_KEYWORDS")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { cols: "auto" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addKeywordClicked())),
                  class: "dark-btn"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, { start: "" }, {
                      default: _withCtx(() => [
                        _createTextVNode("mdi-plus")
                      ]),
                      _: 1
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$tc(`COMPONENTS.SETTINGS.KEYWORDMANAGER.ADD`)), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_BoqDataTable, {
          class: "table-container overflow-y-auto",
          headers: _ctx.headers,
          items: _ctx.keywords,
          "get-config": _ctx.getConfig,
          "onUpdate:getConfig": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.getConfig) = $event)),
          key: "keyword"
        }, {
          [`item.actions`]: _withCtx(({ item }) => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_ActionButton, {
                tooltip: _ctx.$tc('COMPONENTS.SETTINGS.KEYWORDMANAGER.EDIT'),
                icon: "mdi-pencil",
                onButtonClicked: ($event: any) => {
                _ctx.dialog = true;
                _ctx.addMode = false;
                _ctx.keyword = item;
              }
              }, null, 8, ["tooltip", "onButtonClicked"]),
              _createVNode(_component_ActionButton, {
                tooltip: _ctx.$tc('COMPONENTS.SETTINGS.KEYWORDMANAGER.DELETE'),
                icon: "mdi-delete",
                onButtonClicked: ($event: any) => (_ctx.deleteKeyword(item))
              }, null, 8, ["tooltip", "onButtonClicked"])
            ])
          ]),
          [`item.marketId`]: _withCtx(({ item }) => [
            _createTextVNode(_toDisplayString(_ctx.getCountryName(item.marketId, _ctx.marketList)), 1)
          ]),
          _: 2
        }, 1032, ["headers", "items", "get-config"]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.dialog,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dialog) = $event)),
          "max-width": "400px"
        }, {
          default: _withCtx(() => [
            (_ctx.dialog)
              ? (_openBlock(), _createBlock(_component_KeywordDialog, {
                  key: 0,
                  addMode: _ctx.addMode,
                  keyword: _ctx.keyword,
                  onButtonClicked: _ctx.onButtonClicked
                }, null, 8, ["addMode", "keyword", "onButtonClicked"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_v_container, { class: "product-hierarchy-container ml-0" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { class: "align-center mb-2" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => [
                _createElementVNode("h2", null, _toDisplayString(_ctx.$tc("COMPONENTS.SETTINGS.KEYWORDMANAGER.SELECT_PRODUCTGROUP")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_select, {
          modelValue: _ctx.value,
          "onUpdate:modelValue": [
            _cache[3] || (_cache[3] = ($event: any) => ((_ctx.value) = $event)),
            _ctx.saveProductHierarchy
          ],
          items: _ctx.productHierarchyLevels,
          variant: "outlined",
          density: "compact"
        }, null, 8, ["modelValue", "items", "onUpdate:modelValue"])
      ]),
      _: 1
    })
  ]))
}