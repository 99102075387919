import { createI18n } from "vue-i18n";
import en from './locales/en.json';
import de from './locales/de.json';

type MessageSchema = typeof en

/*function loadLocaleMessages() {
  const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: Record<string, any> = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages as { en: MessageSchema, de: MessageSchema };
}*/

const i18n = createI18n<[MessageSchema], 'en' | 'de'>({
  locale: "de",
  fallbackLocale: "en",
  messages: { en, de },
  allowComposition: true,
  legacy: true,
});

export function i18nGlobalTc(key: string) {
  return i18n.global.tc(key);
}

export default i18n;
