import { BoqTableHeader } from "@/core/interfaces/boq-table-header.interface";
import { i18nGlobalTc } from "@/i18n";

export function getQuotationListHeaders(): BoqTableHeader[] {
  return [
    {
      title: i18nGlobalTc("COMPONENTS.QUOTATION.QUOTATIONLIST.HEADER.BOQ_NUMBER"),
      value: "boqId",
      filterable: false,
    },
    {
      title: i18nGlobalTc("COMPONENTS.QUOTATION.QUOTATIONLIST.HEADER.QUOTATION_NUMBER"),
      value: "id",
      filterable: false,
    },
    {
      title: i18nGlobalTc("COMPONENTS.QUOTATION.QUOTATIONLIST.HEADER.EXPORT_ID"),
      value: "exportId",
      filterable: false,
    },
    {
      title: i18nGlobalTc("COMPONENTS.QUOTATION.QUOTATIONLIST.HEADER.CLIENT_NR"),
      value: "clientId",
      filterable: true,
    },
    {
      title: i18nGlobalTc("COMPONENTS.QUOTATION.QUOTATIONLIST.HEADER.CLIENT_NAME"),
      value: "clientName",
      filterable: true,
    },
    {
      title: i18nGlobalTc("COMPONENTS.QUOTATION.QUOTATIONLIST.HEADER.CONTACT_PERSON"),
      value: "contactName",
      filterable: true,
    },
    {
      title: i18nGlobalTc("COMPONENTS.QUOTATION.QUOTATIONLIST.HEADER.AUTHOR"),
      value: "lastEditor",
      filterable: true,
    },
    {
      title: i18nGlobalTc("COMPONENTS.QUOTATION.QUOTATIONLIST.HEADER.PRODUCT_DIVISION"),
      value: "productHierarchy",
      filterable: true,
    },
    {
      title: i18nGlobalTc("COMPONENTS.QUOTATION.QUOTATIONLIST.HEADER.STATUS"),
      value: "status",
      filterable: true,
    },
    {
      title: i18nGlobalTc("COMPONENTS.QUOTATION.QUOTATIONLIST.HEADER.VERSION"),
      value: "version",
    },
    // {
    //   title: "Aktionen",
    //   value: "actions",
    //   width: 110
    // }
  ];
}
