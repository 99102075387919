export default {
  "LANGUAGEKEYS": {
    "de_DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutschland"])},
    "en_US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USA"])},
    "it_IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italien"])},
    "fr_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frankreich"])},
    "el_EL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Griechenland"])}
  },
  "BOQ": {
    "BOQOVERVIEW": {
      "BOQ_OVERVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LV-Übersicht"])},
      "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
      "RESTART": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erkennung des LVs neu starten"])},
      "EXPORT": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angeboten exportiert."])}
      }
    },
    "ADDBOXDIALOG": {
      "ADD_BOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Box hinzugefügt"])},
      "SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
      "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])}
    },
    "ADDBOQDIALOG": {
      "ADD_QUOTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot hinzufügen"])},
      "ADD_BOQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LV hinzufügen"])},
      "BOQ_ENTRY_MASK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LV-Erfassungsmarke"])},
      "QUOTATION_ENTRY_MASK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebots-Erfassungsmarke"])},
      "SOURCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle"])},
      "ERROR_SOURCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie eine Quelle aus!"])},
      "ERROR_GROUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie eine Bearbeitungsgruppe aus!"])},
      "WORKING_GROUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeitungsgruppe"])},
      "ERROR_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie eine Datei aus!"])},
      "UPLOAD_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateiupload (max. 25 MB)"])},
      "LIMIT_PAGES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seiten eingrenzen"])},
      "COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
      "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
      "SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
      "BOQ_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Leistungsverzeichnis wurde erfolgreich hinzugefügt!"])},
      "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist aufgetreten"])},
      "FILETYPE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unzulässiges Dateiformat!"])},
      "MULTIPLE_FILETYPE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei mehreren Dateien ist nur ein Dateiformat zulässig!"])}
    },
    "HEADER": {
      "LV_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
      "QUOTATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebote"])},
      "SOURCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle"])},
      "SALES_TEAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufsteams"])},
      "RECEIPTDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingangsdatum"])},
      "FILENAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateiname"])},
      "COMMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
      "AUTHOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersteller"])},
      "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])}
    }
  },
  "SHARED": {
    "COMPONENTS": {
      "DATATABLE": {
        "ITEMS_PER_PAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeilen pro Seite"])},
        "ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
        "OF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])}
      }
    }
  },
  "CORE": {
    "ERROR_HANDLER": {
      "UNKNOWN_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein unbekannter Fehler aufgetreten!"])}
    },
    "SERVICES": {
      "USER": {
        "USER_SERVICES": {
          "USER_CREATE_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Nutzer wurde erfolgreich erstellt!"])},
          "USER_CREATE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Erstellen des Nutzers ist ein Fehler aufgetreten!"])},
          "USER_CHANGE_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Nutzer wurde erfolgreich geändert!"])},
          "USER_CHANGE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Aktualisieren des Nutzers ist ein Fehler aufgetreten!"])},
          "USER_DELETE_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Nutzer wurde erfolgreich gelöscht!"])},
          "USER_DELETE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Löschen des Nutzers ist ein Fehler aufgetreten!"])}
        }
      },
      "SETTINGS": {
        "PRODUCTDIVISION": {
          "CHANGE_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktbereich geändert!"])},
          "CHANGE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktbereich konnte nicht geändert werden!"])}
        },
        "KEYWORD": {
          "ADD_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlagwort erstellt!"])},
          "ADD_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlagwort konnte nicht erstellt werden!"])},
          "CHANGE_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlagwort geändert!"])},
          "CHANGE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist beim Übernehmen der Änderungen aufgetreten!"])},
          "DELETE_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlagwort gelöscht!"])},
          "DELETE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Löschen ist ein Fehler aufgetreten!"])}
        }
      },
      "MASTER_DATA": {
        "MASTER_DATA": {
          "UPDATE_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Stammdaten wurden erfolgreich aktualisiert!"])},
          "UPDATE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Aktualisieren der Stammdaten ist ein Fehler aufgetreten!"])}
        }
      }
    },
    "MODELS": {
      "BOQ": {
        "BOQ_MODELS": {
          "INITIALIZING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initialisiert"])},
          "WAITING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartet"])},
          "CONVERTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konvertierung"])},
          "AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbar"])},
          "FAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlgeschlagen"])},
          "CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgebrochen"])}
        }
      }
    }
  },
  "VIEWS": {
    "ARTICLES": {
      "ARTICLES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel"])}
    }
  },
  "COMPONENTS": {
    "EXPORTQUOTATIONDIALOG": {
      "WANT_TO_EXPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie das Angebot exportieren möchten?"])},
      "PROCESS_QUOTATION_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte beachten Sie, dass es bis zu 2 Min. dauern kann, bis der Angebotsbeleg vollständig im CRM verarbeitet wurde!"])},
      "CLOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
      "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
      "ERROR": {
        "ANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist aufgetreten!"])},
        "ONLY_ONCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Aktion kann nur ein Mal ausgeführt werden!"])},
        "ALREADY_EXPORTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Angebot wurde bereits exportiert!"])},
        "NO_CUSTOMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurde kein Auftraggeber erfasst!"])},
        "POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Positionen beinhalten noch Fehler!"])},
        "NO_SAPUSER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurde kein SAP-User/Erfasser angegeben!"])}
      },
      "SUCCESS": {
        "EXPORTED_QUOTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Angebot wurde erfolgreich exportiert!"])}
      }
    },
    "POSITION": {
      "IMPORTPOSITION": {
        "IMPORTPOSITIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel-Import"])},
        "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
        "SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
        "UPLOAD_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateiupload (max. 25 MB)"])},
        "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist aufgetreten!"])},
        "SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Angebot wurde erfolgreich importiert"])}
      },
      "FASTENTRYDIALOG": {
        "EAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EAN"])},
        "PRODUCT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt"])},
        "QUANTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
        "UNIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einheit"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurztext"])},
        "POSITIONTYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positionstyp"])},
        "EXTERNAL_REFERENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externe Referenz"])},
        "CONFIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
        "POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
        "CLEAR_UP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufräumen"])},
        "RENUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu nummerieren"])},
        "EXPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Excel-Datei exportieren"])},
        "EXCEL_HOVER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Tabellenkalkulation exportieren"])},
        "VALIDATE": {
          "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unzulässiger Wert!"])},
          "ERROR_QUANTITES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte ergänzen Sie die fehlende Mengenangabe"])},
          "ERROR_ALTERNATIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für mindestens eine Alternativposition oder -gruppe existiert/existieren kein bzw. mehrere Standardelement(e)..."])},
          "ARTICLENUMBER_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige Artikelnummer!"])},
          "EXT_REF_NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externe Referenz konnte nicht gefunden werden"])},
          "EXT_REF_NOT_FOUND_RECONVERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externe Referenz konnte nicht gefunden werden. Angebot hat eine alte Struktur, bitte anpassen!"])}
        },
        "OPTIONS": {
          "POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["01 - Standardposition"])},
          "ALT_POS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["02 - Alternativposition"])},
          "BED_POS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["03 - Bedarfsposition"])},
          "EV_POS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["04 - Eventualposition"])},
          "TEXT_POS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["05 - Textposition"])},
          "GO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["06 - Los"])},
          "CRAFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["07 - Gewerk"])},
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["08 - Titel"])},
          "GROUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09 - Gruppe"])},
          "ALT_GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 - Alternativgruppe"])},
          "BED_GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11 - Bedarfsgruppe"])},
          "EV_GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 - Eventualgruppe"])}
        },
        "INCORRECT_POSITIONTYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unzulässiger Positionstyp"])},
        "ARTICLE_NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel nicht gefunden"])}
      },
      "POSITIONDRAGDROPDIALOG": {
        "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
        "MOVE_AS_CHILD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innerhalb einfügen"])},
        "MOVE_ON_SAME_LEVEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterhalb einfügen"])},
        "MULTIPLE_SELECTED_ELEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben mehrere Positionen ausgewählt. Wie möchten Sie diese verschieben?"])},
        "SINGLE_SELECTED_ELEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie möchten Sie das Element verschieben?"])},
        "MOVE_ELEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Element verschieben"])}
      },
      "POSITION": {
        "TEXTPOSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Textposition"])},
        "POSITION_DETECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positionen"])},
        "FAST_ENTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schnellerfassung"])},
        "ADD_ELEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Element hinzufügen"])},
        "FILTER_POSITIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positionen filtern"])},
        "ADD_SINGLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzelelement hinzufügen"])},
        "IMPORT_POSITIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel-Import"])},
        "BOQ_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die LV-Position wurde erfolgreich verschoben"])},
        "MISSMATCH_ID_PARENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positions-ID passt nicht zur Eltern-Position"])},
        "NO_UNIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Einheit erkannt"])},
        "NO_QUANTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Menge erkannt"])},
        "ID_SMALL_POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erkannte Positions-ID ist kleiner als vorherige Positions-ID"])},
        "UNORDINARY_DISTANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand zu vorheriger Positions-ID ist unüblich"])},
        "NO_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine ID erkannt"])},
        "NO_ARTICLE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine oder ungültige Artikelnummer erkannt"])},
        "NO_ARTICLE_REFERENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Artikel erkannt"])},
        "NO_STANDARD_ARTICLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Artikel vom Typ Standard ausgewählt. Bitte Bezugsposition pflegen."])},
        "NO_STANDARD_GROUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu dieser Alternativgruppe existiert keine Standardgruppe"])},
        "ALTERNATIVE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Gruppenposition kann nicht unter eine andere Gruppenposition geschoben werden"])}
      },
      "POSITION_FILTER": {
        "PDF_VIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF View vom LV"])},
        "BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
        "KEYWORDS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlagwörter"])},
        "PAGE_SELECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seitenauswahl"])},
        "APPLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übernehmen"])},
        "ALL_PAGES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Seiten"])},
        "RELEVANT_PAGES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevante Seiten"])}
      },
      "ADDPOSITIONDIALOG": {
        "ADD_ELEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Element hinzufügen"])},
        "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
        "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer"])},
        "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
        "QUANTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
        "ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
        "EMPTY_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darf nicht leer sein"])},
        "POSITIONTYPES": {
          "GO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los"])},
          "CRAFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewerk"])},
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
          "GROUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe"])},
          "POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
          "STANDARDPOSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardposition"])},
          "TEXT_POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Textposition"])},
          "ALT_POS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternativposition"])},
          "EV_POS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventualposition"])},
          "BED_POS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedarfsposition"])},
          "ALT_GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternativgruppe"])},
          "EV_GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventualgruppe"])},
          "BED_GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedarfsgruppe"])}
        }
      },
      "CHANGEPOSITIONDIALOG": {
        "UPDATE_POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position bearbeiten"])},
        "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
        "SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
        "QUANTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
        "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer"])},
        "NEGATIVE_VALUE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die angegebene Menge darf nicht kleiner als 1 sein!"])},
        "POSITIONTYPES": {
          "GO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los"])},
          "CRAFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewerk"])},
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
          "GROUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe"])},
          "POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardposition"])},
          "TEXT_POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Textposition"])}
        }
      },
      "DELETEPOSITIONDIALOG": {
        "DELETE": {
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
          "SINGLE_ELEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Element löschen?"])},
          "MULTIPLE_ELEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemente löschen?"])},
          "SELECTED_ELEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben mehrere Elemente ausgewählt. Möchten Sie diese wirklich löschen?"])},
          "SELECTED_TYPE": {
            "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie das Element vom Typ"])},
            "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wirklich löschen?"])}
          },
          "SUBPOSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterpositionen ebenfalls löschen"])}
        },
        "MESSAGE": {
          "SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Element wurde erfolgreich gelöscht!"])},
          "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das angegebene Element existiert nicht!"])},
          "CHILDREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es müssen erst alle Unterpositionen gelöscht werden!"])}
        },
        "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])}
      },
      "POSITIONARTICLEDIALOG": {
        "QUANTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "ARTICLE": {
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel"])},
          "ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel hinzufügen"])},
          "CHANGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel bearbeiten"])}
        },
        "RULES": {
          "NOT_EMPTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darf nicht leer sein"])},
          "NOT_VALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine gültige Mengenangabe"])},
          "NO_ARTICLENUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine gültige Artikelnummer"])}
        },
        "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
        "CHANGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern"])},
        "ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
        "MESSAGE": {
          "SUCCESS_ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Artikel wurde erfolgreich hinzugefügt!"])},
          "SUCCESS_CHANGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Artikel wurde erfolgreich geändert!"])}
        },
        "POSITION": {
          "STANDARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardposition"])},
          "ALTERNATIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternativposition"])},
          "REQUIREMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedarfsposition"])},
          "EVENTUAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventualposition"])}
        }
      },
      "POSITION_CONTENT": {
        "POS_NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pos. Nr."])},
        "NOT_AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht vorhanden"])},
        "ARTICLE": {
          "NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art. Nr."])},
          "VIEGA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viega Artikel Nr."])},
          "ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel hinzufügen"])},
          "TYPE": {
            "STANDARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
            "ALTERNATIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternativ"])},
            "REQUIREMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedarf"])},
            "CONTINGENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventual"])}
          }
        },
        "POSITION_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positionstext"])},
        "QUANTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
        "UNIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einheit"])},
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
        "QUOTATION_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebotstext"])},
        "BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorherige"])},
        "NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächste"])}
      }
    },
    "HISTORY": {
      "AUTHOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
      "RELEASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Release"])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
      "LOADING_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Versionsverlauf kann nicht geladen werden"])},
      "VERSIONHISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versionsverlauf"])},
      "CURRENT_VERSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Version"])},
      "NO_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Beschreibung"])},
      "LOAD_MORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr laden"])},
      "NO_FURTHER_RELEASES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine weiteren Releases"])},
      "CHANGES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen"])}
    },
    "PDF_VIEWER": {
      "POSITIONBEGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginn von Position"])},
      "NO_BOQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu diesem Angebot existiert kein Leistungsverzeichnis (PDF-Dokument)"])},
      "DOWNLOAD_PDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF herunterladen"])}
    },
    "ARTICLE": {
      "ARTICLE_OVERVIEW": {
        "ARTICLENUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelnummer"])},
        "SHORTCUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurzbezeichnung"])},
        "QUOTATION_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebotstext"])},
        "INTELLIGENT_SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intelligente Suche..."])}
      },
      "ARTICLE_DETAIL": {
        "ARTICLENUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelnummer"])},
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung/Produktname"])},
        "QUOTATION_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebotstext"])},
        "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sontiges"])},
        "CLOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])}
      }
    },
    "QUOTATION": {
      "QUOTATIONLIST": {
        "EDIT_COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar bearbeiten"])},
        "NEW_QUOTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Angebot"])},
        "BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
        "HEADER": {
          "BOQ_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LV-Nummer"])},
          "QUOTATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebotsnummer"])},
          "EXPORT_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export-ID"])},
          "CLIENT_NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundennummer"])},
          "CLIENT_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenname"])},
          "CONTACT_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansprechpartner"])},
          "AUTHOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersteller"])},
          "PRODUCT_DIVISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktbereich"])},
          "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
          "VERSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])}
        },
        "MESSAGE": {
          "QUOTATION_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Angebot wurde erfolgreich hinzugefügt!"])},
          "QUOTATION_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist aufgetreten!"])},
          "QUOTATION_EDITEDBY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Angebot wird bereits bearbeitet von: "])}
        }
      },
      "QUOTATIONDETAIL": {
        "BOQ_BASE_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stammdaten"])},
        "BOQ_POSITIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positionen"])},
        "RESTART_DETECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erkennung des LVs neu starten"])},
        "PROCESSING_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeitungsfortschritt"])},
        "BOQ_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LV-Nummer"])},
        "QUOTATION_NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebotsnummer"])},
        "SOURCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle"])},
        "SALES_TEAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufsteam"])},
        "RECEIVE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingangsdatum"])},
        "AUTHOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersteller"])},
        "BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
        "UNDO_ACTION_LABEL_IMPORT_POSITIONS": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " Eintrag importiert"]), _normalize([_interpolate(_list(0)), " Einträge importiert"])])},
        "UNDO_ACTION_LABEL_MODIFY_POSITION_LOS": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Los ", _interpolate(_list(0)), " bearbeitet: ", _interpolate(_list(1)), " -> ", _interpolate(_list(2))])},
        "UNDO_ACTION_LABEL_MODIFY_POSITION_GEWERK": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Gewerk ", _interpolate(_list(0)), " bearbeitet: ", _interpolate(_list(1)), " -> ", _interpolate(_list(2))])},
        "UNDO_ACTION_LABEL_MODIFY_POSITION_TITEL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Titel ", _interpolate(_list(0)), " bearbeitet: ", _interpolate(_list(1)), " -> ", _interpolate(_list(2))])},
        "UNDO_ACTION_LABEL_MODIFY_POSITION_GRUPPE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Gruppe ", _interpolate(_list(0)), " bearbeitet: ", _interpolate(_list(1)), " -> ", _interpolate(_list(2))])},
        "UNDO_ACTION_LABEL_MODIFY_POSITION_POSITION": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Position ", _interpolate(_list(0)), " bearbeitet: ", _interpolate(_list(1)), " -> ", _interpolate(_list(2))])},
        "UNDO_ACTION_LABEL_CREATE_ARTICLE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Artikel ", _interpolate(_list(0)), " zu Position ", _interpolate(_list(1)), " hinzugefügt"])},
        "UNDO_ACTION_LABEL_CREATE_POSITION_LOS": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Los ", _interpolate(_list(0)), " hinzugefügt"])},
        "UNDO_ACTION_LABEL_CREATE_POSITION_GEWERK": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Gewerk ", _interpolate(_list(0)), " hinzugefügt"])},
        "UNDO_ACTION_LABEL_CREATE_POSITION_TITEL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Titel ", _interpolate(_list(0)), " hinzugefügt"])},
        "UNDO_ACTION_LABEL_CREATE_POSITION_GRUPPE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Gruppe ", _interpolate(_list(0)), " hinzugefügt"])},
        "UNDO_ACTION_LABEL_CREATE_POSITION_POSITION": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Position ", _interpolate(_list(0)), " hinzugefügt"])},
        "UNDO_ACTION_LABEL_DELETE_POSITION": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " Eintrag gelöscht (", _interpolate(_list(1)), ")"]), _normalize([_interpolate(_list(0)), " Einträge gelöscht (", _interpolate(_list(1)), ")"])])},
        "UNDO_ACTION_LABEL_FASTENTRY": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " Eintrag per Schnelleingabe erstellt"]), _normalize([_interpolate(_list(0)), " Einträge per Schnelleingabe erstellt"])])},
        "UNDO_ACTION_LABEL_MOVE_AFTER_POSITION": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " Eintrag verschoben (", _interpolate(_list(1)), ")"]), _normalize([_interpolate(_list(0)), " Einträge verschoben (", _interpolate(_list(1)), ")"])])},
        "UNDO_ACTION_LABEL_MOVE_BEFORE_POSITION": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " Eintrag verschoben (", _interpolate(_list(1)), ")"]), _normalize([_interpolate(_list(0)), " Einträge verschoben (", _interpolate(_list(1)), ")"])])},
        "UNDO_ACTION_LABEL_MOVE_INTO_POSITION": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " Eintrag verschoben (", _interpolate(_list(1)), ")"]), _normalize([_interpolate(_list(0)), " Einträge verschoben (", _interpolate(_list(1)), ")"])])},
        "UNDO_ACTION_LABEL_REMOVE_ARTICLE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Artikel ", _interpolate(_list(0)), " von Position ", _interpolate(_list(1)), " entfernt"])},
        "UNDO_ACTION_LABEL_UPDATE_ARTICLE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Artikel in Position ", _interpolate(_list(0)), " bearbeitet: ", _interpolate(_list(1)), " -> ", _interpolate(_list(2))])}
      },
      "IMPORTQUOTATIONDIALOG": {
        "UPLOAD_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateiupload (max. 25 MB)"])},
        "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist aufgetreten!"])},
        "SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Angebot wurde erfolgreich importiert"])}
      }
    },
    "MASTER_DATA": {
      "CUSTOMER_SELECTION": {
        "RECIPIENT_SELECTION": {
          "USE_FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte verwenden Sie einen der Filter, um nach Angebotsempfänger zu suchen."])},
          "SEARCH_AGAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut suchen"])},
          "NO_CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine Ergebnisse mit diesen Such-Parametern gefunden."])},
          "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist aufgetreten!"])}
        },
        "HEADERS": {
          "BIDDER_SELECTION": {
            "CLIENT_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundennummer"])},
            "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
            "STREET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
            "POSTAL_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
            "LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])}
          },
          "CLIENT_SELECTION": {
            "CLIENT_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundennummer"])},
            "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
            "STREET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
            "POSTAL_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
            "LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])}
          },
          "CONTACT_SELECTION": {
            "CONTACT_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
            "FIRSTNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
            "LASTNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
            "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
            "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
            "BUSINESSPARTNERNUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Partner Nr."])}
          },
          "PLANNER_SELECTION": {
            "CLIENT_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundennummer"])},
            "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
            "STREET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
            "POSTAL_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
            "LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])}
          },
          "RECIPIENT_SELECTION": {
            "CLIENT_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundennummer"])},
            "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
            "STREET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
            "POSTAL_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
            "LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])}
          },
          "OPPORTUNITY_SELECTION": {
            "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekt-Nummer"])},
            "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
            "CITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
            "POSTAL_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
            "STREET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
            "DECRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
            "DATE_OF_CREATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellungsdatum"])},
            "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
            "INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])}
          }
        },
        "BIDDER_SELECTION": {
          "USE_FILTER": {
            "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte verwenden Sie einen der Filter, um nach"])},
            "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zu suchen."])}
          },
          "SEARCH_AGAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut suchen"])},
          "NO_CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine Ergebnisse mit diesen Such-Parametern gefunden."])},
          "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist aufgetreten!"])},
          "BIDDER_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es dürfen maximal 9 Bieter ausgewählt werden!"])}
        },
        "CONTACT_SELECTION": {
          "USE_FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte verwenden Sie einen der Filter, um nach Ansprechpartner zu suchen."])},
          "SEARCH_AGAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut suchen"])},
          "NO_CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine Ergebnisse mit diesen Such-Parametern gefunden."])},
          "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist aufgetreten!"])}
        },
        "PLANNER_SELECTION": {
          "USE_FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte verwenden Sie einen der Filter, um nach Fachplanern zu suchen."])},
          "SEARCH_AGAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut suchen"])},
          "NO_CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine Ergebnisse mit diesen Such-Parametern gefunden."])},
          "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist aufgetreten!"])}
        },
        "SELECTION_DIALOG": {
          "CLIENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auftraggeber"])},
          "NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
          "COMPLETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschließen"])},
          "BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
          "SALES_AREA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertriebsbereich"])},
          "CONTACT_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansprechpartner"])}
        },
        "SALESAREA_SELECTION": {
          "SALESORGANISATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufsorganisation"])},
          "DISTRIBUTION_CHANNEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertriebsweg"])},
          "DIVISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sparte"])},
          "CLIENTID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundennummer"])}
        },
        "CLIENT_SELECETION": {
          "MESSAGE": {
            "NO_CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine Ergebnisse mit diesen Such-Parametern gefunden."])},
            "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist aufgetreten!"])}
          },
          "SEARCH_AGAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut suchen"])},
          "SEARCH_CLIENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte verwenden Sie einen der Filter, um nach Auftraggeber zu suchen."])}
        },
        "OPPORTUNITY_SELECTION": {
          "USE_FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte verwenden Sie einen der Filter, um nach Opportunities zu suchen."])},
          "SEARCH_AGAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut suchen"])},
          "NO_CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine Ergebnisse mit diesen Such-Parametern gefunden."])},
          "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist aufgetreten!"])}
        },
        "MASTERDATA": {
          "MASTER_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stammdaten"])}
        },
        "CUSTOMER": {
          "CUSTOMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde"])},
          "CLIENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auftraggeber"])},
          "CLIENT_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auftraggeber ID"])},
          "CLIENT_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auftraggeber Adresse"])},
          "OFFEREE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebotsempfänger"])},
          "OFFEREE_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebotsempfänger ID"])},
          "OFFEREE_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebotsempfänger Adresse"])},
          "CONTACT_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansprechpartner"])},
          "CONTACT_PERSON_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansprechpartner ID"])},
          "CONTACT_PERSON_MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansprechpartner E-Mail"])},
          "CONTACT_PERSON_PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansprechpartner Telefonnummer"])}
        },
        "FURTHER_CONTACT": {
          "FURTHER_CONTACTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Kontakte"])},
          "BIDDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bieter"])},
          "BIDDER_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
          "BIDDER_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
          "BIDDERLIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bieterliste"])},
          "PLANNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fachplanner"])},
          "PLANNER_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
          "PLANNER_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])}
        },
        "FURTHER_INFORMATION": {
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Informationen"])},
          "NUMBER_OF_POSITIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl LV Positionen"])},
          "PRODUCT_DIVISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktbereich"])}
        },
        "OPPORTUNITY": {
          "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
          "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])}
        },
        "BOQ": {
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfragedaten"])},
          "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung BV"])},
          "EXTERNAL_REFERENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externe Referenz"])},
          "LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
          "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfragedaten bearbeiten"])},
          "APPLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übernehmen"])},
          "POSTAL_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
          "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
          "STREET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])}
        }
      }
    },
    "SETTINGS": {
      "UNITDIALOG": {
        "UNIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einheit"])},
        "ADD_UNIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einheit hinzufügen"])},
        "CHANGE_UNIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einheit ändern"])},
        "CHANGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern"])},
        "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])}
      },
      "KEYWORDDIALOG": {
        "KEYWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlagwort"])},
        "ADD_KEYWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlagwort hinzufügen"])},
        "CHANGE_KEYWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlagwort ändern"])},
        "CHANGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern"])},
        "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])}
      },
      "UNITMANAGER": {
        "UNIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einheit"])},
        "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
        "DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
        "KEYWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlagwort"])},
        "MANAGE_UNITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einheiten verwalten"])},
        "ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
        "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
        "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])}
      },
      "KEYWORDMANAGER": {
        "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
        "DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
        "KEYWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlagwort"])},
        "MANAGE_KEYWORDS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlagwörter verwalten"])},
        "ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
        "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
        "SELECT_PRODUCTGROUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktbereich wählen"])},
        "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
        "HIERARCHYLEVEL": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stufe 1"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stufe 2"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stufe 3"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stufe 4"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stufe 5"])}
        }
      }
    },
    "USER_ADMIN": {
      "MAPPINGS": {
        "USER_ADMIN_DETAILS": {
          "DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerverwaltung - Nutzerdetails"])},
          "CREATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerverwaltung - Neuen Nutzer Erstellen"])}
        },
        "USER_ADMIN_OVERVIEW": {
          "CREATE_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Benutzer erstellen"])},
          "USER_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerverwaltung"])},
          "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
          "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
          "E_MAIL_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
          "ROLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle"])},
          "SALES_TEAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufsteam"])},
          "USERNAME_SAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalnummer"])},
          "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
          "SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
          "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
          "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])}
        },
        "USER_PROFILE": {
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerprofil"])},
          "FIRSTNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
          "LASTNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
          "E-MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
          "USERNAME_SAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalnummer"])},
          "SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])}
        }
      }
    },
    "NAVIGATIONDRAWER": {
      "BOQ_OVERVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LV-Übersicht"])},
      "ARTICLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel"])},
      "SETTINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
      "USER_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerverwaltung"])},
      "LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
      "SELECTED_LANGUAGE": {
        "en_US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch (US)"])},
        "de_DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
        "fr_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Französisch"])},
        "it_IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italienisch"])}
      }
    },
    "NAVIGATIONBAR": {
      "PROFIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
      "TESTSYSTEM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testsystem"])}
    }
  },
  "SERVICES": {
    "SETTINGS": {
      "KEYWORD": {
        "ADD_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Schlagwort wurde erfolgreich hinzugefügt!"])},
        "ADD_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Hinzufügen des Schlagsworts ist ein Fehler aufgetreten!"])},
        "CHANGE_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Schlagwort wurde erfolgreich aktualisiert!"])},
        "CHANGE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Aktualisieren des Schlagsworts ist ein Fehler aufgetreten!"])},
        "DELETE_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Schlagwort wurde erfolgreich gelöscht!"])},
        "DELETE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Löschen des Schlagsworts ist ein Fehler aufgetreten!"])}
      },
      "UNIT": {
        "ADD_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Einheit wurde erfolgreich hinzugefügt!"])},
        "ADD_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Hinzufügen der Einheit ist ein Fehler aufgetreten!"])},
        "CHANGE_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Einheit wurde erfolgreich aktualisiert!"])},
        "CHANGE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Aktualisieren der Einheit ist ein Fehler aufgetreten!"])},
        "DELETE_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Einheit wurde erfolgreich gelöscht!"])},
        "DELETE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Löschen der Einheit ist ein Fehler aufgetreten!"])}
      },
      "PRODUCTDIVISION": {
        "CHANGE_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Produktbereich wurde erfolgreich geändert!"])},
        "CHANGE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Ändern des Produktbereiches ist ein Fehler aufgetreten!"])}
      }
    }
  }
}