import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d7230aee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = { class: "card__title" }
const _hoisted_3 = { class: "card__title" }
const _hoisted_4 = { class: "d-flex" }
const _hoisted_5 = { class: "card__title" }
const _hoisted_6 = { class: "card__title" }
const _hoisted_7 = { class: "card__title" }
const _hoisted_8 = { class: "card__title" }
const _hoisted_9 = { class: "card__title" }
const _hoisted_10 = { class: "card__title" }
const _hoisted_11 = { class: "card__title" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "card__title" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_ClientSelection = _resolveComponent("ClientSelection")!
  const _component_SalesAreaSelection = _resolveComponent("SalesAreaSelection")!
  const _component_ContactSelection = _resolveComponent("ContactSelection")!
  const _component_SelectionDialog = _resolveComponent("SelectionDialog")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_RecipientSelection = _resolveComponent("RecipientSelection")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_responsive = _resolveComponent("v-responsive")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "mb-4 mr-2 pa-0" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_responsive, { class: "overflow-y-auto fill-height ma-0 pa-0" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, {
            class: "main-card",
            variant: "flat"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CUSTOMER.CUSTOMER")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_container, {
                    fluid: "",
                    class: "px-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { lg: "6" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_1, [
                                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CUSTOMER.CLIENT")), 1),
                                _createElementVNode("span", null, [
                                  _createVNode(_component_SelectionDialog, {
                                    "amount-of-steps": _ctx.amountOfSteps,
                                    "step-one-title": 
                        _ctx.$tc('COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.SELECTION_DIALOG.CLIENT')
                      ,
                                    "step-two-title": _ctx.getSecondTitle(),
                                    onItemOneSelected: _ctx.onClientSelected,
                                    onItemThreeSelected: _ctx.onContactThirdSelected,
                                    onItemTwoSelected: _ctx.onContactSelected,
                                    onSalesItemSelected: _ctx.onSalesAreaSelected,
                                    onSubmit: _ctx.submitClient
                                  }, _createSlots({
                                    activator: _withCtx(({ props }) => [
                                      _createElementVNode("div", _normalizeProps(_guardReactiveProps(props)), [
                                        _createVNode(_component_v_icon, {
                                          class: "px-1",
                                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeStepsAmount(3), _ctx.getSecondTitle()))
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode("mdi-card-search")
                                          ]),
                                          _: 1
                                        })
                                      ], 16)
                                    ]),
                                    stepOne: _withCtx(({ onItemSelected, stepper }) => [
                                      (stepper === 1)
                                        ? (_openBlock(), _createBlock(_component_ClientSelection, {
                                            key: 0,
                                            "initial-value": _ctx.client?.kundennummer,
                                            onItemSelected: onItemSelected
                                          }, null, 8, ["initial-value", "onItemSelected"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    stepTwo: _withCtx(({ onItemSelected, stepper }) => [
                                      (stepper === 2)
                                        ? (_openBlock(), _createBlock(_component_ContactSelection, {
                                            key: 0,
                                            client: _ctx.selectedClient,
                                            onItemSelected: onItemSelected
                                          }, null, 8, ["client", "onItemSelected"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                  }, [
                                    (_ctx.amountOfSteps === 3)
                                      ? {
                                          name: "stepSales",
                                          fn: _withCtx(({ onItemSelected, stepper }) => [
                                            (stepper === 2)
                                              ? (_openBlock(), _createBlock(_component_SalesAreaSelection, {
                                                  key: 0,
                                                  "client-id": _ctx.selectedClient.kundennummer,
                                                  onItemSelected: onItemSelected
                                                }, null, 8, ["client-id", "onItemSelected"]))
                                              : _createCommentVNode("", true)
                                          ]),
                                          key: "0"
                                        }
                                      : undefined,
                                    (_ctx.amountOfSteps === 3)
                                      ? {
                                          name: "stepThree",
                                          fn: _withCtx(({ onItemSelected, stepper }) => [
                                            (stepper === 3)
                                              ? (_openBlock(), _createBlock(_component_ContactSelection, {
                                                  key: 0,
                                                  client: _ctx.selectedClient,
                                                  "sales-area": _ctx.selectedSalesArea,
                                                  onItemSelected: onItemSelected
                                                }, null, 8, ["client", "sales-area", "onItemSelected"]))
                                              : _createCommentVNode("", true)
                                          ]),
                                          key: "1"
                                        }
                                      : undefined
                                  ]), 1032, ["amount-of-steps", "step-one-title", "step-two-title", "onItemOneSelected", "onItemThreeSelected", "onItemTwoSelected", "onSalesItemSelected", "onSubmit"])
                                ])
                              ]),
                              _createTextVNode(" " + _toDisplayString(_ctx.client?.name ? _ctx.client.name : "-"), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { lg: "6" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CUSTOMER.CLIENT_ID")), 1),
                              _createTextVNode(" " + _toDisplayString(_ctx.client?.kundennummer ? _ctx.client.kundennummer : "-"), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { lg: "6" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_4, [
                                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CUSTOMER.OFFEREE")), 1),
                                _createElementVNode("span", null, [
                                  _createVNode(_component_SelectionDialog, {
                                    "step-one-title": 
                        _ctx.$tc('COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CUSTOMER.OFFEREE')
                      ,
                                    "step-two-title": _ctx.getSecondTitle(),
                                    onItemOneSelected: _ctx.onRecipientSelected,
                                    onItemTwoSelected: _ctx.onContactSelected,
                                    onSubmit: _ctx.submitRecipient
                                  }, {
                                    activator: _withCtx(({ props }) => [
                                      _createElementVNode("div", _normalizeProps(_guardReactiveProps(props)), [
                                        _createVNode(_component_v_icon, {
                                          class: "px-1",
                                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeStepsAmount(2), _ctx.getSecondTitle()))
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode("mdi-card-search")
                                          ]),
                                          _: 1
                                        })
                                      ], 16)
                                    ]),
                                    stepOne: _withCtx(({ onItemSelected, stepper }) => [
                                      (stepper === 1)
                                        ? (_openBlock(), _createBlock(_component_RecipientSelection, {
                                            key: 0,
                                            "initial-value": _ctx.recipient?.kundennummer,
                                            onItemSelected: onItemSelected
                                          }, null, 8, ["initial-value", "onItemSelected"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    stepTwo: _withCtx(({ onItemSelected, stepper }) => [
                                      (stepper === 2)
                                        ? (_openBlock(), _createBlock(_component_ContactSelection, {
                                            key: 0,
                                            client: _ctx.selectedRecipient,
                                            onItemSelected: onItemSelected
                                          }, null, 8, ["client", "onItemSelected"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                  }, 8, ["step-one-title", "step-two-title", "onItemOneSelected", "onItemTwoSelected", "onSubmit"])
                                ])
                              ]),
                              _createTextVNode(" " + _toDisplayString(_ctx.recipient?.name ? _ctx.recipient.name : "-"), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { lg: "6" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CUSTOMER.OFFEREE_ID")), 1),
                              _createTextVNode(" " + _toDisplayString(_ctx.recipient?.kundennummer ? _ctx.recipient.kundennummer : "-"), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { lg: "6" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CUSTOMER.CONTACT_PERSON")), 1),
                              _createTextVNode(" " + _toDisplayString(_ctx.contact?.businessPartnerFullName ? _ctx.contact.businessPartnerFullName : "-"), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { lg: "6" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CUSTOMER.CONTACT_PERSON_ID")), 1),
                              _createTextVNode(" " + _toDisplayString(_ctx.contact?.contactId ? _ctx.contact.contactId : "-"), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { lg: "6" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CUSTOMER.CONTACT_PERSON_MAIL")), 1),
                              _createTextVNode(" " + _toDisplayString(_ctx.contact?.emailAddress ? _ctx.contact.emailAddress : "-"), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { lg: "6" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CUSTOMER.CONTACT_PERSON_PHONE")), 1),
                              _createTextVNode(" " + _toDisplayString(_ctx.contact?.phoneNumber ? _ctx.contact.phoneNumber : "-"), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { lg: "6" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CUSTOMER.CLIENT_ADDRESS")), 1),
                              (_ctx.client?.strasse)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                    _createElementVNode("div", null, _toDisplayString(_ctx.client.strasse), 1),
                                    _createElementVNode("div", null, _toDisplayString(_ctx.client.plz) + " " + _toDisplayString(_ctx.client.ort), 1)
                                  ]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_13, " - "))
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { lg: "6" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CUSTOMER.OFFEREE_ADDRESS")), 1),
                              (_ctx.recipient?.strasse)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                    _createElementVNode("div", null, _toDisplayString(_ctx.recipient.strasse), 1),
                                    _createElementVNode("div", null, _toDisplayString(_ctx.recipient.plz) + " " + _toDisplayString(_ctx.recipient.ort), 1)
                                  ]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_16, " - "))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}