import { Boq, BoqDto } from "@/core/models/boq/Boq.model";
import store from "@/core/store";
import axios, { AxiosRequestConfig } from "axios";
import GetConfig, { applyGetConfig } from "../base/get.config";

const resource = "boq";

export function loadBoqList(getConfig?: GetConfig): Promise<Boq[]> {
  store.commit("setLoading", true);
  let config: AxiosRequestConfig = {};
  if (getConfig) {
    config = applyGetConfig(getConfig, config);
  }

  return axios
    .get(`/${resource}/list`, config)
    .then((response) => {
      return response && response.data
        ? response.data.map((item: BoqDto) => new Boq(item))
        : new Boq();
    })
    .finally(() => store.commit("setLoading", false));
}
