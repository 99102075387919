import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_ImportQuotationDialog = _resolveComponent("ImportQuotationDialog")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_ActionButton = _resolveComponent("ActionButton")!
  const _component_BoqDataTable = _resolveComponent("BoqDataTable")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, {
    class: "full-height",
    fluid: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createElementVNode("h1", null, _toDisplayString("Angebote" + (_ctx.dataBaseComment ? " - " + _ctx.dataBaseComment : "")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            "align-self": "end",
            align: "right"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                disabled: _ctx.disableCreateButton,
                class: _normalizeClass({ 'dark-btn': !_ctx.disableCreateButton }),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialog = true))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    color: "white",
                    start: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" mdi-pencil ")
                    ]),
                    _: 1
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$tc("COMPONENTS.QUOTATION.QUOTATIONLIST.EDIT_COMMENT")), 1)
                ]),
                _: 1
              }, 8, ["disabled", "class"]),
              _createVNode(_component_v_dialog, {
                modelValue: _ctx.dialog,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dialog) = $event)),
                width: "500",
                "onClick:outside": _cache[5] || (_cache[5] = ($event: any) => (_ctx.formComment = _ctx.dataBaseComment))
              }, {
                default: _withCtx(() => [
                  (_ctx.dialog)
                    ? (_openBlock(), _createBlock(_component_v_form, {
                        key: 0,
                        ref: "myForm",
                        onSubmit: _cache[3] || (_cache[3] = ($event: any) => (_ctx.submit($event)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card, _normalizeProps(_guardReactiveProps({ loading: _ctx.submitted, disabled: _ctx.submitted })), {
                            default: _withCtx(() => [
                              _createVNode(_component_v_card_title, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.QUOTATION.QUOTATIONLIST.EDIT_COMMENT")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_card_text, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_textarea, {
                                    ref: "formComment",
                                    modelValue: _ctx.formComment,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formComment) = $event)),
                                    label: _ctx.$tc('BOQ.ADDBOQDIALOG.COMMENT'),
                                    variant: "outlined"
                                  }, null, 8, ["modelValue", "label"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_divider),
                              _createVNode(_component_v_card_actions, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_spacer),
                                  _createVNode(_component_v_btn, {
                                    variant: "outlined",
                                    onClick: _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dialog = false), (_ctx.formComment = _ctx.dataBaseComment)))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t("BOQ.ADDBOQDIALOG.CANCEL")), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_btn, {
                                    class: "dark-btn",
                                    elevation: "0",
                                    type: "submit"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t("BOQ.ADDBOQDIALOG.SAVE")), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 16)
                        ]),
                        _: 1
                      }, 512))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _createVNode(_component_v_btn, {
                class: _normalizeClass(["ml-2", { 'dark-btn': !_ctx.disableCreateButton }]),
                disabled: _ctx.disableCreateButton,
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.createQuotation()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    color: "white",
                    start: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" mdi-plus ")
                    ]),
                    _: 1
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$tc("COMPONENTS.QUOTATION.QUOTATIONLIST.NEW_QUOTATION")), 1)
                ]),
                _: 1
              }, 8, ["disabled", "class"]),
              (_ctx.boqId)
                ? (_openBlock(), _createBlock(_component_ImportQuotationDialog, {
                    key: 0,
                    "boq-id": +_ctx.boqId,
                    onSuccess: _cache[7] || (_cache[7] = ($event: any) => (_ctx.listQuotations()))
                  }, null, 8, ["boq-id"]))
                : _createCommentVNode("", true),
              _createVNode(_component_v_btn, {
                class: "dark-btn ml-2",
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.navigateToBoqOverview()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    color: "white",
                    start: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" mdi-arrow-left ")
                    ]),
                    _: 1
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$tc("COMPONENTS.QUOTATION.QUOTATIONLIST.BACK")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_divider, { class: "mb-3" }),
      _createVNode(_component_v_container, {
        class: "pa-0 table-container elevation-5 rounded-lg",
        fluid: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BoqDataTable, {
            "get-config": _ctx.getConfig,
            "onUpdate:getConfig": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.getConfig) = $event)),
            items: _ctx.quotationList,
            headers: _ctx.headers,
            loading: _ctx.loading,
            "onClick:row": _ctx.navigateToDetailView
          }, {
            [`item.boqId`]: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.boqId), 1)
            ]),
            [`item.productHierarchy`]: _withCtx(({ item }) => [
              _createVNode(_component_v_tooltip, { location: "bottom" }, {
                activator: _withCtx(({ props }) => [
                  _createElementVNode("div", _normalizeProps(_guardReactiveProps(props)), _toDisplayString(item.productHierarchyFirst), 17)
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" " + _toDisplayString(item.productHierarchy), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            [`item.status`]: _withCtx(({ item }) => [
              _createElementVNode("div", {
                class: _normalizeClass({
              'status-cancelled': ['Abgebrochen', 'Fehlgeschlagen'].includes(item.status),
              'quotation-disabled': ['Konvertierung', 'Wartet', 'Abgebrochen'].includes(
                item.status
              ),
            })
              }, _toDisplayString(item.status), 3)
            ]),
            [`item.actions`]: _withCtx(({ item }) => [
              _createVNode(_component_ActionButton, {
                tooltip: "Löschen",
                icon: "mdi-delete",
                onButtonClicked: ($event: any) => (_ctx.deleteQuotation(item.id))
              }, null, 8, ["onButtonClicked"])
            ]),
            _: 2
          }, 1032, ["get-config", "items", "headers", "loading", "onClick:row"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}