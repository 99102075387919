import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.dialog,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dialog) = $event)),
    width: "350"
  }, {
    activator: _withCtx(({ props }) => [
      _createVNode(_component_v_btn, _mergeProps(props, {
        class: "dark-btn mb-3 px-1",
        icon: "mdi-delete",
        size: "small",
        style: {"min-width":"0"},
        rounded: ""
      }), null, 16)
    ]),
    default: _withCtx(() => [
      (_ctx.dialog)
        ? (_openBlock(), _createBlock(_component_v_form, {
            key: 0,
            ref: "deleteDialog",
            onSubmit: _cache[1] || (_cache[1] = ($event: any) => (_ctx.submit($event)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Artikel entfernen")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_text, { class: "card1" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createTextVNode(" Wollen Sie den Artikel wirklich entfernen? ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_divider),
                  _createVNode(_component_v_card_actions, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_spacer),
                      _createVNode(_component_v_btn, {
                        variant: "outlined",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialog = false))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Abbrechen ")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_btn, {
                        elevation: "0",
                        type: "submit",
                        class: "dark-btn"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Entfernen ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 512))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}