<template>
  <v-dialog v-model="dialog" width="350">
    <template #activator="{ props }">
      <v-btn
        v-bind="props"
        class="dark-btn mb-3 px-1"
        icon="mdi-delete"
        size="small"
        style="min-width: 0"
        rounded
      />
    </template>

    <v-form v-if="dialog" ref="deleteDialog" @submit="submit($event)">
      <v-card>
        <v-card-title>Artikel entfernen</v-card-title>
        <v-card-text class="card1">
          <v-row> Wollen Sie den Artikel wirklich entfernen? </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn variant="outlined" @click="dialog = false">
            Abbrechen
          </v-btn>
          <v-btn elevation="0" type="submit" class="dark-btn">
            Entfernen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
    props: {
        positionArticleId: {
            type: Number
        }
    },
    data() {
        const successAlert: any = {
                type: "success",
                message: "Der Artikel wurde erfolgreich entfernt!",
              };

        return {
            dialog: false,
            successAlert
        };
    },
    computed: {
        quotationDetail(): any {
            return this.$store.getters["quotation/quotationDetail"];
        }
    },
    methods: {
        submit(event: any) {
            event.preventDefault();

                axios
                  .post(`/position/article/${this.positionArticleId}/remove`)
                  .then((response) => {
                    if (response.status === 200) {
                      this.$store.commit("activateAlert", this.successAlert);
                      this.$emit("remove", response.data);
                      this.close();
                      this.$store.dispatch("quotation/loadActionLog", this.quotationDetail.id);
                    }
                  })
                  .catch(() => {
                    this.handleError();
                  });
        },
        handleError() {
            this.$store.commit("activateAlert", this.$store.state.errorAlert);
        },
        close() {
            this.dialog = false;
        }
    }
})

</script>

<style lang="scss" scoped>
.card1 {
  flex-grow: 1;
}

.button-container {
  flex-grow: 0;
}
</style>
