import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c12ee52"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card__title" }
const _hoisted_2 = { class: "card__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_responsive = _resolveComponent("v-responsive")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "pa-0 mr-2 mb-4" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_responsive, { class: "overflow-y-auto fill-height pa-0 ma-0" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, {
            class: "main-card",
            variant: "flat"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_INFORMATION.TITLE")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_container, {
                    fluid: "",
                    class: "px-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { lg: "6" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$tc(
                      "COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_INFORMATION.NUMBER_OF_POSITIONS"
                    )), 1),
                              _createTextVNode(" " + _toDisplayString(_ctx.quotation && _ctx.quotation.positionCount ? _ctx.quotation.positionCount : "-"), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { lg: "6" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$tc(
                      "COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_INFORMATION.PRODUCT_DIVISION"
                    )), 1),
                              _createTextVNode(" " + _toDisplayString(_ctx.quotation && _ctx.quotation.productHierarchy ? _ctx.quotation.productHierarchy : "-"), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}