import { Keyword, KeywordDto } from "@/core/models/keyword/Keyword.model";
import store from "@/core/store";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { i18nGlobalTc } from "@/i18n";
import GetConfig, { applyGetConfig } from "../base/get.config";

function getList(keywordEnum: string) {
  return axios
    .get(`keyword/${keywordEnum}/list`)
    .then((response) => {
      store.commit("setLoading", false);
      const result: Keyword[] = response.data.map((dto: KeywordDto) => new Keyword(dto));
      return result;
    })
    .catch(() => {
      store.commit("setLoading", false);
      return [];
    });
}

export function loadKeywords(): Promise<Keyword[]> {
  store.commit("setLoading", true);
  return getList("TAG");
}

export function loadUnits(): Promise<Keyword[]> {
  store.commit("setLoading", true);
  return getList("UNIT");
}

function filterElements(keywordEnum: string, getConfig?: GetConfig) {
  store.commit("setLoading", true);
  let config: AxiosRequestConfig = {};
  if (getConfig) {
    config = applyGetConfig(getConfig, config);
  }
  if (config.params) {
    config.params = {
      marketId: config.params.marketId || -1,
      value: config.params.value || "",
    };
  }
  return axios
    .get(`keyword/${keywordEnum}/filter`, config)
    .then((response) => {
      store.commit("setLoading", false);
      const result: Keyword[] = response.data.map((dto: KeywordDto) => new Keyword(dto));
      return result;
    })
    .catch(() => {
      store.commit("setLoading", false);
      return [];
    });
}

export function filterKeywords(getConfig?: GetConfig): Promise<Keyword[]> {
  return filterElements("TAG", getConfig);
}

export function filterUnits(getConfig?: GetConfig): Promise<Keyword[]> {
  return filterElements("UNIT", getConfig);
}

function addElement(
  element: {
    value: string;
    marketId?: number;
  },
  pathVariable: string
): Promise<AxiosResponse<any>> {
  const translationValue = pathVariable == "UNIT" ? "UNIT" : "KEYWORD";
  return axios
    .post(`keyword/${pathVariable}/add`, undefined, {
      params: { value: element.value, marketId: element.marketId },
    })
    .then((response) => {
      if (response.status === 200) {
        store.commit(
          "activateAlert",
          {
            type: "success",
            message: i18nGlobalTc(`SERVICES.SETTINGS.${translationValue}.ADD_SUCCESS`),
          },
          { root: true }
        );
      } else {
        store.commit(
          "activateAlert",
          {
            type: "error",
            message: i18nGlobalTc(`SERVICES.SETTINGS.${translationValue}.ADD_ERROR`),
          },
          { root: true }
        );
      }
      return response;
    })
    .catch((err) => {
      console.error(err);
      return err;
    });
}

export function addKeyword(keyword: {
  value: string;
  marketId?: number;
}): Promise<AxiosResponse<any>> {
  return addElement(keyword, "TAG");
}

export function addUnit(keyword: {
  value: string;
  marketId?: number;
}): Promise<AxiosResponse<any>> {
  return addElement(keyword, "UNIT");
}

function updateElement(element: Keyword, isKeyword?: boolean) {
  const translationValue = isKeyword ? "KEYWORD" : "UNIT";
  return axios
    .put(`keyword/${element.id}`, undefined, {
      params: {
        value: element.value,
        marketId: element.marketId,
      },
    })
    .then((response) => {
      if (response.status === 200) {
        store.commit(
          "activateAlert",
          {
            type: "success",
            message: i18nGlobalTc(`SERVICES.SETTINGS.${translationValue}.CHANGE_SUCCESS`),
          },
          { root: true }
        );
      } else {
        store.commit(
          "activateAlert",
          {
            type: "error",
            message: i18nGlobalTc(`SERVICES.SETTINGS.${translationValue}.CHANGE_ERROR`),
          },
          { root: true }
        );
      }
      return response;
    });
}

export function updateKeyword(keyword: Keyword) {
  return updateElement(keyword, true);
}

export function updateUnit(keyword: Keyword) {
  return updateElement(keyword);
}

function deleteElement(element: Keyword, isKeyword?: boolean) {
  const translationValue = isKeyword ? "KEYWORD" : "UNIT";
  return axios.delete(`keyword/${element.id}`).then((response) => {
    if (response.status === 200) {
      store.commit(
        "activateAlert",
        {
          type: "success",
          message: i18nGlobalTc(`SERVICES.SETTINGS.${translationValue}.DELETE_SUCCESS`),
        },
        { root: true }
      );
    } else {
      store.commit(
        "activateAlert",
        {
          type: "error",
          message: i18nGlobalTc(`SERVICES.SETTINGS.${translationValue}.DELETE_ERROR`),
        },
        { root: true }
      );
    }
    return response;
  });
}

export function deleteKeyword(keyword: Keyword) {
  return deleteElement(keyword, true);
}

export function deleteUnit(keyword: Keyword) {
  return deleteElement(keyword);
}

export function loadProductHierarchy() {
  store.commit("setLoading", true);
  return axios
    .get(`settings/productHierarchyLevel`)
    .then((response) => {
      if (response.status === 200) {
        store.commit("setLoading", false);
        return response.data;
      }
    })
    .catch(() => {
      store.commit("setLoading", false);
      return [];
    });
}

export function updateProductHierarchy(value: number): Promise<AxiosResponse<any>> {
  return axios
    .post(`settings/productHierarchyLevel`, undefined, {
      params: {
        level: value,
      },
    })
    .then((response) => {
      if (response.status === 200) {
        store.commit(
          "activateAlert",
          {
            type: "success",
            message: i18nGlobalTc("SERVICES.SETTINGS.PRODUCTDIVISION.CHANGE_SUCCESS"),
          },
          { root: true }
        );
      } else {
        store.commit(
          "activateAlert",
          {
            type: "error",
            message: i18nGlobalTc("SERVICES.SETTINGS.PRODUCTDIVISION.CHANGE_ERROR"),
          },
          { root: true }
        );
      }
      return response;
    });
}
