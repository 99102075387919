import { Market, MarketDTO } from "@/core/models/market/market.model";
import store from "@/core/store";
import axios, { AxiosRequestConfig } from "axios";
import GetConfig, { applyGetConfig } from "../base/get.config";
import { i18nGlobalTc } from "@/i18n";

export function loadMarketList(getConfig?: GetConfig): Promise<Market[]> {
  store.commit("setLoading", true);
  let config: AxiosRequestConfig = {};
  if (getConfig) {
    config = applyGetConfig(getConfig, config);
  }
  return axios
    .get(`/market`, config)
    .then((response) => {
      return response && response.data
        ? response.data.map((item: MarketDTO) => new Market(item))
        : new Market();
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => store.commit("setLoading", false));
}

export function getCountryName(id?: number, marketObject?: Market[]) {
  if (id && marketObject) {
    const countryElement = marketObject.filter((market) => market.id == id);
    if (countryElement.length == 1) {
      const translatedLanguageName = i18nGlobalTc("LANGUAGEKEYS." + countryElement[0].language);
      return translatedLanguageName;
    }
  }
  return "";
}
