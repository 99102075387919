export function getIsoDateString(date: string | Date): string {
  if(date) {
    return new Date(date).toISOString().substr(0, 10);
  }
  return '';
}

export function formatDate(date: string | Date): string {
  const dateString = getIsoDateString(date);
  const [year, month, day] = dateString.split('-');
  return `${day}.${month}.${year}`
}