import { Module } from "vuex";
import { actions } from "./action";
import mutations from "./mutations";
import { getters } from "./getters";
import { RootState } from "~core/store/state";
import MarketState, { state } from "./state";

export const marketModule: Module<MarketState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
