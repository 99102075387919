import { RootState } from "@/core/store/state";
import { GetterTree } from "vuex";
import QuotationState from "./state";

export const getters: GetterTree<QuotationState, RootState> = {
  quotationList: (state) => state.quotationList,
  quotationDetail: (state) => state.quotationDetail,
  actionLog: (state) => state.actionLog,
  validationValue: (state) => state.validationValue,
};
