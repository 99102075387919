import { ActionTree } from 'vuex';
import { RootState } from "@/core/store/state";
import ArticleState from "./state";
import GetConfig from '@/core/services/base/get.config';
import * as ArticleService from '@/core/services/article/article.service';

export const actions: ActionTree<ArticleState, RootState> = {
  loadArticleList(context, config?: GetConfig) {
    ArticleService.loadArticleList(config).then(response => {
      context.commit('setArticleList', response)
    })
  },

  appendArticleList(context, config?: GetConfig) {
   return ArticleService.loadArticleList(config).then(response => {
      context.commit('appendArticleList', response)
    })
  },

  clearArticleList(context) {
    context.commit('setArticleList', Array.of({}))
  }
}
