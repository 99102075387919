<template>
  <v-navigation-drawer color="#363636" disable-resize-watcher disable-route-watcher>
    <v-list v-if="currentUser" class="nav-list">
      <v-list-item prepend-icon="mdi-home" title="BOQ-Validator" :to="{ name: 'Home' }" />
      <v-list-item
        link
        :to="{ name: 'BoqOverview' }"
        prepend-icon="mdi-format-list-bulleted-square"
        :title="$tc('COMPONENTS.NAVIGATIONDRAWER.BOQ_OVERVIEW')"
      />

      <v-list-item
        link
        :to="{ name: 'Articles' }"
        prepend-icon="mdi-folder"
        :title="$tc('COMPONENTS.NAVIGATIONDRAWER.ARTICLE')"
      />

      <v-list-item
        v-if="['ROLE_MANAGER', 'ROLE_ADMIN'].includes(currentUser.role)"
        link
        :to="{ name: 'Settings' }"
        prepend-icon="mdi-cog"
        :title="$tc('COMPONENTS.NAVIGATIONDRAWER.SETTINGS')"
      />

      <v-list-item
        v-if="['ROLE_MANAGER', 'ROLE_ADMIN'].includes(currentUser.role)"
        prepend-icon="mdi-account-multiple"
        :title="$tc('COMPONENTS.NAVIGATIONDRAWER.USER_ADMIN')"
        :to="{ name: 'UserAdministrationOverview' }"
      />

      <v-list-item id="language-nav-element">
        <v-select
          v-model="inputLanguage"
          :key="selectLanguage"
          :items="computedLanguages"
          :label="$tc('COMPONENTS.NAVIGATIONDRAWER.LANGUAGE')"
          density="compact"
          variant="outlined"
          class="pt-2"
        >
          <template #item="{ props, item }">
            <v-list-item v-bind="props" :title="item.title">
              <template #append>
                <country-flag size="normal" :country="getFlagISO(item.value)" align="center" />
              </template>
            </v-list-item>
          </template>
        </v-select>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { User } from "@/core/models/user/User.model";
import { defineComponent } from "vue";

export default defineComponent({
    data() {
        return {
            selectLanguage: 0,
            inputLanguage: "de"
        };
    },
    computed: {
        computedLanguages() {
            return [
              {
                title: this.$tc("COMPONENTS.NAVIGATIONDRAWER.SELECTED_LANGUAGE.de_DE"),
                value: "de",
              },
              {
                title: this.$tc("COMPONENTS.NAVIGATIONDRAWER.SELECTED_LANGUAGE.en_US"),
                value: "en",
              },
            ];
        },
        currentUser(): User {
            return this.$store.getters["user/currentUser"];
        }
    },
    watch: {
        "inputLanguage": [{
            handler: "selectedLanguage"
        }]
    },
    methods: {
        getFlagISO(value: string): string {
            return value === "en" ? "us" : value;
        },
        selectedLanguage() {
            this.$i18n.locale = this.inputLanguage;
            this.$vuetify.locale.current = this.inputLanguage
        }
    }
})

</script>

<style lang="scss" scoped>
::v-deep .v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 20px;
}

#language-nav-element {
  padding-top: 18px;
}

.normal-flag {
  position: absolute;
  right: 10%;
  bottom: 25%;
  margin: 0;
}

.nav-list {
  &:deep(.v-list-item.v-list-item--active) {
    > .v-list-item__content {
      color: #ff0 !important;
    } 
  }
}
</style>
