import { BoqTableHeader } from "@/core/interfaces/boq-table-header.interface";
import { i18nGlobalTc } from "@/i18n";

export const headers: BoqTableHeader[] = [
  {
    value: "kundennummer",
    title: i18nGlobalTc(
      "COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.HEADERS.BIDDER_SELECTION.CLIENT_NUMBER"
    ),
    filterable: true,
  },
  {
    value: "name",
    title: i18nGlobalTc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.HEADERS.BIDDER_SELECTION.NAME"),
    filterable: true,
  },
  {
    value: "strasse",
    title: i18nGlobalTc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.HEADERS.BIDDER_SELECTION.STREET"),
    filterable: true,
  },
  {
    value: "plz",
    title: i18nGlobalTc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.HEADERS.BIDDER_SELECTION.POSTAL_CODE"),
    filterable: true,
  },
  {
    value: "ort",
    title: i18nGlobalTc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.HEADERS.BIDDER_SELECTION.LOCATION"),
    filterable: true,
  },
];
