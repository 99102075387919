import { ActionTree } from 'vuex';
import * as BoqService from '@/core/services/boq/boq.service';
import { RootState } from '@/core/store/state';
import BoqState from './state';
import { Boq } from '@/core/models/boq/Boq.model';
import GetConfig from '@/core/services/base/get.config';

export const actions: ActionTree<BoqState, RootState> = {
  loadBoqList(context, config?: GetConfig) {
    BoqService.loadBoqList(config).then(response => {
      context.commit('setBoqList', response);
    });
  },

  clearBoqList(context) {
    context.commit('setBoqList', Array.of(new Boq()))
  }
};
