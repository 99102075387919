<template>
  <v-form ref="myForm" @submit="submit($event)">
    <v-card class="text-center">
      <v-card-title>Box bearbeiten</v-card-title>

      <v-card-text>
        <v-text-field v-model="form.value" label="Text"></v-text-field>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn variant="outlined" @click="close()"> Abbrechen </v-btn>
        <v-btn class="dark-btn" elevation="0" type="submit"> Hinzufügen </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
    data() {
        const successAlert: any = {
                type: "success",
                message: "Der Text-Wert wurde erfolgreich bearbeitet!",
              };
        const form: any = {
                value: undefined,
              };

        return {
            form,
            successAlert
        };
    },
    mounted() {
        this.initFormData();
    },
    methods: {
        initFormData() {
            this.form.value = this.clickedBox.value;
        },
        submit(event: any) {
            event.preventDefault();
            const params = {
              line: this.clickedBox.line,
              page: this.clickedBox.page,
              word: this.clickedBox.word,
              value: this.form.value,
            };
            this.$store.commit("activateAlert", this.successAlert);
            this.$emit("finish", params);
            this.close();
        },
        close() {
            this.$emit("close");
        }
    },
    props: {
        clickedBox: {
            type: Object as PropType<any>
        }
    },
    computed: {
        myForm() {
            return this.$refs["myForm"] as HTMLFormElement;
        }
    }
})

</script>

<style lang="scss" scoped>
.v-card-title {
  display: block; //weil der Card-Title sonst nicht centered wird
}
</style>
