import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MasterDataCustomer = _resolveComponent("MasterDataCustomer")!
  const _component_MasterDataFurtherContact = _resolveComponent("MasterDataFurtherContact")!
  const _component_MasterDataOpportunity = _resolveComponent("MasterDataOpportunity")!
  const _component_MasterDataBillOfQuantity = _resolveComponent("MasterDataBillOfQuantity")!
  const _component_MasterDataFurtherInformation = _resolveComponent("MasterDataFurtherInformation")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "pa-0" }, {
    default: _withCtx(() => [
      _createVNode(_component_MasterDataCustomer, {
        client: _ctx.masterdata.client,
        recipient: _ctx.masterdata.recipient,
        contact: _ctx.masterdata.contact,
        salesArea: _ctx.masterdata.salesArea,
        onSubmitMasterdata: _ctx.submitMasterdata
      }, null, 8, ["client", "recipient", "contact", "salesArea", "onSubmitMasterdata"]),
      _createVNode(_component_MasterDataFurtherContact, {
        masterData: _ctx.masterdata,
        planner: _ctx.masterdata.planner,
        onSubmitMasterdata: _ctx.submitMasterdata
      }, null, 8, ["masterData", "planner", "onSubmitMasterdata"]),
      _createVNode(_component_MasterDataOpportunity, {
        opportunity: _ctx.masterdata.opportunity,
        onSubmitMasterdata: _ctx.submitMasterdata
      }, null, 8, ["opportunity", "onSubmitMasterdata"]),
      _createVNode(_component_MasterDataBillOfQuantity, {
        billOfQuantity: _ctx.masterdata.billOfQuantityStammdaten,
        onSubmitMasterdata: _ctx.submitMasterdata
      }, null, 8, ["billOfQuantity", "onSubmitMasterdata"]),
      _createVNode(_component_MasterDataFurtherInformation, { quotation: _ctx.quotation }, null, 8, ["quotation"])
    ]),
    _: 1
  }))
}