export class BillOfQuantityView implements BillOfQuantityDto {
  name = "";
  externalReference = "";
  location = "";
  country = "";
  postalCode = "";
  street = "";

  constructor(dto?: BillOfQuantityDto) {
    if (dto) {
      this.name = dto.name;
      this.externalReference = dto.externalReference;
      this.location = dto.location;
      this.country = dto.country;
      this.postalCode = dto.postalCode;
      this.street = dto.street;
    }
  }
}

export interface BillOfQuantityDto {
  name: string;
  externalReference: string;
  location: string;
  country: string;
  postalCode: string;
  street: string;
}
