export class ClientView implements ClientDto {
  kundennummer = "";
  name = "";
  strasse = "";
  plz = "";
  ort = "";
  partnerObj: PartnerObj[] = [];

  constructor(dto?: ClientDto) {
    if (dto) {
      this.kundennummer = dto.kundennummer;
      this.name = dto.name;
      this.strasse = dto.strasse;
      this.plz = dto.plz;
      this.ort = dto.ort;
      if ((dto.partnerObj as PartnerObj[]).length > 0) {
        this.partnerObj = dto.partnerObj as PartnerObj[];
      }
    }
  }
}

export interface ClientDto {
  kundennummer: string;
  name: string;
  strasse: string;
  plz: string;
  ort: string;
  partnerObj: PartnerObj[];
}

export interface PartnerObj {
  BusinessPartnerPerson: string;
  PartnerNumber: string;
}
