import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!

  return (_openBlock(), _createBlock(_component_v_tooltip, { location: "bottom" }, {
    activator: _withCtx(({ props }) => [
      _createVNode(_component_v_btn, _mergeProps(props, {
        variant: "text",
        icon: "",
        onClick: _withModifiers(_ctx.buttonClicked, ["stop"])
      }), {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, {
            color: _ctx.iconColor,
            size: _ctx.iconSize
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.icon), 1)
            ]),
            _: 1
          }, 8, ["color", "size"])
        ]),
        _: 2
      }, 1040, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createTextVNode(" " + _toDisplayString(_ctx.tooltip), 1)
    ]),
    _: 1
  }))
}