export class Keyword implements KeywordDto {
  id = 0;
  value = "";
  marketId = -1;
  marketName = "";
  type = 0;

  constructor(dto?: KeywordDto) {
    if (dto) {
      this.id = dto.id;
      this.value = dto.value;
      this.marketId = dto.marketId;
      this.marketName = dto.marketName;
      this.type = dto.type;
    }
  }
}

export interface KeywordDto {
  id: number;
  value: string;
  marketId: number;
  marketName: string;
  type: KeywordType;
}

enum KeywordType {
  TAG = 0,
  UNIT = 1,
}
