<template>
  <v-dialog v-model="dialog" width="500">
    <template #activator="{ props }">
      <v-row>
        <v-spacer />
        <v-col cols="auto" class="pa-0">
          <v-btn
            v-bind="props"
            prepend-icon="mdi-plus"
            class="dark-btn"
            @click="(disableUploadAndCheckbox = true), getCurrentCountry()"
          >
            {{ $t("BOQ.ADDBOQDIALOG.ADD_QUOTATION") }}
          </v-btn>
        </v-col>
        <v-col cols="auto" class="py-0">
          <v-btn
            v-bind="props"
            class="dark-btn"
            @click="(disableUploadAndCheckbox = false), getCurrentCountry()"
          >
            <v-icon start>
              mdi-plus
            </v-icon>
            {{ $t("BOQ.ADDBOQDIALOG.ADD_BOQ") }}
          </v-btn>
        </v-col>
      </v-row>
    </template>

    <v-form v-if="dialog" ref="myForm" :lazy-validation="true" @submit="submit($event)">
      <v-card v-bind="{ loading: submitted, disabled: submitted }">
        <v-card-title>{{ formTitle }}</v-card-title>

        <v-card-text>
          <v-row>
            <v-col>
              <v-select
                v-model="form.source"
                :items="sources"
                :label="$t('BOQ.ADDBOQDIALOG.SOURCE')"
                variant="outlined"
                density="compact"
                :rules="[(v) => !!v || $t('BOQ.ADDBOQDIALOG.ERROR_SOURCE')]"
                required
              />
            </v-col>
            <v-col>
              <v-select
                v-model="form.teamId"
                :items="teams"
                item-title="name"
                item-value="id"
                :label="$t('BOQ.ADDBOQDIALOG.WORKING_GROUP')"
                variant="outlined"
                density="compact"
                required
                :rules="[(v) => !!v || $t('BOQ.ADDBOQDIALOG.ERROR_GROUP')]"
              />
            </v-col>
          </v-row>
          <v-textarea
            v-model="form.comment"
            :label="$t('BOQ.ADDBOQDIALOG.COMMENT')"
            variant="outlined"
          />
          <v-file-input
            v-if="!disableUploadAndCheckbox"
            :label="$t('BOQ.ADDBOQDIALOG.UPLOAD_FILE')"
            variant="outlined"
            multiple
            :accept="supportedTypes.join()"
            show-size
            density="compact"
            required
            :rules="[(v) => !!v || $t('BOQ.ADDBOQDIALOG.ERROR_FILE')]"
            @update:model-value="onFileSelected"
          />
          <v-row v-if="!disableUploadAndCheckbox" class="my-0">
            <v-col cols="auto" align-self="start" class="pt-1 pb-5">
              <v-checkbox
                v-model="trimPages"
                class="ma-0"
                density="compact"
                :label="$t('BOQ.ADDBOQDIALOG.LIMIT_PAGES')"
                hide-details
              />
            </v-col>
            <v-col v-if="trimPages" align-self="start" class="pt-0">
              <v-textarea
                v-model="form.pageFilter"
                variant="outlined"
                density="compact"
                placeholder="1, 10-20, 99"
                hide-details
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn variant="outlined"  @click="dialog = false">
            {{ $t("BOQ.ADDBOQDIALOG.CANCEL") }}
          </v-btn>
          <v-btn class="dark-btn" elevation="0" type="submit">
            {{ $t("BOQ.ADDBOQDIALOG.SAVE") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
    data() {
        const successAlert: any = {
                type: "success",
                message: this.$tc("BOQ.ADDBOQDIALOG.BOQ_SUCCESS"),
              };
        const errorAlert: any = {
                type: "error",
                message: this.$tc("BOQ.ADDBOQDIALOG.ERROR"),
              };
        const selectedFiles: File[] = [];
        const form: any = {
                source: undefined,
                teamId: undefined,
                comment: undefined,
                pageFilter: undefined,
              };
        const teams: { id: number; name: string }[] = [];
        const sources: string[] = ["E-Mail", "Post", "Fax"];

        return {
            dialog: false,
            sources,
            teams,
            submitted: false,
            form,
            disableUploadAndCheckbox: false,
            selectedFiles,
            trimPages: false,
            supportedTypes: [".csv", ".pdf", ".xlsx"],
            errorAlert,
            successAlert,
            invalidFileType: {
                    type: "error",
                    message: this.$tc("BOQ.ADDBOQDIALOG.FILETYPE_ERROR"),
                  },
            multipleFileType: {
                    type: "error",
                    message: this.$tc("BOQ.ADDBOQDIALOG.MULTIPLE_FILETYPE_ERROR"),
                  }
        };
    },
    computed: {
        formTitle(): string {
            return this.disableUploadAndCheckbox
            ? this.$tc("BOQ.ADDBOQDIALOG.QUOTATION_ENTRY_MASK")
            : this.$tc("BOQ.ADDBOQDIALOG.BOQ_ENTRY_MASK");
        },
        myForm() {
            return this.$refs["myForm"] as HTMLFormElement;
        }
    },
    watch: {
        "trimPages": [{
            handler: "inTrimPagesChange"
        }]
    },
    methods: {
        async getCurrentCountry() {
            try {
              const response = await axios({
                method: `get`,
                url: `/team/teamsOfCurrentCountry`,
              });
              if (response && response.data && response.status == 200) {
                this.teams = response.data;
              }
            } catch (err) {
              console.error(err);
            }
        },
        submit(event: any) {
            event.preventDefault();
                const validation = (this.$refs.myForm as any).validate();

                if (validation) {
                  this.submitted = true;
                  const fd = new FormData();

                  fd.append("info", new Blob([JSON.stringify(this.form)], { type: "application/json" }));

                  if (this.selectedFiles.length > 0) {
                    this.selectedFiles.forEach((file: any) => {
                      fd.append("files", file, file.name);
                    });
                    //Check if correct Datatype was choosen
                    const nameEndings: string[] = [];
                    this.selectedFiles.forEach((file: any) => {
                      const fileName = file.name.toLowerCase();
                      nameEndings.push(fileName.slice(fileName.lastIndexOf(".")));
                    });
                    const numberOfDifferentTypes = new Set(nameEndings).size;
                    if (numberOfDifferentTypes != 1) {
                      this.handleMultipleFileType();
                      return;
                    }
                    let typeError = false;
                    nameEndings.forEach((ending) => {
                      if (!this.supportedTypes.includes(ending)) {
                        this.handleUnsupportedFileType();
                        typeError = true;
                        return;
                      }
                    });

                    if (typeError) return;
                  }

                  axios
                    .post("/boq/create", fd)
                    .then((response) => this.handleResponse(response))
                    .catch(() => {
                      this.handleError();
                    });
                }
        },
        handleResponse(response: any) {
            console.log(response);
            if (response.status === 200) {
              this.$store.commit("activateAlert", this.successAlert);
              this.dialog = false;
              //trigger parent component to refresh lists
              this.emitSucces();
              this.clearForm();
            } else {
              this.handleError();
            }
        },
        emitSucces(): void {
            this.$emit("success");
        },
        handleError() {
            this.submitted = false;
            this.$store.commit("activateAlert", this.errorAlert);
        },
        handleUnsupportedFileType() {
            this.submitted = false;
            this.$store.commit("activateAlert", this.invalidFileType);
        },
        handleMultipleFileType() {
            this.submitted = false;
            this.$store.commit("activateAlert", this.multipleFileType);
        },
        clearForm() {
            this.submitted = false;
            this.myForm.reset();
        },
        addLVMode() {
            this.disableUploadAndCheckbox = false;
        },
        onFileSelected(files: File[]) {
            this.selectedFiles = files;
        },
        inTrimPagesChange() {
            this.form.pageFilter = undefined;
        }
    }
})

</script>
