<template>
  <div class="position-tree">
    <!-- eslint-disable-next-line vue/no-v-for-template-key -->
    <div v-for="item in items" :key="item.id">
      <drag
        class="drag"
        :transfer-data="{ item }"
        @drag="$emit('drag', item)"
        @dragend="$emit('dragend')"
      >
        <!-- image slot doesnt seem to work anymore after the switch from vue-drag-drop to vue3-drag-drop -->
        <!--template #image>
          <div class="drag-content">
            <v-container class="pa-1 py-0">
              <v-row class="tree-title py-1" dense>
                <v-col> {{ item.name }}</v-col>
              </v-row>
            </v-container>
          </div>
        </template-->
        <template #default="{ transferData }">
          <div v-if="transferData">
            <v-row class="dragActive" dense>
              <v-container class="pa-1 py-0">
                <v-row class="tree-title py-1" dense>
                  <v-col>{{ transferData.item.name }}</v-col>
                </v-row>
              </v-container>
            </v-row>
          </div>
          <!-- original item -->
          <div v-else>
            <PositionTreeTitle
              v-if="!item.name.startsWith('Content')"
              :position="item"
              :show-add-button="showAddButton(item)"
              :dialog-fast-entry="dialogFastEntry"
              :expanded="expandedItemIds?.some(i => i === item.id) ?? false"
              :selected="selectedItemIds?.some(id => id === item.id) ?? false"
              :loading="loadingIds.some(id => id === item.id)"
              @click:add-single="$emit('click:add-single', item)"
              @click:fast-entry="$emit('click:fast-entry', item)"
              @click:import-positions="$emit('click:import-positions', item)"
              @click:edit="$emit('click:edit', item)"
              @click:delete="$emit('click:delete', item)"
              @click:pdf-scroll="$emit('click:pdf-scroll', item)"
              @click:remove-error="$emit('click:remove-error', item, $event)"
              @update:expanded="toggleExpanded(item)"
              @update:selected="toggleSelected(item)"
            />
            <PositionContent
              v-else
              :id="`position-${item.id}`"
              :item="item"
              :dialog-fast-entry="dialogFastEntry"
              @show-prev="$emit('click:prev', item)"
              @show-next="$emit('click:next', item)"
              @update-position="$emit('updatePosition', $event)"
            />
          </div>
        </template>
      </drag>

      <drop class="drop" @drop="(transferData: any, nativeEvent: any) => $emit('drop', item, transferData)">
        <template #default="props">
          <div v-if="props.transferData" class="drag">
            <v-container class="px-1 py-0">
              <v-row class="tree-title py-1" dense>
                <v-col>
                  {{ props.transferData.item.name }}
                </v-col>
              </v-row>
            </v-container>
          </div>
        </template>
      </drop>
      
      <div class="ml-4">
        <PositionTree
          v-if="expandedItemIds?.some(i => i === item.id) ?? false"
          :expanded-item-ids="expandedItemIds"
          :selected-item-ids="selectedItemIds"
          :items="item.children ?? []"
          :dialog-fast-entry="dialogFastEntry"
          @click:add-single="$emit('click:add-single', $event)"
          @click:fast-entry="$emit('click:fast-entry', $event)"
          @click:import-positions="$emit('click:import-positions', $event)"
          @click:edit="$emit('click:edit', $event)"
          @click:delete="$emit('click:delete', $event)"
          @click:pdf-scroll="$emit('click:pdf-scroll', $event)"
          @click:remove-error="(pos: PositionDto, index: number) => $emit('click:remove-error', pos, index)"
          @click:prev="$emit('click:prev', $event)"
          @click:next="$emit('click:next', $event)"
          @update-position="$emit('updatePosition', $event)"
          @drop="(pos: PositionDto, transferData: any) => $emit('drop', pos, transferData)"
          @drag="$emit('drag', $event)"
          @expand-item="$emit('expandItem', $event)"
          @close-item="$emit('closeItem', $event)"
          @update:selected-item-ids="$emit('update:selectedItemIds', $event)"
          @update:expanded-item-ids="$emit('update:expandedItemIds', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PositionDto } from '@/core/models/Position/Position.model';
import PositionTreeTitle from './PositionTreeTitle.vue';
import PositionContent from '@/components/position/PositionContent.vue';
import { Drag, Drop } from "vue3-drag-drop";

const props = withDefaults(
  defineProps<{
    items: PositionDto[];
    dialogFastEntry: boolean;
    expandedItemIds: number[];
    selectedItemIds: number[];
    loadingIds?: number[];
  }>(),
  {
    items: () => [],
    expandedItemIds: () => [],
    selectedItemIds: () => [],
    loadingIds: () => [],
  }
);

const emit = defineEmits<{
  (e: 'click:add-single', item: PositionDto): void,
  (e: 'click:fast-entry', item: PositionDto): void,
  (e: 'click:import-positions', item: PositionDto): void,
  (e: 'click:remove-error', item: PositionDto, index: number): void,
  (e: 'click:pdf-scroll', item: PositionDto): void,
  (e: 'click:delete', item: PositionDto): void,
  (e: 'click:edit', item: PositionDto): void,
  (e: 'click:next', item: PositionDto): void,
  (e: 'click:prev', item: PositionDto): void,
  (e: 'updatePosition', position: any): void,
  (e: 'drag', item: PositionDto): void,
  (e: 'dragend'): void,
  (e: 'drop', item: PositionDto, transferData: any): void,
  (e: 'expandItem', item: PositionDto): void,
  (e: 'closeItem', item: PositionDto): void,
  (e: 'update:expandedItemIds', value: number[]): void,
  (e: 'update:selectedItemIds', value: number[]): void,
}>()

function showAddButton(item: any) {
  const positionTypes = ["TEXT", "POSITION"];
  if (item && item.positionType) {
    return !positionTypes.includes(item.positionType);
  }
  return true;
}

function toggleExpanded(item: PositionDto) {
  if (props.expandedItemIds.some(i => i === item.id)) {
    emit('update:expandedItemIds', props.expandedItemIds.filter(i => i !== item.id));
    emit('closeItem', item);
  } else {
    emit('update:expandedItemIds', [ ...props.expandedItemIds, item.id ]);
    emit('expandItem', item);
  }
}

function toggleSelected(item: PositionDto) {
  if (props.selectedItemIds.some(id => id === item.id)) {
    emit('update:selectedItemIds', props.selectedItemIds.filter(id => id !== item.id));
  } else {
    emit('update:selectedItemIds', [ ...props.selectedItemIds, item.id ]);
  }
}
</script>

<style lang="scss">
@use "@/style/styles";

.position-tree {
  .drag {
    cursor: grab;
    width: 100%;
    .drag-content {
      color: #000;
    }
    .dragActive {
      opacity: 0.3;
    }
  }
  .position-content {
    padding: 5px;
  }

  .drop {
    height: 5px;
    padding: 5px 0;
    width: 100%;
    &.dropFirstPos {
      padding-left: 40px;
    }
    .drag {
      opacity: 0.6;
    }
  }

  .tree-title {
    padding: 8px;
    background: styles.$card-header;
    border-color: styles.$card-border;
    border-style: solid;
    border-width: 1px;
    border-radius: 8px 8px 8px 8px;

    &#positiontype-LOS,
    &#positiontype-GEWERK,
    &#positiontype-TITEL,
    &#positiontype-GRUPPE,
    &#positiontype-GRUPPE_ALT,
    &#positiontype-GRUPPE_BED,
    &#positiontype-GRUPPE_EVT {
      background: #999999cc;
    }

    &#positiontype-LOS hr.v-divider,
    &#positiontype-GEWERK hr.v-divider,
    &#positiontype-TITEL hr.v-divider,
    &#positiontype-GRUPPE hr.v-divider,
    &#positiontype-GRUPPE_ALT hr.v-divider,
    &#positiontype-GRUPPE_BED hr.v-divider,
    &#positiontype-GRUPPE_EVT hr.v-divider {
      border-color: rgba(173, 173, 173, 255);
    }

    &#positiontype-TEXT hr.v-divider,
    &#positiontype-POSITION hr.v-divider {
      border-color: rgba(242, 243, 245, 255);
    }
  }

  .description {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
