<template>
  <v-container v-if="userDetail">
    <v-row>
      <v-col>
        <h1>
          {{
            userDetail && userDetail.firstname
              ? $tc("COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_DETAILS.DETAILS")
              : $tc("COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_DETAILS.CREATE")
          }}
        </h1>
      </v-col>
    </v-row>
    <v-divider class="mb-3"></v-divider>
    <v-container class="elevation-5 rounded-lg">
      <v-form>
        <v-row>
          <v-col>
            <v-text-field
              :label="$tc('COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.FIRST_NAME')"
              type="text"
              v-model="userDetail.firstname"
            />
          </v-col>
          <v-col>
            <v-text-field
              :label="$tc('COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.LAST_NAME')"
              type="text"
              v-model="userDetail.lastname"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              :label="$tc('COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.E_MAIL_ADDRESS')"
              type="email"
              v-model="userDetail.email"
            />
          </v-col>
          <v-col>
            <v-select
              :label="$tc('COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.ROLE')"
              type="text"
              v-model="userDetail.role"
              :items="roles"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              :label="$tc('COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.COUNTRY')"
              type="text"
              :items="marketList"
              item-title="name"
              item-value="id"
              @update:model-value="onChange"
              v-model="userDetail.marketId"
            />
          </v-col>
          <v-col>
            <v-select
              :label="$tc('COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.SALES_TEAM')"
              type="text"
              v-model="userDetail.teamId"
              :items="teams"
              item-value="id"
              item-title="name"
              :rules="[
                (v) =>
                  v != -1 ||
                  !!!v ||
                  $tc('COMPONENTS.POSITION.POSITIONARTICLEDIALOG.RULES.NOT_EMPTY'),
              ]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              :label="$tc('COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.USERNAME_SAP')"
              type="text"
              v-model="userDetail.sapNumber"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn @click="navigateBack()" class="dark-btn">{{
              $tc("COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.CANCEL")
            }}</v-btn>
            <v-btn class="dark-btn ml-2" @click="submitUser()">{{
              $tc("COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.SAVE")
            }}</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-container>
</template>

<script lang="ts">
import { User } from "@/core/models/user/User.model";
import { rolesMapping } from "./mappings/roles.mapping";
import axios from "axios";
import { Market } from "@/core/models/market/market.model";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  components: {},
    data() {
        const teams: any[] = [];
        const roles: any[] = [];

        return {
            roles,
            teams
        };
    },
    async mounted() {
        await this.loadUserData();

            this.roles = rolesMapping;

            await this.loadMarkets();
            this.fetchTeams();
    },
    methods: {
        async onChange() {
            try {
              this.userDetail.teamId = -1;
              await this.fetchTeams();
            } catch (err) {
              console.error(err);
            }
        },
        async fetchTeams() {
            try {
                  const response = await axios({
                    method: "get",
                    url: "/team/list",
                    params: {
                      marketId: this.userDetail.marketId,
                    },
                  });

                  if (response && response.data && response.status == 200) {
                    this.teams = response.data;
                  }
                } catch (err) {
                  console.log(err);
                }
        },
        async loadMarkets() {
            await this.$store.dispatch("market/loadMarketList");
        },
        async loadUserData() {
            if (this.userId && this.userId != -1) {
              await this.$store.dispatch("user/loadUserDetail", this.userId);
            } else {
              await this.$store.dispatch("user/loadUserNew");
            }
        },
        navigateBack() {
            this.$router.push({ name: "UserAdministrationOverview" });
        },
        async submitUser() {
            if (this.userId && this.userId != -1) {
                  await this.$store.dispatch("user/updateUser", {
                    userId: this.userId,
                    user: this.userDetail,
                  });
                } else {
                  await this.$store.dispatch("user/createUser", {
                    user: this.userDetail,
                  });
                }
                await this.$store.dispatch("user/getCurrentUser");
                this.$store.dispatch("user/setUserChanged", false);

                // const currentLanguage = this.countryObject.filter(
                //   (element) => element.id == this.selectedCountryId
                // );

                // if (currentLanguage.length) {
                //   this.$i18n.locale = currentLanguage[0].language;
                // }

                this.navigateBack();
        }
    },
    props: {
        userId: {
            type: Object as PropType<number | undefined>
        }
    },
    computed: {
        userDetail(): User {
            return this.$store.getters["user/userDetail"];
        },
        marketList(): Market[] {
            return this.$store.getters["market/market"];
        }
    }
})

</script>

<style scoped lang="scss"></style>
