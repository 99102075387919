<template>
  <v-container class="position-content" mb-1 py-0>
    <v-row>
      <v-col>
        <v-row>
          <v-col lg="6">
            <div class="content-title">
              {{ $tc("COMPONENTS.POSITION.POSITION_CONTENT.POS_NR") }}
            </div>
            <div class="text-wrap content-message">
              {{ item.position.positionNumber }}
            </div>
          </v-col>
          <v-col lg="6">
            <div class="content-title">
              {{ $tc("COMPONENTS.POSITION.POSITION_CONTENT.ARTICLE.NR") }}
            </div>
            <div
              v-if="item.position.positionArticleNumber !== -1"
              class="text-wrap content-message"
            >
              {{ item.position.positionArticleNumber }}
            </div>
            <div v-else class="text-wrap content-message">
              {{ $tc("COMPONENTS.POSITION.POSITION_CONTENT.NOT_AVAILABLE") }}
            </div>
          </v-col>
          <v-col lg="6">
            <div class="content-title">
              {{ $tc("COMPONENTS.POSITION.POSITION_CONTENT.QUANTITY") }}
            </div>
            <div class="text-wrap content-message">
              {{ item.position.positionQuantity }}
            </div>
          </v-col>
          <v-col lg="6">
            <div class="content-title">
              {{ $tc("COMPONENTS.POSITION.POSITION_CONTENT.UNIT") }}
            </div>
            <div class="text-wrap content-message">
              {{ item.position.positionUnit }}
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-container class="px-0 text-wrap">
          <div class="content-title">
            {{ $tc("COMPONENTS.POSITION.POSITION_CONTENT.POSITION_TEXT") }}
          </div>
          <v-responsive class="content-message overflow-y-auto" max-height="300px">
            {{
              item.position.positionText
            }}
          </v-responsive>
        </v-container>
      </v-col>
    </v-row>

    <!-- Artikel -->
    <div class="positionArticels">
      <v-row v-for="articleObject in item.position.articles" :key="articleObject.id">
        <v-col>
          <div class="content-title">
            {{ $tc("COMPONENTS.POSITION.POSITION_CONTENT.ARTICLE.VIEGA") }}
          </div>
          <ArticleDetailDialog :article="articleObject.article">
            <template #activator="{ props }">
              <div v-bind="props" class="text-wrap content-message-article">
                {{
                  articleObject.article.artikelnummer ? articleObject.article.artikelnummer : "-"
                }}
              </div>
            </template>
          </ArticleDetailDialog>
          <div v-if="!dialogFastEntry" class="editArticle">
            <PositionArticleDialog
              :position="item.position"
              :article-object="articleObject"
              @change="changeArticle($event)"
            />
            <DeleteArticleDialog
              :position-article-id="articleObject.id"
              @remove="removeArticle($event, articleObject.id)"
            />
          </div>
        </v-col>
        <v-col>
          <div class="content-title">
            {{ $tc("COMPONENTS.POSITION.POSITION_CONTENT.TYPE") }}
          </div>
          <div class="text-wrap content-message">
            {{ articleObject.type ? getArticleType(articleObject.type) : "Standard" }}
          </div>
        </v-col>
        <v-col cols="4">
          <div class="content-title">
            {{ $tc("COMPONENTS.POSITION.POSITION_CONTENT.QUOTATION_TEXT") }}
          </div>
          <div class="text-wrap content-message">
            {{ articleObject.article.angebotstext ? articleObject.article.angebotstext : "-" }}
          </div>
        </v-col>
        <v-col>
          <div class="content-title">
            {{ $tc("COMPONENTS.POSITION.POSITION_CONTENT.QUANTITY") }}
          </div>
          <div class="text-wrap content-message">
            {{ quantityString(articleObject.quantity) }}
          </div>
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-col>
        <v-btn variant="outlined" prepend-icon="mdi-chevron-left" @click="showPreviousItem()">
          {{ $tc("COMPONENTS.POSITION.POSITION_CONTENT.BACK") }}
        </v-btn>
      </v-col>
      <v-col v-if="!dialogFastEntry" cols="auto">
        <PositionArticleDialog :position="item.position" @article-added="addArticle($event)" />
      </v-col>
      <v-col cols="auto">
        <v-btn class="dark-btn" elevation="0" @click="showNextItem()">
          {{ $tc("COMPONENTS.POSITION.POSITION_CONTENT.NEXT") }}
          <v-icon color="white" end>
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import ArticleDetailDialog from "@/components/article/ArticleDetailDialog.vue";
import PositionArticleDialog from "./PositionArticleDialog.vue";
import DeleteArticleDialog from "./DeleteArticleDialog.vue";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    components: {
        ArticleDetailDialog,
        PositionArticleDialog,
        DeleteArticleDialog,
    },
    props: {
        item: {
            type: Object as PropType<any>
        },
        dialogFastEntry: { required: true,
            type: Boolean
        }
    },
    data() {
        const localItem: any = this.item;
        return { localItem };
    },
    watch: {
        "item": [{
            handler: "itemChanged",
            immediate: true,
        }]
    },
    methods: {
        getArticleType(articleType: string) {
            switch (articleType) {
              case "STANDARD":
                return this.$tc("COMPONENTS.POSITION.POSITION_CONTENT.ARTICLE.TYPE.STANDARD");
              case "ALTERNATIV":
                return this.$tc("COMPONENTS.POSITION.POSITION_CONTENT.ARTICLE.TYPE.ALTERNATIVE");
              case "BEDARF":
                return this.$tc("COMPONENTS.POSITION.POSITION_CONTENT.ARTICLE.TYPE.REQUIREMENT");
              case "EVENTUAL":
                return this.$tc("COMPONENTS.POSITION.POSITION_CONTENT.ARTICLE.TYPE.CONTINGENT");
            }
        },
        quantityString(articleQuantity: number): string {
            if (articleQuantity >= 0) {
              return articleQuantity.toLocaleString();
            }
            return "";
        },
        showPreviousItem() {
            this.$emit("showPrev");
        },
        showNextItem() {
            this.$emit("showNext");
        },
        articleChangedSuccessfully() {
            //
        },
        addArticle(positionModification: any) {
            this.localItem.position.articles.push(positionModification.article);
            this.$emit("updatePosition", positionModification.position);
        },
        changeArticle(positionModification: any) {
            this.$emit("updatePosition", positionModification.position);
            for (let i = 0; i < this.localItem.position.articles.length; i++) {
              if (this.localItem.position.articles[i].id == positionModification.article.id) {
                this.localItem.position.articles.splice(i, 1, positionModification.article);
                return;
              }
            }
        },
        removeArticle(positionModification: any, articleId: number) {
            this.localItem.position.articles = this.localItem.position.articles.filter(
              (element: any) => element.id !== articleId
            );
            this.$emit("updatePosition", positionModification.position);
        },
        itemChanged(newValue: any) {
            this.localItem = newValue;
        },
    },
})

</script>

<style lang="scss">
@use "@/style/styles";

.editArticle {
  margin-top: 10px;
}

.v-treeview-node__level {
  width: 1%;
}

.position-content {
  padding: 8px;
  border-color: styles.$card-border;
  border-style: solid;
  border-width: 1px;
  border-radius: 0px 0px 8px 8px;
  font-size: 14px;
}
.content-title {
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  color: styles.$primary;
}
.content-message {
  color: styles.$secondary;
}
.content-message-article {
  text-decoration: underline;
  font-weight: bold;
}
</style>
