export class User {
  id = 0;
  firstname = "";
  lastname = "";
  email = "";
  role = "";
  teamId = -1;
  sapNumber = "";
  marketId = -1;
  teamName = "";

  constructor(userDto?: UserDto) {
    if (userDto) {
      this.id = userDto.id;
      this.firstname = userDto.firstname;
      this.lastname = userDto.lastname;
      this.email = userDto.email;
      this.role = userDto.role;
      this.teamId = userDto.teamId;
      this.sapNumber = userDto.sapNumber;
      this.marketId = userDto.marketId;
      this.teamName = userDto.teamName;
    }
  }

  static makeLocalAdmin(): User {
    const admin = new User();
    admin.firstname = "Admin";
    admin.lastname = "ADMIN";
    admin.role = "ROLE_ADMIN";
    return admin;
  }
}

export interface UserDto {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  role: string;
  teamId: number;
  sapNumber: string;
  marketId: number;
  teamName: string;
}
