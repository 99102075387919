import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, _mergeProps({ loading: _ctx.submitted, disabled: _ctx.submitted }, { class: "text-center" }), {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, { "text-align-self": "center" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.multiplePositions && _ctx.multiplePositions.length > 1
          ? _ctx.$tc("COMPONENTS.POSITION.DELETEPOSITIONDIALOG.DELETE.MULTIPLE_ELEMENTS")
          : _ctx.$tc("COMPONENTS.POSITION.DELETEPOSITIONDIALOG.DELETE.SINGLE_ELEMENT")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_subtitle, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, {
            size: "100",
            color: "error"
          }, {
            default: _withCtx(() => [
              _createTextVNode("mdi-help-circle")
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.multiplePositions && _ctx.multiplePositions.length > 1
          ? _ctx.$tc("COMPONENTS.POSITION.DELETEPOSITIONDIALOG.DELETE.SELECTED_ELEMENTS")
          : `${_ctx.$tc(
              "COMPONENTS.POSITION.DELETEPOSITIONDIALOG.DELETE.SELECTED_TYPE.1"
            )} ${_ctx.positionType} ${_ctx.$tc(
              "COMPONENTS.POSITION.DELETEPOSITIONDIALOG.DELETE.SELECTED_TYPE.2"
            )}`), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_v_divider),
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          (_ctx.canHaveChildren())
            ? (_openBlock(), _createBlock(_component_v_checkbox, {
                key: 0,
                modelValue: _ctx.deleteChildren,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.deleteChildren) = $event)),
                label: _ctx.$tc('COMPONENTS.POSITION.DELETEPOSITIONDIALOG.DELETE.SUBPOSITION')
              }, null, 8, ["modelValue", "label"]))
            : _createCommentVNode("", true),
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_btn, {
            variant: "outlined",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.cancel()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.POSITION.DELETEPOSITIONDIALOG.CANCEL")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_btn, {
            class: "dark-btn",
            elevation: "0",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.submit()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.POSITION.DELETEPOSITIONDIALOG.DELETE.TITLE")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 16))
}