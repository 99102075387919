<template>
  <v-container class="pa-0 mr-2 mb-4">
    <v-responsive class="overflow-y-auto fill-height pa-0 ma-0">
      <v-card class="main-card" variant="flat">
        <v-card-title>
          {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_INFORMATION.TITLE") }}
        </v-card-title>
        <v-card-text>
          <v-container fluid class="px-0">
            <v-row>
              <v-col lg="6">
                <div class="card__title">
                  {{
                    $tc(
                      "COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_INFORMATION.NUMBER_OF_POSITIONS"
                    )
                  }}
                </div>
                {{ quotation && quotation.positionCount ? quotation.positionCount : "-" }}
              </v-col>
              <v-col lg="6">
                <div class="card__title">
                  {{
                    $tc(
                      "COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_INFORMATION.PRODUCT_DIVISION"
                    )
                  }}
                </div>
                {{ quotation && quotation.productHierarchy ? quotation.productHierarchy : "-" }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-responsive>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        quotation: {
            type: Object as PropType<any>
        }
    }
})

</script>
<style lang="scss" scoped>
.card__title {
  color: #333;
  font-weight: 600;
  text-transform: uppercase;
}
</style>
