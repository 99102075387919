import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, {
    ref: "myForm",
    onSubmit: _cache[6] || (_cache[6] = ($event: any) => (_ctx.submit($event))),
    key: _ctx.key
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, _mergeProps({ loading: _ctx.submitted, disabled: _ctx.submitted }, { class: "text-center" }), {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.POSITION.ADDPOSITIONDIALOG.ADD_ELEMENT")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_select, {
                modelValue: _ctx.form.positionType,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.positionType) = $event)),
                label: "Typ",
                items: _ctx.positionTypes
              }, null, 8, ["modelValue", "items"]),
              (_ctx.checkPosition(_ctx.form))
                ? (_openBlock(), _createBlock(_component_v_text_field, {
                    key: 0,
                    modelValue: _ctx.form.positionLabel,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.positionLabel) = $event)),
                    label: _ctx.$tc('COMPONENTS.POSITION.ADDPOSITIONDIALOG.LABEL')
                  }, null, 8, ["modelValue", "label"]))
                : _createCommentVNode("", true),
              (_ctx.form.positionType == 'TEXT')
                ? (_openBlock(), _createBlock(_component_v_textarea, {
                    key: 1,
                    modelValue: _ctx.form.positionLabel,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.positionLabel) = $event)),
                    label: _ctx.$tc('COMPONENTS.POSITION.ADDPOSITIONDIALOG.LABEL')
                  }, null, 8, ["modelValue", "label"]))
                : _createCommentVNode("", true),
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.form.positionNumber,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.positionNumber) = $event)),
                label: _ctx.$tc('COMPONENTS.POSITION.ADDPOSITIONDIALOG.NUMBER'),
                rules: [(v) => !!v || _ctx.$tc('COMPONENTS.POSITION.ADDPOSITIONDIALOG.EMPTY_ERROR')]
              }, null, 8, ["modelValue", "label", "rules"]),
              (_ctx.checkIfGroup(_ctx.form))
                ? (_openBlock(), _createBlock(_component_v_text_field, {
                    key: 2,
                    modelValue: _ctx.form.groupQuantity,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.groupQuantity) = $event)),
                    label: _ctx.$tc('COMPONENTS.POSITION.ADDPOSITIONDIALOG.QUANTITY'),
                    type: "number",
                    rules: [(v) => !!v || _ctx.$tc('COMPONENTS.POSITION.ADDPOSITIONDIALOG.EMPTY_ERROR')]
                  }, null, 8, ["modelValue", "label", "rules"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_v_divider),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                variant: "outlined",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.cancel()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.POSITION.ADDPOSITIONDIALOG.CANCEL")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                class: "dark-btn",
                elevation: "0",
                type: "submit"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.POSITION.ADDPOSITIONDIALOG.ADD")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 16)
    ]),
    _: 1
  }))
}