<template>
  <v-container class="pa-0 mr-2 mb-4">
    <v-responsive class="overflow-y-auto fill-height pa-0 ma-0">
      <v-card class="main-card" variant="flat">
        <v-card-title class="d-flex align-center">
          {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BOQ.TITLE") }}
          <v-dialog v-model="dialog" width="20vw" @keydown.alt="submitViaShortcut">
            <template #activator="{ props }">
              <v-btn
                icon="mdi-pencil"
                class="ml-2"
                size="small"
                variant="text"
                v-bind="props"
              />
            </template>

            <v-card>
              <v-card-title class="d-flex align-center">
                {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BOQ.EDIT") }}
                <v-btn
                  class="align-self-center ml-auto"
                  icon="mdi-close"
                  color="black"
                  size="small"
                  @click="close"
                />
              </v-card-title>
              <v-divider />
              <v-card-text class="mt-5">
                <v-form>
                  <v-text-field
                    v-model="billOfQuantityForm.externalReference"
                    :label="$tc('COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BOQ.EXTERNAL_REFERENCE')"
                    required
                    density="compact"
                    variant="outlined"
                  />
                  <v-text-field
                    v-model="billOfQuantityForm.name"
                    :label="$tc('COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BOQ.DESCRIPTION')"
                    required
                    density="compact"
                    variant="outlined"
                  />

                  <v-text-field
                    v-model="billOfQuantityForm.street"
                    :label="$tc('COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BOQ.STREET')"
                    required
                    density="compact"
                    variant="outlined"
                  />

                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="billOfQuantityForm.postalCode"
                        :label="$tc('COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BOQ.POSTAL_CODE')"
                        required
                        density="compact"
                        variant="outlined"
                      />
                    </v-col>

                    <v-col>
                      <v-text-field
                        v-model="billOfQuantityForm.location"
                        :label="$tc('COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BOQ.LOCATION')"
                        required
                        density="compact"
                        variant="outlined"
                      />
                    </v-col>
                  </v-row>

                  <v-text-field
                    v-model="billOfQuantityForm.country"
                    :label="$tc('COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BOQ.COUNTRY')"
                    required
                    density="compact"
                    variant="outlined"
                  />
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn class="dark-btn" @click="submit">
                  {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BOQ.APPLY") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-card-text>
          <v-container fluid class="px-0">
            <v-row>
              <v-col lg="6">
                <div class="card__title">
                  {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BOQ.DESCRIPTION") }}
                </div>
                {{ billOfQuantity?.name ? billOfQuantity.name : "-" }}
              </v-col>
              <v-col lg="6">
                <div class="card__title">
                  {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BOQ.EXTERNAL_REFERENCE") }}
                </div>
                {{ billOfQuantity?.externalReference ? billOfQuantity.externalReference : "-" }}
              </v-col>
              <v-col lg="6">
                <div class="card__title">
                  {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BOQ.STREET") }}
                </div>
                {{ billOfQuantity?.street ? billOfQuantity.street : "-" }}
              </v-col>

              <v-col lg="6">
                <div class="card__title">
                  {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BOQ.POSTAL_CODE") }}
                </div>
                {{ billOfQuantity?.postalCode ? billOfQuantity.postalCode : "-" }}
              </v-col>
              <v-col lg="6">
                <div class="card__title">
                  {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BOQ.LOCATION") }}
                </div>
                {{ billOfQuantity?.location ? billOfQuantity.location : "-" }}
              </v-col>

              <v-col lg="6">
                <div class="card__title">
                  {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BOQ.COUNTRY") }}
                </div>
                {{ billOfQuantity?.country ? billOfQuantity.country : "-" }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-responsive>
  </v-container>
</template>

<script lang="ts">
import { BillOfQuantityView } from "@/core/models/master-data/BillOfQuantity.model";
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        billOfQuantity: {
            type: BillOfQuantityView
        }
    },
    data() {
        const billOfQuantityForm: BillOfQuantityView = new BillOfQuantityView();

        return {
            billOfQuantityForm,
            dialog: false
        };
    },
    watch: {
        "billOfQuantity": [{
            handler: "OnBillOfQuantityChanged"
        }]
    },
    mounted() {
        if (this.billOfQuantity) {
            this.billOfQuantityForm = { ...this.billOfQuantity };
        }
    },
    methods: {
        submitViaShortcut(event: any) {
            if (event.key == "s") {
              this.submit();
            }
        },
        submit() {
            this.$store.dispatch("masterdata/setBillOfQuantity", this.billOfQuantityForm);
                this.close();

                this.$emit("submitMasterdata");
        },
        close() {
            this.dialog = false;
        },
        OnBillOfQuantityChanged() {
          if (this.billOfQuantity) {
            this.billOfQuantityForm = { ...this.billOfQuantity };
          }
        }
    }
})

</script>
<style lang="scss" scoped>
.card__title {
  color: #333;
  font-weight: 600;
  text-transform: uppercase;
}
</style>
