import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withKeys as _withKeys, createBlock as _createBlock, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-73658d80"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filter-row" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_date_picker = _resolveComponent("v-date-picker")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_data_table = _resolveComponent("v-data-table")!

  return (_openBlock(), _createBlock(_component_v_data_table, {
    modelValue: _ctx.selected,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selected) = $event)),
    "calculate-widths": true,
    "disable-pagination": _ctx.disablePaginate,
    "footer-props": {
      itemsPerPageText: _ctx.$t('SHARED.COMPONENTS.DATATABLE.ITEMS_PER_PAGE'),
      itemsPerPageAllText: _ctx.$t('SHARED.COMPONENTS.DATATABLE.ALL'),
    },
    headers: _ctx.headers,
    "filter-keys": (_ctx.headers.filter(h => h.filterable).map(h => h.key).filter(key => !!key) as string[]),
    height: _ctx.height,
    "hide-default-footer": _ctx.disablePaginate,
    "item-key": _ctx.itemKey,
    items: _ctx.filteredItems,
    "return-object": "",
    loading: _ctx.loading,
    "show-select": _ctx.showSelect,
    "select-strategy": _ctx.singleSelect ? 'single' : undefined,
    class: "boq-data-table fill-height",
    "disable-sort": "",
    "onUpdate:options": _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('optionsChanged', $event)))
  }, _createSlots({
    [`body.prepend`]: _withCtx(({}) => [
      _createElementVNode("tr", _hoisted_1, [
        (_ctx.showSelect)
          ? (_openBlock(), _createElementBlock("td", _hoisted_2))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header, index) => {
          return (_openBlock(), _createElementBlock("td", {
            key: header.value,
            class: "filter-column"
          }, [
            (header.filterable && header.type == 'select')
              ? (_openBlock(), _createBlock(_component_v_select, {
                  key: 0,
                  modelValue: _ctx.filterValues[index],
                  "onUpdate:modelValue": [
                    ($event: any) => ((_ctx.filterValues[index]) = $event),
                    _cache[0] || (_cache[0] = ($event: any) => (_ctx.debouncedFilterColumn()))
                  ],
                  items: _ctx.marketList,
                  clearable: "",
                  "item-title": "name",
                  "item-value": "id",
                  placeholder: "Filter",
                  density: "compact",
                  rounded: "",
                  "hide-details": "",
                  variant: "solo-filled",
                  onKeydown: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('enterPressed')), ["enter"]))
                }, null, 8, ["modelValue", "onUpdate:modelValue", "items"]))
              : _createCommentVNode("", true),
            (header.filterable && !header.type)
              ? (_openBlock(), _createBlock(_component_v_text_field, {
                  key: 1,
                  modelValue: _ctx.filterValues[index],
                  "onUpdate:modelValue": [
                    ($event: any) => ((_ctx.filterValues[index]) = $event),
                    _cache[2] || (_cache[2] = ($event: any) => (_ctx.debouncedFilterColumn()))
                  ],
                  clearable: "",
                  density: "compact",
                  "hide-details": "",
                  placeholder: "Filter",
                  "prepend-inner-icon": "mdi-magnify",
                  rounded: "",
                  variant: "solo-filled",
                  onKeydown: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.$emit('enterPressed')), ["enter"]))
                }, null, 8, ["modelValue", "onUpdate:modelValue"]))
              : _createCommentVNode("", true),
            (header.filterable && header.type === 'date')
              ? (_openBlock(), _createBlock(_component_v_menu, {
                  key: 2,
                  modelValue: _ctx.dateMenu[index],
                  "onUpdate:modelValue": ($event: any) => ((_ctx.dateMenu[index]) = $event),
                  "close-on-content-click": false,
                  transition: "scale-transition"
                }, {
                  activator: _withCtx(({ props }) => [
                    _createVNode(_component_v_text_field, _mergeProps({
                      "model-value": _ctx.transformToDateString(_ctx.filterValues[index] as string),
                      clearable: "",
                      density: "compact",
                      "hide-details": "",
                      placeholder: "Filter",
                      "prepend-inner-icon": "mdi-calendar",
                      readonly: "",
                      rounded: "",
                      variant: "solo-filled"
                    }, props, {
                      "onClick:clear": ($event: any) => {
                  _ctx.filterValues[index] = '';
                  _ctx.debouncedFilterColumn();
                }
                    }), null, 16, ["model-value", "onClick:clear"])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_v_date_picker, {
                      "model-value": (_ctx.filterValues[index] as string),
                      color: "primary",
                      "onUpdate:modelValue": ($event: any) => {
                _ctx.filterValues[index] = $event;
                _ctx.dateMenu[index] = false;
                _ctx.debouncedFilterColumn();
              }
                    }, null, 8, ["model-value", "onUpdate:modelValue"])
                  ]),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue"]))
              : _createCommentVNode("", true),
            (header.filterable && header.type === 'date-range')
              ? (_openBlock(), _createBlock(_component_v_menu, {
                  key: 3,
                  modelValue: _ctx.dateMenu[index],
                  "onUpdate:modelValue": ($event: any) => ((_ctx.dateMenu[index]) = $event),
                  "close-on-content-click": false,
                  transition: "scale-transition"
                }, {
                  activator: _withCtx(({ props }) => [
                    _createVNode(_component_v_text_field, _mergeProps({
                      "model-value": _ctx.transformToDateStringFromDateRange(_ctx.filterValues[index] as string[]),
                      clearable: "",
                      density: "compact",
                      variant: "solo-filled",
                      "hide-details": "",
                      placeholder: "Filter",
                      "prepend-inner-icon": "mdi-calendar",
                      readonly: "",
                      rounded: ""
                    }, props, {
                      "onClick:clear": ($event: any) => {
                  _ctx.filterValues[index] = [];
                  _ctx.debouncedFilterColumn();
                }
                    }), null, 16, ["model-value", "onClick:clear"])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_v_date_picker, {
                      "model-value": _ctx.filterValues[index] as string[],
                      color: "primary",
                      multiple: "",
                      "onUpdate:modelValue": ($event: any) => {
                _ctx.filterValues[index] = $event;
                (_ctx.filterValues[index] as string).length === 2 ? _ctx.debouncedFilterColumn() : null;
              }
                    }, null, 8, ["model-value", "onUpdate:modelValue"])
                  ]),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue"]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ]),
    _: 2
  }, [
    _renderList(Object.keys(_ctx.$slots), (scopedSlotName) => {
      return {
        name: scopedSlotName,
        fn: _withCtx((slotData) => [
          _renderSlot(_ctx.$slots, scopedSlotName, _normalizeProps(_guardReactiveProps(slotData)), undefined, true)
        ])
      }
    })
  ]), 1032, ["modelValue", "disable-pagination", "footer-props", "headers", "filter-keys", "height", "hide-default-footer", "item-key", "items", "loading", "show-select", "select-strategy"]))
}