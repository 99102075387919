import Quotation from "@/core/models/quotation/quotation.model";
import QuotationState from "./state";

export const mutations = {
  setQuotationList: (state: QuotationState, value: Quotation[]) => {
    state.quotationList = value;
  },
  setQuotationDetail: (state: QuotationState, value: any) => {
    state.quotationDetail = value;
  },
  setActionLog: (state: QuotationState, value: any) => {
    state.actionLog = value;
  },
  setValidationValue: (state: QuotationState, value: number) => {
    state.validationValue = value;
  },
};
