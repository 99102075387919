import { Keyword } from "@/core/models/keyword/Keyword.model";
import GetConfig from "@/core/services/base/get.config";
import * as SettingsService from "@/core/services/settings/settings.service";
import { RootState } from "@/core/store/state";
import { ActionTree } from "vuex";
import SettingsState from "./state";

export const actions: ActionTree<SettingsState, RootState> = {
  async loadKeywords(context) {
    try {
      const response = await SettingsService.loadKeywords();
      context.commit("setKeywords", response);
    } catch (err) {
      console.error(err);
    }
  },

  async loadUnits(context) {
    try {
      const response = await SettingsService.loadUnits();
      context.commit("setUnits", response);
    } catch (err) {
      console.error(err);
    }
  },

  addKeyword(context, keyword: { value: string; marketId?: number }) {
    return SettingsService.addKeyword(keyword).then((response) => response);
  },

  addUnit(context, keyword: { value: string; marketId?: number }) {
    return SettingsService.addUnit(keyword).then((response) => response);
  },

  deleteKeyword(context, keyword: Keyword) {
    return SettingsService.deleteKeyword(keyword).then((response) => response);
  },

  deleteUnit(context, keyword: Keyword) {
    return SettingsService.deleteUnit(keyword).then((response) => response);
  },

  updateKeyword(context, keyword: Keyword) {
    return SettingsService.updateKeyword(keyword);
  },

  updateUnit(context, keyword: Keyword) {
    return SettingsService.updateUnit(keyword);
  },

  clearKeywords(context) {
    context.commit("setKeywords", []);
  },

  clearUnits(context) {
    context.commit("setUnits", []);
  },

  loadProductHierarchy(context) {
    return SettingsService.loadProductHierarchy().then((response) =>
      context.commit("setProductHierarchy", response)
    );
  },

  setProductHierarchy(context, val) {
    context.commit("setProductHierarchy", val);
  },

  updateProductHierarchy(context, value) {
    return SettingsService.updateProductHierarchy(value).then(() =>
      context.commit("setProductHierarchy", value)
    );
  },

  async filterKeywords(context, config?: GetConfig) {
    try {
      const response: Keyword[] = await SettingsService.filterKeywords(config);

      context.commit("setKeywords", response);
      return response;
    } catch (err) {
      console.error(err);
    }
  },

  async filterUnits(context, config?: GetConfig) {
    try {
      const response: Keyword[] = await SettingsService.filterUnits(config);

      context.commit("setUnits", response);
      return response;
    } catch (err) {
      console.error(err);
    }
  },
};
