import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_ArticleOverview = _resolveComponent("ArticleOverview")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, {
    ref: "myForm",
    onSubmit: _cache[1] || (_cache[1] = ($event: any) => (_ctx.submit($event)))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { height: "70vh" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode("Artikel ändern")
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, { class: "card1" }, {
            default: _withCtx(() => [
              _createVNode(_component_ArticleOverview, {
                defaultValue: _ctx.position.positionText,
                preselected: _ctx.selectedManual,
                onArticleSelected: _cache[0] || (_cache[0] = ($event: any) => (_ctx.articleSelectedManual($event)))
              }, null, 8, ["defaultValue", "preselected"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_divider),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                variant: "outlined",
                onClick: _ctx.close
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Abbrechen ")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_btn, {
                elevation: "0",
                type: "submit",
                disabled: _ctx.selected.length === 0,
                class: _normalizeClass({ 'dark-btn': _ctx.selected.length !== 0 })
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Übernehmen ")
                ]),
                _: 1
              }, 8, ["disabled", "class"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 512))
}