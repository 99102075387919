import { ActionTree } from 'vuex';
import { RootState } from '@/core/store/state';
import AuthState from "./state";
import * as AuthService from "@/core/services/login/login.service";

export const actions: ActionTree<AuthState, RootState> = {
  setToken(context, token: string) {
    context.commit('setToken', token);
  },

  loginAsLocalAdmin(context, credentials) {
    return AuthService.loginAsLocalAdmin(credentials);
  },

  login(context, payload: { token: string; user: object }) {
    context.commit('setToken', payload.token);
    context.commit('setUser', payload.user);
  }
};
