<template>
  <v-container class="customer-selection">
    <v-alert
      v-if="Object.keys({ ...getConfig.filter }).length === 0"
      class="mt-3"
      density="compact"
      variant="text"
      type="info"
    >
      {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CLIENT_SELECETION.SEARCH_CLIENT") }}
    </v-alert>
    <div class="table-container">
      <BoqDataTable
        ref="dataTable"
        :headers="headers"
        :items="clientList"
        :singleSelect="true"
        :showSelect="true"
        itemKey="kundennummer"
        :loading="loading"
        v-model:get-config="getConfig"
        :debounceTime="0"
        height="100%"
        @itemSelected="onItemSelected"
        @enterPressed="loadClients"
      >
      </BoqDataTable>
    </div>
    <v-row class="flex-grow-0" justify="start">
      <v-col cols="auto">
        <v-btn
          variant="outlined"
          prepend-icon="mdi-magnify"
          :disabled="loading"
          @click="loadClients()"
        >
          {{ $tc('COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CLIENT_SELECETION.SEARCH_AGAIN') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { ClientView } from "@/core/models/master-data/Client.model";
import BoqDataTable from "@/shared/components/data-table.component.vue";
import GetConfig from "@/core/services/base/get.config";
import { BoqTableHeader } from "@/core/interfaces/boq-table-header.interface";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    BoqDataTable,
  },
    data() {
        const errorAlert: any = {
                type: "error",
                message: this.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CLIENT_SELECETION.MESSAGE.ERROR"),
              };
        const noContentAlert: any = {
                type: "info",
                message: this.$tc(
                  "COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CLIENT_SELECETION.MESSAGE.NO_CONTENT"
                ),
              };
        const headers: BoqTableHeader[] = [
                {
                  value: "kundennummer",
                  title: this.$tc(
                    "COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.HEADERS.CLIENT_SELECTION.CLIENT_NUMBER"
                  ),
                  filterable: true,
                },
                {
                  value: "name",
                  title: this.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.HEADERS.CLIENT_SELECTION.NAME"),
                  filterable: true,
                },
                {
                  value: "strasse",
                  title: this.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.HEADERS.CLIENT_SELECTION.STREET"),
                  filterable: true,
                },
                {
                  value: "plz",
                  title: this.$tc(
                    "COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.HEADERS.CLIENT_SELECTION.POSTAL_CODE"
                  ),
                  filterable: true,
                },
                {
                  value: "ort",
                  title: this.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.HEADERS.CLIENT_SELECTION.LOCATION"),
                  filterable: true,
                },
              ];
        const getConfig: GetConfig = GetConfig.default();

        return {
            getConfig,
            headers,
            noContentAlert,
            errorAlert
        };
    },
    mounted() {
        this.getConfig.filter = {
          kundennummer: this.initialValue,
        };
        (this.$refs.dataTable as InstanceType<typeof BoqDataTable>).initFiltersFromGetConfig(this.getConfig);
        if (this.initialValue) this.loadClients();
    },
    unmounted() {
        this.$store.dispatch("masterdata/clearClientList");
    },
    methods: {
        loadClients() {
            if (this.loading) return;
            this.$store.dispatch("masterdata/loadClientList", this.getConfig);
        },
        onItemSelected(item: ClientView[]) {
            this.$emit("itemSelected", item[0]);
        },
        opportunityListChanged(clientList: ClientView[]) {
            if (clientList.length !== 0) {
              const clientListItem = clientList.find((e) => e.kundennummer === this.initialValue);
              if (clientListItem) {
                (this.$refs.dataTable as InstanceType<typeof BoqDataTable>).selectPreselected(clientListItem);
                this.onItemSelected([clientListItem]);
              }
            }
        }
    },
    props: {
        initialValue: { default: "",
            type: String
        }
    },
    watch: {
        "clientList": [{
            handler: "opportunityListChanged"
        }]
    },
    computed: {
        clientList(): ClientView[] {
          return this.$store.getters["masterdata/clientList"];
        },
        loading(): boolean {
            return this.$store.getters["loading"];
        }
    }
})

</script>

<style lang="scss" scoped>
.customer-selection {
  flex-grow: 1;
  height: 1%;
  display: flex;
  flex-direction: column;
}

.table-container {
  overflow-y: auto;
  flex-grow: 1;
  flex-shrink: 1;
}
</style>
