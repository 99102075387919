import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_stepper_item = _resolveComponent("v-stepper-item")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_stepper_header = _resolveComponent("v-stepper-header")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_stepper_window_item = _resolveComponent("v-stepper-window-item")!
  const _component_v_stepper_window = _resolveComponent("v-stepper-window")!
  const _component_v_stepper = _resolveComponent("v-stepper")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.dialog,
    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.dialog) = $event))
  }, {
    activator: _withCtx((slotData) => [
      _renderSlot(_ctx.$slots, "activator", _normalizeProps(_guardReactiveProps(slotData)), undefined, true)
    ]),
    default: _withCtx(() => [
      (_ctx.dialog)
        ? (_openBlock(), _createBlock(_component_v_stepper, {
            key: 0,
            modelValue: _ctx.stepper,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.stepper) = $event)),
            class: "stepper-container"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_stepper_header, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_stepper_item, { value: 1 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.stepOneTitle), 1)
                    ]),
                    _: 1
                  }),
                  (_ctx.showStepper && _ctx.amountOfSteps > 1)
                    ? (_openBlock(), _createBlock(_component_v_divider, { key: 0 }))
                    : _createCommentVNode("", true),
                  (_ctx.showStepper && _ctx.amountOfSteps === 3)
                    ? (_openBlock(), _createBlock(_component_v_stepper_item, {
                        key: 1,
                        value: 2
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.stepTwoTitle), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.showStepper && _ctx.amountOfSteps !== 3)
                    ? (_openBlock(), _createBlock(_component_v_stepper_item, {
                        key: 2,
                        value: 2
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.SELECTION_DIALOG.CONTACT_PERSON")), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.showStepper && _ctx.amountOfSteps === 3)
                    ? (_openBlock(), _createBlock(_component_v_divider, { key: 3 }))
                    : _createCommentVNode("", true),
                  (_ctx.showStepper && _ctx.amountOfSteps === 3)
                    ? (_openBlock(), _createBlock(_component_v_stepper_item, {
                        key: 4,
                        value: 3
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.SELECTION_DIALOG.CONTACT_PERSON")), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    class: "align-self-center mr-3",
                    icon: "mdi-close",
                    color: "black",
                    onClick: _ctx.close
                  }, null, 8, ["onClick"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_stepper_window, { class: "flex-grow-1" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_stepper_window_item, {
                    class: "selection-container",
                    value: 1
                  }, {
                    default: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "stepOne", {
                        onItemSelected: _ctx.onItemOneSelected,
                        stepper: _ctx.stepper
                      }, undefined, true),
                      _createVNode(_component_v_divider),
                      _createVNode(_component_v_row, {
                        class: "flex-grow-0",
                        justify: "space-between"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_spacer),
                          _createVNode(_component_v_col, { cols: "auto" }, {
                            default: _withCtx(() => [
                              (_ctx.showStepper)
                                ? (_openBlock(), _createBlock(_component_v_btn, {
                                    key: 0,
                                    disabled: !_ctx.itemOneSelected,
                                    class: "dark-btn",
                                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.stepper = _ctx.stepper + 1))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.SELECTION_DIALOG.NEXT")), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["disabled"]))
                                : _createCommentVNode("", true),
                              (!_ctx.showStepper || _ctx.oneStepper)
                                ? (_openBlock(), _createBlock(_component_v_btn, {
                                    key: 1,
                                    class: "dark-btn",
                                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.submit()))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.SELECTION_DIALOG.COMPLETE")), 1)
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 3
                  }),
                  (_ctx.showStepper && _ctx.amountOfSteps === 2)
                    ? (_openBlock(), _createBlock(_component_v_stepper_window_item, {
                        key: 0,
                        class: "selection-container",
                        value: 2
                      }, {
                        default: _withCtx(() => [
                          _renderSlot(_ctx.$slots, "stepTwo", {
                            onItemSelected: _ctx.onItemTwoSelected,
                            stepper: _ctx.stepper
                          }, undefined, true),
                          _createVNode(_component_v_divider),
                          _createVNode(_component_v_row, {
                            class: "flex-grow-0",
                            justify: "space-between"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, { cols: "auto" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_btn, {
                                    class: "dark-btn",
                                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.stepper = _ctx.stepper - 1))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.SELECTION_DIALOG.BACK")), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, { cols: "auto" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_btn, {
                                    disabled: !_ctx.itemTwoSelected && _ctx.amountOfSteps !== 2,
                                    class: "dark-btn",
                                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.submit()))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.SELECTION_DIALOG.COMPLETE")), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["disabled"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 3
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.showStepper && _ctx.amountOfSteps === 3)
                    ? (_openBlock(), _createBlock(_component_v_stepper_window_item, {
                        key: 1,
                        class: "selection-container",
                        value: 2
                      }, {
                        default: _withCtx(() => [
                          _renderSlot(_ctx.$slots, "stepSales", {
                            onItemSelected: _ctx.onItemSalesSelected,
                            stepper: _ctx.stepper
                          }, undefined, true),
                          _createVNode(_component_v_divider),
                          _createVNode(_component_v_row, {
                            class: "flex-grow-0",
                            justify: "space-between"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, { cols: "auto" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_btn, {
                                    class: "dark-btn",
                                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.stepper = _ctx.stepper - 1))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.SELECTION_DIALOG.BACK")), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, { cols: "auto" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_btn, {
                                    disabled: !_ctx.itemSalesSelected,
                                    class: "dark-btn",
                                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.stepper = _ctx.stepper + 1))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.SELECTION_DIALOG.NEXT")), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["disabled"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 3
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.showStepper && _ctx.amountOfSteps === 3)
                    ? (_openBlock(), _createBlock(_component_v_stepper_window_item, {
                        key: 2,
                        class: "selection-container",
                        value: 3
                      }, {
                        default: _withCtx(() => [
                          _renderSlot(_ctx.$slots, "stepThree", {
                            onItemSelected: _ctx.onItemThreeSelected,
                            stepper: _ctx.stepper
                          }, undefined, true),
                          _createVNode(_component_v_divider),
                          _createVNode(_component_v_row, {
                            class: "flex-grow-0",
                            justify: "space-between"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, { cols: "auto" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_btn, {
                                    class: "dark-btn",
                                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.stepper = _ctx.stepper - 1))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.SELECTION_DIALOG.BACK")), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, { cols: "auto" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_btn, {
                                    class: "dark-btn",
                                    onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.submit()))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.SELECTION_DIALOG.COMPLETE")), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 3
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 3
              })
            ]),
            _: 3
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["modelValue"]))
}