import Quotation from "@/core/models/quotation/quotation.model";
import GetConfig from "@/core/services/base/get.config";
import * as QuotationService from "@/core/services/quotation/quotation.service";
import { RootState } from "@/core/store/state";
import { ActionTree } from "vuex";
import QuotationState from "./state";

export const actions: ActionTree<QuotationState, RootState> = {
  loadQuotationList(context, payload: { boqId: number; getConfig: GetConfig }) {
    QuotationService.loadQuotationList(payload.boqId, payload.getConfig).then((response) => {
      context.commit("setQuotationList", response);
    });
  },

  loadQuotationDetail(context, quotationId: number) {
    QuotationService.loadQuotationDetail(quotationId).then((response) => {
      context.commit("setQuotationDetail", response);
    });
  },

  createQuotation(context, boqId: number) {
    QuotationService.createQuotation(boqId).then((response) => {
      console.log(response);
    });
  },

  deleteQuotation(context, quotationId: number) {
    QuotationService.deleteQuotation(quotationId);
  },

  clearQuotationList(context) {
    context.commit("setQuotationList", Array.of(new Quotation()));
  },

  clearQuotationDetail(context) {
    context.commit("setQuotationDetail", new Quotation());
  },

  loadActionLog(context, quotationId: number) {
    QuotationService.loadActionLog(quotationId).then((response) => {
      context.commit("setActionLog", response);
    });
  },

  updateValidationValue(context, quotationId: number) {
    QuotationService.getValidationValue(quotationId).then((response) => {
      context.commit("setValidationValue", response);
    });
  },
};
