<template>
  <v-form>
    <v-card>
      <v-card-actions>
        <v-row align-center="center">
          <v-col cols="auto">
            <v-btn location="top" class="dark-btn" @click="clearUp">
              {{
                $tc("COMPONENTS.POSITION.FASTENTRYDIALOG.CLEAR_UP")
              }}
            </v-btn>
          </v-col>

          <v-col cols="auto">
            <v-btn location="top" class="dark-btn" @click="renumber">
              {{
                $tc("COMPONENTS.POSITION.FASTENTRYDIALOG.RENUMBER")
              }}
            </v-btn>
          </v-col>

          <v-spacer />

          <v-col cols="auto">
            <v-tooltip location="bottom">
              <template #activator="{ props }">
                <v-btn location="top" class="dark-btn" v-bind="props" @click="exportAsExcel">
                  <v-icon>mdi-microsoft-excel</v-icon>
                </v-btn>
              </template>
              <span>{{ $tc("COMPONENTS.POSITION.FASTENTRYDIALOG.EXCEL_HOVER") }}</span>
            </v-tooltip>
          </v-col>

          <v-col cols="auto">
            <v-btn
              id="close-btn"
              variant="text"
              color="black"
              location="top"
              class="dark-btn"
              @click="$emit('close')"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>

      <v-card-text @keydown="onKeyDown">
        <div style="width: 100%; height: 100%; position: relative;">
          <vue-excel-editor
            v-if="data"
            v-model="data"
            id="vue-excel-editor"
            ref="editorRef"
            height="550px"
            width="1170px"
            new-if-bottom
            no-header-edit
            no-paging
            :cell-style="changeCellStyle"
            no-mouse-scroll
            @update="onUpdateCell"
            @cell-focus="onCellFocus"
            @cell-click="onCellClick"
            @select="onSelect"
          >
            <vue-excel-column
              field="pos"
              :label="$tc('COMPONENTS.POSITION.FASTENTRYDIALOG.POSITION')"
              type="string"
            />
            <vue-excel-column
              field="ean"
              :label="$tc('COMPONENTS.POSITION.FASTENTRYDIALOG.EAN')"
              type="number"
            />
            <vue-excel-column
              field="product"
              :label="$tc('COMPONENTS.POSITION.FASTENTRYDIALOG.PRODUCT')"
              readonly
            />
            <vue-excel-column
              field="quantity"
              :label="$tc('COMPONENTS.POSITION.FASTENTRYDIALOG.QUANTITY')"
              type="string"
            />
            <vue-excel-column
              field="unit"
              :label="$tc('COMPONENTS.POSITION.FASTENTRYDIALOG.UNIT')"
              readonly
            />
            <vue-excel-column
              field="description"
              :label="$tc('COMPONENTS.POSITION.FASTENTRYDIALOG.DESCRIPTION')"
              width="300px"
            />
            <vue-excel-column
              field="positionType"
              :label="$tc('COMPONENTS.POSITION.FASTENTRYDIALOG.POSITIONTYPE')"
              type="map"
              :options="options"
              width="150px"
              :init-style="{ 'overflow-x': 'hidden', 'text-indent': '-30px' }"
            />
            <vue-excel-column
              field="extRefPos"
              :label="$tc('COMPONENTS.POSITION.FASTENTRYDIALOG.EXTERNAL_REFERENCE')"
              type="string"
              readonly
            />
            <vue-excel-column
              field="extRef"
              type="string"
              readonly
              invisible
            />

            <vue-excel-column
              invisible
              field="level"
              type="number"
              label="Level"
              readonly
            />
          </vue-excel-editor>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn block class="dark-btn" @click="submit">
          {{ $tc("COMPONENTS.POSITION.FASTENTRYDIALOG.CONFIRM") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script setup lang="ts">
import axios, { AxiosResponse } from "axios";
import { onMounted, onBeforeUnmount, getCurrentInstance, ref, computed } from "vue";
import { useStore } from 'vuex'
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const { commit } = useStore()

const props = defineProps<{
  quotationId: number,
  extRef: string | null,
  positionType: string,
  positionId?: number,
}>()

const emit = defineEmits<{
  (e: 'close'): void,
}>()

const editorRef = ref<any>();

const currentCell = ref<{ row: number | null; col: number | null }>({ row: null, col: null });
// const dialog = ref(false);
const data = ref<any[]>([]);
const lastKey = ref<string | undefined>(undefined);
const colName = ["pos", "ean", "product", "quantity", "unit", "description", "positionType", "extRef"];
// const entrysToDelete = ref<any[]>([]);
const posType = ref(props.positionType);
const selectedRows = ref<any[]>([]);
const selectMode = ref(false);

const hierarchy = [
  { value: "LOS", hierLevel: 1 },
  { value: "GEWERK", hierLevel: 2 },
  { value: "TITEL", hierLevel: 3 },
  { value: "GRUPPE", hierLevel: 4 },
  { value: "GRUPPE_EVT", hierLevel: 4 },
  { value: "GRUPPE_ALT", hierLevel: 4 },
  { value: "GRUPPE_BED", hierLevel: 4 },
  { value: "POSITION", hierLevel: 5 },
  { value: "EV_POS", hierLevel: 5 },
  { value: "ALT_POS", hierLevel: 5 },
  { value: "BED_POS", hierLevel: 5 },
  { value: "TEXT_POS", hierLevel: 5 },
];

const options = computed(() => ({
  LOS: t("COMPONENTS.POSITION.FASTENTRYDIALOG.OPTIONS.GO"),
  GEWERK: t("COMPONENTS.POSITION.FASTENTRYDIALOG.OPTIONS.CRAFT"),
  TITEL: t("COMPONENTS.POSITION.FASTENTRYDIALOG.OPTIONS.TITLE"),
  GRUPPE: t("COMPONENTS.POSITION.FASTENTRYDIALOG.OPTIONS.GROUP"),
  ALT_POS: t("COMPONENTS.POSITION.FASTENTRYDIALOG.OPTIONS.ALT_POS"),
  EV_POS: t("COMPONENTS.POSITION.FASTENTRYDIALOG.OPTIONS.EV_POS"),
  BED_POS: t("COMPONENTS.POSITION.FASTENTRYDIALOG.OPTIONS.BED_POS"),
  GRUPPE_ALT: t("COMPONENTS.POSITION.FASTENTRYDIALOG.OPTIONS.ALT_GR"),
  GRUPPE_BED: t("COMPONENTS.POSITION.FASTENTRYDIALOG.OPTIONS.BED_GR"),
  GRUPPE_EVT: t("COMPONENTS.POSITION.FASTENTRYDIALOG.OPTIONS.EV_GR"),
  POSITION: t("COMPONENTS.POSITION.FASTENTRYDIALOG.OPTIONS.POSITION"),
  TEXT_POS: t("COMPONENTS.POSITION.FASTENTRYDIALOG.OPTIONS.TEXT_POS"),
}));

onMounted(() => {
  for (let i = 1; i <= 35; i++) {
    data.value = data.value.concat({
      pos: i,
      positionType: "POSITION",
      extRef: props.extRef,
      level: 1,
      extRefPos: props.extRef ? props.extRef + "." + i : "",
      ean: '',
      description: '',
      product: '',
      quantity: '',
      unit: '',
    });
  }

  const elements = document.getElementsByClassName('first-col');
  if (elements.length > 0) {
    elements[0].addEventListener('click', () => {
      emptyMarkedCells();
    });
  }

  if (editorRef.value) {
    setTimeout(() => {
      if (editorRef.value) {
        editorRef.value.moveTo(0, 1);
      }
      window.addEventListener("keydown", removeViaShortcut, true);
    }, 500);
  }
})

onBeforeUnmount(() => {
  posType.value = "";
  selectedRows.value = [];
  selectMode.value = false;
  window.removeEventListener("keydown", removeViaShortcut, true);
  window.removeEventListener("click", emptyMarkedCells, true);
})

// helper function because the vue-excel-editor does not detect changes when directly modifying data with .splice
function spliceData(start: number, deleteCount: number, newItems: any[]) {
  const newArray = [...data.value];
  newArray.splice(start, deleteCount, ...newItems);
  data.value = [...newArray];
}

// helper function because the vue-excel-editor does not detect changes when directly modifying an attribute of a data item
function updateDataAttribute(index: number, key: string, value: any) {
  const newArray = [...data.value];
  newArray[index][key] = value;
  data.value = [...newArray];
}

function changeCellStyle(tableRow: any, cell: any) {
  let hexString = "";
  if (tableRow) {
    if (tableRow.positionType == "TEXT_POS") {
      if (cell.name == "ean" || cell.name == "quantity") {
        return { "background-color": "#e9ecef", class: "readonly" };
      }
    } else {
      switch (tableRow.positionType) {
        case "TITEL":
          hexString = "#FFFF96";
          break;
        case "GEWERK":
          hexString = "#FFFF00";
          break;
        case "LOS":
          hexString = "#F0F000";
      }
      if (["GRUPPE", "GRUPPE_BED", "GRUPPE_EVT", "GRUPPE_ALT"].includes(tableRow.positionType)) {
        hexString = "#FFFFE1";
      }
    }
  }
  return { "background-color": hexString || "white" };
}

function onSelect(event: number[], mode: boolean) {
  selectMode.value = mode;
  selectedRows.value = editorRef.value.getSelectedRecords();
}

function emptyMarkedCells() {
  if (selectedRows.value.length && !selectMode.value) {
    selectedRows.value.forEach((row: any) => {
      const rowIndex = data.value.findIndex((e) => e.$id === row.$id);
      if (rowIndex != -1) {
        updateDataAttribute(rowIndex, 'pos', row.pos);
        updateDataAttribute(rowIndex, 'positionType', 'POSITION');
        updateDataAttribute(rowIndex, 'ean', '');
        onUpdateEan(data.value[rowIndex]);
        updateDataAttribute(rowIndex, 'description', '');
        updateDataAttribute(rowIndex, 'unit', '');
        updateDataAttribute(rowIndex, 'quantity', null);
        updateDataAttribute(rowIndex, 'product', '');
        updateDataAttribute(rowIndex, 'extRef', row.extRef);
      }
    });
  }

  selectedRows.value = editorRef.value.getSelectedRecords();
}

function removeViaShortcut(event: any) {
  if (event.altKey && event.key == "Delete") {
    const selectedRows: any[] = editorRef.value.getSelectedRecords();
    editorRef.value.clearAllSelected();
    selectedRows.forEach((row) => {
      const rowIndex = data.value.findIndex((e) => e.$id === row.$id);
      if (rowIndex != -1) {
        updateDataAttribute(rowIndex, 'pos', row.pos);
        updateDataAttribute(rowIndex, 'positionType', 'POSITION');
        updateDataAttribute(rowIndex, 'ean', '');
        onUpdateEan(data.value[rowIndex]);
        updateDataAttribute(rowIndex, 'description', '');
        updateDataAttribute(rowIndex, 'unit', '');
        updateDataAttribute(rowIndex, 'quantity', null);
        updateDataAttribute(rowIndex, 'product', '');
        updateDataAttribute(rowIndex, 'extRef', row.extRef);
      }
    });
    //  this.entrysToDelete = [];
    getCurrentInstance()?.proxy?.$forceUpdate();
    editorRef.value.$forceUpdate()
  }
}

function validatePosition() {
  let result = false;
  const { currentRow, currentRowNumber } = getValuesForPositionCheck();
  const posValues = ["POSITION", "EV_POS", "BED_POS", "ALT_POS", "TEXT_POS"];

  const groupValues = ["GRUPPE_EVT", "GRUPPE_ALT", "GRUPPE"];

  function getHierLevel(loopElement: any, currentElement: any) {
    const actualHierarchyElement = hierarchy.filter(
      (element) => element.value == loopElement.positionType
    )[0];
    const currentHierarchyElement = hierarchy.filter(
      (element) => element.value == currentElement.positionType
    )[0];

    return { actualHierarchyElement, currentHierarchyElement };
  }

  if (
    currentRow.level == 1 ||
    posValues.includes(currentRow.positionType) ||
    (groupValues.includes(currentRow.positionType) && currentRow.level == 1)
  ) {
    return true;
  }

  for (let i = currentRowNumber; i >= 0; i--) {
    const actualLoopRow = data.value[i];
    if (actualLoopRow.level < currentRow.level || i == 0) {
      const { actualHierarchyElement, currentHierarchyElement } = getHierLevel(
        actualLoopRow,
        currentRow
      );
      //Bugfix
      if (actualHierarchyElement && currentHierarchyElement) {
        result = actualHierarchyElement.hierLevel < currentHierarchyElement.hierLevel;
      }
      break;
    }
  }

  //Last check up to proof if an Element with a higher hierarchyorder comes direktly after
  // the choosen element or directly after a subelement of the choosen element
  for (let i = currentRowNumber + 1; i < data.value.length; i++) {
    const actualLoopRow = data.value[i];
    const { actualHierarchyElement, currentHierarchyElement } = getHierLevel(
      actualLoopRow,
      currentRow
    );
    if (actualLoopRow.level > currentRow.level) {
      if (actualHierarchyElement.hierLevel <= currentHierarchyElement.hierLevel) {
        result = false;
      }
      break;
    }
  }
  return result;
}

function fillUpTable() {
  // let lastPos = data.value[data.value.length-1].pos
  let lastPos = -1;
  for (let i = data.value.length - 1; i >= 0; i--) {
    if (data.value[i].level == 1) {
      lastPos = data.value[i].pos;
      break;
    }
  }
  for (let k = data.value.length - 1; k <= 33; k++) {
    editorRef.value.newRecord({});
    updateDataAttribute(data.value.length - 1, 'pos', lastPos);
    updateDataAttribute(data.value.length - 1, 'positionType', 'POSITION');
    lastPos++;
  }

  setTimeout(() => editorRef.value.moveTo(0, 0), 50);
}

function renumber() {
  // Needs to be excetuded two times if one of the external References
  // was changed manually, otherwise the position numbers are not right.
  for (let index = 0; index < 2; index++) {
    renumberPositions();
    renumberExtRef();
  }
}

function renumberPositions() {
  const uniqueEntrys = new Map<string, number>();
  data.value.forEach(({ extRef, level }: { extRef: string; level: number }, index) => {
    if (index >= 1) {
      const lastElement = data.value[index - 1];
      if (level < lastElement.level) {
        uniqueEntrys.delete(lastElement.extRef);
      }
    }

    let newValue: number;
    if (level === 1) {
      if (uniqueEntrys.has("level1")) {
        newValue = (uniqueEntrys.get("level1") as number) + 1;
      } else {
        // Only for the first element of the table
        newValue = 1;
      }
      uniqueEntrys.set("level1", newValue);
      updateDataAttribute(index, 'pos', newValue);
    } else {
      if (uniqueEntrys.has(extRef)) {
        newValue = (uniqueEntrys.get(extRef) as number) + 1;
      } else {
        newValue = 1;
      }
      uniqueEntrys.set(extRef, newValue);
      updateDataAttribute(index, 'pos', '>>'.repeat(level - 1) + newValue);
    }
  });
}

function renumberExtRef(newPosValue?: string | number, rowIndex?: number) {
  const uniqueEntrys = new Map<number, string>();
  if (!newPosValue) {
    // "Renumber was clicked"
    data.value.forEach((element, index) => {
      if (element.level) {
        const lastElement = data.value[index - 1];
        if (index >= 1 && element.level < lastElement.level) {
          for (let i = lastElement.level; i > element.level; i--) {
            uniqueEntrys.delete(i);
          }
        }
        if (element.level > 1) {
          const currentElement = data.value[index];
          if (uniqueEntrys.has(currentElement.level)) {
            currentElement.extRef = uniqueEntrys.get(element.level);
          } else {
            let lastPosAsNumber: string;
            let newExtRef;
            if (element.level > 2) {
              lastPosAsNumber = lastElement.pos.toString().replaceAll(">", "");
              newExtRef = (uniqueEntrys.get(element.level - 1) as string) + "." + lastPosAsNumber;
            } else {
              //  element.level == 2
              lastPosAsNumber = lastElement.pos;
              newExtRef = lastElement.extRef
                ? lastElement.extRef + "." + lastPosAsNumber
                : lastPosAsNumber;
            }
            uniqueEntrys.set(element.level, newExtRef);
            currentElement.extRef = newExtRef;
          }
        } else {
          uniqueEntrys.clear();
        }
      }
    });
  } else {
    //Position was changed manually

    const currentIndex = rowIndex!;
    const currentElement = data.value[currentIndex];
    newPosValue = newPosValue.toString();
    const newPosValueAsNumber: string = newPosValue.replaceAll(">", "");
    // The current string of extRef, which is about to change
    const oldExtRef: string = data.value[currentIndex + 1].extRef;
    const changingExtRef: string = currentElement.extRef
      ? currentElement.extRef + "." + newPosValueAsNumber
      : newPosValueAsNumber;
    for (let index = currentIndex + 1; data.value[index].level > currentElement.level; index++) {
      const actualLoopElement = data.value[index];

      if (typeof actualLoopElement.extRef == "string") {
        actualLoopElement.extRef = actualLoopElement.extRef.replace(
          oldExtRef.toString(),
          changingExtRef
        );
      } else {
        actualLoopElement.extRef = changingExtRef;
      }
    }
  }
  renumberExtRefPos();
}

function clearUp() {
  const posTypes = [
    "GEWERK",
    "LOS",
    "GRUPPE",
    "TITEL",
    "GRUPPE_EVT",
    "GRUPPE_ALT",
    "GRUPPE_BED",
    "TEXT_POS",
  ];

  function condition(element: any) {
    return posTypes.includes(element.positionType) || !!element.ean || !!element.quantity;
  }

  let lastIndex = -1;
  for (let index = data.value.length - 1; index > 0; index--) {
    if (condition(data.value[index])) {
      lastIndex = index;
      break;
    }
  }

  data.value = data.value.filter((entry, index) => {
    return (
      entry.ean ||
      entry.quantity ||
      posTypes.includes(entry.positionType) ||
      (index > lastIndex && entry.level == 1)
    );
  });

  //Refill Excel table with empty row if to many rows where deleted in the code above --> Table looks prettier
  setTimeout(() => {
    fillUpTable();
  }, 50);
}

function exportAsExcel() {
  editorRef.value.exportTable("xlsx", false, "Excel-Export");
}

// function validateNumber(newVal: any, oldVal: any/*, _record: never, _field: never*/) {
//   //Check if a subelement is edited
//   const containerNumber = data.value[currentCell.value.row as number].extRef;
//   if (oldVal != newVal) {
//     if (containerNumber % 1 == 0) {
//       if (newVal % 1 != 0) {
//         return t("COMPONENTS.POSITION.FASTENTRYDIALOG.VALIDATE.ERROR");
//       } else {
//         return;
//       }
//     }
//     const rest = newVal - Math.trunc(oldVal);
//     if (rest >= 1 || rest <= 0) {
//       return t("COMPONENTS.POSITION.FASTENTRYDIALOG.VALIDATE.ERROR");
//     }
//   }
// }

function getValuesForPositionCheck() {
  const currentRowNumber = currentCell.value.row as number;
  const currentRow = data.value[currentRowNumber];
  const lastRow = data.value[currentRowNumber - 1];
  return {
    lastRow,
    currentRow,
    currentRowNumber,
  };
}

function onUpdateQuantity(cell: { newVal: string; oldVal: string; $id: string }) {
  if (cell.newVal != cell.oldVal) {
    const rowIndex = data.value.findIndex((element) => element.$id === cell.$id);
    const currentRow = data.value[rowIndex];

    const numberOfDecimalPlaces = cell.newVal.replace(",", ".").split(".")[1]?.length || 0;
    const floatValue = parseFloat(cell.newVal.replace(",", "."));
    if (!Number.isNaN(floatValue) && numberOfDecimalPlaces > 3) {
      const roundToThreeDecimalPlacesAndRemoveTrailingZeros = (num: number) => parseFloat(num.toFixed(3)).toString();
      cell.newVal = roundToThreeDecimalPlacesAndRemoveTrailingZeros(floatValue);
    } 

    currentRow.quantity = cell.newVal.replace(".", ",");
  }
}

function onUpdateType(cell: { newVal: string; oldVal: string; $id: string }) {
  //Check if the position of a subelement was changed
  const positionValues = ["POSITION", "ALT_POS", "EV_POS", "BED_POS", "TEXT_POS"];
  if (cell.newVal != cell.oldVal) {
    let { currentRowNumber } = getValuesForPositionCheck();
    const { currentRow } = getValuesForPositionCheck();
    if (currentRow) {
      if (currentRow.level > 1 && !validatePosition()) {
        updateDataAttribute(currentRowNumber, 'positionType', cell.oldVal);
        alert(t("COMPONENTS.POSITION.FASTENTRYDIALOG.INCORRECT_POSITIONTYPE"));
        return;
      }
      if (posType.value) {
        const globalHierarchyLevel = hierarchy.filter(
          (element) => element.value == posType.value
        )[0].hierLevel;
        const currentHierarchyLevel = hierarchy.filter(
          (element) => element.value == currentRow.positionType
        )[0].hierLevel;

        if (globalHierarchyLevel >= currentHierarchyLevel) {
          alert(t("COMPONENTS.POSITION.FASTENTRYDIALOG.INCORRECT_POSITIONTYPE"));
          currentRow.positionType = cell.oldVal;
          return;
        }
      }

      if (data.value[currentRowNumber].positionType == "TEXT_POS") {
        const rowIndex = data.value.findIndex((e) => e.$id === cell.$id);
        const row = data.value[rowIndex];
        addRow(row);
      }

      const setProductValues = function () {
        switch (cell.newVal) {
          case "GEWERK":
            currentRow.product = "VAGHIEGEW";
            currentRow.ean = "";
            break;
          case "LOS":
            currentRow.product = "VAGHIELOS";
            currentRow.ean = "";
            break;
          case "GRUPPE":
          case "GRUPPE_ALT":
          case "GRUPPE_EVT":
          case "GRUPPE_BED":
            currentRow.product = "VAGHIEGRP";
            currentRow.ean = "";
            break;
          case "TITEL":
            currentRow.product = "VAGHIETTL";
            currentRow.ean = "";
        }
      };

      if (data.value[currentRowNumber + 1]) {
        const nextRowLevel = data.value[currentRowNumber + 1].level;
        if (nextRowLevel == currentRow.level + 1) {
          if (positionValues.includes(cell.newVal)) {
            currentRow.product = currentRow.ean ? currentRow.product : "";
          } else {
            setProductValues();
          }
          getCurrentInstance()?.proxy?.$forceUpdate();
          editorRef.value.$forceUpdate()
          return;
        }
      }

      if (!positionValues.includes(cell.newVal)) {
        //Update Value for Products columns
        setProductValues();

        let newEntrys: { pos: string; positionType: string; extRef: string; level: number }[] =
          [];

        let extRefEnd = currentRow.pos;
        const arrowString = ">>".repeat(currentRow.level);
        let commingExtRef = "";

        // Trim all ">" Characters for the external Reference if Positionvalues contain those.
        if (currentRow.level > 1) {
          const arrowTrim = ">>".repeat(currentRow.level - 1);
          extRefEnd = extRefEnd.replace(arrowTrim, "");
        }

        commingExtRef = currentRow.extRef ? currentRow.extRef + "." + extRefEnd : extRefEnd;

        const amountOfNewRows = 1;
        for (let i = 1; i <= amountOfNewRows; i++) {
          const posString: string = arrowString + i;
          newEntrys.push({
            pos: posString,
            positionType: "POSITION",
            extRef: commingExtRef,
            level: currentRow.level + 1,
          });
          currentRowNumber++;
        }
        // data.value.splice(currentRowNumber, 0, ...newEntrys);
        spliceData(currentRowNumber, 0, newEntrys);
        newEntrys = [];

        if (
          ["GEWERK", "GRUPPE", "GRUPPE_EVT", "GRUPPE_ALT", "TITEL"].includes(
            data.value[currentRowNumber - 1].positionType
          )
        ) {
          try {
            const arrows: string = data.value[currentRowNumber - 1].pos.toString().replace(/[^>]/g, "");
            const newPosNumber: number =
              parseInt(data.value[currentRowNumber - 1].pos.toString().replace(/[>]/g, "")) + 1;

            const newPosString = arrows + newPosNumber;

            newEntrys.push({
              pos: newPosString,
              positionType: "POSITION",
              extRef: data.value[currentRowNumber - 1].extRef,
              level: currentRow.level,
            });
            // data.value.splice(currentRowNumber + amountOfNewRows, 0, ...newEntrys);
            spliceData(currentRowNumber + amountOfNewRows, 0, newEntrys);
          } catch (err) {
            console.warn(err);
          }
        }
      }
      getCurrentInstance()?.proxy?.$forceUpdate();
      editorRef.value.$forceUpdate()
    }
  }
}

function renumberExtRefPos(): void {
  data.value.forEach((row) => {
    if (
      row.level > 1 ||
      !["POSITION", "EV_POS", "ALT_POS", "BED_POS"].includes(row.positionType) ||
      !!row.ean ||
      props.extRef
    ) {
      if (row.level == 1) {
        row.extRefPos = props.extRef ? row.extRef + "." + row.pos : row.pos;
      } else {
        row.extRefPos = row.extRef + "." + row.pos.toString().replaceAll(">", "");
      }
    }
  });
}

function prepareDataBeforeSubmit() {
  // Deep Cloning to create a new variable identical to data.value without refrencing it....
  let copyData = JSON.parse(JSON.stringify(data.value));
  const groupAndPosValues = [
    "EV_POS",
    "BED_POS",
    "ALT_POS",
    "TEXT_POS",
    "GRUPPE_BED",
    "GRUPPE_EVT",
    "GRUPPE_ALT",
  ];

  const positionValues = ["ALT_POS", "EV_POS", "BED_POS"];
  const allPosititionValues = [...positionValues, "POSITION"];
  copyData.forEach((element: any) => {
    const { positionType, quantity } = element;
    if (groupAndPosValues.includes(positionType)) {
      if (positionType == "GRUPPE_EVT" || positionType == "EV_POS") {
        element.articleType = "EVENTUAL";
      } else if (positionType == "GRUPPE_ALT" || positionType == "ALT_POS") {
        element.articleType = "ALTERNATIV";
      } else if (positionType == "BED_POS") {
        element.articleType = "BEDARF";
      } else if (positionType == "TEXT_POS") {
        element.positionType = "TEXT";
      }
      element.quantity = Math.floor(quantity);
    }
    if (positionValues.includes(positionType)) {
      element.positionType = "POSITION";
    }
    element.quantity = quantity ? quantity.replace(",", ".") : "";
  });
  // If a row is empty there might occure an error --> remove empty rows!
  copyData = copyData.filter(
    (element: any) => element.ean || !allPosititionValues.includes(element.positionType)
  );

  return copyData;
}

function checkQuantites(copyData: any) {
  const groups = ["GRUPPE", "GRUPPE_EVT", "GRUPPE_BED", "GRUPPE_ALT"];

  const positions = ["POSITION", "EV_POS", "BED_POS", "ALT_POS"];
  for (const element of copyData) {
    if (
      ((positions.includes(element.positionType) && element.ean) ||
        groups.includes(element.positionType)) &&
      (!element.quantity)
    ) {
      return element.pos;
    }
  }
  return false;
}

function isStandardPosition(x: { positionType: string; articleType: string | undefined}) {
  return x.positionType === "POSITION" && !x.articleType;
}

function checkAlternativeValues(copyData: any): boolean {
  //extRefPos of alternativeGroups
  const uniqueALTGroups = new Map<string, string | boolean>();
  const altGroupArray = [];

  for (const index in copyData) {
    const { positionType, articleType, extRefPos } = copyData[index];
    if (positionType == "GRUPPE_ALT") {
      if (uniqueALTGroups.has(extRefPos)) {
        //duplicate elements with same extrefPos
        return false;
      }
      uniqueALTGroups.set(extRefPos, false);
      altGroupArray.push(extRefPos);
    } else if (positionType == "POSITION" && articleType == "ALTERNATIV") {
      //first position can't be an alternativposition
      if (Number(index) === 0) {
        return false;
      }

      const prevPosition = copyData[Number(index) - 1];
      //previous position of any alternative position must be a standardposition with the same extRefPos
      const hasMatchingStandardPosition = isStandardPosition(prevPosition) && extRefPos == prevPosition.extRefPos;
      if (!hasMatchingStandardPosition) {
        return false;
      }
    }
  }

  //If an alternative group exists there can be exactly one group position with the same extRefPos
  if (altGroupArray.length) {
    for (const element of altGroupArray) {

      let deepCopyData: any[] = JSON.parse(JSON.stringify(copyData));
      deepCopyData = deepCopyData.filter(
        (data: any) =>
          data.positionType == "GRUPPE" && data.extRefPos.toString() == element.toString()
      );
      //If  deepCopyData > 1 ==> duplicate extrefs
      // If deepCopyData == 0 ==> No matching Standardposition to an alternativposition --> Error!
      if (deepCopyData.length != 1) return false;
    }
  }
  return true;
}

function checkBeforSave(data: any): boolean {
  const missingQuantityPos = checkQuantites(data);
  if (missingQuantityPos) {
    commit(
      "activateAlert",
      {
        type: "error",
        message: t("COMPONENTS.POSITION.FASTENTRYDIALOG.VALIDATE.ERROR_QUANTITES"),
      },
      { root: true }
    );
    const RowIndex = data.value.findIndex((element: any) => element.pos === missingQuantityPos);
    editorRef.value.moveTo(RowIndex, 3);
    return false;
  }

  if (!checkAlternativeValues(data)) {
    commit(
      "activateAlert",
      {
        type: "error",
        message: t("COMPONENTS.POSITION.FASTENTRYDIALOG.VALIDATE.ERROR_ALTERNATIVE"),
      },
      { root: true }
    );
    return false;
  }
  return true;
}

function submit() {
  const data: [] = prepareDataBeforeSubmit();
  if (!data.length) {
    return;
  } else if (!checkBeforSave(data)) {
    return;
  }

  axios({
    method: "post",
    url: "/position/addFromFastEntry",
    params: {
      quotationId: props.quotationId,
      positionId: props.positionId,
    },
    data: data,
  })
    .then((response: AxiosResponse<boolean> | any) => {
      if (response.status === 200) {
        if (response.data.status) {
          emit("close");
        } else {
          if (response.data.message) {
            commit(
              "activateAlert",
              {
                type: "error",
                message: t(
                  "COMPONENTS.POSITION.FASTENTRYDIALOG.VALIDATE." + response.data.message
                ),
              },
              { root: true }
            );
          } else if (response.data) {
            emit("close");
          }
        }
      }
    })
    .catch((err) => {
      console.error(err);
    });
}

function onCellClick() {
  lastKey.value = "MouseClick";
}

function onKeyDown(event: KeyboardEvent) {
  switch (event.key) {
    case "ArrowDown":
      lastKey.value = "ArrowDown";
      if (event.altKey) {
        editorRef.value.moveTo(data.value.length - 2, 0);
      }
      break;
    case "ArrowLeft":
      lastKey.value = "ArrowLeft";
    case "ArrowRight":
      lastKey.value = "ArrowRight";
    case "ArrowUp":
      lastKey.value = event.key;
      if (event.altKey) {
        editorRef.value.moveTo(0, 0);
      }
      break;
    case "Tab":
      lastKey.value = event.shiftKey ? "ArrowLeft" : "Enter";
      break;
    case "Enter":
      lastKey.value = event.shiftKey ? "ArrowLeft" : "Enter";
      if (currentCell.value.col == 7) {
        createNewRowAndJump();
      }
      break;
    case "F1":
      if (event.altKey) {
        clearUp();
      }
      break;
    case "F2":
      if (event.altKey) {
        renumber();
      }
      break;
    case "F3":
      if (event.altKey) {
        exportAsExcel();
      }
      break;
    case "s":
      if (event.altKey) {
        submit();
      }
      break;
  }
}

function createNewRowAndJump(): void {
  if (data.value.length == (currentCell.value.row as number) + 1) {
    editorRef.value.newRecord({});
    const newIndex: number = (currentCell.value.row as number) + 2;
    updateDataAttribute(data.value.length - 1, 'pos', newIndex);
    updateDataAttribute(data.value.length - 1, 'positionType', 'POSITION');
  }

  editorRef.value.moveTo((currentCell.value.row as number) + 1, 0);
}

function getExcelValue(newVal: string) {
  try {
    //If a value from an Excel file was copied, the last 4 characters of the string are always "\r\n"
    const globalRowIndex = currentCell.value.row as number;
    let globalColIndex = currentCell.value.col as number;

    let newRowEntrys = newVal.split("\r\n");

    if (newRowEntrys.length > 1) {
      newRowEntrys = (newRowEntrys as string[]).slice(0, -1);
    }

    const rowNumbers = (newRowEntrys as string[]).length;
    let colNumbers = 0;
    //Check if multiple cols where copied
    if (newRowEntrys.length && newRowEntrys[0].includes("\t")) {
      const splitRows = newRowEntrys[0].split("\t");
      colNumbers = splitRows.length;
    }

    const result: { value: string; col: number; row: number }[] = [];

    let totalColIndex: number;
    const validate = function (
      colIndex: number,
      element: string
    ): { passed: boolean; value: string } {
      if (colIndex == 0 || colIndex == 3) {
        element = !isNaN(parseInt(element)) ? element : "";
        return { value: element, passed: true };
      } else if (colIndex == 1) {
        return { value: element, passed: true };
      }
      return { value: "", passed: false };
    };
    // Check if multiple columns AND multiple rows exist!
    if (colNumbers > 1 && rowNumbers > 1) {
      newRowEntrys.forEach((entry: string, indexRow) => {
        entry.split("\t").forEach((element, indexCol) => {
          totalColIndex = globalColIndex + indexCol;
          totalColIndex = totalColIndex == 2 ? 3 : totalColIndex;
          // Only Columns "EAN"(Index = 1) or Columns "Quantity"(Index = 3) should be filled with values
          if (validate(totalColIndex, element).passed) {
            element = validate(totalColIndex, element).value;
            result.push({
              value: element,
              row: globalRowIndex + indexRow,
              col: totalColIndex,
            });
          }
        });
      });
    } // Check if only multiple columns exist
    else if (colNumbers) {
      newRowEntrys.forEach((element, indexCol) => {
        const colEntrys = element.split("\t");
        colEntrys.forEach((entry) => {
          totalColIndex = globalColIndex + indexCol;
          totalColIndex = totalColIndex == 2 ? 3 : totalColIndex;
          if (validate(totalColIndex, entry).passed) {
            entry = validate(totalColIndex, entry).value;
            result.push({
              value: entry,
              row: globalRowIndex,
              col: totalColIndex,
            });
          }
        });
      });
    } else {
      // No Columns where copied, only rows!
      newRowEntrys.forEach((element, indexRow) => {
        globalColIndex = globalColIndex == 2 ? 3 : globalColIndex;
        if (validate(globalColIndex, element).passed) {
          element = validate(globalColIndex, element).value;
          result.push({
            value: element,
            row: globalRowIndex + indexRow,
            col: globalColIndex,
          });
        }
      });
    }
    return result.length ? result : null;
  } catch (err) {
    console.error(err);
    return null;
  }
}

function getAmountOfNewRows(numberOfRows: number) {
  const currentRowIndex = currentCell.value.row as number;
  const currentRow = data.value[currentRowIndex];
  const lastIndex = currentRowIndex + numberOfRows - 1;

  let newRowsAmount = 0;
  let startIndex = currentRowIndex - 1;

  if (currentRow) {
    for (let i = currentRowIndex; i <= lastIndex; i++) {
      const actualForRow = data.value[i];

      if (actualForRow) {
        if (actualForRow.level < currentRow.level) {
          newRowsAmount++;
        } else {
          startIndex++;
        }
      } else {
        newRowsAmount++;
      }
    }
  }

  return { newRowsAmount, startIndex };
}

function createNewRows(newRowsAmount: number, startIndex: number) {
  const currentRow = data.value[startIndex];
  let posNumber: number =
    currentRow.level == 1 ? currentRow.pos : parseInt(currentRow.pos.toString().replace(/[>]/g, ""));
  posNumber++;
  const arrows: string = ">>".repeat(currentRow.level - 1);

  for (let i = 0; i < newRowsAmount; i++) {
    if (startIndex + i > data.value.length - 1) {
      editorRef.value.newRecord({});
    } else {
      const newPosString: string = arrows + posNumber.toString();
      const newEntry = {
        pos: newPosString,
        level: currentRow.level,
        positionType: "POSITION",
        extRef: currentRow.extRef,
      };
      // data.value.splice(startIndex + i + 1, 0, newEntry);
      spliceData(startIndex + i + 1, 0, [newEntry]);
      posNumber++;
    }
  }
}

async function pasteCopiedExcelValues(cell: any) {
  const newEntrys: string[] = [];
  const results:
    {
      value: string;
      row: number;
      col: number;
      ean?: string;
      unit?: string;
      description?: string;
      product?: string;
      quantity?: string;
    }[] | null = getExcelValue(cell.newVal);

  if (results && results.length) {
    const numberOfCopiedRows = results[results.length - 1].row - results[0].row + 1;
    const { newRowsAmount, startIndex } = getAmountOfNewRows(numberOfCopiedRows);
    createNewRows(newRowsAmount, startIndex);

    for (const element of results) {
      // const currentCell = data.value[element.row];
      if (colName[element.col] == "ean") {
        newEntrys.push(element.value);
      } else {
        //Check if Positionnumbers should be pasted
        // If yes, consider probable ">>"-String
        updateDataAttribute(
          element.row,
          colName[element.col],
          element.col == 0
            ? ">>".repeat(data.value[element.row].level - 1) + element.value
            : element.value
        );
      }
    }

    if (newEntrys.length) {
      try {
        const response = await axios.post("/article/findListOfArticles", {
          articleList: newEntrys,
        });

        if (response.data && response.data.length) {
          let allArticles: {
            value: string;
            row: number;
            col: number;
            ean?: string;
            unit?: string;
            description?: string;
            product?: string;
          }[];
          response.data.forEach((article: any) => {
            allArticles = results.filter((result) => result.value == article.artikelnummer);

            //Object structure of result changes for every object with correact ean
            // ean, unit and description property will be add for elements with valid ean value.
            allArticles.forEach((element) => {
              element.ean = element.value;
              element.unit = article.basismengeneinheit;
              element.description = article.materialkurztext;
              element.product = article.bezeichnungProduktname;
            });
          });
          results.forEach((result) => {
            const row = data.value[result.row];

            if (result.ean && result.col == 1) {
              //If an articlenumber is correct and exists in the database, this element
              // will have a property named ean
              row.ean = result.value;
              row.unit = result.unit;
              row.description = result.description;
              row.product = result.product;
              row.quantity = result.quantity;
              const refs = editorRef.value;
              // Current row might be invalid due to a previous entered ean value --> remove validation
              refs.setRowError(null, row);
            } else if (result.col == 1) {
              // Invalid articlenumber --> Make an error if value != null | ""
              const error: string | null = result.value
                ? t("COMPONENTS.POSITION.FASTENTRYDIALOG.ARTICLE_NOT_FOUND")
                : null;
              editorRef.value.setRowError(error, row);
              row.unit = "";
              row.description = "";
              row.product = "";
              row.quantity = "";
              row.ean = result.value;
            } else if (result.col == 3) {
              row.quantity = result.value;
            }
          });
        } else {
          //newEntrys Array is filled with values like the result Array from above
          // Furthermore this array is only filled with invalid articlenumbers
          results.forEach((result) => {
            const currentRow: any = data.value[result.row];
            currentRow.ean = result.value;
            currentRow.description = "";
            currentRow.unit = "";
            currentRow.product = "";
            currentRow.quantity = "";
            let errorText: string | null;
            if (result.value) {
              errorText = t("COMPONENTS.POSITION.FASTENTRYDIALOG.ARTICLE_NOT_FOUND");
            } else {
              errorText = null;
            }
            editorRef.value.setRowError(errorText, currentRow);
          });
        }
      } catch (err) {
        console.error(err);
      }
    }
  }
}

async function onUpdateCell(array: any) {
  for (const cell of array) {
    //Check if values are copied from an excel file
    if (cell.newVal && (cell.newVal.includes("\r\n") || cell.newVal.includes("\t"))) {
      await pasteCopiedExcelValues(cell);
    } else {
      switch (cell.name) {
        case "pos":
          onUpdatePos(cell);
          break;
        case "ean":
          await onUpdateEan(cell);
          break;
        case "type":
          onUpdateType(cell);
          break;
        case "description":
          onUpdateDescription(cell);
          break;
        case "positionType":
          onUpdateType(cell);
          break;
        case "quantity":
          onUpdateQuantity(cell);
          break;
      }
    }
  }
  renumberExtRefPos();
  getCurrentInstance()?.proxy?.$forceUpdate();
  editorRef.value.$forceUpdate()
}

function getNextPosNumber(rowIndex: number) {
  for (let index = rowIndex; index >= 0; index--) {
    const currentPos = data.value[index].pos;
    // Check if currentpos != ">>..xx" or is not Nan
    if (typeof currentPos != "string" && currentPos) {
      return currentPos + 1;
    }
  }
}

function addRow(currentRow: {
  $id: string;
  pos: string;
  positionType: string;
  level: number;
  extRef: string;
  }) {
  const rowIndex = data.value.findIndex((element) => element.$id === currentRow.$id);
  if (currentRow.level > data.value[rowIndex + 1].level) {
    const arrows = currentRow.pos.toString().replace(/[^>]/g, "");
    const newPosNumber = parseInt(currentRow.pos.toString().replace(/[>]/g, "")) + 1;
    const posString = arrows + newPosNumber;
    const newEntry = {
      pos: posString,
      level: currentRow.level,
      positionType: "POSITION",
      extRef: currentRow.extRef,
    };
    // data.value.splice(rowIndex + 1, 0, newEntry);
    spliceData(rowIndex + 1, 0, [newEntry]);
  }
}

function onUpdatePos(cell: any) {
  const rowIndex = data.value.findIndex((e) => e.$id === cell.$id);
  const row = data.value[rowIndex];
  if (cell.newVal === null) {
    if (rowIndex >= 0) {
      const prevRow = data.value[rowIndex - 1];
      row.pos = Number.parseInt(prevRow.pos) + 1;
      // Check if row.pos == NaN
      if (!row.pos) {
        row.pos = getNextPosNumber(rowIndex);
      }
      row.extRef = prevRow.extRef;
      row.positionType = "POSITION";
      row.level = 1;
    }
  } else if (cell.oldVal != cell.newVal) {
    if (cell.newVal.includes(">")) {
      cell.newVal = cell.oldVal;
    } else if (row.level > 1) {
      const oldVal = cell.oldVal.toString();
      const arrows: string = oldVal.replace(/[^>]/g, "");
      cell.newVal = arrows + cell.newVal;
    }
    row.pos = cell.newVal;

    if (cell.newVal != cell.oldVal) {
      renumberExtRef(cell.newVal, rowIndex);
    }
  }
}

async function onUpdateEan(cell: any) {
  const row = data.value.find((e) => e.$id === cell.$id);

  if (!cell.newVal || cell.newVal === "") {
    row.description = "";
    row.product = "";
    row.quantity = "";
    row.unit = "";
    row.extRefPos = "";

    const currentRefs = editorRef.value;
    currentRefs.setRowError(null, row);
    return;
  } else if (
    // If positiontype is a non-position --> Value Change not allowed!
    row &&
    ["GEWERK", "LOS", "TITEL", "GRUPPE", "GRUPPE_BED", "GRUPPE_ALT", "GRUPPE_EVT"].includes(
      row.positionType
    )
  ) {
    row.ean = "";
    return;
  }
  try {
    const response = await axios({
      method: "get",
      url: "/article/findByArticleNumber",
      params: { articleNumber: cell.newVal },
    });

    if (response.status === 200) {
      const article = response.data;
      row.product = article.bezeichnungProduktname;
      row.unit = article.basismengeneinheit;
      row.description = article.materialkurztext;
      editorRef.value.setRowError(null, row);
      addRow(row);
    }
  } catch (err) {
    console.error(err);
    editorRef.value.setRowError(
      t("COMPONENTS.POSITION.FASTENTRYDIALOG.ARTICLE_NOT_FOUND"),
      row
    );
  }
}

function onUpdateDescription(cell: any) {
  const rowIndex = data.value.findIndex((e) => e.$id === cell.$id);
  if (rowIndex >= 0) {
    const row = data.value[rowIndex];
    if (row.positionType === "POSITION") {
      row.description = cell.oldVal;
      getCurrentInstance()?.proxy?.$forceUpdate()
    }
  }
}

function scrollExcelTable(dings: any) {
  const containerElement = document.getElementById("systable")?.parentElement as HTMLElement;
  const cellElement = dings.cell as HTMLElement;
  const cellTop = cellElement.offsetTop - 45;
  const containerHeight = containerElement.offsetHeight - 90;
  const behavior =
    Math.abs((currentCell.value.row as number) - dings.rowPos) > 1 ? "auto" : "smooth";
  if (cellTop < containerElement.scrollTop) {
    containerElement.scrollTo({
      top: cellTop,
      behavior: behavior,
    });
  } else if (cellTop > containerHeight) {
    const scrollTo = cellTop - containerHeight;
    if (scrollTo > containerElement.scrollTop) {
      containerElement.scrollTo({
        top: scrollTo,
        behavior: behavior,
      });
    }
  }
}

function tabEnterJump() {
  const positions = ["POSITION", "EV_POS", "ALT_POS", "BED_POS"];
  const cell = data.value[currentCell.value.row as number];
  const currentRow = currentCell.value.row as number;
  if (lastKey.value == "Tab" || lastKey.value == "Enter") {
    if (positions.includes(cell.positionType)) return;

    if (currentCell.value.col == 1) {
      editorRef.value.moveTo(currentRow, 5);
    } else if (currentCell.value.col == 6) {
      editorRef.value.moveTo(currentRow + 1, 0);
    }
  }
}

function clickAndArrowNavigation() {
  switch (lastKey.value) {
    case "ArrowDown":
      editorRef.value.moveSouth();
      break;
    case "ArrowLeft":
      editorRef.value.moveWest();
      break;
    case "ArrowRight":
      editorRef.value.moveEast();
      break;
    case "ArrowUp":
      editorRef.value.moveNorth();
      break;
  }
}

function onCellFocus(cell: any) {
  const groups = ["GRUPPE", "GRUPPE_EVT", "GRUPPE_BED", "GRUPPE_ALT"];
  scrollExcelTable(cell);
  if (cell.colPos == 7) {
    switch (lastKey.value) {
      case "ArrowRight":
      case "MouseClick":
        editorRef.value.moveWest();
        return;
      case "Enter":
        createNewRowAndJump();
        return;
    }
  }
  currentCell.value = { row: cell.rowPos, col: cell.colPos };
  const { col } = currentCell.value;
  //editorRef.value.moveEast();

  if (lastKey.value) {
    if (cell.cell.classList.contains("readonly")) {
      clickAndArrowNavigation();
      switch (lastKey.value) {
        case "MouseClick":
          editorRef.value.moveEast();
          break;
        // case "+":
        case "Enter":
        case "Tab":
          if (col == 2) {
            (editorRef.value as any).moveEast();
          } else if (
            cell.record.positionType == "TEXT_POS" ||
            groups.includes(cell.record.positionType)
          ) {
            editorRef.value.moveEast();
          } else {
            // Check if a new row must be added and add if necessary
            createNewRowAndJump();
          }
          break;
      }
    }
    // Check if positionType == "Text-Pos" in the same row
    else if (cell.record && cell.record.positionType == "TEXT_POS") {
      if (![0, 5, 6].includes(col as number)) {
        clickAndArrowNavigation();
      }
      switch (lastKey.value) {
        case "MouseClick":
          if (col == 1 || col == 3) {
            editorRef.value.moveEast();
          }
          break;
        case "Enter":
        case "Tab":
          if (col == 1 || col == 3) {
            editorRef.value.moveEast();
          } else if ((col as number) > 3) {
            tabEnterJump();
          }
      }
    } else if (cell.record && groups.includes(cell.record.positionType)) {
      if (![0, 3, 5, 6].includes(col as number)) {
        clickAndArrowNavigation();
      }
      switch (lastKey.value) {
        case "MouseClick":
          if (col == 1) {
            editorRef.value.moveEast();
          }
          break;
        case "Enter":
        case "Tab":
          if (col == 1) {
            editorRef.value.moveEast();
          } else if ((col as number) > 5) {
            tabEnterJump();
          }
      }
    } else {
      tabEnterJump();
    }
  }
}
</script>

<style lang="scss">
.readonly {
  background-color: #e9ecef !important;
}

#vue-excel-editor {
  display: block !important;
}

//Hide Numbers on the Index Column of the vue excel editor
tr > td > span {
  visibility: hidden;
}

//Disable clicking on columns headers
thead > tr {
  pointer-events: none;
}

// For being able to select all elements
th:first-child {
  pointer-events: visible;
}

// Show column seperators
.col-sep {
  pointer-events: visible;
}

// Because the dropdown menu of the selection cells has a weird offset
.autocomplete-results {
    transform: translate(calc(-0.48*(100vw - 1170px)), calc(-0.3*(100vh - 500px)));
}
</style>
