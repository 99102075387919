/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
//import '@mdi/font/css/materialdesignicons.css'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import 'vuetify/styles'

// Composables
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { de, en } from 'vuetify/locale'
import { createVuetify } from 'vuetify'

export default createVuetify({
  components,
  directives,
  locale: {
    locale: 'de',
    fallback: 'en',
    messages: { de, en },
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: { mdi },
  },
  theme: {
    variations: {
      colors: ['primary', 'secondary', 'accent'],
      lighten: 6,
      darken: 6,
    },
    themes: {
      light: {
        dark: false,
        colors: {
          primary: "#333333",
          secondary: "#999999",
          accent: "#ffff00",
        },
      },
    },
  },
  defaults: {},
})
