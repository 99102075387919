<template>
  <v-container class="pa-0">
    <MasterDataCustomer
      :client="masterdata.client"
      :recipient="masterdata.recipient"
      :contact="masterdata.contact"
      :salesArea="masterdata.salesArea"
      @submitMasterdata="submitMasterdata"
    />

    <MasterDataFurtherContact
      :masterData="masterdata"
      :planner="masterdata.planner"
      @submitMasterdata="submitMasterdata"
    />

    <MasterDataOpportunity
      :opportunity="masterdata.opportunity"
      @submitMasterdata="submitMasterdata"
    />

    <MasterDataBillOfQuantity
      :billOfQuantity="masterdata.billOfQuantityStammdaten"
      @submitMasterdata="submitMasterdata"
    />

    <MasterDataFurtherInformation :quotation="quotation" />
  </v-container>
</template>

<script lang="ts">
import MasterDataCustomer from "@/components/master-data/MasterDataCustomer.vue";
import MasterDataFurtherContact from "@/components/master-data/MasterDataFurtherContact.vue";
import MasterDataOpportunity from "@/components/master-data/MasterDataOpportunity.vue";
import MasterDataBillOfQuantity from "@/components/master-data/MasterDataBillOfQuantity.vue";
import MasterDataFurtherInformation from "@/components/master-data/MasterDataFurtherInformation.vue";
import { MasterdataView } from "@/core/models/master-data/MasterData.model";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  components: {
    MasterDataCustomer,
    MasterDataFurtherContact,
    MasterDataOpportunity,
    MasterDataBillOfQuantity,
    MasterDataFurtherInformation,
  },
  // beforeRouteEnter(to, from, next){
  //       next(vm => {
  //           alert('Entering Foo');
  //       });
  //   next();
  // }
    methods: {
        restoreMasterdata() {
            this.$store.dispatch("masterdata/loadMasterdata", this.quotation.id);
            this.$store.dispatch("masterdata/setMasterdataChanged", false);
        },
        submitMasterdata() {
            this.$store.dispatch("masterdata/updateMasterdata", {
              quotationId: this.quotation.id,
              masterdata: this.masterdata,
            });
            this.$store.dispatch("masterdata/setMasterdataChanged", false);
        }
    },
    props: {
        quotation: {
            type: Object as PropType<any>
        }
    },
    computed: {
        masterdata(): MasterdataView {
            return this.$store.getters["masterdata/masterdata"];
        },
        masterdataChanged(): boolean {
            return this.$store.getters["masterdata/masterdataChanged"];
        }
    }
})

</script>
