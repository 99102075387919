<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>{{ $tc("COMPONENTS.USER_ADMIN.MAPPINGS.USER_PROFILE.TITLE") }}</h1>
      </v-col>
    </v-row>
    <v-divider class="mb-3"></v-divider>
    <v-container class="elevation-5 rounded-lg">
      <v-form>
        <v-row>
          <v-col>
            <v-text-field
              :label="$tc('COMPONENTS.USER_ADMIN.MAPPINGS.USER_PROFILE.FIRSTNAME')"
              type="text"
              disabled
              v-model="currentUser.firstname"
            />
          </v-col>
          <v-col>
            <v-text-field
              :label="$tc('COMPONENTS.USER_ADMIN.MAPPINGS.USER_PROFILE.LASTNAME')"
              type="text"
              disabled
              v-model="currentUser.lastname"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              :label="$tc('COMPONENTS.USER_ADMIN.MAPPINGS.USER_PROFILE.E-MAIL')"
              type="text"
              disabled
              v-model="currentUser.email"
            />
          </v-col>
          <v-col>
            <v-select
              :label="$tc('COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.ROLE')"
              type="text"
              disabled
              v-model="currentUser.role"
              :items="roles"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              :label="$tc('COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.COUNTRY')"
              type="text"
              :items="marketList"
              item-title="name"
              item-value="id"
              disabled
              v-model="currentUser.marketId"
            />
          </v-col>
          <v-col>
            <v-select
              :label="$tc('COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.SALES_TEAM')"
              type="text"
              v-model="currentUser.teamId"
              :items="teams"
              disabled
              item-value="id"
              item-title="name"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              :label="$tc('COMPONENTS.USER_ADMIN.MAPPINGS.USER_PROFILE.USERNAME_SAP')"
              type="text"
              v-model="currentUser.sapNumber"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn @click="updateUserProfile()" class="dark-btn">{{
              $tc("COMPONENTS.USER_ADMIN.MAPPINGS.USER_PROFILE.SAVE")
            }}</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-container>
</template>

<script lang="ts">
import { User } from "@/core/models/user/User.model";
import { Market } from "@/core/models/market/market.model";
import { rolesMapping } from "./mappings/roles.mapping";
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
    data() {
        const roles: any[] = [];
        const teams: any[] = [];

        return {
            teams,
            roles
        };
    },
    async mounted() {
        this.roles = rolesMapping;
        await this.fetchTeams();
    },
    methods: {
        async fetchTeams() {
            try {
                  const response = await axios({
                    method: "get",
                    url: "/team/list",
                    params: {
                      marketId: this.currentUser.marketId,
                    },
                  });

                  if (response && response.data && response.status == 200) {
                    this.teams = response.data;
                  }
                } catch (err) {
                  console.log(err);
                }
        },
        updateUserProfile() {
            this.$store
            .dispatch("user/updateUserProfile", {
            userId: this.currentUser.id,
            sapNumber: this.currentUser.sapNumber,
            })
            .then(() => this.$store.dispatch("user/getCurrentUser"));
        }
    },
    computed: {
        currentUser(): User {
            return this.$store.getters["user/currentUser"];
        },
        marketList(): Market[] {
            return this.$store.getters["market/market"];
        }
    }
})

</script>

<style lang="scss">
@use "@/style/styles";

.user-table {
  thead {
    background: styles.$primary;
    span {
      color: white;
    }
  }
}
</style>
