<template>
  <v-form ref="myForm">
    <v-card class="text-center">
      <v-card-title>
        {{ $tc("COMPONENTS.POSITION.POSITIONDRAGDROPDIALOG.MOVE_ELEMENT") }}
      </v-card-title>
      <v-card-text>
        {{
          multiplePositions
            ? $tc("COMPONENTS.POSITION.POSITIONDRAGDROPDIALOG.MULTIPLE_SELECTED_ELEMENTS")
            : $tc("COMPONENTS.POSITION.POSITIONDRAGDROPDIALOG.SINGLE_SELECTED_ELEMENT")
        }}
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn variant="outlined" @click="close">
          {{ $tc("COMPONENTS.POSITION.POSITIONDRAGDROPDIALOG.CANCEL") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn class="dark-btn" elevation="0" @click="moveSameLevel">
          {{ $tc("COMPONENTS.POSITION.POSITIONDRAGDROPDIALOG.MOVE_ON_SAME_LEVEL") }}
        </v-btn>
        <v-btn class="dark-btn" elevation="0" @click="moveChild">
          {{ $tc("COMPONENTS.POSITION.POSITIONDRAGDROPDIALOG.MOVE_AS_CHILD") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    mounted() {
        console.log("dragdrop");
    },
    methods: {
        moveSameLevel() {
            this.$emit("moveSameLevel");
            this.close();
        },
        moveChild() {
            this.$emit("moveChild");
            this.close();
        },
        close() {
            this.$emit("close");
        }
    },
    props: {
        multiplePositions: {
            type: Boolean
        }
    },
    computed: {
        myForm() {
                return this.$refs["myForm"] as HTMLFormElement;
        }
    }
})

</script>

<style lang="scss" scoped>
.v-card-title {
  display: block; //weil der Card-Title sonst nicht centered wird
}
</style>
