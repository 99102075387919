export default {
  "LANGUAGEKEYS": {
    "de_DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germany"])},
    "en_US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USA"])},
    "it_IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italy"])},
    "fr_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["France"])},
    "el_EL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greece"])}
  },
  "BOQ": {
    "BOQOVERVIEW": {
      "BOQ_OVERVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOQ Overview"])},
      "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "RESTART": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restart BOQ-Detection"])},
      "EXPORT": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quotation was/were exported."])}
      }
    },
    "ADDBOXDIALOG": {
      "ADD_BOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Box"])},
      "SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
    },
    "ADDBOQDIALOG": {
      "ADD_QUOTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Quotation"])},
      "ADD_BOQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add BOQ"])},
      "BOQ_ENTRY_MASK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOQ Entry Mask"])},
      "QUOTATION_ENTRY_MASK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quotation Entry Mask"])},
      "SOURCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
      "ERROR_SOURCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a source!"])},
      "ERROR_GROUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a working group!"])},
      "ERROR_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a file!"])},
      "WORKING_GROUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working Group"])},
      "UPLOAD_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload File (max. 25MB)"])},
      "LIMIT_PAGES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit Pages"])},
      "COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
      "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "BOQ_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bill of quantity was added!"])},
      "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured!"])},
      "FILETYPE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsupported filetype!"])},
      "MULTIPLE_FILETYPE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only one file type is allowed when uploading multiple files!"])}
    },
    "HEADER": {
      "LV_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LV-Number"])},
      "QUOTATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quotations"])},
      "SOURCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
      "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "SALES_TEAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales Team"])},
      "RECEIPTDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Receipt"])},
      "FILENAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filename"])},
      "COMMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
      "AUTHOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Author"])},
      "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
    }
  },
  "SHARED": {
    "COMPONENTS": {
      "DATATABLE": {
        "ITEMS_PER_PAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rows per page"])},
        "ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
        "OF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])}
      }
    }
  },
  "CORE": {
    "ERROR_HANDLER": {
      "UNKNOWN_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unknown error occured!"])}
    },
    "SERVICES": {
      "USER": {
        "USER_SERVICES": {
          "USER_CREATE_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User created!"])},
          "USER_CREATE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured while creating the user!"])},
          "USER_CHANGE_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succesfully changed user!"])},
          "USER_CHANGE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured while changing the user"])},
          "USER_DELETE_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully deleted user!"])},
          "USER_DELETE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured during the delete process!"])}
        }
      },
      "SETTINGS": {
        "PRODUCTDIVISION": {
          "CHANGE_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product division updated!"])},
          "CHANGE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product division could not be updated!"])}
        },
        "KEYWORD": {
          "ADD_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword created!"])},
          "ADD_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword could not be created"])},
          "CHANGE_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword updated!"])},
          "CHANGE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured while updating the keyword"])},
          "DELETE_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword deleted!"])},
          "DELETE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured during the deleting process"])}
        }
      },
      "MASTER_DATA": {
        "MASTER_DATA": {
          "UPDATE_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated base data!"])},
          "UPDATE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured while updating base data"])}
        }
      }
    },
    "MODELS": {
      "BOQ": {
        "BOQ_MODELS": {
          "INITIALIZING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initialiszing"])},
          "WAITING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting"])},
          "CONVERTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Converting"])},
          "AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
          "FAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
          "CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])}
        }
      }
    }
  },
  "VIEWS": {
    "ARTICLES": {
      "ARTICLES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Articles"])}
    }
  },
  "COMPONENTS": {
    "EXPORTQUOTATIONDIALOG": {
      "WANT_TO_EXPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure, you want to export this quotation?"])},
      "PROCESS_QUOTATION_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please consider that it can take up to 2 min to process the quotation document in CRM."])},
      "CLOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "ERROR": {
        "ANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured!"])},
        "ONLY_ONCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This operation can only be executed once!"])},
        "ALREADY_EXPORTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Quotation already has been exported"])},
        "NO_CUSTOMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No customer is registered!"])},
        "POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The positions still contain errors!"])},
        "NO_SAPUSER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No SAP-User is specified!"])}
      },
      "SUCCESS": {
        "EXPORTED_QUOTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quotation was successfully exported!"])}
      }
    },
    "POSITION": {
      "IMPORTPOSITION": {
        "IMPORTPOSITIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel Import"])},
        "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "UPLOAD_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload File (max. 25MB)"])},
        "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist aufgetreten!"])},
        "SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quotation was successfully imported!"])}
      },
      "CHANGEPOSITIONDIALOG": {
        "UPDATE_POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Position"])},
        "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "QUANTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
        "NEGATIVE_VALUE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The quantity must be greater than zero!"])},
        "POSITIONTYPES": {
          "GO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go"])},
          "CRAFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Craft"])},
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
          "GROUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
          "POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard Position"])},
          "TEXT_POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text Position"])}
        }
      },
      "FASTENTRYDIALOG": {
        "EAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EAN"])},
        "PRODUCT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
        "QUANTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
        "EXCEL_HOVER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export to table calculation"])},
        "UNIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "POSITIONTYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position Type"])},
        "EXTERNAL_REFERENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External Reference"])},
        "CLEAR_UP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear Up"])},
        "RENUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renumber"])},
        "EXPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export as Excel file"])},
        "VALIDATE": {
          "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not a valid value!"])},
          "ERROR_QUANTITES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing quantity!"])},
          "ERROR_ALTERNATIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No matching standard position/group or multiple matching standard positions/groups for at least one alternative position/group"])},
          "ARTICLENUMBER_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid article number!"])},
          "EXT_REF_NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External Reference could not be found!"])},
          "EXT_REF_NOT_FOUND_RECONVERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External Reference not found. Quotation has an old data structure, please reconvert!"])}
        },
        "CONFIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
        "POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
        "OPTIONS": {
          "POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["01 - Standard Position"])},
          "ALT_POS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["02 - Alternative Position"])},
          "BED_POS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["03 - Requirements Position"])},
          "EV_POS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["04 - Contingent Position"])},
          "TEXT_POS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["05 - Text Position"])},
          "GO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["06 - Go"])},
          "CRAFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["07 - Craft"])},
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["08 - Title"])},
          "GROUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09 - Group"])},
          "ALT_GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 - Alternative Group"])},
          "BED_GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11 - Requirements Group"])},
          "EV_GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 - Contingent Group"])}
        },
        "ARTICLE_NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article not found"])},
        "INCORRECT_POSITIONTYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect Positiontype"])}
      },
      "POSITIONDRAGDROPDIALOG": {
        "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "MOVE_AS_CHILD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move below"])},
        "MOVE_ON_SAME_LEVEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move element next to"])},
        "MULTIPLE_SELECTED_ELEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You selected multiple elements. How do you want to move them?"])},
        "SINGLE_SELECTED_ELEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How do you want to move the selected element?"])},
        "MOVE_ELEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move element"])}
      },
      "POSITION": {
        "TEXTPOSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text position"])},
        "POSITION_DETECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positions"])},
        "FAST_ENTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fast Entry"])},
        "ADD_ELEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Element"])},
        "FILTER_POSITIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter Positions"])},
        "ADD_SINGLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Single Element"])},
        "IMPORT_POSITIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel Import"])},
        "BOQ_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOQ-Position was moved successfully"])},
        "MISSMATCH_ID_PARENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positions-ID is incompatible with parent-ID"])},
        "NO_UNIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find no unit"])},
        "NO_QUANTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find no quantity"])},
        "ID_SMALL_POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recognized position-ID is smaller then the prior position-ID"])},
        "UNORDINARY_DISTANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The distance to the prior position-ID is unusual"])},
        "NO_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find no ID"])},
        "NO_ARTICLE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find no article number"])},
        "NO_STANDARD_ARTICLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Article of the type Standard was selected. Please choose position of reference."])},
        "NO_STANDARD_GROUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no default group for this alternative group"])},
        "ALTERNATIVE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is not allowed to place a group below another group"])}
      },
      "POSITION_FILTER": {
        "PDF_VIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF View of the BOQ"])},
        "BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
        "KEYWORDS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keywords"])},
        "PAGE_SELECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Selection"])},
        "APPLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
        "ALL_PAGES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Pages"])},
        "RELEVANT_PAGES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevant Pages"])}
      },
      "ADDPOSITIONDIALOG": {
        "ADD_ELEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Element"])},
        "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label"])},
        "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
        "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "QUANTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
        "ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
        "EMPTY_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must not be empty"])},
        "POSITIONTYPES": {
          "GO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go"])},
          "CRAFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Craft"])},
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
          "GROUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
          "POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
          "STANDARDPOSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard Position"])},
          "TEXT_POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text Position"])},
          "ALT_POS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternative Position"])},
          "EV_POS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contingent Position"])},
          "BED_POS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requirements Position"])},
          "ALT_GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternative Group"])},
          "EV_GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contingent Group"])},
          "BED_GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requirements Group"])}
        }
      },
      "DELETEPOSITIONDIALOG": {
        "DELETE": {
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
          "SINGLE_ELEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete element?"])},
          "MULTIPLE_ELEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete elements?"])},
          "SELECTED_ELEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have selected multiple elements. Do you really want to delete them all?"])},
          "SELECTED_TYPE": {
            "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete the element of type"])},
            "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["?"])}
          },
          "SUBPOSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Also delete the subpositions"])}
        },
        "MESSAGE": {
          "SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Element deleted successfully!"])},
          "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The specified element does not exist!"])},
          "CHILDREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First delete all subpositions!"])}
        },
        "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
      },
      "POSITIONARTICLEDIALOG": {
        "QUANTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
        "ARTICLE": {
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article"])},
          "ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Article"])},
          "CHANGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Article"])}
        },
        "RULES": {
          "NOT_EMPTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must not be empty"])},
          "NOT_VALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not a valid indication of quantity!"])},
          "NO_ARTICLENUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not a valid article number"])}
        },
        "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canel"])},
        "CHANGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
        "ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
        "MESSAGE": {
          "SUCCESS_ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article added successfully!"])},
          "SUCCESS_CHANGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changed Article successfully!"])}
        },
        "POSITION": {
          "STANDARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard Position"])},
          "ALTERNATIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternative Position"])},
          "REQUIREMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rquirements Position"])},
          "EVENTUAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contingent Position"])}
        }
      },
      "POSITION_CONTENT": {
        "POS_NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pos. Nr."])},
        "NOT_AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not available"])},
        "ARTICLE": {
          "NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art. Nr."])},
          "VIEGA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viega Article Nr."])},
          "ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Article"])},
          "TYPE": {
            "STANDARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
            "ALTERNATIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternative"])},
            "REQUIREMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requirement"])},
            "CONTINGENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contingent"])}
          }
        },
        "POSITION_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position Text"])},
        "QUANTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quant."])},
        "UNIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit"])},
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "QUOTATION_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quotation Text"])},
        "BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
        "NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])}
      }
    },
    "HISTORY": {
      "AUTHOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Author"])},
      "RELEASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Release"])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
      "LOADING_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The version history can not be loaded"])},
      "VERSIONHISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version History"])},
      "CURRENT_VERSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Version"])},
      "NO_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Description"])},
      "LOAD_MORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load more"])},
      "NO_FURTHER_RELEASES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No further releases"])},
      "CHANGES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen"])}
    },
    "ARTICLE": {
      "ARTICLE_OVERVIEW": {
        "ARTICLENUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article number"])},
        "SHORTCUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shortcut"])},
        "QUOTATION_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quotation text"])},
        "INTELLIGENT_SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intelligent search..."])}
      },
      "ARTICLE_DETAIL": {
        "ARTICLENUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article nr"])},
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description/Product name"])},
        "QUOTATION_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quotation text"])},
        "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
        "CLOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
      }
    },
    "PDF_VIEWER": {
      "POSITIONBEGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begin of Position"])},
      "NO_BOQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No BOQ available for this position (PDF-Document)"])},
      "DOWNLOAD_PDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download PDF"])}
    },
    "QUOTATION": {
      "QUOTATIONLIST": {
        "EDIT_COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit comment"])},
        "NEW_QUOTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Quotation"])},
        "BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
        "HEADER": {
          "BOQ_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOQ Number"])},
          "QUOTATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quotation Number"])},
          "EXPORT_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export-ID"])},
          "CLIENT_NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Number"])},
          "CLIENT_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Name"])},
          "CONTACT_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Person"])},
          "AUTHOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Author"])},
          "PRODUCT_DIVISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Division"])},
          "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
          "VERSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])}
        },
        "MESSAGE": {
          "QUOTATION_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quotation created!"])},
          "QUOTATION_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured!"])},
          "QUOTATION_EDITEDBY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Quotation is currently edited by"])}
        }
      },
      "QUOTATIONDETAIL": {
        "BOQ_BASE_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOQ Base Data"])},
        "BOQ_POSITIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOQ Positions"])},
        "RESTART_DETECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restart BOQ detection"])},
        "PROCESSING_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing progress"])},
        "BOQ_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOQ number"])},
        "QUOTATION_NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quotation number"])},
        "SOURCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
        "SALES_TEAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales Team"])},
        "RECEIVE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of receive"])},
        "AUTHOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Author"])},
        "BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])}
      },
      "IMPORTQUOTATIONDIALOG": {
        "UPLOAD_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload File (max. 25MB)"])},
        "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured!"])},
        "SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quotation successfully imported!"])}
      }
    },
    "MASTER_DATA": {
      "CUSTOMER_SELECTION": {
        "RECIPIENT_SELECTION": {
          "USE_FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please use a filter to search for an Offeree."])},
          "SEARCH_AGAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search again"])},
          "NO_CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No entry for the choosen criteria were found"])},
          "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured"])}
        },
        "HEADERS": {
          "BIDDER_SELECTION": {
            "CLIENT_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Number"])},
            "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
            "STREET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
            "POSTAL_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code"])},
            "LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])}
          },
          "CLIENT_SELECTION": {
            "CLIENT_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Number"])},
            "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
            "STREET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
            "POSTAL_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code"])},
            "LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])}
          },
          "CONTACT_SELECTION": {
            "CONTACT_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
            "FIRSTNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
            "LASTNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
            "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
            "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
            "BUSINESSPARTNERNUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Partner Nr."])}
          },
          "PLANNER_SELECTION": {
            "CLIENT_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Number"])},
            "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
            "STREET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
            "POSTAL_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code"])},
            "LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])}
          },
          "RECIPIENT_SELECTION": {
            "CLIENT_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Number"])},
            "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
            "STREET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
            "POSTAL_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code"])},
            "LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])}
          },
          "OPPORTUNITY_SELECTION": {
            "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
            "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
            "CITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
            "POSTAL_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code"])},
            "STREET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
            "DECRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decription"])},
            "DATE_OF_CREATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Creation"])},
            "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
            "INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])}
          }
        },
        "BIDDER_SELECTION": {
          "USE_FILTER": {
            "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To search for"])},
            "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please use the filter."])}
          },
          "SEARCH_AGAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search again"])},
          "NO_CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No entrys for the choosen criteria were found"])},
          "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured!"])},
          "BIDDER_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of selected bidders must be equal or less than 9!"])}
        },
        "CONTACT_SELECTION": {
          "USE_FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please use a filter to search for a contact person."])},
          "SEARCH_AGAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search again"])},
          "NO_CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No entrys for the choosen criteria were found!"])},
          "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured!"])}
        },
        "PLANNER_SELECTION": {
          "USE_FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please use a filter to search for a planner."])},
          "SEARCH_AGAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search again"])},
          "NO_CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No entrys for the choosen criteria were found!"])},
          "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured!"])}
        },
        "SELECTION_DIALOG": {
          "CLIENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
          "NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
          "COMPLETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete"])},
          "BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
          "SALES_AREA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales Area"])},
          "CONTACT_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Person"])}
        },
        "SALESAREA_SELECTION": {
          "SALESORGANISATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales Organization"])},
          "DISTRIBUTION_CHANNEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution Channel"])},
          "DIVISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division"])},
          "CLIENTID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client ID"])}
        },
        "CLIENT_SELECETION": {
          "MESSAGE": {
            "NO_CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No entrys for the choosen criteria were found!"])},
            "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured!"])}
          },
          "SEARCH_AGAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search again"])},
          "SEARCH_CLIENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please use a filter to search for a client."])}
        },
        "OPPORTUNITY_SELECTION": {
          "USE_FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please use a filter to search for an opportunity."])},
          "SEARCH_AGAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search again"])},
          "NO_CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No entrys for the choosen criteria were found!"])},
          "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured!"])}
        },
        "MASTERDATA": {
          "MASTER_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master data"])}
        },
        "CUSTOMER": {
          "CUSTOMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])},
          "CLIENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
          "CLIENT_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client ID"])},
          "OFFEREE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offeree"])},
          "OFFEREE_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offeree ID"])},
          "CONTACT_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact person"])},
          "CONTACT_PERSON_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact person ID"])},
          "CONTACT_PERSON_MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Contact person mail"])},
          "CONTACT_PERSON_PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Contact Person phone number"])},
          "CLIENT_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client address"])},
          "OFFEREE_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offeree address"])}
        },
        "FURTHER_CONTACT": {
          "FURTHER_CONTACTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Further Contacts"])},
          "BIDDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bidder"])},
          "BIDDER_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
          "BIDDERLIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of Bidders"])},
          "BIDDER_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
          "PLANNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planner"])},
          "PLANNER_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address"])},
          "PLANNER_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])}
        },
        "OPPORTUNITY": {
          "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
          "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])}
        },
        "BOQ": {
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bill Of Quantity"])},
          "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description CP"])},
          "EXTERNAL_REFERENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External reference"])},
          "LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
          "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit BOQ"])},
          "APPLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply changes"])},
          "POSTAL_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code"])},
          "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
          "STREET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])}
        },
        "FURTHER_INFORMATION": {
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Further Informations"])},
          "NUMBER_OF_POSITIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of BOQ-Positions"])},
          "PRODUCT_DIVISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product division"])}
        }
      }
    },
    "SETTINGS": {
      "UNITDIALOG": {
        "UNIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit"])},
        "ADD_UNIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Unit"])},
        "CHANGE_UNIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Unit"])},
        "CHANGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
        "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])}
      },
      "KEYWORDDIALOG": {
        "KEYWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword"])},
        "ADD_KEYWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add keyword"])},
        "CHANGE_KEYWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit keyword"])},
        "CHANGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])},
        "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])}
      },
      "UNITMANAGER": {
        "UNIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit"])},
        "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
        "DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
        "MANAGE_UNITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Units"])},
        "ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
        "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
        "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
      },
      "KEYWORDMANAGER": {
        "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
        "DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
        "KEYWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword"])},
        "MANAGE_KEYWORDS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Keywords"])},
        "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
        "ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
        "SELECT_PRODUCTGROUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select product division"])},
        "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "HIERARCHYLEVEL": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level 1"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level 2"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level 3"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level 4"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level 5"])}
        }
      }
    },
    "USER_ADMIN": {
      "MAPPINGS": {
        "USER_ADMIN_DETAILS": {
          "DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Administration - User Details"])},
          "CREATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" User Administration - Create New User"])}
        },
        "USER_ADMIN_OVERVIEW": {
          "CREATE_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new user"])},
          "USER_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Administration"])},
          "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname"])},
          "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastname"])},
          "E_MAIL_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail Address"])},
          "ROLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
          "SALES_TEAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales Team"])},
          "USERNAME_SAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnel Number"])},
          "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
          "SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
          "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
          "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])}
        },
        "USER_PROFILE": {
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Profile"])},
          "FIRSTNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
          "LASTNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
          "E-MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
          "USERNAME_SAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnel Number"])},
          "SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
        }
      }
    },
    "NAVIGATIONDRAWER": {
      "BOQ_OVERVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOQ-Overview"])},
      "ARTICLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article"])},
      "SETTINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
      "USER_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Administration"])},
      "LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
      "SELECTED_LANGUAGE": {
        "en_US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English (US)"])},
        "de_DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
        "fr_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
        "it_IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])}
      }
    },
    "NAVIGATIONBAR": {
      "PROFIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
      "TESTSYSTEM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test System"])}
    }
  },
  "SERVICES": {
    "SETTINGS": {
      "KEYWORD": {
        "ADD_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword created!"])},
        "ADD_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword could not be created"])},
        "CHANGE_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword updated!"])},
        "CHANGE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured while updating the keyword"])},
        "DELETE_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword deleted!"])},
        "DELETE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured during the deleting process"])}
      },
      "UNIT": {
        "ADD_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit created!"])},
        "ADD_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit could not be created"])},
        "CHANGE_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit updated!"])},
        "CHANGE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured while updating the unit"])},
        "DELETE_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit deleted!"])},
        "DELETE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured during the deleting process"])}
      },
      "PRODUCTDIVISION": {
        "CHANGE_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product division updated!"])},
        "CHANGE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product division could not be updated!"])}
      }
    }
  }
}