import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createBlock as _createBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3af4a4dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ml-3" }
const _hoisted_2 = { class: "ml-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_PdfView = _resolveComponent("PdfView")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_card, { height: "90vh" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, { class: "d-flex align-center" }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, _toDisplayString(_ctx.$tc("COMPONENTS.POSITION.POSITION_FILTER.PDF_VIEW")), 1),
          _createVNode(_component_v_btn, {
            class: "dark-btn ml-auto",
            onClick: _ctx.close
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.POSITION.POSITION_FILTER.BACK")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, { class: "dialog-content" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { class: "fill-height" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "3",
                class: "col-left fill-height"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, { class: "main-card keywords overflow-y-auto" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_text, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.POSITION.POSITION_FILTER.KEYWORDS")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_divider),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.keywords, (keyword, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "keyword__item d-flex align-center",
                          key: index
                        }, [
                          _createVNode(_component_v_checkbox, {
                            modelValue: _ctx.selectedKeywords,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedKeywords) = $event)),
                            value: keyword.value,
                            "hide-details": ""
                          }, null, 8, ["modelValue", "value"]),
                          _createElementVNode("span", _hoisted_1, _toDisplayString(keyword.value), 1),
                          _createElementVNode("span", _hoisted_2, _toDisplayString(`(${
                  _ctx.keywordCount.get(keyword.value.toLowerCase())
                    ? _ctx.keywordCount.get(keyword.value.toLowerCase())
                    : 0
                })`), 1),
                          _createVNode(_component_v_icon, {
                            class: "ml-auto",
                            onClick: _withModifiers(($event: any) => (_ctx.scrollToKeyword = { positionNumber: keyword.value.toLowerCase() }), ["stop"])
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("mdi-target")
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ]))
                      }), 128))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_card, { class: "main-card mt-3 page-selection" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_text, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.POSITION.POSITION_FILTER.PAGE_SELECTION")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_divider),
                      _createVNode(_component_v_card_text, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_radio_group, {
                            modelValue: _ctx.selectedOption,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedOption) = $event)),
                            density: "compact"
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.radioOptions, (option) => {
                                return (_openBlock(), _createBlock(_component_v_radio, {
                                  key: option.value,
                                  label: option.text,
                                  value: option.value
                                }, null, 8, ["label", "value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue"]),
                          _createVNode(_component_v_form, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                modelValue: _ctx.internalPageFilter,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.internalPageFilter) = $event)),
                                label: "",
                                placeholder: "1, 10-20, 99",
                                variant: "outlined",
                                density: "compact",
                                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectedOption = 'Relevante Seiten'))
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_btn, {
                            class: "dark-btn ml-auto",
                            onClick: _ctx.save
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.POSITION.POSITION_FILTER.APPLY")), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "9",
                class: "fill-height"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_PdfView, {
                    boqId: _ctx.boqId,
                    quotationId: _ctx.quotationId,
                    filterView: true,
                    showOverlay: true,
                    scrollToElement: _ctx.scrollToKeyword,
                    "onUpdate:scrollToElement": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.scrollToKeyword) = $event)),
                    keywordFilter: _ctx.selectedKeywords,
                    onKeywordCount: _cache[5] || (_cache[5] = ($event: any) => (_ctx.keywordCount = $event))
                  }, {
                    "scrolling-overlay": _withCtx(({ box, makePositionId, transform }) => [
                      _createVNode(_component_v_sheet, {
                        class: "id-overlay-sheet",
                        id: makePositionId(box.value),
                        width: box.xend - box.xstart,
                        height: box.yend - box.ystart,
                        "data-translate-y": box.ystart,
                        "data-page": box.page,
                        "data-line": box.line,
                        "data-word": box.word,
                        style: _normalizeStyle(transform(box)),
                        color: "yellow-accent-2"
                      }, null, 8, ["id", "width", "height", "data-translate-y", "data-page", "data-line", "data-word", "style"])
                    ]),
                    _: 1
                  }, 8, ["boqId", "quotationId", "scrollToElement", "keywordFilter"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}