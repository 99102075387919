<template>
  <v-container v-if="initialized" class="full-height" fluid>
    <v-row class="fill-height">
      <v-col class="fill-height" cols="6">
        <v-container class="fill-height overflow-y-auto row-container">
          <!-- 1. Leistungsverzeichnis einlesen  -->
          <v-row>
            <v-col>
              <h1>1. Leistungsverzeichnis einlesen</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pr-0">
              <v-tooltip location="bottom">
                <template #activator="{ props }">
                  <div v-bind="props">
                    <v-btn
                      style="border-radius: 5px 0px 0px 5px"
                      :disabled="definitelyNotTextBased"
                      elevation="0"
                      :variant="forceImageBased ? 'outlined' : undefined"
                      :class="{ 'dark-btn': !forceImageBased }"
                      @click="forceImageBased = false"
                    >
                      Textbasiert (PDF-Box)
                    </v-btn>
                  </div>
                </template>
                Der Text wird direkt aus der PDF-Datei extrahiert. Das ist sehr genau,
                funktioniert<br>
                aber beispielsweise nicht bei eingescannten PDFs.
              </v-tooltip>
            </v-col>
            <v-col cols="auto" class="pl-0">
              <v-tooltip location="bottom">
                <template #activator="{ props }">
                  <div v-bind="props">
                    <v-btn
                      style="border-radius: 0px 5px 5px 0px"
                      :class="{ 'dark-btn': forceImageBased }"
                      :variant="!forceImageBased ? 'outlined' : undefined"
                      elevation="0"
                      @click="forceImageBased = true"
                    >
                      Bildbasiert (OCR)
                    </v-btn>
                  </div>
                </template>
                PDF wird mit Hilfe von OCR eingelesen. Dabei kann es (vor allem bei<br>
                schlechter Qualität) zu Fehlern bei der Erkennung kommen. Trotzdem ist diese<br>
                Methode oft notwendig, weil die Datei textbasiert gar nicht eingelesen werden kann.
              </v-tooltip>
            </v-col>
            <v-spacer />
            <v-col cols="auto">
              <v-btn v-bind="{ disabled: currentlyImporting }" @click="sendReimport()">
                <div class="mr-3">
                  <v-icon v-if="!currentlyImporting">
                    mdi-refresh
                  </v-icon>
                  <v-progress-circular
                    v-else
                    indeterminate
                    size="24"
                    width="3"
                  />
                </div>
                Erneut einlesen
              </v-btn>
            </v-col>
          </v-row>
          <!-- 2. Struktur definieren -->
          <v-row>
            <v-col>
              <h1 class="mt-5">
                2. Struktur definieren
              </h1>
              Wort-Typ auswählen und anschließend zugehörige Box im Overlay anklicken:
            </v-col>
          </v-row>
          <v-row>
            <!-- Auswahl-Boxen für jeden BoxType -->
            <v-col>
              <v-chip
                v-for="boxType in boxTypes"
                v-show="boxType.show"
                :key="boxType.value"
                class="ma-1"
                :color="boxType.color"
                :variant="boxType.outlined ? 'outlined' : undefined"
                @click="selectBox(boxType.value)"
              >
                <template #append>
                  <v-icon v-show="boxType.value === currentBoxType" end icon="mdi-check" :color="boxType.color" />
                </template>
                <span :class="{ 'font-weight-bold': boxType.value === currentBoxType }">{{ boxType.label }}</span>
              </v-chip>
            </v-col>
            <!-- Button, um Struktur neu zu erkennen -->
            <v-col cols="auto">
              <v-btn
                v-bind="{ disabled: currentlyConverting }"
                @click="sendReconvert()"
              >
                <template #prepend>
                  <v-icon v-if="!currentlyConverting">
                    mdi-refresh
                  </v-icon>
                  <v-progress-circular
                    v-else
                    indeterminate
                    size="24"
                    width="3"
                  />
                </template>
                Erneut konvertieren
                <template #append>
                  <v-tooltip location="bottom">
                    <template #activator="{ props }">
                      <v-checkbox
                        v-model="reConvertOnChange"
                        v-bind="props"
                        hide-details
                        density="compact"
                        @click.stop
                      />
                    </template>
                    Bei jeder Änderung erneut konvertieren
                  </v-tooltip>
                </template>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h4>Weitere Optionen:</h4>
              <v-checkbox
                v-bind="{ 'persistent-hint': customBoxData.noPositionNumbers }"
                v-model="customBoxData.noPositionNumbers"
                hint="ACHTUNG: Mit dieser Option müssen trotzdem die jeweils ersten Wörter einiger
                Positionen als 'Positionsnummer' markiert werden, damit das System genügend Anhaltspunkte hat"
                label="Leistungsverzeichnis hat keine Positionsnummern"
                @click="reconvertIfReconvertOnChange"
              />
              <v-text-field
                v-model="customBoxData.positionStructure"
                class="mt-3"
                variant="outlined"
                density="compact"
                label="Positionsstruktur"
                clearable
                placeholder="*"
                width="200px"
                :disabled="customBoxData.noPositionNumbers"
                @update:model-value="reconvertIfReconvertOnChange"
                @click:clear="reconvertIfReconvertOnChange"
              >
                <template #append>
                  <v-tooltip location="bottom">
                    <template #activator="{ props }">
                      <v-icon v-bind="props">
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    Hier kann die Positionsstruktur eingegrenzt werden.<br>
                    - Punkte (.) grenzen Los/Gewerk/Titel/ … voneinander ab.<br>
                    - Kommas (,) grenzen die Positionsnummern voneinander ab (z.B. 1.2.10, 1.2.20,
                    …).<br>
                    - Sternchen (*) geben eine beliebige Zahl an (Platzhalter).<br>
                    - Mit Hilfe von Bindestrichen können Zahlenbereiche festgelegt werden (z.B.
                    1.2.10-30).<br>
                    - Mit Hilfe von Semikolons können mehrere Zahlen/Zahlenbereiche auf einmal
                    festgelegt werden (z.B. 1.2.1;3;5).
                  </v-tooltip>
                </template>
              </v-text-field>

              <div />
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center">
              <hr>
            </v-col>
            <v-col cols="auto" class="px-0 pr-0">
              <v-icon>mdi-chevron-down</v-icon>
            </v-col>
            <v-col cols="auto">
              <div class="font-weight-bold">
                Definierte Boxen
              </div>
            </v-col>
            <v-col cols="auto" class="px-0">
              <v-icon>mdi-chevron-down</v-icon>
            </v-col><v-col align-self="center">
              <hr>
            </v-col>
          </v-row>
          <!-- Hinweistext, wenn keine Hilfs-Box definiert ist -->
          <v-row v-if="customBoxData.list.length === 0">
            <v-col>
              <h4>- Keine manuelle Box definiert -</h4>
              <p class="text-body-2">
                Um dem System beim Erkennen der LV-Struktur zu helfen können Positionsnummern,
                Artikelnummer, etc. manuell definiert werden. Klicken Sie dafür erst eine der ovalen
                Boxen und anschließend das passende Feld im PDF-Overlay an.
              </p>
            </v-col>
          </v-row>
          <!-- Definierte Hinweis-Boxen -->
          <v-row v-else>
            <v-col>
              <v-chip
                v-for="(box, index) of customBoxData.list"
                :key="index"
                closable
                :color="boxTypes.find((e) => e.value === box.boxType)?.color"
                variant="outlined"
                :class="{
                  'line-through': !boxTypes.find((e) => e.value === box.boxType)?.show,
                }"
                class="ma-1"
                @click:close="removeManualBox(index)"
                @click="scrollToBox(box)"
              >
                {{ box.value }}
                <v-icon
                  end
                  size="18"
                  :color="boxTypes.find((e) => e.value === box.boxType)?.color"
                  @click="openEditBoxDialog(box)"
                >
                  mdi-pencil
                </v-icon>
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center">
              <hr>
            </v-col>
            <v-col cols="auto" class="px-0 pr-0">
              <v-icon>mdi-chevron-down</v-icon>
            </v-col>
            <v-col cols="auto">
              <div class="font-weight-bold">
                Erkannte Positionen
              </div>
            </v-col>
            <v-col cols="auto" class="px-0">
              <v-icon>mdi-chevron-down</v-icon>
            </v-col><v-col align-self="center">
              <hr>
            </v-col>
          </v-row>
          <!-- Vom System erkannte Positions-Boxen -->
          <v-row v-if="!currentlyFetchingPositions" class="pa-3">
            <v-col
              v-for="position of positions"
              :key="`${position.page}-${position.line}-${position.word}`"
              cols="auto"
              class="pa-0"
            >
              <!-- {{ position }} -->
              <v-chip
                color="green"
                variant="outlined"
                class="ma-1"
                closable
                @click="scrollToBox(position)"
                @click:close="removeExistingBox(position)"
              >
                {{ position.positionNumber }}
              </v-chip>
            </v-col>
          </v-row>
          <v-row v-else class="pa-3">
            <v-col
              v-for="position of positions"
              :key="position.positionNumber"
              cols="auto"
              class="pa-0"
            >
              <v-skeleton-loader type="chip" class="ma-1" size="xl" />
            </v-col>
          </v-row>
          <!-- 3. Neues Angebot generieren -->
          <v-row>
            <v-col>
              <h1 class="mt-5">3. Neues Angebot generieren</h1>
              Wenn die Positionsstruktur im PDF-Viewer korrekt angezeigt wird, kann ein neues
              Angebot erzeugt werden
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn class="dark-btn" block @click="sendCreateQuotation()">
                Angebot generieren
                <v-icon color="white" end>
                  mdi-open-in-new
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="6" class="fill-height">
        <PdfView
          v-model:scroll-to-element="scrollToPositionNumber"
          v-model:reload-pdf-triggered="reloadPdfTriggered"
          :specific-reload-triggered="specificReloadTriggered"
          :reload-triggered="reloadTriggered"
          :boq-id="boqId"
          :custom-box-data="customBoxData"
          :show-overlay-default="true"
          :show-specific-overlay-default="false"
          @position-clicked="positionClicked"
          @add-from-overlay="addManualBoxFromCurrentSelection"
          @on-pdf-click="openAddBoxDialog"
        >
          <template #scrolling-overlay="{ box, makePositionId }">
            <v-sheet
              :id="makePositionId(box.value)"
              class="id-overlay-sheet"
              :width="box.xend - box.xstart"
              :height="box.yend - box.ystart"
              :data-translate-y="box.ystart"
              :data-page="box.page"
              :data-line="box.line"
              :data-word="box.word"
            />
          </template>
        </PdfView>
      </v-col>
    </v-row>
    <v-dialog v-model="showEditBoxDialog" width="400px">
      <EditBoxDialog
        v-if="showEditBoxDialog"
        :clicked-box="clickedBox"
        :clicked-location="clickedLocation"
        @close="showEditBoxDialog = false"
        @finish="finishEditBoxDialog"
      />
    </v-dialog>
    <v-dialog v-model="showAddBoxDialog" width="400px">
      <AddBoxDialog
        v-if="showAddBoxDialog"
        :clicked-location="clickedLocation"
        @close="showAddBoxDialog = false"
        @finish="finishAddBoxDialog"
      />
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import PdfView from "@/components/pdfviewer/PdfView.vue";
import EditBoxDialog from "@/components/boq/EditBoxDialog.vue";
import AddBoxDialog from "@/components/boq/AddBoxDialog.vue";
import {
  ModifyOverlayDto,
  BoxType,
  ModifyOverlayBox,
  ModifyOverlay,
} from "@/core/models/overlay/overlay.model";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  components: {
    PdfView,
    EditBoxDialog,
    AddBoxDialog,
  },
    props: {
        boqId: {
            type: Number
        }
    },
    data() {
        const clickedLocation: any = undefined;
        const clickedBox: any = undefined;
        const timer: any = undefined;
        const scrollToPositionNumber: any = null;
        const positionIdsToOpen: number[] = [];
        const customBoxData: ModifyOverlayDto = new ModifyOverlay();
        const currentBoxType = ref<BoxType | null>(null);

        return {
            initialized: false,
            definitelyNotTextBased: false,
            forceImageBased: false,
            currentBoxType,
            customBoxData,
            positions: [] as any[],
            positionIdsToOpen,
            scrollToPositionNumber,
            reloadPdfTriggered: false,
            specificReloadTriggered: false,
            reloadTriggered: false,
            reConvertOnChange: true,
            timer,
            currentlyImporting: false,
            currentlyConverting: false,
            currentlyCreatingQuotation: false,
            currentlyFetchingPositions: false,
            clickedBox,
            showEditBoxDialog: false,
            clickedLocation,
            showAddBoxDialog: false,
            boxTypes: [
                {
                    value: BoxType.POSITION_NUMBER,
                    label: "Positionsnummer",
                    color: "green",
                    outlined: true,
                    show: true,
                    clickableForBox: true,
                },
                {
                    value: BoxType.NOT_POSITION_NUMBER,
                    label: "Positionsnummer",
                    color: "green",
                    outlined: true,
                    show: false,
                    clickableForBox: true,
                },
                {
                    value: BoxType.QUANTITY,
                    label: "Menge",
                    color: "#00008B",
                    outlined: true,
                    show: true,
                    clickableForBox: true,
                },
                {
                    value: BoxType.UNIT,
                    label: "Einheit",
                    color: "#FF69B4",
                    outlined: true,
                    show: true,
                    clickableForBox: true,
                },
                {
                    value: BoxType.ARTICLE_NUMBER,
                    label: "Artikelnummer",
                    color: "orange",
                    outlined: true,
                    show: true,
                    clickableForBox: true,
                },
                {
                    value: BoxType.START,
                    label: "Start",
                    color: "red",
                    outlined: false,
                    textColor: "white",
                    show: true,
                    clickableForBox: true,
                },
                {
                    value: BoxType.END,
                    label: "Ende",
                    color: "purple",
                    outlined: false,
                    textColor: "white",
                    show: true,
                    clickableForBox: true,
                },
                {
                    value: BoxType.NEW_BOX,
                    label: "Neue Box",
                    color: "black",
                    outlined: false,
                    textColor: "white",
                    show: true,
                    clickableForBox: false,
                },
            ],
        };
    },
    watch: {
        "specificReloadTriggered": [{
            handler: "onSpecificReloadTriggered"
        }]
    },
    mounted() {
        this.initBoq();
    },
    methods: {
        openEditBoxDialog(box: any) {
            this.clickedBox = box;
            this.showEditBoxDialog = true;
        },
        openAddBoxDialog(location: any) {
            if (this.currentBoxType !== BoxType.NEW_BOX) return;
            this.clickedLocation = location;
            this.showAddBoxDialog = true;
        },
        finishEditBoxDialog(box: any) {
            this.clickedBox.value = box.value;
            this.reconvertIfReconvertOnChange();
        },
        finishAddBoxDialog(location: any) {
            axios({
              method: "post",
              url: `/boq/${this.boqId}/addWord`,
              params: {
                page: location.page,
                x: location.x,
                y: location.y,
                value: location.value,
              },
            }).then((response: any) => {
              if (response.status === 200) {
                if (!this.reconvertIfReconvertOnChange()) {
                  this.reloadOverlay();
                }
              }
            });
        },
        positionClicked(positionIdsToOpen: number[]) {
            this.positionIdsToOpen = positionIdsToOpen;
        },
        selectBox(value: BoxType) {
            if (this.currentBoxType === value) {
              this.currentBoxType = null;
            } else {
              this.currentBoxType = value;
            }
        },
        addManualBoxFromCurrentSelection(newBox: any) {
            if (this.currentBoxType != null) {
              this.addManualBox(newBox, this.currentBoxType);
            }
        },
        addManualBox(newBox: any, boxType: BoxType) {
            const box = new ModifyOverlayBox(newBox.value, boxType, newBox.page, newBox.line, newBox.word);
            const index = this.customBoxData.list.findIndex(
              (e) => e.page === box.page && e.line === box.line && e.word === box.word
            );
            if (index && index >= 0) {
              this.customBoxData.list.splice(index, 1);
              // newBox.type = newBox.currentBoxType;
            } else {
              if (this.boxTypes.find((e) => e.value === boxType)?.clickableForBox) {
                this.customBoxData.list.push(box);
                newBox.type = this.currentBoxType;
              }
            }
            this.reconvertIfReconvertOnChange();
        },
        removeManualBox(index: number) {
            this.customBoxData.list.splice(index, 1);
            this.reconvertIfReconvertOnChange();
        },
        removeExistingBox(position: any) {
            this.addManualBox(
              {
                page: position.page,
                line: position.line,
                word: position.word,
                value: position.positionNumber,
                type: BoxType.NOT_POSITION_NUMBER,
              },
              BoxType.NOT_POSITION_NUMBER
            );
            this.reconvertIfReconvertOnChange();
        },
        scrollToBox(box: any) {
            this.scrollToPositionNumber = box;
        },
        getCustomBoxData() {
            // return this.customBoxData.data.entries();
            return "-";
        },
        reloadOverlay() {
            console.log("reload overlay!");
            this.reloadTriggered = !this.reloadTriggered;
        },
        reloadPdf() {
            this.reloadPdfTriggered = !this.reloadPdfTriggered;
        },
        initBoq() {
            axios({
              method: `get`,
              url: `/boq/${this.boqId}`,
            }).then((response: any) => {
              if (response.status === 200) {
                this.initialized = true;
                this.definitelyNotTextBased = response.data.definitelyNotTextBased;
                this.forceImageBased = response.data.forceImageBased;
                if (this.isConverting(response.data.status)) {
                  if (!this.timer) {
                    this.startConversionTimer();
                  }
                } else {
                  this.listPositions();
                  if (this.timer) {
                    this.reloadPdf();
                    this.stopConversionTimer();
                  }
                }
              }
            });
        },
        listPositions() {
            this.currentlyFetchingPositions = true;
            axios({
              method: "get",
              url: `/boq/${this.boqId}/listPositions`,
            }).then((response: any) => {
              this.currentlyFetchingPositions = false;
              if (response.status === 200) {
                this.positions = response.data;
              }
            });
        },
        isConverting(status: any) {
            return ["CONVERTING", "QUEUED"].includes(status);
        },
        sendReimport() {
            this.currentlyImporting = true;
            axios({
              method: "post",
              url: `/boq/${this.boqId}/reimport`,
              params: {
                forceImageBased: this.forceImageBased,
              },
            })
              .then((response: any) => {
                if (response.status === 200) {
                  this.startConversionTimer();
                } else {
                  this.currentlyImporting = false;
                }
              })
              .catch(() => {
                this.currentlyImporting = false;
              });
        },
        reconvertIfReconvertOnChange() {
            if (this.reConvertOnChange) {
              this.sendReconvert();
              return true;
            } else return false;
        },
        sendReconvert() {
            this.currentlyConverting = true;
            axios({
              method: "post",
              url: `/boq/${this.boqId}/reconvert`,
              data: this.customBoxData,
            })
              .then((response: any) => {
                if (response.status === 200) {
                  this.reloadOverlay();
                  this.listPositions();
                } else if (response.status === 400) {
                  this.$store.commit("activateAlert", this.$store.state.errorAlert);
                }
              })
              .finally(() => {
                this.currentlyConverting = false;
              });
        },
        sendCreateQuotation() {
            this.currentlyCreatingQuotation = true;
            axios({
              method: "post",
              url: "/quotation/create",
              params: {
                boqId: this.boqId,
              },
            })
              .then((response) => {
                if (response.status === 201) {
                  this.$router.push(`/boq/${this.boqId}`);
                } else {
                  this.$store.commit("activateAlert", this.$store.state.errorAlert);
                }
              })
              .finally(() => {
                this.currentlyCreatingQuotation = false;
              });
        },
        startConversionTimer() {
            this.currentlyImporting = true;
            this.timer = setInterval(() => this.initBoq(), 5000);
        },
        beforeUnmount() {
            this.stopConversionTimer();
        },
        stopConversionTimer() {
            clearInterval(this.timer);
            this.timer = undefined;
            this.currentlyImporting = false;
        },
        onSpecificReloadTriggered() {
            console.log("onSpecificReloadTriggered");
        }
    }
})

</script>

<style lang="scss" scoped>
@use "@/style/styles";
$header-height: 56px;

.boq-modify-container-left {
  height: 300px;
}

.id-overlay-sheet {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.line-through {
  text-decoration: line-through;
}

.row-container {
  > .v-row {
    width: 100%;
  }
}
</style>
