<template>
  <v-container class="customer-selection">
    <v-alert
      v-if="Object.keys({ ...getConfig.filter }).length === 0"
      class="mt-3"
      density="compact"
      variant="text"
      type="info"
    >
      {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.PLANNER_SELECTION.USE_FILTER") }}
    </v-alert>
    <div class="table-container">
      <BoqDataTable
        ref="dataTable"
        :headers="headers"
        :items="plannerList"
        :singleSelect="true"
        :showSelect="true"
        itemKey="kundennummer"
        :loading="loading"
        v-model:get-config="getConfig"
        :debounceTime="0"
        @itemSelected="onItemSelected"
        @enterPressed="loadPlanners"
      >
      </BoqDataTable>
    </div>
    <v-row class="flex-grow-0" justify="start">
      <v-col cols="auto">
        <v-btn
          variant="outlined"
          prepend-icon="mdi-magnify"
          :disabled="loading"
          @click="loadPlanners()"
        >
          {{ $tc('COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.PLANNER_SELECTION.SEARCH_AGAIN') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import BoqDataTable from "@/shared/components/data-table.component.vue";
import { headers as HEADERS } from "./headers/planner-selection-header";
import GetConfig from "@/core/services/base/get.config";
import { PlannerView } from "@/core/models/master-data/Planner.mode";
import { BoqTableHeader } from "@/core/interfaces/boq-table-header.interface";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    BoqDataTable,
  },
    data() {
        const errorAlert: any = {
                type: "error",
                message: this.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.PLANNER_SELECTION.ERROR"),
              };
        const noContentAlert: any = {
                type: "info",
                message: this.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.PLANNER_SELECTION.NO_CONTENT"),
              };
        const headers: BoqTableHeader[] = HEADERS;
        const getConfig: GetConfig = GetConfig.default();

        return {
            getConfig,
            headers,
            noContentAlert,
            errorAlert
        };
    },
    mounted() {
        this.getConfig.filter = {
          kundennummer: this.initialValue,
        };
        (this.$refs.dataTable as InstanceType<typeof BoqDataTable>).initFiltersFromGetConfig(this.getConfig);
        if (this.initialValue) this.loadPlanners();
    },
    unmounted() {
        this.$store.dispatch("masterdata/clearPlannerList");
    },
    methods: {
        loadPlanners() {
            if (this.loading) return;
            this.$store.dispatch("masterdata/loadPlannerList", this.getConfig);
        },
        onItemSelected(item: PlannerView[]) {
            this.$emit("itemSelected", item[0]);
        },
        opportunityListChanged(plannerList: PlannerView[]) {
            if (plannerList.length !== 0) {
              const plannerListItem = plannerList.find((e) => e.kundennummer === this.initialValue);
              if (plannerListItem) {
                (this.$refs.dataTable as InstanceType<typeof BoqDataTable>).selectPreselected(plannerListItem);
                this.onItemSelected([plannerListItem]);
              }
            }
        }
    },
    props: {
        initialValue: { default: "",
            type: String
        }
    },
    watch: {
        "plannerList": [{
            handler: "opportunityListChanged"
        }]
    },
    computed: {
        plannerList(): PlannerView[] {
            return this.$store.getters["masterdata/plannerList"];
        },
        loading(): boolean {
            return this.$store.getters["loading"];
        }
    }
})

</script>

<style lang="scss" scoped>
.customer-selection {
  flex-grow: 1;
  height: 1%;
  display: flex;
  flex-direction: column;
}

.table-container {
  overflow-y: auto;
  flex-grow: 1;
  flex-shrink: 1;
}
</style>
