import Vuex from "vuex";
import mutations from "@/core/store/mutations";
import { actions } from "@/core/store/actions";
import { getters } from "@/core/store/getters";
import { RootState } from "@/core/store/state";
import { masterdataModule } from "@/modules/master-data/store/master-data.module";
import { boqModule } from "@/modules/boq/store/boq.module";
import { quotationModule } from "@/modules/quotation/store/quotation.module";
import { positionModule } from "@/modules/position/store/position.module";
import { settingsModule } from "@/modules/settings/store/settings.module";
import { userModule } from "@/modules/user/store/user.module";
import { authModule } from "@/modules/auth/store/auth.module";
import { articleModule } from "@/modules/article/store/article.module";
import { marketModule } from "@/modules/market/store/market.module";

export default new Vuex.Store({
  state: new RootState(),
  mutations,
  actions,
  getters,
  modules: {
    masterdata: masterdataModule,
    boq: boqModule,
    quotation: quotationModule,
    position: positionModule,
    settings: settingsModule,
    user: userModule,
    auth: authModule,
    article: articleModule,
    market: marketModule,
  },
});
