import { BoqTableHeader } from "@/core/interfaces/boq-table-header.interface";
import { i18nGlobalTc } from "@/i18n";

export const headers: BoqTableHeader[] = [
  {
    value: "kundennummer",
    title: i18nGlobalTc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.SALESAREA_SELECTION.CLIENTID"),
  },
  {
    value: "salesOrganization",
    title: i18nGlobalTc(
      "COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.SALESAREA_SELECTION.SALESORGANISATION"
    ),
  },
  {
    value: "distributionChannel",
    title: i18nGlobalTc(
      "COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.SALESAREA_SELECTION.DISTRIBUTION_CHANNEL"
    ),
  },
  {
    value: "division",
    title: i18nGlobalTc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.SALESAREA_SELECTION.DIVISION"),
  },
];
