import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';
import Home from "@/components/HomeView.vue";
import BoqOverview from "@/components/boq/BoqOverview.vue";
import BoqModify from "@/components/boq/BoqModify.vue";
import QuotationList from "@/components/quotation/QuotationList.vue";
import QuotationDetail from "@/components/quotation/QuotationDetail.vue";
import Articles from "../views/ArticlesView.vue";
import Settings from "@/components/settings/settings-view.component.vue";
import Login from "@/components/login/LoginView.vue";
import UserAdministrationOverview from "@/components/user-administration/UserAdministrationOverview.vue";
import UserAdministrationDetail from "@/components/user-administration/UserAdministrationDetail.vue";
import UserProfile from "@/components/user-administration/UserProfile.vue";
import store from "@/core/store";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/articles",
    name: "Articles",
    component: Articles,
  },
  {
    path: "/boq",
    name: "BoqOverview",
    component: BoqOverview,
  },
  {
    path: "/boq/:boqId",
    name: "QuotationList",
    component: QuotationList,
    props: true,
  },
  {
    path: "/boq/:boqId/modify",
    name: "BoqModify",
    component: BoqModify,
    props: true,
  },
  {
    path: "/boq/:boqId/quotation/:quotationId",
    name: "QuotationDetail",
    component: QuotationDetail,
    props: true,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/user-administration",
    name: "UserAdministrationOverview",
    component: UserAdministrationOverview,
  },
  {
    path: "/user-administration/:userId",
    name: "UserAdministrationDetail",
    component: UserAdministrationDetail,
    props: true,
  },
  {
    path: "/user-profile",
    name: "UserProfile",
    component: UserProfile,
  },
];

const router = createRouter({
  routes: routes,
  history: createWebHistory(),
});

router.beforeEach((to, from, next) => {
  document.title = "BoQ Validator";
  if (store.getters["user/currentUser"] === null) {
    store.dispatch("user/getCurrentUser").then(() => {
      next()
    })
  } 
  else {
    next()
  }
})

export default router;
