<template>
  <div class="full-height d-flex flex-column justify-center">
    <v-container class="login elevation-5 rounded-lg">
      <v-row>
        <v-col>
          <h1>Login mit Viega AD</h1>
        </v-col>
      </v-row>
      <v-divider />
      <v-btn class="dark-btn mt-6" @click="navigateToViegaAD()">
        Weiter
      </v-btn>
    </v-container>

    <v-tooltip location="left">
      <template #activator="{ props }">
        <v-btn
          class="dark-btn local-admin-login-button"
          size="small"
          icon
          v-bind="props"
          @click="dialog = true"
        >
          <v-icon>mdi-login</v-icon>
        </v-btn>
      </template>
      <span>Login als lokaler Admin</span>
    </v-tooltip>
    <v-dialog v-model="dialog" width="400">
      <div class="login elevation-5 rounded-lg">
        <v-row>
          <v-col>
            <h1>Login als lokaler Admin</h1>
          </v-col>
        </v-row>
        <v-divider />

        <v-form ref="loginForm" class="login-form">
          <v-text-field
            v-model="username"
            label="Admin-Benutzername"
            type="text"
            variant="outlined"
            density="compact"
          />
          <v-text-field
            v-model="password"
            label="Passwort"
            type="password"
            variant="outlined"
            density="compact"
          />
          <v-divider />
          <div class="d-flex">
            <v-btn class="dark-btn mt-6" @click="loginAsLocalAdmin()">
              Login
            </v-btn>
          </div>
        </v-form>
      </div>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { User } from "@/core/models/user/User.model";
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
    data() {
        return {
            adminChecked: false,
            dialog: false,
            username: "",
            password: ""
        };
    },
    computed: {
        baseAuthentification64() {
            if (this.username && this.password) {
              return btoa(`${this.username}:${this.password}`);
            }
            return "";
        },
        loginForm() {
            return this.$refs["loginForm"] as HTMLFormElement;
        }
    },
    methods: {
        async loginAsLocalAdmin() {
            try {
                  let canLogin = false;

                  const result = await this.$store.dispatch("auth/loginAsLocalAdmin", {
                    username: this.username,
                    password: this.password,
                  });
                  if (result && result.status == 200 && result.data) {
                    canLogin = result.data;
                  }

                  if (canLogin) {
                    this.$store.dispatch("auth/setToken", this.baseAuthentification64);

                    const admin = User.makeLocalAdmin();
                    this.$store.dispatch("user/setCurrentUser", admin);

                    this.$router.push({ name: "BoqOverview" });
                  } else {
                    this.$store.dispatch("triggerAlert", {
                      type: "error",
                      message: `Username oder Password sind falsch`,
                    });
                  }
                } catch (err) {
                  console.error(err);
                }
        },
        navigateToViegaAD() {
            window.location.href = process.env.VUE_APP_LOGIN_URL_VIEGA_AD as string;
        }
    }
})

</script>

<style lang="scss">
@use "@/style/styles";

.login-form {
  margin-top: 20px;
}

.admin-checkbox {
  padding-top: 0;
  margin-top: 0;
}

.login {
  width: 400px;
  background: #fff;
  padding: 10px 30px 30px 30px;

  .loginButtons {
    margin-top: 25px;
  }
}

.local-admin-login-button {
  position: absolute;
  right: 20px;
  bottom: 20px;
}
</style>
