import QuotationDetail, { ActionLogDto } from "@/core/models/quotation/quotation-detail.model";
import Quotation from "@/core/models/quotation/quotation.model";

export default class QuotationState {
  quotationList: Quotation[] = [];
  quotationDetail: QuotationDetail | null = null;
  actionLog: ActionLogDto | null = null;
  validationValue: number | null = 1;
}

export const state = new QuotationState();
