<template>
  <v-dialog v-model="dialog" width="400px">
    <template #activator="{ props }">
      <v-btn v-bind="props" class="dark-btn">
        Export
      </v-btn>
    </template>

    <v-form ref="myForm" @submit="submit($event)">
      <v-card v-bind="{ loading: submitted, disabled: submitted }" class="text-center">
        <div v-if="QuotationExported">
          <v-card-title>
            {{ $tc("COMPONENTS.EXPORTQUOTATIONDIALOG.SUCCESS.EXPORTED_QUOTATION") }}
          </v-card-title>
          <v-card-text>
            {{ "Export-ID: " + exportId }}
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn variant="outlined" @click="dialog = false">
              {{
                $tc("COMPONENTS.EXPORTQUOTATIONDIALOG.CLOSE")
              }}
            </v-btn>
          </v-card-actions>
        </div>
        <div v-else>
          <v-card-title>
            {{
              exportId
                ? $tc("COMPONENTS.EXPORTQUOTATIONDIALOG.ERROR.ALREADY_EXPORTED")
                : $tc("COMPONENTS.EXPORTQUOTATIONDIALOG.WANT_TO_EXPORT")
            }}
          </v-card-title>

          <v-card-subtitle>
            <v-icon size="100" color="error">
              mdi-help-circle
            </v-icon>
          </v-card-subtitle>

          <v-card-text>
            <v-row>
              <v-col>
                {{
                  exportId
                    ? "Export-ID: " + exportId
                    : $tc("COMPONENTS.EXPORTQUOTATIONDIALOG.ERROR.ONLY_ONCE")
                }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                {{ $tc("COMPONENTS.EXPORTQUOTATIONDIALOG.PROCESS_QUOTATION_DOCUMENT") }}
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn variant="outlined" @click="dialog = false">
              {{
                exportId
                  ? $tc("COMPONENTS.EXPORTQUOTATIONDIALOG.CLOSE")
                  : $tc("COMPONENTS.EXPORTQUOTATIONDIALOG.CANCEL")
              }}
            </v-btn>
            <v-btn v-if="!exportId" class="dark-btn" elevation="0" type="submit">
              Exportieren
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        boqId: {
            type: Number
        },
        quotationId: {
            type: Number
        },
        exportId: {
            type: Number
        }
    },
    data() {
        return {
            dialog: false,
            submitted: false,
            QuotationExported: false,
            localExportId: this.exportId,
            errorAlert: {
                    type: "error",
                    message: this.$tc("COMPONENTS.EXPORTQUOTATIONDIALOG.ERROR.ANY"),
                  },
            successAlert: {
                    type: "success",
                    message: this.$tc("COMPONENTS.EXPORTQUOTATIONDIALOG.SUCCESS.EXPORTED_QUOTATION"),
                  },
            alreadyExportedAlert: {
                    type: "error",
                    message: this.$tc("COMPONENTS.EXPORTQUOTATIONDIALOG.ERROR.ALREADY_EXPORTED"),
                  },
            clientMissingAlert: {
                    type: "error",
                    message: this.$tc("COMPONENTS.EXPORTQUOTATIONDIALOG.ERROR.NO_CUSTOMER"),
                  },
            validationErrorsAlert: {
                    type: "error",
                    message: this.$tc("COMPONENTS.EXPORTQUOTATIONDIALOG.ERROR.POSITION"),
                  },
            userMissingAlert: {
                    type: "error",
                    message: this.$tc("COMPONENTS.EXPORTQUOTATIONDIALOG.ERROR.NO_SAPUSER"),
                  }
        };
    },
    watch: {
        "exportId": [{
            handler: "exportIdChanged",
            immediate: true,
        }]
    },
    methods: {
        submit(event: any) {
            event.preventDefault();
            this.submitted = true;
            axios
              .post(`/quotation/${this.quotationId}/export`)
              .then((response) => {
                if (response.status === 200) {
                  switch (response.data.status) {
                    case "SUCCESS":
                      this.downloadExportFile(response);
                      this.$store.commit("activateAlert", this.successAlert);
                      this.QuotationExported = true;
                      this.localExportId = response.data.quotationExportResponseDto.EV_QUOTE_ID;
                      this.clearDialog();
                      this.$emit("exported");
                      break;
                    case "ALREADY_EXPORTED":
                      this.$store.commit("activateAlert", this.alreadyExportedAlert);
                      this.submitted = false;
                      break;
                    case "CLIENT_MISSING":
                      this.$store.commit("activateAlert", this.clientMissingAlert);
                      this.submitted = false;
                      break;
                    case "VALIDATION_ERRORS":
                      this.$store.commit("activateAlert", this.validationErrorsAlert);
                      this.submitted = false;
                      break;
                    case "USER_MISSING":
                      this.$store.commit("activateAlert", this.userMissingAlert);
                      this.submitted = false;
                      break;
                  }
                } else {
                  this.handleError();
                }
              })
              .catch(() => {
                this.handleError();
              });
        },
        handleError() {
            this.$store.commit("activateAlert", this.errorAlert);
            this.submitted = false;
        },
        clearDialog() {
            this.submitted = false;
        },
        downloadExportFile(response: any) {
            const url = window.URL.createObjectURL(new Blob([JSON.stringify(response.data)]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "export.json"); // or any other extension
            document.body.appendChild(link);
            link.click();
        },
        exportIdChanged(newValue: any) {
            this.localExportId = newValue;
        },
    },
})

</script>

<style lang="scss" scoped>
.v-card-text,
.v-card-title {
  word-break: normal; /* maybe !important  */
}
</style>
