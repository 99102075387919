<template>
  <v-container class="pa-0 mr-2 mb-4">
    <v-responsive class="overflow-y-auto fill-height pa-0 ma-0">
      <v-card class="main-card" variant="flat">
        <v-card-title> Opportunity </v-card-title>
        <v-card-text>
          <v-container fluid class="px-0">
            <v-row>
              <v-col>
                <div class="d-flex">
                  <div class="card__title">
                    ID
                  </div>
                  <span>
                    <SelectionDialog
                      :show-stepper="false"
                      step-one-title="Opportunity"
                      :one-stepper="true"
                      @item-one-selected="onOpportunitySelected"
                      @submit="submitOpportunity"
                    >
                      <template #activator="{ props }">
                        <div v-bind="props">
                          <v-icon class="px-1" @click="{}">mdi-card-search</v-icon>
                        </div>
                      </template>

                      <template #stepOne="{ onItemSelected }">
                        <OpportunitySelection
                          :initial-value="opportunity ? opportunity.number : undefined"
                          @item-selected="onItemSelected"
                        />
                      </template>
                    </SelectionDialog>
                  </span>
                </div>
                <div>
                  {{ opportunity?.number ?? "-" }}
                </div>
              </v-col>
              <v-col>
                <div class="card__title">
                  {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.OPPORTUNITY.DESCRIPTION") }}
                </div>
                {{ opportunity?.name ?? "-" }}
              </v-col>
              <v-col>
                <div class="card__title">
                  {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.OPPORTUNITY.ADDRESS") }}
                </div>
                <div v-if="opportunity && opportunity.street">
                  <div>{{ opportunity.street }}</div>
                  <div>{{ opportunity.zip }} {{ opportunity.city }}</div>
                </div>
                <div v-else>
                  -
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-responsive>
  </v-container>
</template>

<script lang="ts">
import SelectionDialog from "@/components/master-data/customer-selection/SelectionDialog.vue";
import OpportunitySelection from "@/components/master-data/customer-selection/OpportunitySelection.vue";
import { OpportunityView } from "@/core/models/master-data/Opportunity.model";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  components: {
    SelectionDialog,
    OpportunitySelection,
  },
    props: {
        opportunity: {
            type: Object as PropType<OpportunityView>
        }
    },
    data() {
        const selectedOpportunity: OpportunityView = new OpportunityView();

        return {
            selectedOpportunity
        };
    },
    methods: {
        onOpportunitySelected(item: OpportunityView) {
            this.selectedOpportunity = item || new OpportunityView();
        },
        formatDate(): string {
            // format of date property: "(d)d.(m)m.yyyy" --> Needs to be "yyyy.mm.dd"
            // eslint-disable-next-line prefer-const
            let [day, month, year] = this.selectedOpportunity.date.split(".");
            if (day.length === 1) {
              day = "0" + day;
            }
            if (month.length === 1) {
              month = "0" + month;
            }
            return year + month + day;
        },
        submitOpportunity() {
            this.$store.dispatch("masterdata/setOpportunity", this.selectedOpportunity);
            if (this.selectedOpportunity) this.selectedOpportunity.date = this.formatDate();
            this.$emit("submitMasterdata");
        }
    }
})

</script>
<style lang="scss" scoped>
.card__title {
  color: #333;
  font-weight: 600;
  text-transform: uppercase;
}
</style>
