<template>
  <v-container class="mb-4 mr-2 pa-0">
    <v-responsive class="overflow-y-auto fill-height ma-0 pa-0">
      <v-card class="main-card" variant="flat">
        <v-card-title>
          {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CUSTOMER.CUSTOMER") }}
        </v-card-title>
        <v-card-text>
          <v-container fluid class="px-0">
            <v-row>
              <v-col lg="6">
                <div class="d-flex">
                  <span class="card__title">
                    {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CUSTOMER.CLIENT") }}
                  </span>
                  <span>
                    <SelectionDialog
                      :amount-of-steps="amountOfSteps"
                      :step-one-title="
                        $tc('COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.SELECTION_DIALOG.CLIENT')
                      "
                      :step-two-title="getSecondTitle()"
                      @item-one-selected="onClientSelected"
                      @item-three-selected="onContactThirdSelected"
                      @item-two-selected="onContactSelected"
                      @sales-item-selected="onSalesAreaSelected"
                      @submit="submitClient"
                    >
                      <template #activator="{ props }">
                        <div v-bind="props">
                          <v-icon
                            class="px-1"
                            @click="changeStepsAmount(3), getSecondTitle()"
                          >mdi-card-search</v-icon>
                        </div>
                      </template>

                      <template #stepOne="{ onItemSelected, stepper }">
                        <ClientSelection
                          v-if="stepper === 1"
                          :initial-value="client?.kundennummer"
                          @item-selected="onItemSelected"
                        />
                      </template>

                      <!-- sales area -->
                      <template
                        v-if="amountOfSteps === 3"
                        #stepSales="{ onItemSelected, stepper }"
                      >
                        <SalesAreaSelection
                          v-if="stepper === 2"
                          :client-id="selectedClient.kundennummer"
                          @item-selected="onItemSelected"
                        />
                      </template>

                      <template #stepTwo="{ onItemSelected, stepper }">
                        <ContactSelection
                          v-if="stepper === 2"
                          :client="selectedClient"
                          @item-selected="onItemSelected"
                        />
                      </template>

                      <template
                        v-if="amountOfSteps === 3"
                        #stepThree="{ onItemSelected, stepper }"
                      >
                        <ContactSelection
                          v-if="stepper === 3"
                          :client="selectedClient"
                          :sales-area="selectedSalesArea"
                          @item-selected="onItemSelected"
                        />
                      </template>
                    </SelectionDialog>
                  </span>
                </div>
                {{ client?.name ? client.name : "-" }}
              </v-col>
              <v-col lg="6">
                <div class="card__title">
                  {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CUSTOMER.CLIENT_ID") }}
                </div>
                {{ client?.kundennummer ? client.kundennummer : "-" }}
              </v-col>

              <v-col lg="6">
                <div class="d-flex">
                  <span class="card__title">
                    {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CUSTOMER.OFFEREE") }}
                  </span>
                  <span>
                    <SelectionDialog
                      :step-one-title="
                        $tc('COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CUSTOMER.OFFEREE')
                      "
                      :step-two-title="getSecondTitle()"
                      @item-one-selected="onRecipientSelected"
                      @item-two-selected="onContactSelected"
                      @submit="submitRecipient"
                    >
                      <template #activator="{ props }">
                        <div v-bind="props">
                          <v-icon
                            class="px-1"
                            @click="changeStepsAmount(2), getSecondTitle()"
                          >mdi-card-search</v-icon>
                        </div>
                      </template>

                      <template #stepOne="{ onItemSelected, stepper }">
                        <RecipientSelection
                          v-if="stepper === 1"
                          :initial-value="recipient?.kundennummer"
                          @item-selected="onItemSelected"
                        />
                      </template>

                      <template #stepTwo="{ onItemSelected, stepper }">
                        <ContactSelection
                          v-if="stepper === 2"
                          :client="selectedRecipient"
                          @item-selected="onItemSelected"
                        />
                      </template>
                    </SelectionDialog>
                  </span>
                </div>
                {{ recipient?.name ? recipient.name : "-" }}
              </v-col>
              <v-col lg="6">
                <div class="card__title">
                  {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CUSTOMER.OFFEREE_ID") }}
                </div>
                {{ recipient?.kundennummer ? recipient.kundennummer : "-" }}
              </v-col>
              <v-col lg="6">
                <div class="card__title">
                  {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CUSTOMER.CONTACT_PERSON") }}
                </div>
                {{ contact?.businessPartnerFullName ? contact.businessPartnerFullName : "-" }}
              </v-col>
              <v-col lg="6">
                <div class="card__title">
                  {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CUSTOMER.CONTACT_PERSON_ID") }}
                </div>
                {{ contact?.contactId ? contact.contactId : "-" }}
              </v-col>
              <v-col lg="6">
                <div class="card__title">
                  {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CUSTOMER.CONTACT_PERSON_MAIL") }}
                </div>
                {{ contact?.emailAddress ? contact.emailAddress : "-" }}
              </v-col>
              <v-col lg="6">
                <div class="card__title">
                  {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CUSTOMER.CONTACT_PERSON_PHONE") }}
                </div>
                {{ contact?.phoneNumber ? contact.phoneNumber : "-" }}
              </v-col>
              <v-col lg="6">
                <div class="card__title">
                  {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CUSTOMER.CLIENT_ADDRESS") }}
                </div>
                <div v-if="client?.strasse">
                  <div>{{ client.strasse }}</div>
                  <div>{{ client.plz }} {{ client.ort }}</div>
                </div>
                <div v-else>
                  -
                </div>
              </v-col>
              <v-col lg="6">
                <div class="card__title">
                  {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CUSTOMER.OFFEREE_ADDRESS") }}
                </div>
                <div v-if="recipient?.strasse">
                  <div>{{ recipient.strasse }}</div>
                  <div>{{ recipient.plz }} {{ recipient.ort }}</div>
                </div>
                <div v-else>
                  -
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-responsive>
  </v-container>
</template>

<script lang="ts">
import SelectionDialog from "@/components/master-data/customer-selection/SelectionDialog.vue";
import ClientSelection from "@/components/master-data/customer-selection/ClientSelection.vue";
import RecipientSelection from "@/components/master-data/customer-selection/RecipientSelection.vue";
import ContactSelection from "@/components/master-data/customer-selection/ContactSelection.vue";
import SalesAreaSelection from "@/components/master-data/customer-selection/SalesAreaSelection.vue";
import { ClientView } from "@/core/models/master-data/Client.model";
import { RecipientView } from "@/core/models/master-data/Recipient.model";
import { ContactView } from "@/core/models/master-data/Contact.model";
import { SalesView } from "@/core/models/master-data/Sales.model";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  components: {
    SelectionDialog,
    ClientSelection,
    RecipientSelection,
    ContactSelection,
    SalesAreaSelection,
  },
    props: {
        client: {
            type: Object as PropType<ClientView>
        },
        recipient: {
            type: Object as PropType<RecipientView>
        },
        contact: {
            type: Object as PropType<ContactView>
        },
        salesArea: {
            type: Object as PropType<SalesView>
        }
    },
    data() {
        const selectedSalesArea: SalesView = new SalesView();
        const selectedContact: any = new ContactView();
        const selectedRecipient: RecipientView = new RecipientView();
        const selectedClient: ClientView = new ClientView();

        return {
            selectedClient,
            selectedRecipient,
            selectedContact,
            selectedSalesArea,
            amountOfSteps: 2
        };
    },
    methods: {
        getSecondTitle() {
            return this.amountOfSteps == 2
            ? this.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.SELECTION_DIALOG.CONTACT_PERSON")
            : this.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.SELECTION_DIALOG.SALES_AREA");
        },
        changeStepsAmount(value: number) {
            this.amountOfSteps = value;
        },
        onClientSelected(item: ClientView | undefined) {
            this.selectedClient = item || new ClientView();
        },
        onRecipientSelected(item: RecipientView | undefined) {
            this.selectedRecipient = item || new RecipientView();
        },
        onContactSelected(item: ContactView | undefined) {
            this.selectedContact = item || new ContactView();
        },
        onContactThirdSelected(item: ContactView | undefined) {
            this.selectedContact = item || new ContactView();
        },
        onSalesAreaSelected(item: SalesView | undefined) {
            this.selectedSalesArea = item || new SalesView();
        },
        cleanSelectedContactData() {
            if (this.selectedContact && this.selectedContact.businessPartner) {
              const contactId = this.selectedContact.businessPartner;
              delete this.selectedContact.businessPartner;
              this.selectedContact.contactId = contactId;
            }
        },
        submitClient() {
            //Any Client that is chosen is also set as Recipient
                //TODO Refactoring
                this.cleanSelectedContactData();

                this.$store.dispatch("masterdata/setClient", this.selectedClient);
                this.$store.dispatch("masterdata/setRecipient", this.selectedClient);
                this.$store.dispatch("masterdata/setContact", this.selectedContact);
                if (this.selectedSalesArea) {
                  this.$store.dispatch("masterdata/setSalesArea", this.selectedSalesArea);
                }

                this.$emit("submitMasterdata");
        },
        submitRecipient() {
            this.cleanSelectedContactData();

                this.$store.dispatch("masterdata/setRecipient", this.selectedRecipient);
                this.$store.dispatch("masterdata/setContact", this.selectedContact);

                this.$emit("submitMasterdata");
        }
    }
})

</script>
<style lang="scss" scoped>
.card__title {
  color: #333;
  font-weight: 600;
  text-transform: uppercase;
}
</style>
