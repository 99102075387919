<template>
  <v-container class="scrollable full-height">
    <h1>{{ $tc("COMPONENTS.HISTORY.CURRENT_VERSION") }}</h1>
    <p>{{ boqVersion }}</p>
    <h1>{{ $tc("COMPONENTS.HISTORY.VERSIONHISTORY") }}</h1>
    <v-row v-if="fetchFailed">
      <v-col cols="auto">
        <v-alert type="error" density="compact">
          {{ $tc("COMPONENTS.HISTORY.LOADING_ERROR") }}
        </v-alert>
      </v-col>
    </v-row>
    <p v-if="fetchFailed" />
    <div v-for="nestedMajor in nestedTags" :key="nestedMajor.value">
      <!-- <h1>Release {{ nestedMajor.value }}</h1> -->
      <v-card
        v-for="nestedMinor in nestedMajor.minors"
        :key="nestedMinor.value"
        class="my-6"
        elevation="10"
      >
        <v-card-title>
          <h2>
            {{ $tc("COMPONENTS.HISTORY.RELEASE") }} {{ nestedMajor.value }}.{{ nestedMinor.value }}
          </h2>
        </v-card-title>
        <v-card-text>
          <v-row v-for="nestedPatch in nestedMinor.patches" :key="nestedPatch.value">
            <v-col cols="1">
              <h3>
                {{ nestedMajor.value }}.{{ nestedMinor.value }}.{{ nestedPatch.value }}
                <v-icon
                  class="mb-1"
                  align-self="center"
                  size="small"
                  @click="openInformationDialog(nestedPatch)"
                >
                  mdi-information
                </v-icon>
              </h3>
            </v-col>
            <v-col v-if="nestedPatch.data.release != null">
              <!-- {{ nestedPatch.data }} -->
              <ul
                v-for="block in nestedPatch.data.release.description.split('\r\n\r\n')"
                :key="block"
              >
                <li v-for="line in block.split('\n')" :key="line">
                  {{ line }}
                </li>
              </ul>
            </v-col>
            <v-col v-else>
              <ul>
                <li class="font-italic">
                  {{ $tc("COMPONENTS.HISTORY.NO_DESCRIPTION") }}
                </li>
              </ul>
            </v-col>
            <v-col cols="auto">
              {{ new Date(nestedPatch.data.commit.created_at).toLocaleDateString("de-DE") }}
            </v-col>
          </v-row>
          <!-- <div v-for="nestedPatch in nestedMinor.patches" :key="nestedPatch.value">
              {{ nestedMajor.value }}.{{ nestedMinor.value }}.{{ nestedPatch.value }}
              <ul
                v-for="block in nestedPatch.data.release.description.split('\r\n\r\n')"
                :key="block"
                class="mb-5"
              >
                <li v-for="line in block.split('\n')" :key="line">
                  {{ line }}
                </li>
              </ul>
              <v-icon @click="openInformationDialog(nestedPatch)">mdi-information</v-icon>
            </div> -->
        </v-card-text>
      </v-card>
    </div>
    <!-- <v-card v-for="tag in tags" :key="tag.name" class="my-5">
      <v-card-title> Version {{ tag.name }} </v-card-title>
      <v-card-subtitle>
        {{ new Date(tag.commit.created_at).toLocaleString("de-DE") }}
      </v-card-subtitle>
      <v-card-text>
        <h4>Titel</h4>
        <p>{{ tag.message }}</p>
        <h4>Autor</h4>
        <p>{{ tag.commit.author_name }} ({{ tag.commit.author_email }})</p>
        <div v-if="tag.release !== null">
          <h4>Änderungen</h4>
          <ul v-for="block in tag.release.description.split('\r\n\r\n')" :key="block" class="mb-5">
            <li v-for="line in block.split('\n')" :key="line">
              {{ line }}
            </li>
          </ul>
        </div>
      </v-card-text>
    </v-card> -->
    <v-btn v-if="!fetchFailed" v-bind="{ disabled: reachedEnd }" @click="loadMore">
      <div v-if="!reachedEnd">
        {{ $tc("COMPONENTS.HISTORY.LOAD_MORE") }}
      </div>
      <div v-else>
        {{ $tc("COMPONENTS.HISTORY.NO_FURTHER_RELEASES") }}
      </div>
    </v-btn>
    <v-dialog v-if="showTagInfo" v-model="showTagInfo" max-width="1000px">
      <v-card>
        <v-card-title> {{ $tc("COMPONENTS.HISTORY.VERSION") }} {{ currentTag?.name }} </v-card-title>
        <v-card-subtitle>
          {{ new Date(currentTag?.commit.created_at).toLocaleString("de-DE") }}
        </v-card-subtitle>
        <v-card-text>
          <h4>{{ $tc("COMPONENTS.HISTORY.TITLE") }}</h4>
          <p>{{ currentTag?.message }}</p>
          <h4>{{ $tc("COMPONENTS.HISTORY.AUTHOR") }}</h4>
          <p>{{ currentTag?.commit.author_name }} ({{ currentTag?.commit.author_email }})</p>
          <div v-if="currentTag?.release !== null">
            <h4>{{ $tc("COMPONENTS.HISTORY.CHANGES") }}</h4>
            <ul
              v-for="block in currentTag?.release.description.split('\r\n\r\n')"
              :key="block"
              class="mb-5"
            >
              <li v-for="line in block.split('\n')" :key="line">
                {{ line }}
              </li>
            </ul>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
    data() {
        const tags: string[] = [];

        return {
            tags,
            nestedTags: [] as any[],
            boqVersion: "0.0.0",
            page: 1,
            reachedEnd: false,
            fetchFailed: false,
            tagRegex: /(\d+)\.(\d+)\.(\d+.*)/,
            showTagInfo: false,
            currentTag: null as (any | null)
        };
    },
    mounted() {
        this.loadHistory();
        this.boqVersion = process.env.VUE_APP_BOQ_VERSION as string;
    },
    methods: {
        loadHistory() {
            // todo: anfrage übers backend schicken, damit der token nicht auslesbar ist
            // create new instance of axios to avoid using existing error handler
            axios
              .create()({
                method: "get",
                url: "https://gitlab.explicatis.com/api/v4/projects/235/repository/tags",
                headers: {
                  // "PRIVATE-TOKEN": "11dMCGmEh16NLWmD8kBP",
                  "PRIVATE-TOKEN": "XZuCmtxrkU5u8QREyFpL",
                },
                params: {
                  page: this.page,
                },
              })
              .then((response) => {
                if (response.data.length === 0) {
                  this.reachedEnd = true;
                } else {
                  this.tags = this.tags.concat(response.data);
                  this.initMinorTags(response.data);
                }
              })
              .catch(() => {
                this.fetchFailed = true;
                this.reachedEnd = true;
              });
        },
        initMinorTags(tags: any[]) {
            for (const tag of tags) {
              const result = this.tagRegex.exec(tag.name);
              const major = result?.[1];
              const minor = result?.[2];
              const patch = result?.[3];
              let nestedMajor = this.nestedTags.find((e) => e.value === major);
              if (!nestedMajor) {
                nestedMajor = { value: major, minors: [] };
                this.nestedTags.push(nestedMajor);
              }
              let nestedMinor = nestedMajor.minors.find((e: any) => e.value === minor);
              if (!nestedMinor) {
                nestedMinor = { value: minor, patches: [] };
                nestedMajor.minors.push(nestedMinor);
              }
              const nestedPatch = { value: patch, data: tag };
              nestedMinor.patches.push(nestedPatch);
            }
        },
        openInformationDialog(patch: any) {
            this.currentTag = patch.data;
            this.showTagInfo = true;
        },
        loadMore() {
            this.page++;
            this.loadHistory();
        }
    }
})

</script>
