import { ActionTree } from "vuex";
import * as UserService from "@/core/services/user/user.service";
import { RootState } from "@/core/store/state";
import UserState from "./state";
import { User } from "@/core/models/user/User.model";

export const actions: ActionTree<UserState, RootState> = {
  loadUserList(context) {
    UserService.loadUserList().then((response) => {
      context.commit("setUserList", response);
    });
  },

  async loadUserDetail(context, userId: number) {
    const response = await UserService.loadUserDetail(userId);
    context.commit("setUserDetail", response);
  },

  loadUserNew(context) {
    context.commit("setUserDetail", new User());
  },

  clearUserList(context) {
    context.commit("setUserList", Array.of(new User()));
  },

  updateUser(context, { userId, user }) {
    return UserService.updateUser(userId, user);
  },

  updateUserProfile(context, { userId, sapNumber }) {
    return UserService.updateUserProfile(userId, sapNumber);
  },

  createUser(context, { user }) {
    return UserService.createUser(user);
  },

  setUserChanged(context, userChanged: boolean) {
    context.commit("setUserChanged", userChanged);
  },

  async getCurrentUser(context) {
    return await UserService.getCurrentUser().then((user) => {
      //The user object is used to determine whether somebody is logged in or out. When logged out, the getCurrentUser route returns an object with null values
      //The currentUser object must only be set, when somebody is logged in.
      if (user && user.role) {
        context.commit("setCurrentUser", user);
      }
    });
  },

  setCurrentUser(context, user: User) {
    context.commit("setCurrentUser", user);
  },

  deleteUser(context, userId: string) {
    return UserService.deleteUser(userId);
  },
};
