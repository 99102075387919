import axios, { AxiosResponse, AxiosRequestConfig } from "axios";

export function signUp(credentials: any) {
  return axios
    .post("/sign-up", credentials)
    .then((response) => {
      response.data;
    })
    .catch((err) => {
      console.error(err);
    });
}

export function loginAsLocalAdmin(credentials: {
  username: string;
  password: string;
}): Promise<AxiosResponse<any>> {
  const config: AxiosRequestConfig = {};
  config.params = {
    username: credentials.username,
    password: credentials.password,
  };

  return axios.get("user/can-login", config);
}
