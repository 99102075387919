
export function getPositionValidationErrorColor(validationError: string) {
  switch (validationError) {
    case "HIERARCHY":
      return "error";
    case "NO_UNIT":
      return "error";
    case "NO_QUANTITY":
      return "error";
    case "NO_ID":
      return "error";
    case "NO_ARTICLE_REFERENCE":
      return "error";
    case "NO_GROUP_REFERENCE":
      return "error";
    default:
      return "warning";
  }
}
