import PositionState from './state';

export default {
  setPositions: (
    state: PositionState,
    value: any
  ) => {
    state.positions = value;
  },
  setPositionChildren: (
    state: PositionState,
    value: any
  ) => {
    state.positionChildren = value;
  }
};
