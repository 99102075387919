import { BoqTableHeader } from "@/core/interfaces/boq-table-header.interface";
// import { BoqTableHeader } from "@/shared/components/data-table.component.vue";
import { i18nGlobalTc } from "@/i18n";

export function getBoqHeaders(): BoqTableHeader[] {
  return [
    {
      title: i18nGlobalTc("BOQ.HEADER.LV_NUMBER"),
      value: "id",
      filterable: false,
      // width: 30,
    },
    {
      key: 'quotations',
      title: i18nGlobalTc("BOQ.HEADER.QUOTATIONS"),
      value: "quotations",
      filterable: false,
      width: 90,
      sortable: false
    },
    {
      title: i18nGlobalTc("BOQ.HEADER.COUNTRY"),
      value: "marketId",
      filterable: true,
      type: "select",
      // width: 120,
    },
    {
      title: i18nGlobalTc("BOQ.HEADER.SALES_TEAM"),
      value: "team",
      filterable: true,
      // width: 180,
    },
    {
      title: i18nGlobalTc("BOQ.HEADER.RECEIPTDATE"),
      value: "date",
      filterable: true,
      type: "date-range",
      // width: 125,
    },
    {
      title: i18nGlobalTc("BOQ.HEADER.FILENAME"),
      value: "pdfFileName",
      filterable: true,
      // width: 200,
    },
    {
      title: i18nGlobalTc("BOQ.HEADER.COMMENTS"),
      value: "comment",
      filterable: true,
      // width: 300,
    },
    {
      title: i18nGlobalTc("BOQ.HEADER.AUTHOR"),
      value: "lastEditor",
      filterable: true,
      // width: 150,
    },
    {
      title: "Status",
      value: "status",
      filterable: true,
      // width: 120,
    },
    {
      title: "Version",
      value: "version",
    },
    {
      title: i18nGlobalTc("BOQ.HEADER.ACTIONS"),
      value: "actions",
      // width: 50,
    },
  ];
}
