import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_ActionButton = _resolveComponent("ActionButton")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { class: "mb-1" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createElementVNode("h1", null, _toDisplayString(_ctx.$tc("COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.USER_ADMIN")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            "align-self": "end",
            align: "right"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                onClick: _ctx.createNewUser,
                class: "dark-btn"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, { start: "" }, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-plus")
                    ]),
                    _: 1
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("COMPONENTS.USER_ADMIN.MAPPINGS.USER_ADMIN_OVERVIEW.CREATE_USER")), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_container, { class: "pa-0 elevation-5 rounded-lg" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_data_table, {
            class: "user-table",
            headers: _ctx.headers,
            items: _ctx.userList,
            "onClick:row": _ctx.showUserDetails
          }, {
            [`item.role`]: _withCtx(({ item }) => [
              _createTextVNode(_toDisplayString(_ctx.getRoleText(item.role)), 1)
            ]),
            [`item.marketId`]: _withCtx(({ item }) => [
              _createTextVNode(_toDisplayString(_ctx.getCountryName(item.marketId, _ctx.marketList)), 1)
            ]),
            [`item.actions`]: _withCtx(({ item }) => [
              _createVNode(_component_ActionButton, {
                tooltip: "Löschen",
                icon: "mdi-delete",
                onButtonClicked: ($event: any) => (_ctx.deleteUser(item))
              }, null, 8, ["onButtonClicked"])
            ]),
            _: 2
          }, 1032, ["headers", "items", "onClick:row"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}