<template>
  <v-container class="full-height" fluid>
    <v-row class="mb-1">
      <v-col>
        <h1>{{ $t("BOQ.BOQOVERVIEW.BOQ_OVERVIEW") }}</h1>
      </v-col>
      <v-col align-self="center">
        <AddBoqDialog @success="boqCreatedSuccessfully" />
      </v-col>
    </v-row>
    <v-container class="pa-0 table-container elevation-5 rounded-lg" fluid>
      <BoqDataTable
        ref="dataTable"
        v-model:get-config="getConfig"
        :headers="headers"
        :items="boqList"
        :loading="loading"
        @click:row="clickRow"
      >
        <!-- tooltip for quotations -->
        <!--template #[`item.quotations`]="{ item }">
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <div v-bind="props">
                xyz<{{ item.quotations }}>
              </div>
            </template>
            {{ item.exportedQuotationsCount }} {{ $tc("BOQ.BOQOVERVIEW.EXPORT.1") }}
            {{ item.quotationsCount }} {{ $tc("BOQ.BOQOVERVIEW.EXPORT.2") }}
          </v-tooltip>
        </template-->

        <!-- convert milliseconds to date -->
        <template #[`item.date`]="{ item }">
          {{ transformToDateString(item.date) }}
        </template>

        <template #[`item.marketId`]="{ item }">
          {{ getCountryName(item.marketId, marketList) }}
        </template>

        <template #[`item.status`]="{ item }">
          <div
            :class="{
              'status-cancelled': ['Abgebrochen', 'Fehlgeschlagen'].includes(item.status),
              'boq-disabled': ['Konvertierung', 'Wartet', 'Abgebrochen'].includes(item.status),
            }"
          >
            {{ item.status }}
          </div>
        </template>

        <template #[`item.actions`]="{ item }">
          <!-- Abbrechen -->
          <ActionButton
            v-if="['Wartet'].includes(item.status)"
            :tooltip="$tc('BOQ.BOQOVERVIEW.CANCEL')"
            icon="mdi-close-circle-outline"
            icon-color="red"
            @button-clicked="cancelConversion(item)"
          />
          <!-- Neustarten -->
          <ActionButton
            v-if="['Verfügbar', 'Abgebrochen', 'Fehlgeschlagen'].includes(item.status)"
            :tooltip="$tc('BOQ.BOQOVERVIEW.RESTART')"
            icon="mdi-restart"
            icon-color="green"
            @button-clicked="restartConversion(item)"
          />
        </template>
      </BoqDataTable>
    </v-container>
  </v-container>
</template>

<script lang="ts">
import axios from "axios";
import AddBoqDialog from "@/components/boq/AddBoqDialog.vue";
import BoqDataTable from "@/shared/components/data-table.component.vue";
import ActionButton from "@/shared/components/action-button.component.vue";
import { Boq } from "@/core/models/boq/Boq.model";
import { formatDate } from "@/core/helper/date-time";
import { getBoqHeaders } from "./headers";
import { BoqTableHeader } from "@/core/interfaces/boq-table-header.interface";
import GetConfig from "@/core/services/base/get.config";
import { User } from "@/core/models/user/User.model";
import { Market } from "@/core/models/market/market.model";
import * as MarketService from "@/core/services/market/market.service";
import { defineComponent } from "vue";
import type { VDataTableRowClickData } from '@/core/models/vuetify/vuetify.model';

export default defineComponent({
  components: {
    AddBoqDialog,
    BoqDataTable,
    ActionButton,
  },
    data() {
        const dateMenu: boolean[] = [];
        const filterValues: (string | number | boolean | null | undefined)[] = [];
        const headers: BoqTableHeader[] = getBoqHeaders();
        const timer: any = undefined;
        const getConfig: GetConfig = GetConfig.default();

        return {
            getConfig,
            timer,
            showBoqCreationAlert: false,
            headers,
            filterValues,
            dateMenu
        };
    },
    computed: {
        filteredBoqList() {
            return this.boqList;
        },
        boqList(): Boq[] {
            return this.$store.getters["boq/boq"];
        },
        loading(): boolean {
            return this.$store.getters["loading"];
        },
        currentUser(): User {
            return this.$store.getters["user/currentUser"];
        },
        marketList(): Market[] {
            return this.$store.getters["market/market"];
        }
    },
    watch: {
        "getConfig": [{
            handler: "getConfigChanged"
        }],
        "boqList": [{
            handler: "boqListChanges"
        }]
    },
    mounted() {
        if (this.currentUser && this.currentUser.role != "ROLE_ADMIN") {
            // Country/market filter
            this.headers[3].filterable = false;
        }

        if (this.currentUser && this.currentUser.marketId != -1) {
            this.listMarkets();
            this.getConfig.filter = {
                marketId: this.currentUser.marketId.toString(),
                team: this.currentUser.teamName,
            };
        }

        (this.$refs.dataTable as InstanceType<typeof BoqDataTable>).initFiltersFromGetConfig(this.getConfig);
        this.startTimer();
    },
    methods: {
        listMarkets() {
            this.$store.dispatch("market/loadMarketList", this.getConfig);
        },
        getCountryName(marketId: number, marketList: Market[]) {
            return MarketService.getCountryName(marketId, marketList);
        },
        beforeUnmount() {
            this.stopTimer();
        },
        startTimer() {
            //this.timer = setInterval(() => this.listBoqs(), 5000);
        },
        stopTimer() {
            clearInterval(this.timer);
        },
        listBoqs() {
            this.$store.dispatch("boq/loadBoqList", this.getConfig);
        },
        clickRow(clickEvent: MouseEvent, data: VDataTableRowClickData<Boq>) {
            if (data.item.status === "Verfügbar" || "Available") {
              this.$router.push({ path: `/boq/${data.item.id}`, query: { comment: data.item.comment } });
              return true;
            }
            return false;
        },
        boqCreatedSuccessfully() {
            this.startTimer();
            this.listBoqs();
        },
        cancelConversion(item: Boq) {
            console.log(`cancel: ${item.id}`);
            axios.post(`/boq/${item.id}/cancelConversion`).then(() => {
              // todo: boq in daten aktualisieren, nicht jedes mal neu laden
              this.listBoqs();
            });
        },
        restartConversion(item: Boq) {
            this.$router.push(`/boq/${item.id}/modify`);
        },
        filterColumn(index: number) {
            console.log(this.filterValues[index]);
        },
        transformToDateString(filterValue: string | Date): string {
            if (filterValue) {
              return formatDate(filterValue);
            }
            return filterValue;
        },
        getConfigChanged() {
            this.listBoqs();
        },
        boqListChanges() {
            if (!this.boqList.find((e) => ["Konvertierung", "Wartet"].includes(e.status))) {
              this.stopTimer();
            }
        }
    }
})

</script>

<style lang="scss" scoped>
@use "@/style/styles";
.table-container {
  height: styles.$table-container-height;
}

.boq-disabled {
  color: styles.$secondary;
}

.status-cancelled {
  color: red;
}
</style>
