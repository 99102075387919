import LoginState from "./state";

export default {
  setToken: (state: LoginState, token: string) => {
    state.token = token;
  },
  setUser(state: LoginState, user: object) {
    state.user = user;
  },
  reset: (state: LoginState) => {
    Object.assign(state, new LoginState());
  }
};
