import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_ArticleOverview = _resolveComponent("ArticleOverview")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, {
    class: "full-height",
    fluid: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createElementVNode("h1", null, _toDisplayString(_ctx.$tc("VIEWS.ARTICLES.ARTICLES")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_container, {
        class: "pa-0 table-container elevation-5 rounded-lg",
        fluid: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ArticleOverview, {
            onScroll: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleScroll()))
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}