import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10be135e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fill-height" }
const _hoisted_2 = { class: "article-table" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "text-no-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_ArticleDetailDialog = _resolveComponent("ArticleDetailDialog")!
  const _component_BoqDataTable = _resolveComponent("BoqDataTable")!
  const _directive_intersect = _resolveDirective("intersect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_row, { dense: "" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          "align-self": "center",
          cols: "auto"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, { class: "pl-5" }, {
              default: _withCtx(() => [
                _createTextVNode("mdi-magnify")
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_textarea, {
              modelValue: _ctx.search,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                _ctx.specialSearch
              ],
              label: _ctx.$tc('COMPONENTS.ARTICLE.ARTICLE_OVERVIEW.INTELLIGENT_SEARCH'),
              class: "search",
              rows: "3",
              variant: "outlined"
            }, null, 8, ["modelValue", "label", "onUpdate:modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BoqDataTable, {
        ref: "dataTable",
        items: _ctx.articles,
        disablePaginate: true,
        filterable: true,
        "get-config": _ctx.getConfig,
        "onUpdate:getConfig": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.getConfig) = $event)),
        headers: _ctx.headers,
        loading: _ctx.articlesLoading,
        preselected: _ctx.preselected,
        showSelect: true,
        singleSelect: true,
        itemKey: "artikelnummer",
        onItemSelected: _ctx.onSelectedChange
      }, {
        [`item.artikelnummer`]: _withCtx(({ item }) => [
          _createVNode(_component_ArticleDetailDialog, { article: item }, {
            activator: _withCtx(({ props, article }) => [
              _createElementVNode("div", _normalizeProps(_guardReactiveProps(props)), [
                _createTextVNode(_toDisplayString(article.artikelnummer) + " ", 1),
                _createVNode(_component_v_icon, {
                  color: "primary",
                  size: "medium"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("mdi-information")
                  ]),
                  _: 1
                })
              ], 16)
            ]),
            _: 2
          }, 1032, ["article"]),
          (_ctx.articles.length > 0 && item.id === _ctx.articles[_ctx.articles.length - 1].id)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, null, 512)), [
                [_directive_intersect, _ctx.scrollArticleList]
              ])
            : _createCommentVNode("", true)
        ]),
        [`item.displayScore`]: _withCtx(({ item }) => [
          _createElementVNode("p", _hoisted_4, _toDisplayString(item.displayScore), 1)
        ]),
        _: 2
      }, 1032, ["items", "get-config", "headers", "loading", "preselected", "onItemSelected"])
    ])
  ]))
}