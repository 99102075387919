<template>
  <v-container class="full-height" fluid>
    <v-row>
      <v-col>
        <h1>{{ $tc("VIEWS.ARTICLES.ARTICLES") }}</h1>
      </v-col>
    </v-row>
    <v-container class="pa-0 table-container elevation-5 rounded-lg" fluid>
      <ArticleOverview  v-on:scroll.native="handleScroll()"/>
    </v-container>
  </v-container>
</template>

<script lang="ts">
import ArticleOverview from "@/components/article/ArticleOverview.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    ArticleOverview,
  },
    methods: {
        handleScroll() {
            debugger
            const test = document.getElementsByClassName("v-data-table__wrapper")[0]
            if(test){
              // const testParent = test.parentElement
            }
        }
    }
})

</script>

<style lang="scss" scoped>
.table-container {
  height: 85%;
}
</style>
