import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_country_flag = _resolveComponent("country-flag")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!

  return (_openBlock(), _createBlock(_component_v_navigation_drawer, {
    color: "#363636",
    "disable-resize-watcher": "",
    "disable-route-watcher": ""
  }, {
    default: _withCtx(() => [
      (_ctx.currentUser)
        ? (_openBlock(), _createBlock(_component_v_list, {
            key: 0,
            class: "nav-list"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_list_item, {
                "prepend-icon": "mdi-home",
                title: "BOQ-Validator",
                to: { name: 'Home' }
              }),
              _createVNode(_component_v_list_item, {
                link: "",
                to: { name: 'BoqOverview' },
                "prepend-icon": "mdi-format-list-bulleted-square",
                title: _ctx.$tc('COMPONENTS.NAVIGATIONDRAWER.BOQ_OVERVIEW')
              }, null, 8, ["title"]),
              _createVNode(_component_v_list_item, {
                link: "",
                to: { name: 'Articles' },
                "prepend-icon": "mdi-folder",
                title: _ctx.$tc('COMPONENTS.NAVIGATIONDRAWER.ARTICLE')
              }, null, 8, ["title"]),
              (['ROLE_MANAGER', 'ROLE_ADMIN'].includes(_ctx.currentUser.role))
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 0,
                    link: "",
                    to: { name: 'Settings' },
                    "prepend-icon": "mdi-cog",
                    title: _ctx.$tc('COMPONENTS.NAVIGATIONDRAWER.SETTINGS')
                  }, null, 8, ["title"]))
                : _createCommentVNode("", true),
              (['ROLE_MANAGER', 'ROLE_ADMIN'].includes(_ctx.currentUser.role))
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 1,
                    "prepend-icon": "mdi-account-multiple",
                    title: _ctx.$tc('COMPONENTS.NAVIGATIONDRAWER.USER_ADMIN'),
                    to: { name: 'UserAdministrationOverview' }
                  }, null, 8, ["title"]))
                : _createCommentVNode("", true),
              _createVNode(_component_v_list_item, { id: "language-nav-element" }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_component_v_select, {
                    modelValue: _ctx.inputLanguage,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputLanguage) = $event)),
                    key: _ctx.selectLanguage,
                    items: _ctx.computedLanguages,
                    label: _ctx.$tc('COMPONENTS.NAVIGATIONDRAWER.LANGUAGE'),
                    density: "compact",
                    variant: "outlined",
                    class: "pt-2"
                  }, {
                    item: _withCtx(({ props, item }) => [
                      _createVNode(_component_v_list_item, _mergeProps(props, {
                        title: item.title
                      }), {
                        append: _withCtx(() => [
                          _createVNode(_component_country_flag, {
                            size: "normal",
                            country: _ctx.getFlagISO(item.value),
                            align: "center"
                          }, null, 8, ["country"])
                        ]),
                        _: 2
                      }, 1040, ["title"])
                    ]),
                    _: 1
                  }, 8, ["modelValue", "items", "label"]))
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}