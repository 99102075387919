<template>
  <v-tooltip location="bottom">
    <template #activator="{ props }">
      <v-btn v-bind="props" variant="text" icon @click.stop="buttonClicked">
        <v-icon :color="iconColor" :size="iconSize">
          {{ icon }}
        </v-icon>
      </v-btn>
    </template>
    {{ tooltip }}
  </v-tooltip>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        tooltip: {
            type: String
        },
        icon: {
            type: String
        },
        iconColor: { default: "grey-darken-3",
            type: String
        },
        iconSize: { default: 24,
            type: Number
        }
    },
    methods: {
        buttonClicked() {
            this.$emit("buttonClicked");
        }
    }
})

</script>
