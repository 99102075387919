<template>
  <v-container class="contact-selection">
    <v-alert
      v-if="!getConfig.filter || Object.keys(getConfig.filter).length === 0"
      class="mt-3"
      density="compact"
      variant="text"
      type="info">
      {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CONTACT_SELECTION.USE_FILTER") }}
    </v-alert>
    <div class="table-container">
      <BoqDataTable
        ref="dataTable"
        :headers="headers"
        :items="filteredContactList"
        :singleSelect="true"
        :showSelect="true"
        itemKey="businessPartner"
        :loading="loading"
        v-model:get-config="getConfig"
        :debounceTime="0"
        :partnerObjects="partnerObjects"
        @itemSelected="onItemSelected"
        @enterPressed="loadContacts"
      >
      </BoqDataTable>
    </div>
    <v-row class="flex-grow-0" justify="start">
      <v-col cols="auto">
        <v-btn variant="outlined"
          prepend-icon="mdi-magnify"
          :disabled="loading"
          @click="loadContacts()"
        >
          {{ $tc('COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CONTACT_SELECTION.SEARCH_AGAIN') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { ContactView } from "@/core/models/master-data/Contact.model";
import BoqDataTable from "@/shared/components/data-table.component.vue";
import GetConfig from "@/core/services/base/get.config";
import { BoqTableHeader } from "@/core/interfaces/boq-table-header.interface";
import { SalesView } from "@/core/models/master-data/Sales.model";
import { ClientView, PartnerObj } from "@/core/models/master-data/Client.model";
import axios from "axios";
import { defineComponent, PropType } from "vue";

type ContactViewKeyType = keyof ContactView;

export default defineComponent({
  components: {
    BoqDataTable,
  },
    data() {
        const errorAlert: any = {
                type: "error",
                message: this.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CONTACT_SELECTION.ERROR"),
              };
        const noContentAlert: any = {
                type: "info",
                message: this.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CONTACT_SELECTION.NO_CONTENT"),
              };
        const selectedContact: any = null;
        const headers: BoqTableHeader[] = [
                {
                  value: "businessPartner",
                  title: this.$tc(
                    "COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.HEADERS.CONTACT_SELECTION.BUSINESSPARTNERNUMBER"
                  ),
                  filterable: true,
                },
                {
                  value: "firstName",
                  title: this.$tc(
                    "COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.HEADERS.CONTACT_SELECTION.FIRSTNAME"
                  ),
                  filterable: true,
                },
                {
                  value: "lastName",
                  title: this.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.HEADERS.CONTACT_SELECTION.LASTNAME"),
                  filterable: true,
                },
                {
                  value: "phoneNumber",
                  title: this.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.HEADERS.CONTACT_SELECTION.PHONE"),
                  filterable: true,
                },
                {
                  value: "emailAddress",
                  title: this.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.HEADERS.CONTACT_SELECTION.EMAIL"),
                  filterable: true,
                },
              ];
        const getConfig: GetConfig = GetConfig.default();
        const boomiContactList: any[] = [];
        const partnerObjects: PartnerObj[] = [];

        return {
            partnerObjects,
            boomiContactList,
            getConfig,
            headers,
            selectedContact,
            noContentAlert,
            errorAlert
        };
    },
    computed: {
        filteredContactList(): ContactView[] {
            const filters = this.getConfig.filter;
            if (filters && Object.keys(filters).length > 0) {
              return this.contactList.filter((contact: ContactView) => {
                return Object.keys(filters).every((key: string) => {
                  const _key: ContactViewKeyType = key as ContactViewKeyType;
                  return contact[_key].toLowerCase().includes(filters[key].toLowerCase());
                });
              });
            }
            return this.contactList;
        },
        contactList(): ContactView[] {
            return this.$store.getters["masterdata/contactList"];
        },
        loading(): boolean {
            return this.$store.getters["loading"];
        }
    },
    mounted() {
        (this.$refs.dataTable as InstanceType<typeof BoqDataTable>).initFiltersFromGetConfig(this.getConfig);

            this.partnerObjects = this.client?.partnerObj ?? [];
            this.loadFromBoomiInterface();
    },
    unmounted() {
        this.$store.dispatch("masterdata/clearContactList");
        this.partnerObjects = [];
    },
    methods: {
        async loadRecipientBoomi() {
            //
        },
        async loadFromBoomiInterface() {
            try {
                  const bpNumbers: string[] = [];
                  if (this.partnerObjects.length) {
                    this.partnerObjects.forEach(({ BusinessPartnerPerson }) => {
                      bpNumbers.push(BusinessPartnerPerson);
                    });
                  }

                  const response = await axios.post("/businessPartnerContacts", {
                    businessPartnerNumbers: bpNumbers,
                  });
                  if (response.status == 200 && response.data && response.data.length) {
                    this.$store.dispatch("masterdata/loadBoomiContactList", response.data);
                  }
                } catch (err) {
                  console.error(err);
                }
        },
        loadContacts() {
            if (this.loading) return;
        },
        handleResponse(response: any) {
            if (response.status === 200) {
              //this.loading = false;
            } else if (response.status === 204) {
              this.$store.commit("activateAlert", this.noContentAlert);
              //this.loading = false;
            } else {
              this.handleError();
            }
        },
        handleError() {
            this.$store.commit("activateAlert", this.errorAlert);
        },
        onItemSelected(item: ContactView[]) {
            this.$emit("itemSelected", item[0]);
        },
        onSelectedContactChange(val: any) {
            this.$emit("customerSelected", val[0]);
        }
    },
    props: {
        client: {
            type: Object as PropType<ClientView>
        },
        salesArea: {
            type: Object as PropType<SalesView>
        }
    },
    watch: {
        "selectedContact": [{
            handler: "onSelectedContactChange"
        }]
    }
})

</script>

<style lang="scss" scoped>
.contact-selection {
  flex-grow: 1;
  height: 1%;
  display: flex;
  flex-direction: column;
}

.table-container {
  overflow-y: auto;
  flex-grow: 1;
  flex-shrink: 1;
}
</style>
