import { ContactView } from "@/core/models/master-data/Contact.model";
import { ClientView } from "@/core/models/master-data/Client.model";
import { MasterdataView } from "@/core/models/master-data/MasterData.model";
import { RecipientView } from "@/core/models/master-data/Recipient.model";
import { BidderView } from "@/core/models/master-data/Bidder.model";
import { PlannerView } from "@/core/models/master-data/Planner.mode";
import { OpportunityView } from "@/core/models/master-data/Opportunity.model";
import { SalesView } from "@/core/models/master-data/Sales.model";

export default class MasterdataState {
  clientList: ClientView[] = [];
  recipientList: RecipientView[] = [];
  contactList: ContactView[] = [];
  bidderList: BidderView[] = [];
  plannerList: PlannerView[] = [];
  opportunityList: OpportunityView[] = [];
  masterdata: MasterdataView = new MasterdataView();
  salesArea: SalesView[] = [];
  masterdataChanged = false;
}

export const state = new MasterdataState();
