<template>
  <div class="fill-height">
    <!-- todo: Durch debounce werden alle paar Millisekunden Artikel geladen... -->
    <!-- <v-text-field v-debounce="loadArticles" v-model="search"></v-text-field> -->
    <v-row dense>
      <v-col align-self="center" cols="auto">
        <v-icon class="pl-5">mdi-magnify</v-icon>
      </v-col>
      <v-col>
        <v-textarea
          v-model="search"
          :label="$tc('COMPONENTS.ARTICLE.ARTICLE_OVERVIEW.INTELLIGENT_SEARCH')"
          class="search"
          rows="3"
          variant="outlined"
          @update:model-value="specialSearch"
        ></v-textarea>
      </v-col>
    </v-row>
    <div class="article-table">
      <BoqDataTable
        ref="dataTable"
        :items="articles"
        :disablePaginate="true"
        :filterable="true"
        v-model:get-config="getConfig"
        :headers="headers"
        :loading="articlesLoading"
        :preselected="preselected"
        :showSelect="true"
        :singleSelect="true"
        itemKey="artikelnummer"
        @itemSelected="onSelectedChange"
      >
        <template v-slot:[`item.artikelnummer`]="{ item }">
          <ArticleDetailDialog :article="item">
            <template v-slot:activator="{ props, article }">
              <div v-bind="props">
                {{ article.artikelnummer }}
                <v-icon color="primary" size="medium">mdi-information</v-icon>
              </div>
            </template>
          </ArticleDetailDialog>
          <div
            v-if="articles.length > 0 && item.id === articles[articles.length - 1].id"
            v-intersect="scrollArticleList"
          ></div>
        </template>
        <template v-slot:[`item.displayScore`]="{ item }">
          <p class="text-no-wrap">{{ item.displayScore }}</p>
        </template>
      </BoqDataTable>
    </div>
  </div>
</template>

<script lang="ts">
import ArticleDetailDialog from "@/components/article/ArticleDetailDialog.vue";
import BoqDataTable from "@/shared/components/data-table.component.vue";
import { BoqTableHeader } from "@/core/interfaces/boq-table-header.interface";
import GetConfig from "@/core/services/base/get.config";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  components: {
    ArticleDetailDialog,
    BoqDataTable
  },
    data() {
        const getConfig: GetConfig = GetConfig.default();
        const headers: BoqTableHeader[] = [
                {
                  value: "artikelnummer",
                  title: this.$tc("COMPONENTS.ARTICLE.ARTICLE_OVERVIEW.ARTICLENUMBER"),
                  filterable: true,
                },
                {
                  value: "bezeichnungProduktname",
                  title: this.$tc("COMPONENTS.ARTICLE.ARTICLE_OVERVIEW.SHORTCUT"),
                  filterable: true,
                },
                {
                  value: "angebotstext",
                  title: this.$tc("COMPONENTS.ARTICLE.ARTICLE_OVERVIEW.QUOTATION_TEXT"),
                  filterable: true,
                },
                {
                  value: "displayScore",
                  title: "Score",
                  filterable: false,
                },
              ];
        const selected: unknown[] = [];

        return {
            loading: false,
            currentPage: 0,
            itemsPerPage: 35,
            search: "",
            selected,
            articlesLoading: false,
            headers,
            getConfig
        };
    },
    computed: {
        searchItem() {
            if (this.search) return this.search;
            return this.defaultValue;
        },
        articles(): any[] {
            return this.$store.getters["article/articleList"];
        }
    },
    mounted() {
        //defaultValue is Article List Pop Up
        if (this.defaultValue) {
          this.itemsPerPage = 10
          this.search = this.defaultValue;
          this.getConfig.filter = { ...this.getConfig.filter, search: this.defaultValue };
        }

        this.loadArticles();
    },
    unmounted() {
        this.$store.dispatch("article/clearArticleList");
    },
    methods: {
        onSelectedChange(value: any[]) {
          console.log('onSelectedChange', value);
            this.selected = value;
            this.$emit("articleSelected", this.selected);
        },
        scrollArticleList(): void {
            const tableElements = (document.getElementsByTagName("tr"));
            if (tableElements) {
              const lastTableElement = tableElements[tableElements.length - 1];
              if ((lastTableElement.getBoundingClientRect().y < window.innerHeight)
                && (this.articles.length === (tableElements.length - 2))
                && !this.articlesLoading) {
                this.articlesLoading = true;
                this.currentPage += 1;
                this.getConfig.page = this.currentPage;
                this.getConfig.perPage = this.itemsPerPage;
                this.$store.dispatch("article/appendArticleList", this.getConfig)
                  .then(() => {
                    console.log("Success");
                  }).catch((err: unknown) => {
                  console.error(err);
                }).finally(() => {
                  this.articlesLoading = false;
                });
              }
            }
        },
        loadArticles() {
            this.currentPage = 0;
            this.getConfig.page = this.currentPage;
            this.getConfig.perPage = this.itemsPerPage;
            this.$store.dispatch("article/loadArticleList", this.getConfig);
        },
        specialSearch() {
            this.getConfig.filter = { ...this.getConfig.filter, search: this.search };
            if (!this.search) delete this.getConfig.filter["search"];
            this.loadArticles();
        },
        handleError() {
            this.$store.commit("activateAlert", this.$store.state.errorAlert);
        },
        getConfigChanged() {
            this.loadArticles();
        }
    },
    props: {
        defaultValue: {
            type: Object as PropType<any>
        },
        preselected: { default: null,
            type: Array as PropType<any[]>
        }
    },
    watch: {
        "getConfig": [{
            handler: "getConfigChanged"
        }]
    }
})

</script>

<style lang="scss" scoped>
.search {
  padding: 20px 10px 0 10px;
}

.article-table {
  height: 85%;

  tbody {
    height: 80%;
    overflow: auto !important;
  }
}
</style>
