import axios, { AxiosResponse } from "axios";

//To be changed in backend
const resource = "quotation";

export function loadAllPositions(quotationId: number): Promise<any> {
  return axios.get(`/${resource}/${quotationId}/positionDetails`).then((response) => response.data);
}

export function listPositions(quotationId: number): Promise<any> {
  return axios.get(`/${resource}/${quotationId}/listPositions`).then((response) => response.data);
}

export function loadPositionsToplevel(quotationId: number): Promise<any> {
  return axios
    .get(`/${resource}/${quotationId}/positionHierarchy`)
    .then((response) => response.data);
}

export function loadPositionChildren(quotationId: number, positionId: number): Promise<any> {
  return axios
    .get(`/${resource}/${quotationId}/positionHierarchy?positionId=${positionId}`)
    .then((response) => response.data);
}

export function filterPositions(
  quotationId: number,
  pageFilter: string
): Promise<AxiosResponse<any>> {
  return axios.post(`/${resource}/${quotationId}/filterPositions`, null, {
    params: {
      pageFilter: pageFilter,
    },
  });
}
