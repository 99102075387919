import ArticleState from "./state";

export default {
  setArticleList: (
    state: ArticleState,
    value: any[]
  ) => {
    state.articleList = value;
  },
  appendArticleList: (
    state: ArticleState,
    value: any[]
  ) => {
    state.articleList = [...state.articleList, ...value];
  }
};
