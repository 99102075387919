import { i18nGlobalTc } from "@/i18n";

export class Boq {
  id = 0;
  quotations = "";
  quotationsCount = 0;
  exportedQuotationsCount = 0;
  source = "";
  constructionProject = "";
  location = "";
  status = "";
  team = "";
  date = new Date();
  pdfFileName = "";
  comment = "";
  version = "";
  lastEditor = "";
  marketLanguage = "";
  marketId = -1;

  constructor(boqDto?: BoqDto) {
    if (boqDto) {
      this.id = boqDto.id;
      this.quotationsCount = boqDto.quotationsCount;
      this.exportedQuotationsCount = boqDto.exportedQuotationsCount;
      this.quotations = boqDto.exportedQuotationsCount + " / " + boqDto.quotationsCount;
      this.source = boqDto.source;
      this.constructionProject = "";
      this.location = "";
      this.status = Boq.getStatus(boqDto.status);
      this.team = boqDto.editingGroup;
      this.date = new Date(boqDto.date);
      this.comment = boqDto.comment;
      this.pdfFileName = boqDto.pdfFileName;
      this.version = boqDto.version;
      this.lastEditor = boqDto.lastEditor;
      this.marketId = boqDto.marketId;
      this.marketLanguage = boqDto.marketLanguage;
    }
  }

  static getStatus(status: string): string {
    switch (status) {
      case "INIT":
        return i18nGlobalTc("CORE.MODELS.BOQ.BOQ_MODELS.INITIALIZING");
      case "QUEUED":
        return i18nGlobalTc("CORE.MODELS.BOQ.BOQ_MODELS.WAITING");
      case "CONVERTING":
        return i18nGlobalTc("CORE.MODELS.BOQ.BOQ_MODELS.CONVERTING");
      case "AVAILABLE":
        return i18nGlobalTc("CORE.MODELS.BOQ.BOQ_MODELS.AVAILABLE");
      case "FAILED":
        return i18nGlobalTc("CORE.MODELS.BOQ.BOQ_MODELS.FAILED");
      case "CANCELLED":
        return i18nGlobalTc("CORE.MODELS.BOQ.BOQ_MODELS.CANCELLED");
      default:
        return status;
    }
  }
}

export interface BoqDto {
  id: number;
  source: string;
  constructionProject: string;
  location: string;
  status: string;
  editingGroup: string;
  purchasingTeam: string;
  date: number;
  comment: string;
  pdfFileName: string;
  version: string;
  quotationsCount: number;
  exportedQuotationsCount: number;
  lastEditor: string;
  marketLanguage: string;
  marketId: number;
}
