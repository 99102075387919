import UserState from './state';
import { User } from '@/core/models/user/User.model';

export default {
  setUserList: (
    state: UserState,
    value: User[]
  ) => {
    state.userList = value;
  },
  setUserDetail: (
    state: UserState,
    value: User
  ) => {
    state.userDetail = value;
  },
  setUserChanged: (
    state: UserState,
    value: boolean
  ) => {
    state.userChanged = value;
  },
  setCurrentUser: (
    state: UserState,
    value: User
  ) => {
    state.currentUser = value;
  }
};
