<template>
  <v-container fluid class="d-flex mt-5">
    <KeywordManager />
    <UnitManager />
  </v-container>
</template>

<script lang="ts">
import KeywordManager from "@/components/settings/keyword-manager.component.vue";
import UnitManager from "@/components/settings/unit-manager.component.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    KeywordManager,
    UnitManager,
  },
    mounted() {
        this.$store.dispatch("market/loadMarketList");
    }
})

</script>

<style lang="scss" scoped>
.container {
  overflow: auto;
  height: 100%;
}
</style>
