import "./router/component.hooks";
import { createApp } from "vue";
import axios, { AxiosRequestHeaders } from "axios";
import App from "./App.vue";
import router from "./router";
import store from "./core/store";
import { state } from "@/modules/auth/store/state";
import { handleAxiosErrorResponse } from "./core/error-handler/error-handler";
import VueExcelEditor  from "vue3-excel-editor";
import i18n from "./i18n";
import CountryFlag from "vue-country-flag-next";
import vuetify from "./plugins/vuetify";
import { Drag, Drop } from "vue3-drag-drop";

const app = createApp(App)

// app.config.productionTip = false;

axios.defaults.baseURL = process.env.NODE_ENV === "production"
  ? "https://" + window.location.hostname as string + ":443/api"
  : "http://" + window.location.hostname as string + ":443/api";

axios.interceptors.request.use((request) => {
  if (state.token) {
    request.headers = {
      ...request.headers,
      Authorization: `Basic ${state.token}`,
    } as AxiosRequestHeaders;
  }
  return request;
});

axios.interceptors.response.use(function (response) {
  const data = response.data as string;
  if (typeof data === "string" && data.includes("login.microsoftonline.com")) {
    console.log("go back to login page");
    router.push("/login");
  }
  return response;
}, handleAxiosErrorResponse);

app.component("country-flag", CountryFlag);

// eslint-disable-next-line vue/multi-word-component-names
app.component("drag", Drag);
// eslint-disable-next-line vue/multi-word-component-names
app.component("drop", Drop);

app.use(VueExcelEditor );

app.use(vuetify)
app.use(store)
app.use(router)
app.use(i18n as any)
app.mount('#app')
