import Quotation, { QuotationDto } from "@/core/models/quotation/quotation.model";
import store from "@/core/store";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import GetConfig, { applyGetConfig } from "../base/get.config";

const resource = "quotation";

export function loadQuotationList(boqId: number, getConfig: GetConfig): Promise<Quotation[]> {
  store.commit("setLoading", true);
  let config: AxiosRequestConfig = {};

  config.params = {
    boqId: boqId,
  };

  if (getConfig) {
    config = applyGetConfig(getConfig, config);
  }

  return axios
    .get(`/${resource}/list`, config)
    .then((response) => {
      const result = response.data.map((item: QuotationDto) => new Quotation(item));
      return result;
    })
    .finally(() => store.commit("setLoading", false));
}

export function loadQuotationDetail(quotationId: number): Promise<any> {
  return axios.get(`/${resource}/${quotationId}`).then((response) => response.data);
}

export function createQuotation(boqId: number): Promise<AxiosResponse<any>> {
  return axios.post(`/${resource}/create`, {
    params: {
      boqId: boqId,
    },
  });
}

export function deleteQuotation(quotationId: number) {
  console.log(quotationId);
}

export function loadActionLog(quotationId: number): Promise<any> {
  return axios.get(`/${resource}/${quotationId}/actionLog`).then((response) => response.data);
}

export function getValidationValue(quotationId: number): Promise<number> {
  return  axios.get(`/quotation/${quotationId}/validationValue`).then((response) => response.data);
}
