<template>
  <v-container class="customer-selection">
    <v-chip-group v-if="bidders && bidders.length">
      <v-chip
        v-for="bidder in bidders"
        :key="bidder.kundennummer"
        filter
        :model-value="bidders.some(b => b.kundennummer === bidder.name)"
        class="ma-2"
        v-show="bidder"
        closable
        color="##ccc"
        @click:close="removeBidder(bidder)"
        >{{ bidder.name }}</v-chip
      >
    </v-chip-group>
    <v-alert class="mt-3"
      v-if="!getConfig.filter || Object.keys(getConfig.filter).length === 0"
      density="compact"
      variant="text"
      type="info"
    >
      {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BIDDER_SELECTION.USE_FILTER.1") }}
      {{ entityName }}
      {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BIDDER_SELECTION.USE_FILTER.2") }}.
    </v-alert>
    <div class="table-container">
      <BoqDataTable
        ref="dataTable"
        :headers="headers"
        :items="bidderList"
        :singleSelect="false"
        :selectedBidders="bidders"
        :showSelect="true"
        itemKey="kundennummer"
        :loading="loading"
        v-model:get-config="getConfig"
        :debounceTime="0"
        @itemSelected="onItemSelected"
        @enterPressed="loadBidders"
      >
      </BoqDataTable>
    </div>
    <v-row class="flex-grow-0" justify="start">
      <v-col cols="auto">
        <v-btn
          variant="outlined"
          prepend-icon="mdi-magnify"
          :disabled="loading"
          @click="loadBidders()"
        >
          {{ $tc('COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BIDDER_SELECTION.SEARCH_AGAIN') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import BoqDataTable from "@/shared/components/data-table.component.vue";
import { headers as HEADERS } from "./headers/bidder-selection-header";
import GetConfig from "@/core/services/base/get.config";
import { BidderDto, BidderView } from "@/core/models/master-data/Bidder.model";
import { BoqTableHeader } from "@/core/interfaces/boq-table-header.interface";
import { MasterdataView } from "@/core/models/master-data/MasterData.model";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    BoqDataTable,
  },
    data() {
        const bidderAlert: any = {
                type: "error",
                message: this.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BIDDER_SELECTION.BIDDER_ERROR"),
              };
        const errorAlert: any = {
                type: "error",
                message: this.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BIDDER_SELECTION.ERROR"),
              };
        const noContentAlert: any = {
                type: "info",
                message: this.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BIDDER_SELECTION.NO_CONTENT"),
              };
        const headers: BoqTableHeader[] = HEADERS;
        const bidders: BidderDto[] = [];
        const getConfig: GetConfig = GetConfig.default();

        return {
            getConfig,
            bidders,
            headers,
            noContentAlert,
            errorAlert,
            bidderAlert
        };
    },
    mounted() {
        this.bidders = this.masterData ? this.masterData.bidder : [];
    },
    unmounted() {
        this.$store.dispatch("masterdata/clearBidderList");
    },
    methods: {
        removeBidder(bidderToRemove: BidderView) {
            this.bidders = this.bidders.filter(
              (bidder) => bidder.kundennummer != bidderToRemove.kundennummer
            );
        },
        loadBidders() {
            if (this.loading) return;
            this.$store.dispatch("masterdata/loadBidderList", this.getConfig);
        },
        handleResponse(response: any) {
            if (response.status === 200) {
              this.loading = false;
            } else if (response.status === 204) {
              this.$store.commit("activateAlert", this.noContentAlert);
              this.loading = false;
            } else {
              this.handleError();
            }
        },
        handleError() {
            this.$store.commit("activateAlert", this.errorAlert);
            this.loading = false;
        },
        onItemSelected(item: BidderDto[]) {
            this.bidders = item;
            this.$emit("itemSelected", item);
        },
        checkBidders() {
            if (this.bidders.length > 9) {
              this.bidders = this.bidders.slice(0, 9);
              this.$store.commit("activateAlert", this.bidderAlert);
            }
        }
    },
    props: {
        entityName: {
            type: String
        }
    },
    watch: {
        "bidders": [{
            handler: "checkBidders"
        }]
    },
    computed: {
        bidderList(): BidderView[] {
            return this.$store.getters["masterdata/bidderList"];
        },
        loading(): boolean {
            return this.$store.getters["loading"];
        },
        masterData(): MasterdataView {
            return this.$store.getters["masterdata/masterdata"];
        }
    }
})

</script>

<style lang="scss" scoped>
.customer-selection {
  flex-grow: 1;
  height: 1%;
  display: flex;
  flex-direction: column;
}

.table-container {
  overflow-y: auto;
  flex-grow: 1;
  flex-shrink: 1;
}
</style>
