import { User } from '@/core/models/user/User.model';
import { GetterTree } from 'vuex';
import { RootState } from '~core/store/state';
import UserState from './state';

export const getters: GetterTree<UserState, RootState> = {
  userList: state => state.userList,
  userDetail: state => state.userDetail,
  currentUser: state => state.currentUser ? new User(state.currentUser) : null,
};
