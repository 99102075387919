<template>
  <v-container class="pa-0 mr-2 mb-4">
    <v-responsive class="overflow-y-auto fill-height pa-0 ma-0">
      <v-card class="main-card" variant="flat">
        <v-card-title>
          {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.FURTHER_CONTACTS") }}
        </v-card-title>
        <v-card-text>
          <v-container fluid class="px-0">
            <v-row>
              <v-col>
                <v-expansion-panels v-if="masterData && masterData.bidder.length > 1">
                  <v-expansion-panel>
                    <v-expansion-panel-title>
                      {{
                        $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.BIDDERLIST")
                      }}
                    </v-expansion-panel-title>
                    <v-expansion-panel-text
                      v-for="bidder in masterData.bidder"
                      :key="bidder.kundennummer"
                    >
                      <v-row>
                        <v-col lg="5">
                          <div class="d-flex">
                            <div class="card__title">
                              {{
                                $tc(
                                  "COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.BIDDER"
                                )
                              }}
                            </div>
                            <span>
                              <SelectionDialog
                                :show-stepper="false"
                                :step-one-title="
                                  $tc(
                                    'COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.BIDDER'
                                  )
                                "
                                @item-one-selected="onBidderSelected"
                                @submit="submitBidder"
                              >
                                <template #activator="{ props }">
                                  <div v-bind="props">
                                    <v-icon class="px-1" @click="{}">mdi-card-search</v-icon>
                                  </div>
                                </template>

                                <template #stepOne="{ onItemSelected }">
                                  <BidderSelection
                                    :entity-name="
                                      $tc(
                                        'COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.BIDDER'
                                      )
                                    "
                                    @item-selected="onItemSelected"
                                  />
                                </template>
                              </SelectionDialog>
                            </span>
                          </div>
                          {{ bidder.name ? bidder.name : "-" }}
                        </v-col>

                        <v-col lg="4">
                          <div class="card__title">
                            {{
                              $tc(
                                "COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.BIDDER_ADDRESS"
                              )
                            }}
                          </div>
                          <div v-if="bidder.strasse">
                            <div>{{ bidder.strasse }}</div>
                            <div>{{ bidder.plz }} {{ bidder.ort }}</div>
                          </div>
                          <div v-else>
                            -
                          </div>
                        </v-col>
                        <v-col lg="auto">
                          <div class="card__title">
                            {{
                              $tc(
                                "COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.BIDDER_ID"
                              )
                            }}
                          </div>
                          {{ bidder.kundennummer ? bidder.kundennummer : "-" }}
                        </v-col>
                      </v-row>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-row v-else>
                  <v-col>
                    <div class="d-flex">
                      <div class="card__title">
                        {{
                          $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.BIDDER")
                        }}
                      </div>
                      <span>
                        <SelectionDialog
                          :show-stepper="false"
                          :step-one-title="
                            $tc('COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.BIDDER')
                          "
                          @item-one-selected="onBidderSelected"
                          @submit="submitBidder"
                        >
                          <template #activator="{ props }">
                            <div v-bind="props">
                              <v-icon class="px-1" @click="{}">mdi-card-search</v-icon>
                            </div>
                          </template>

                          <template #stepOne="{ onItemSelected }">
                            <BidderSelection
                              :entity-name="
                                $tc(
                                  'COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.BIDDER'
                                )
                              "
                              @item-selected="onItemSelected"
                            />
                          </template>
                        </SelectionDialog>
                      </span>
                    </div>
                    {{ singleBidder && singleBidder.name ? singleBidder.name : "-" }}
                  </v-col>

                  <v-col>
                    <div class="card__title">
                      {{
                        $tc(
                          "COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.BIDDER_ADDRESS"
                        )
                      }}
                    </div>
                    <div v-if="singleBidder">
                      <div>{{ singleBidder.strasse || "-" }}</div>
                      <div>{{ singleBidder.plz || "-" }} {{ singleBidder.ort || "-" }}</div>
                    </div>

                    <div v-else>
                      -
                    </div>
                  </v-col>
                  <v-col>
                    <div class="card__title">
                      {{
                        $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.BIDDER_ID")
                      }}
                    </div>
                    {{ singleBidder ? singleBidder.kundennummer : "-" }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="d-flex">
                  <div class="card__title">
                    {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.PLANNER") }}
                  </div>
                  <span>
                    <SelectionDialog
                      :show-stepper="false"
                      :planner-mode="true"
                      :step-one-title="
                        $tc('COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.PLANNER')
                      "
                      @item-one-selected="onPlannerSelected"
                      @submit="submitPlanner"
                    >
                      <template #activator="{ props }">
                        <div v-bind="props">
                          <v-icon class="px-1" @click="{}">mdi-card-search</v-icon>
                        </div>
                      </template>

                      <template #stepOne="{ onItemSelected }">
                        <PlannerSelection
                          :initial-value="planner?.kundennummer"
                          @item-selected="onItemSelected"
                        />
                      </template>
                    </SelectionDialog>
                  </span>
                </div>
                {{ planner?.name ? planner.name : "-" }}
              </v-col>
              <v-col>
                <div class="card__title">
                  {{
                    $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.PLANNER_ADDRESS")
                  }}
                </div>
                <div v-if="planner?.strasse">
                  <div>{{ planner.strasse }}</div>
                  <div>{{ planner.plz }} {{ planner.ort }}</div>
                </div>
                <div v-else>
                  -
                </div>
              </v-col>
              <v-col>
                <div class="card__title">
                  {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.PLANNER_ID") }}
                </div>
                {{ planner?.kundennummer ? planner.kundennummer : "-" }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-responsive>
  </v-container>
</template>

<script lang="ts">
import BidderSelection from "@/components/master-data/customer-selection/BidderSelection.vue";
import PlannerSelection from "@/components/master-data/customer-selection/PlannerSelection.vue";
import SelectionDialog from "@/components/master-data/customer-selection/SelectionDialog.vue";
import { PlannerView } from "@/core/models/master-data/Planner.mode";
import { BidderView } from "@/core/models/master-data/Bidder.model";
import { MasterdataView } from "@/core/models/master-data/MasterData.model";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  components: {
    SelectionDialog,
    BidderSelection,
    PlannerSelection,
  },
    props: {
        planner: {
            type: Object as PropType<PlannerView>
        },
        masterData: {
            type: Object as PropType<MasterdataView>
        }
    },
    data() {
        const selectedPlanner: PlannerView = new PlannerView();
        const selectedBidder: BidderView[] = [];

        return {
            selectedBidder,
            selectedPlanner
        };
    },
    computed: {
        singleBidder() {
            return this.masterData?.bidder.length ? this.masterData.bidder[0] : null;
        }
    },
    methods: {
        onBidderSelected(item: BidderView[]) {
            this.selectedBidder = item;
        },
        onPlannerSelected(item: PlannerView) {
            console.log(item);
            this.selectedPlanner = item ? item : new PlannerView();
        },
        submitBidder() {
            this.$store.dispatch("masterdata/setBidder", this.selectedBidder);

                this.$emit("submitMasterdata");
        },
        submitPlanner() {
            this.$store.dispatch("masterdata/setPlanner", this.selectedPlanner);

                this.$emit("submitMasterdata");
        }
    }
})

</script>
<style lang="scss" scoped>
.card__title {
  color: #333;
  font-weight: 600;
  text-transform: uppercase;
}
.v-card-text {
  padding-bottom: 0;
}

.center {
  text-align: center;
}

.v-card-text > .col {
  padding: 2px 0 14px 0;
}
div.v-expansion-panel-content__wrap {
  padding-left: 5px;
}
.v-expansion-panels {
  z-index: 0;
}
.v-expansion-panel-content {
  border: solid;
  border-style: inset;
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-width: thin;
}
</style>
