import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-611a2c36"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card__title" }
const _hoisted_2 = { class: "card__title" }
const _hoisted_3 = { class: "card__title" }
const _hoisted_4 = { class: "card__title" }
const _hoisted_5 = { class: "card__title" }
const _hoisted_6 = { class: "card__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_responsive = _resolveComponent("v-responsive")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "pa-0 mr-2 mb-4" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_responsive, { class: "overflow-y-auto fill-height pa-0 ma-0" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, {
            class: "main-card",
            variant: "flat"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "d-flex align-center" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BOQ.TITLE")) + " ", 1),
                  _createVNode(_component_v_dialog, {
                    modelValue: _ctx.dialog,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.dialog) = $event)),
                    width: "20vw",
                    onKeydown: _withModifiers(_ctx.submitViaShortcut, ["alt"])
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_btn, _mergeProps({
                        icon: "mdi-pencil",
                        class: "ml-2",
                        size: "small",
                        variant: "text"
                      }, props), null, 16)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_title, { class: "d-flex align-center" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BOQ.EDIT")) + " ", 1),
                              _createVNode(_component_v_btn, {
                                class: "align-self-center ml-auto",
                                icon: "mdi-close",
                                color: "black",
                                size: "small",
                                onClick: _ctx.close
                              }, null, 8, ["onClick"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_divider),
                          _createVNode(_component_v_card_text, { class: "mt-5" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_form, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_text_field, {
                                    modelValue: _ctx.billOfQuantityForm.externalReference,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.billOfQuantityForm.externalReference) = $event)),
                                    label: _ctx.$tc('COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BOQ.EXTERNAL_REFERENCE'),
                                    required: "",
                                    density: "compact",
                                    variant: "outlined"
                                  }, null, 8, ["modelValue", "label"]),
                                  _createVNode(_component_v_text_field, {
                                    modelValue: _ctx.billOfQuantityForm.name,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.billOfQuantityForm.name) = $event)),
                                    label: _ctx.$tc('COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BOQ.DESCRIPTION'),
                                    required: "",
                                    density: "compact",
                                    variant: "outlined"
                                  }, null, 8, ["modelValue", "label"]),
                                  _createVNode(_component_v_text_field, {
                                    modelValue: _ctx.billOfQuantityForm.street,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.billOfQuantityForm.street) = $event)),
                                    label: _ctx.$tc('COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BOQ.STREET'),
                                    required: "",
                                    density: "compact",
                                    variant: "outlined"
                                  }, null, 8, ["modelValue", "label"]),
                                  _createVNode(_component_v_row, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_col, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_text_field, {
                                            modelValue: _ctx.billOfQuantityForm.postalCode,
                                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.billOfQuantityForm.postalCode) = $event)),
                                            label: _ctx.$tc('COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BOQ.POSTAL_CODE'),
                                            required: "",
                                            density: "compact",
                                            variant: "outlined"
                                          }, null, 8, ["modelValue", "label"])
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_text_field, {
                                            modelValue: _ctx.billOfQuantityForm.location,
                                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.billOfQuantityForm.location) = $event)),
                                            label: _ctx.$tc('COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BOQ.LOCATION'),
                                            required: "",
                                            density: "compact",
                                            variant: "outlined"
                                          }, null, 8, ["modelValue", "label"])
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_text_field, {
                                    modelValue: _ctx.billOfQuantityForm.country,
                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.billOfQuantityForm.country) = $event)),
                                    label: _ctx.$tc('COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BOQ.COUNTRY'),
                                    required: "",
                                    density: "compact",
                                    variant: "outlined"
                                  }, null, 8, ["modelValue", "label"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_card_actions, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_spacer),
                              _createVNode(_component_v_btn, {
                                class: "dark-btn",
                                onClick: _ctx.submit
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BOQ.APPLY")), 1)
                                ]),
                                _: 1
                              }, 8, ["onClick"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue", "onKeydown"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_container, {
                    fluid: "",
                    class: "px-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { lg: "6" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BOQ.DESCRIPTION")), 1),
                              _createTextVNode(" " + _toDisplayString(_ctx.billOfQuantity?.name ? _ctx.billOfQuantity.name : "-"), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { lg: "6" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BOQ.EXTERNAL_REFERENCE")), 1),
                              _createTextVNode(" " + _toDisplayString(_ctx.billOfQuantity?.externalReference ? _ctx.billOfQuantity.externalReference : "-"), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { lg: "6" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BOQ.STREET")), 1),
                              _createTextVNode(" " + _toDisplayString(_ctx.billOfQuantity?.street ? _ctx.billOfQuantity.street : "-"), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { lg: "6" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BOQ.POSTAL_CODE")), 1),
                              _createTextVNode(" " + _toDisplayString(_ctx.billOfQuantity?.postalCode ? _ctx.billOfQuantity.postalCode : "-"), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { lg: "6" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BOQ.LOCATION")), 1),
                              _createTextVNode(" " + _toDisplayString(_ctx.billOfQuantity?.location ? _ctx.billOfQuantity.location : "-"), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { lg: "6" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.BOQ.COUNTRY")), 1),
                              _createTextVNode(" " + _toDisplayString(_ctx.billOfQuantity?.country ? _ctx.billOfQuantity.country : "-"), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}