<template>
  <v-app>
    <NavigationDrawer v-model="showDrawer" />
    <NavigationBar @click:drawer-icon="showDrawer = !showDrawer" />
    <v-main>
      <router-view />
    </v-main>
    <MySnackbar />
  </v-app>
</template>

<script lang="ts">
import NavigationBar from "./components/NavigationBar.vue";
import NavigationDrawer from "./components/NavigationDrawer.vue";
import MySnackbar from "./components/MySnackbar.vue";
import { User } from "./core/models/user/User.model";
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    NavigationBar,
    NavigationDrawer,
    MySnackbar,
  },
    data() {
        const keepAliveTimer: any = undefined;
        const showDrawer = true;

        return {
            keepAliveTimer,
            showDrawer,
        };
    },
    mounted() {
        //For development purposes only:
            //Avoid login during development (when application is served with npm run serve)
            //add .env.development.local - file in root folder and add env variable VUE_APP_LOCAL_ADMIN_NAME & VUE_APP_LOCAL_ADMIN_PASSWORD
            if (
              process.env.NODE_ENV === "development" &&
              process.env.VUE_APP_LOCAL_ADMIN_NAME &&
              process.env.VUE_APP_LOCAL_ADMIN_PASSWORD
            ) {
              const baseAuth = btoa(
                `${process.env.VUE_APP_LOCAL_ADMIN_NAME}:${process.env.VUE_APP_LOCAL_ADMIN_PASSWORD}`
              );
              this.$store.dispatch("auth/setToken", baseAuth);

              const admin = User.makeLocalAdmin();
              this.$store.dispatch("user/setCurrentUser", admin);
            }

            this.keepAliveTimer = setInterval(() => {
              axios.get("/keepAlive");
            }, 600000);
    },
    methods: {
        beforeUnmount() {
            clearInterval(this.keepAliveTimer);
            this.keepAliveTimer = undefined;
        }
    }
})

</script>

<style lang="scss">
@use '@/style/styles';

#app, .v-application {
  background-color: styles.$background !important;
}
</style>
