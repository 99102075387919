<template>
  <v-form ref="myForm" @submit="submit($event)">
    <v-card class="text-center">
      <v-card-title> Position hinzufügen </v-card-title>

      <v-card-text>
        <v-text-field v-model="form.positionNumber" label="Positionsnummer"></v-text-field>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn variant="outlined" @click="close()"> Abbrechen </v-btn>
        <v-btn class="dark-btn" elevation="0" type="submit"> Hinzufügen </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    data() {
        const successAlert: any = {
                type: "success",
                message: "Die Position wurde erfolgreich hinzugefügt!",
              };
        const form: any = {
                positionNumber: undefined,
              };

        return {
            form,
            successAlert
        };
    },
    mounted() {
        this.initFormData();
    },
    methods: {
        initFormData() {
            this.form.positionNumber = this.clickedBox.value;
        },
        submit(event: any) {
            event.preventDefault();
            const params = {
              line: this.clickedBox.line,
              page: this.clickedBox.page,
              positionNumber: this.form.positionNumber,
              quotationId: this.quotationId,
              word: this.clickedBox.word,
            };
            axios
              .post(`/position/addFromOverlay`, null, { params })
              .then((response) => this.handleResponse(response))
              .catch(() => this.handleError());
        },
        handleResponse(response: any) {
            this.$store.commit("activateAlert", this.successAlert);
            this.$emit('positionAddedFromOverlay', response.data);
            this.close();
        },
        handleError() {
            this.$store.commit("activateAlert", this.$store.state.errorAlert);
        },
        close() {
            this.$emit("close");
        }
    },
    props: {
        clickedBox: {
            type: Object as PropType<any>
        },
        quotationId: {
            type: Number
        }
    },
    computed: {
        myForm() {
            return this.$refs["myForm"] as HTMLFormElement;
        }
    }
})

</script>

<style lang="scss" scoped>
.v-card-title {
  display: block; //weil der Card-Title sonst nicht centered wird
}
</style>
