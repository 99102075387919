import { Dictionary } from '~core/interfaces/dictionary.interface';
import { AxiosRequestConfig } from 'axios';

// export enum SortDirection {
//   Ascending = 'Ascending',
//   Descending = 'Descending',
// }

export default class GetConfig {
  // sortBy?: string;
  // sortDirection?: SortDirection;

  filter?: Dictionary<string>;

  page?: number;
  perPage?: number;

  additionalParams?: Dictionary<string | number | boolean | string[]>;

  constructor(page: number, perPage: number, filter: Dictionary<string>) {
    this.page = page;
    this.perPage = perPage;
    this.filter = filter;
  }

  public static default(): GetConfig {
    return new GetConfig(1, 10, {});
  }
}

export function validateGetConfig(config: GetConfig): void {
  if (config.page && config.page < 1) {
    throw new Error('Attribute page must be greater than or equal 1');
  }
  if (config.perPage && config.perPage < -1) {
    throw new Error('Attribute perPage must be greater than or equal -1');
  }
}

/** Applies a given getConfig to an axiosRequestConfig */
export function applyGetConfig(
  getConfig: GetConfig,
  axiosRequestConfig?: AxiosRequestConfig
): AxiosRequestConfig {
  validateGetConfig(getConfig);

  const newConfig: AxiosRequestConfig = axiosRequestConfig ?? {};

  newConfig.params = {
    ...axiosRequestConfig?.params,
    ...getConfig.additionalParams,
  };

  // Add sorting
  // newConfig.params.orderBy = getConfig.sortBy;
  // newConfig.params.sortDirection = getConfig.sortDirection;

  // Add pagination
  // newConfig.params.pageIndex = getConfig.page;
  // newConfig.params.pageSize = getConfig.perPage;

  // Add filter params
  if (getConfig.filter) {
    Object.keys(getConfig.filter).forEach(key => {
      newConfig.params[`${key}`] = getConfig.filter?.[key];
    });
  }
  return newConfig;
}
