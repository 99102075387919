import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_file_input = _resolveComponent("v-file-input")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.dialog,
    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.dialog) = $event)),
    width: "500"
  }, {
    activator: _withCtx(({ props }) => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_col, {
            cols: "auto",
            class: "pa-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, _mergeProps(props, {
                "prepend-icon": "mdi-plus",
                class: "dark-btn",
                onClick: _cache[0] || (_cache[0] = ($event: any) => ((_ctx.disableUploadAndCheckbox = true), _ctx.getCurrentCountry()))
              }), {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("BOQ.ADDBOQDIALOG.ADD_QUOTATION")), 1)
                ]),
                _: 2
              }, 1040)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_v_col, {
            cols: "auto",
            class: "py-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, _mergeProps(props, {
                class: "dark-btn",
                onClick: _cache[1] || (_cache[1] = ($event: any) => ((_ctx.disableUploadAndCheckbox = false), _ctx.getCurrentCountry()))
              }), {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, { start: "" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" mdi-plus ")
                    ]),
                    _: 1
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("BOQ.ADDBOQDIALOG.ADD_BOQ")), 1)
                ]),
                _: 2
              }, 1040)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1024)
    ]),
    default: _withCtx(() => [
      (_ctx.dialog)
        ? (_openBlock(), _createBlock(_component_v_form, {
            key: 0,
            ref: "myForm",
            "lazy-validation": true,
            onSubmit: _cache[8] || (_cache[8] = ($event: any) => (_ctx.submit($event)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, _normalizeProps(_guardReactiveProps({ loading: _ctx.submitted, disabled: _ctx.submitted })), {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.formTitle), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_select, {
                                modelValue: _ctx.form.source,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.source) = $event)),
                                items: _ctx.sources,
                                label: _ctx.$t('BOQ.ADDBOQDIALOG.SOURCE'),
                                variant: "outlined",
                                density: "compact",
                                rules: [(v) => !!v || _ctx.$t('BOQ.ADDBOQDIALOG.ERROR_SOURCE')],
                                required: ""
                              }, null, 8, ["modelValue", "items", "label", "rules"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_select, {
                                modelValue: _ctx.form.teamId,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.teamId) = $event)),
                                items: _ctx.teams,
                                "item-title": "name",
                                "item-value": "id",
                                label: _ctx.$t('BOQ.ADDBOQDIALOG.WORKING_GROUP'),
                                variant: "outlined",
                                density: "compact",
                                required: "",
                                rules: [(v) => !!v || _ctx.$t('BOQ.ADDBOQDIALOG.ERROR_GROUP')]
                              }, null, 8, ["modelValue", "items", "label", "rules"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_textarea, {
                        modelValue: _ctx.form.comment,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.comment) = $event)),
                        label: _ctx.$t('BOQ.ADDBOQDIALOG.COMMENT'),
                        variant: "outlined"
                      }, null, 8, ["modelValue", "label"]),
                      (!_ctx.disableUploadAndCheckbox)
                        ? (_openBlock(), _createBlock(_component_v_file_input, {
                            key: 0,
                            label: _ctx.$t('BOQ.ADDBOQDIALOG.UPLOAD_FILE'),
                            variant: "outlined",
                            multiple: "",
                            accept: _ctx.supportedTypes.join(),
                            "show-size": "",
                            density: "compact",
                            required: "",
                            rules: [(v) => !!v || _ctx.$t('BOQ.ADDBOQDIALOG.ERROR_FILE')],
                            "onUpdate:modelValue": _ctx.onFileSelected
                          }, null, 8, ["label", "accept", "rules", "onUpdate:modelValue"]))
                        : _createCommentVNode("", true),
                      (!_ctx.disableUploadAndCheckbox)
                        ? (_openBlock(), _createBlock(_component_v_row, {
                            key: 1,
                            class: "my-0"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, {
                                cols: "auto",
                                "align-self": "start",
                                class: "pt-1 pb-5"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_checkbox, {
                                    modelValue: _ctx.trimPages,
                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.trimPages) = $event)),
                                    class: "ma-0",
                                    density: "compact",
                                    label: _ctx.$t('BOQ.ADDBOQDIALOG.LIMIT_PAGES'),
                                    "hide-details": ""
                                  }, null, 8, ["modelValue", "label"])
                                ]),
                                _: 1
                              }),
                              (_ctx.trimPages)
                                ? (_openBlock(), _createBlock(_component_v_col, {
                                    key: 0,
                                    "align-self": "start",
                                    class: "pt-0"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_textarea, {
                                        modelValue: _ctx.form.pageFilter,
                                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.pageFilter) = $event)),
                                        variant: "outlined",
                                        density: "compact",
                                        placeholder: "1, 10-20, 99",
                                        "hide-details": ""
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_divider),
                  _createVNode(_component_v_card_actions, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_spacer),
                      _createVNode(_component_v_btn, {
                        variant: "outlined",
                        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.dialog = false))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("BOQ.ADDBOQDIALOG.CANCEL")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_btn, {
                        class: "dark-btn",
                        elevation: "0",
                        type: "submit"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("BOQ.ADDBOQDIALOG.SAVE")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 16)
            ]),
            _: 1
          }, 512))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}