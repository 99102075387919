import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_AddBoqDialog = _resolveComponent("AddBoqDialog")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_ActionButton = _resolveComponent("ActionButton")!
  const _component_BoqDataTable = _resolveComponent("BoqDataTable")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, {
    class: "full-height",
    fluid: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { class: "mb-1" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createElementVNode("h1", null, _toDisplayString(_ctx.$t("BOQ.BOQOVERVIEW.BOQ_OVERVIEW")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { "align-self": "center" }, {
            default: _withCtx(() => [
              _createVNode(_component_AddBoqDialog, { onSuccess: _ctx.boqCreatedSuccessfully }, null, 8, ["onSuccess"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_container, {
        class: "pa-0 table-container elevation-5 rounded-lg",
        fluid: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BoqDataTable, {
            ref: "dataTable",
            "get-config": _ctx.getConfig,
            "onUpdate:getConfig": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.getConfig) = $event)),
            headers: _ctx.headers,
            items: _ctx.boqList,
            loading: _ctx.loading,
            "onClick:row": _ctx.clickRow
          }, {
            [`item.date`]: _withCtx(({ item }) => [
              _createTextVNode(_toDisplayString(_ctx.transformToDateString(item.date)), 1)
            ]),
            [`item.marketId`]: _withCtx(({ item }) => [
              _createTextVNode(_toDisplayString(_ctx.getCountryName(item.marketId, _ctx.marketList)), 1)
            ]),
            [`item.status`]: _withCtx(({ item }) => [
              _createElementVNode("div", {
                class: _normalizeClass({
              'status-cancelled': ['Abgebrochen', 'Fehlgeschlagen'].includes(item.status),
              'boq-disabled': ['Konvertierung', 'Wartet', 'Abgebrochen'].includes(item.status),
            })
              }, _toDisplayString(item.status), 3)
            ]),
            [`item.actions`]: _withCtx(({ item }) => [
              (['Wartet'].includes(item.status))
                ? (_openBlock(), _createBlock(_component_ActionButton, {
                    key: 0,
                    tooltip: _ctx.$tc('BOQ.BOQOVERVIEW.CANCEL'),
                    icon: "mdi-close-circle-outline",
                    "icon-color": "red",
                    onButtonClicked: ($event: any) => (_ctx.cancelConversion(item))
                  }, null, 8, ["tooltip", "onButtonClicked"]))
                : _createCommentVNode("", true),
              (['Verfügbar', 'Abgebrochen', 'Fehlgeschlagen'].includes(item.status))
                ? (_openBlock(), _createBlock(_component_ActionButton, {
                    key: 1,
                    tooltip: _ctx.$tc('BOQ.BOQOVERVIEW.RESTART'),
                    icon: "mdi-restart",
                    "icon-color": "green",
                    onButtonClicked: ($event: any) => (_ctx.restartConversion(item))
                  }, null, 8, ["tooltip", "onButtonClicked"]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["get-config", "headers", "items", "loading", "onClick:row"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}