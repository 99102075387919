import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_ChangeArticleDialog = _resolveComponent("ChangeArticleDialog")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.dialog,
    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.dialog) = $event)),
    width: "350"
  }, {
    activator: _withCtx(({ props }) => [
      (_ctx.articleObject)
        ? (_openBlock(), _createBlock(_component_v_btn, _mergeProps({ key: 0 }, props, {
            class: "dark-btn mb-3 px-1 mr-3",
            style: {"min-width":"0"},
            size: "small",
            icon: "mdi-pencil",
            rounded: ""
          }), null, 16))
        : (_openBlock(), _createBlock(_component_v_btn, _mergeProps({
            key: 1,
            variant: "outlined"
          }, props, { "prepend-icon": "mdi-plus" }), {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.POSITION.POSITIONARTICLEDIALOG.ARTICLE.ADD")), 1)
            ]),
            _: 2
          }, 1040))
    ]),
    default: _withCtx(() => [
      (_ctx.dialog)
        ? (_openBlock(), _createBlock(_component_v_form, {
            key: 0,
            ref: "myForm",
            "lazy-validation": "",
            onSubmit: _cache[6] || (_cache[6] = ($event: any) => (_ctx.submit($event)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, _normalizeProps(_guardReactiveProps({ loading: _ctx.submitted, disabled: _ctx.submitted })), {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.articleObject
              ? _ctx.$tc("COMPONENTS.POSITION.POSITIONARTICLEDIALOG.ARTICLE.CHANGE")
              : _ctx.$tc("COMPONENTS.POSITION.POSITIONARTICLEDIALOG.ARTICLE.ADD")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_text, { class: "card1" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: "8" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_select, {
                                modelValue: _ctx.form.articleType,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.articleType) = $event)),
                                items: _ctx.articleTypes,
                                label: _ctx.$tc('COMPONENTS.POSITION.POSITIONARTICLEDIALOG.TYPE'),
                                variant: "outlined",
                                density: "compact",
                                "hide-details": "",
                                "item-value": "value",
                                "item-title": "title"
                              }, null, 8, ["modelValue", "items", "label"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { cols: "4" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                modelValue: _ctx.form.quantity,
                                "onUpdate:modelValue": [
                                  _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.quantity) = $event)),
                                  _ctx.resetValidation
                                ],
                                label: _ctx.$tc('COMPONENTS.POSITION.POSITIONARTICLEDIALOG.QUANTITY'),
                                variant: "outlined",
                                density: "compact",
                                rules: [
                  (v) => !!v || _ctx.$tc('COMPONENTS.POSITION.POSITIONARTICLEDIALOG.RULES.NOT_EMPTY'),
                  (v) =>
                    /^\d+([.,]\d+)?$/.test(v) ||
                    _ctx.$tc('COMPONENTS.POSITION.POSITIONARTICLEDIALOG.RULES.NOT_VALID'),
                ]
                              }, null, 8, ["modelValue", "label", "rules", "onUpdate:modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_dialog, {
                                modelValue: _ctx.dialogChange,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dialogChange) = $event)),
                                scrollable: "",
                                "max-width": "80vw"
                              }, {
                                activator: _withCtx(({ props }) => [
                                  _createVNode(_component_v_btn, _mergeProps(props, {
                                    size: "small",
                                    class: "dark-btn mb-3 px-1 mr-3",
                                    style: {"min-width":"0"}
                                  }), {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_icon, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(" mdi-magnify ")
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 2
                                  }, 1040)
                                ]),
                                default: _withCtx(() => [
                                  (_ctx.dialogChange)
                                    ? (_openBlock(), _createBlock(_component_ChangeArticleDialog, {
                                        key: 0,
                                        position: _ctx.position,
                                        "article-id": _ctx.articleObject ? _ctx.articleObject.article.id : undefined,
                                        onSuccess: _ctx.articleChangedSuccessfully,
                                        onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateArticle($event))),
                                        onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.dialogChange = false))
                                      }, null, 8, ["position", "article-id", "onSuccess"]))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 1
                              }, 8, ["modelValue"]),
                              _createVNode(_component_v_text_field, {
                                modelValue: _ctx.form.artikelnummer,
                                "onUpdate:modelValue": [
                                  _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.artikelnummer) = $event)),
                                  _ctx.resetValidation
                                ],
                                label: _ctx.$tc('COMPONENTS.POSITION.POSITIONARTICLEDIALOG.ARTICLE.TITLE'),
                                variant: "outlined",
                                density: "compact",
                                rules: [
                  (v) => !!v || _ctx.$tc('COMPONENTS.POSITION.POSITIONARTICLEDIALOG.RULES.NOT_EMPTY'),
                  (v) =>
                    /^\d{6}$/.test(v) ||
                    _ctx.$tc('COMPONENTS.POSITION.POSITIONARTICLEDIALOG.RULES.NO_ARTICLENUMBER'),
                ]
                              }, null, 8, ["modelValue", "label", "rules", "onUpdate:modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_divider),
                  _createVNode(_component_v_card_actions, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_spacer),
                      _createVNode(_component_v_btn, {
                        variant: "outlined",
                        onClick: _ctx.close
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.POSITION.POSITIONARTICLEDIALOG.CANCEL")), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"]),
                      _createVNode(_component_v_btn, {
                        elevation: "0",
                        type: "submit",
                        class: "dark-btn"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.articleObject
                ? _ctx.$tc("COMPONENTS.POSITION.POSITIONARTICLEDIALOG.CHANGE")
                : _ctx.$tc("COMPONENTS.POSITION.POSITIONARTICLEDIALOG.ADD")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 16)
            ]),
            _: 1
          }, 512))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}