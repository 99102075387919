export class Article {
  artikelnummer = 0;
  bezeichnungProduktname = '';
  angebotstext = '';
  groupScore = '';
  groupMatchingScore = '';
  articleScore = '';
  score = '';

  constructor(articleDto?: ArticleDto) {
    if(articleDto) {
      this.artikelnummer = articleDto.artikelnummer;
      this.bezeichnungProduktname = articleDto.bezeichnungProduktname;
      this.angebotstext = articleDto.angebotstext;
      // this.score = parseFloat(articleDto.groupScore + articleDto.groupMatchingScore).toFixed(2) + " (+" + articleDto.articleScore + ")";
      this.score = parseFloat(articleDto.groupScore + articleDto.groupMatchingScore).toFixed(2);
    }
  }
}

export interface ArticleDto {
  artikelnummer: number;
  bezeichnungProduktname: string;
  angebotstext: string;
  groupScore: string;
  groupMatchingScore: string;
  articleScore: string;
  score: string;
}
