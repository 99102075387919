export class RootState {
  public message: string;
  public alert: any;
  public errorAlert: any;
  public loading: boolean;

  constructor() {
    this.message = "";
    this.alert = { type: undefined, message: undefined, active: false };
    this.errorAlert = { type: "error", message: "Ein Fehler ist aufgetreten!" };
    this.loading = false;
  }

  setMessage(value: string) {
    this.message = value;
  }

  activateAlert(value: any) {
    this.alert.type = value.type;
    this.alert.message = value.message;
  }

}
