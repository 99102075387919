import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f94a672"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = { class: "card__title" }
const _hoisted_3 = { class: "card__title" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "card__title" }
const _hoisted_7 = { class: "d-flex" }
const _hoisted_8 = { class: "card__title" }
const _hoisted_9 = { class: "card__title" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "card__title" }
const _hoisted_13 = { class: "d-flex" }
const _hoisted_14 = { class: "card__title" }
const _hoisted_15 = { class: "card__title" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { class: "card__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_BidderSelection = _resolveComponent("BidderSelection")!
  const _component_SelectionDialog = _resolveComponent("SelectionDialog")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_PlannerSelection = _resolveComponent("PlannerSelection")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_responsive = _resolveComponent("v-responsive")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "pa-0 mr-2 mb-4" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_responsive, { class: "overflow-y-auto fill-height pa-0 ma-0" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, {
            class: "main-card",
            variant: "flat"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.FURTHER_CONTACTS")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_container, {
                    fluid: "",
                    class: "px-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, null, {
                            default: _withCtx(() => [
                              (_ctx.masterData && _ctx.masterData.bidder.length > 1)
                                ? (_openBlock(), _createBlock(_component_v_expansion_panels, { key: 0 }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_expansion_panel, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_expansion_panel_title, null, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.BIDDERLIST")), 1)
                                            ]),
                                            _: 1
                                          }),
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.masterData.bidder, (bidder) => {
                                            return (_openBlock(), _createBlock(_component_v_expansion_panel_text, {
                                              key: bidder.kundennummer
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_row, null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_col, { lg: "5" }, {
                                                      default: _withCtx(() => [
                                                        _createElementVNode("div", _hoisted_1, [
                                                          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$tc(
                                  "COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.BIDDER"
                                )), 1),
                                                          _createElementVNode("span", null, [
                                                            _createVNode(_component_SelectionDialog, {
                                                              "show-stepper": false,
                                                              "step-one-title": 
                                  _ctx.$tc(
                                    'COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.BIDDER'
                                  )
                                ,
                                                              onItemOneSelected: _ctx.onBidderSelected,
                                                              onSubmit: _ctx.submitBidder
                                                            }, {
                                                              activator: _withCtx(({ props }) => [
                                                                _createElementVNode("div", _normalizeProps(_guardReactiveProps(props)), [
                                                                  _createVNode(_component_v_icon, {
                                                                    class: "px-1",
                                                                    onClick: ($event: any) => ({})
                                                                  }, {
                                                                    default: _withCtx(() => [
                                                                      _createTextVNode("mdi-card-search")
                                                                    ]),
                                                                    _: 1
                                                                  })
                                                                ], 16)
                                                              ]),
                                                              stepOne: _withCtx(({ onItemSelected }) => [
                                                                _createVNode(_component_BidderSelection, {
                                                                  "entity-name": 
                                      _ctx.$tc(
                                        'COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.BIDDER'
                                      )
                                    ,
                                                                  onItemSelected: onItemSelected
                                                                }, null, 8, ["entity-name", "onItemSelected"])
                                                              ]),
                                                              _: 1
                                                            }, 8, ["step-one-title", "onItemOneSelected", "onSubmit"])
                                                          ])
                                                        ]),
                                                        _createTextVNode(" " + _toDisplayString(bidder.name ? bidder.name : "-"), 1)
                                                      ]),
                                                      _: 2
                                                    }, 1024),
                                                    _createVNode(_component_v_col, { lg: "4" }, {
                                                      default: _withCtx(() => [
                                                        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$tc(
                                "COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.BIDDER_ADDRESS"
                              )), 1),
                                                        (bidder.strasse)
                                                          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                                              _createElementVNode("div", null, _toDisplayString(bidder.strasse), 1),
                                                              _createElementVNode("div", null, _toDisplayString(bidder.plz) + " " + _toDisplayString(bidder.ort), 1)
                                                            ]))
                                                          : (_openBlock(), _createElementBlock("div", _hoisted_5, " - "))
                                                      ]),
                                                      _: 2
                                                    }, 1024),
                                                    _createVNode(_component_v_col, { lg: "auto" }, {
                                                      default: _withCtx(() => [
                                                        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$tc(
                                "COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.BIDDER_ID"
                              )), 1),
                                                        _createTextVNode(" " + _toDisplayString(bidder.kundennummer ? bidder.kundennummer : "-"), 1)
                                                      ]),
                                                      _: 2
                                                    }, 1024)
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ]),
                                              _: 2
                                            }, 1024))
                                          }), 128))
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }))
                                : (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_col, null, {
                                        default: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_7, [
                                            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.BIDDER")), 1),
                                            _createElementVNode("span", null, [
                                              _createVNode(_component_SelectionDialog, {
                                                "show-stepper": false,
                                                "step-one-title": 
                            _ctx.$tc('COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.BIDDER')
                          ,
                                                onItemOneSelected: _ctx.onBidderSelected,
                                                onSubmit: _ctx.submitBidder
                                              }, {
                                                activator: _withCtx(({ props }) => [
                                                  _createElementVNode("div", _normalizeProps(_guardReactiveProps(props)), [
                                                    _createVNode(_component_v_icon, {
                                                      class: "px-1",
                                                      onClick: ($event: any) => ({})
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createTextVNode("mdi-card-search")
                                                      ]),
                                                      _: 1
                                                    })
                                                  ], 16)
                                                ]),
                                                stepOne: _withCtx(({ onItemSelected }) => [
                                                  _createVNode(_component_BidderSelection, {
                                                    "entity-name": 
                                _ctx.$tc(
                                  'COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.BIDDER'
                                )
                              ,
                                                    onItemSelected: onItemSelected
                                                  }, null, 8, ["entity-name", "onItemSelected"])
                                                ]),
                                                _: 1
                                              }, 8, ["step-one-title", "onItemOneSelected", "onSubmit"])
                                            ])
                                          ]),
                                          _createTextVNode(" " + _toDisplayString(_ctx.singleBidder && _ctx.singleBidder.name ? _ctx.singleBidder.name : "-"), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, null, {
                                        default: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$tc(
                          "COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.BIDDER_ADDRESS"
                        )), 1),
                                          (_ctx.singleBidder)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                                _createElementVNode("div", null, _toDisplayString(_ctx.singleBidder.strasse || "-"), 1),
                                                _createElementVNode("div", null, _toDisplayString(_ctx.singleBidder.plz || "-") + " " + _toDisplayString(_ctx.singleBidder.ort || "-"), 1)
                                              ]))
                                            : (_openBlock(), _createElementBlock("div", _hoisted_11, " - "))
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, null, {
                                        default: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.BIDDER_ID")), 1),
                                          _createTextVNode(" " + _toDisplayString(_ctx.singleBidder ? _ctx.singleBidder.kundennummer : "-"), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, null, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_13, [
                                _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.PLANNER")), 1),
                                _createElementVNode("span", null, [
                                  _createVNode(_component_SelectionDialog, {
                                    "show-stepper": false,
                                    "planner-mode": true,
                                    "step-one-title": 
                        _ctx.$tc('COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.PLANNER')
                      ,
                                    onItemOneSelected: _ctx.onPlannerSelected,
                                    onSubmit: _ctx.submitPlanner
                                  }, {
                                    activator: _withCtx(({ props }) => [
                                      _createElementVNode("div", _normalizeProps(_guardReactiveProps(props)), [
                                        _createVNode(_component_v_icon, {
                                          class: "px-1",
                                          onClick: ($event: any) => ({})
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode("mdi-card-search")
                                          ]),
                                          _: 1
                                        })
                                      ], 16)
                                    ]),
                                    stepOne: _withCtx(({ onItemSelected }) => [
                                      _createVNode(_component_PlannerSelection, {
                                        "initial-value": _ctx.planner?.kundennummer,
                                        onItemSelected: onItemSelected
                                      }, null, 8, ["initial-value", "onItemSelected"])
                                    ]),
                                    _: 1
                                  }, 8, ["step-one-title", "onItemOneSelected", "onSubmit"])
                                ])
                              ]),
                              _createTextVNode(" " + _toDisplayString(_ctx.planner?.name ? _ctx.planner.name : "-"), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, null, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.PLANNER_ADDRESS")), 1),
                              (_ctx.planner?.strasse)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                    _createElementVNode("div", null, _toDisplayString(_ctx.planner.strasse), 1),
                                    _createElementVNode("div", null, _toDisplayString(_ctx.planner.plz) + " " + _toDisplayString(_ctx.planner.ort), 1)
                                  ]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_17, " - "))
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, null, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.FURTHER_CONTACT.PLANNER_ID")), 1),
                              _createTextVNode(" " + _toDisplayString(_ctx.planner?.kundennummer ? _ctx.planner.kundennummer : "-"), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}