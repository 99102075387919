export class BidderView implements BidderDto {
  kundennummer = '';
  name = '';
  strasse = '';
  plz = '';
  ort = '';

  constructor(bidderDto?: BidderDto) {
    if (bidderDto) {
      this.kundennummer = bidderDto.kundennummer;
      this.name = bidderDto.name;
      this.strasse = bidderDto.strasse;
      this.plz = bidderDto.plz;
      this.ort = bidderDto.ort;
    }
  }
}

export interface BidderDto {
  kundennummer: string;
  name: string;
  strasse: string;
  plz: string;
  ort: string;
}