<template>
  <v-dialog v-model="dialog" width="350">
    <template #activator="{ props }">
      <v-btn
        v-if="articleObject"
        v-bind="props"
        class="dark-btn mb-3 px-1 mr-3"
        style="min-width: 0"
        size="small"
        icon="mdi-pencil"
        rounded
      />
      <v-btn v-else variant="outlined" v-bind="props" prepend-icon="mdi-plus">
        {{ $tc("COMPONENTS.POSITION.POSITIONARTICLEDIALOG.ARTICLE.ADD") }}
      </v-btn>
    </template>

    <v-form v-if="dialog" ref="myForm" lazy-validation @submit="submit($event)">
      <v-card v-bind="{ loading: submitted, disabled: submitted }">
        <v-card-title>
          {{
            articleObject
              ? $tc("COMPONENTS.POSITION.POSITIONARTICLEDIALOG.ARTICLE.CHANGE")
              : $tc("COMPONENTS.POSITION.POSITIONARTICLEDIALOG.ARTICLE.ADD")
          }}
        </v-card-title>
        <v-card-text class="card1">
          <v-row>
            <v-col cols="8">
              <v-select
                v-model="form.articleType"
                :items="articleTypes"
                :label="$tc('COMPONENTS.POSITION.POSITIONARTICLEDIALOG.TYPE')"
                variant="outlined"
                density="compact"
                hide-details
                item-value="value"
                item-title="title"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="form.quantity"
                :label="$tc('COMPONENTS.POSITION.POSITIONARTICLEDIALOG.QUANTITY')"
                variant="outlined"
                density="compact"
                :rules="[
                  (v) => !!v || $tc('COMPONENTS.POSITION.POSITIONARTICLEDIALOG.RULES.NOT_EMPTY'),
                  (v) =>
                    /^\d+([.,]\d+)?$/.test(v) ||
                    $tc('COMPONENTS.POSITION.POSITIONARTICLEDIALOG.RULES.NOT_VALID'),
                ]"
                @update:model-value="resetValidation"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-dialog v-model="dialogChange" scrollable max-width="80vw">
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    size="small"
                    class="dark-btn mb-3 px-1 mr-3"
                    style="min-width: 0"
                  >
                    <v-icon> mdi-magnify </v-icon>
                  </v-btn>
                </template>
                <ChangeArticleDialog
                  v-if="dialogChange"
                  :position="position"
                  :article-id="articleObject ? articleObject.article.id : undefined"
                  @success="articleChangedSuccessfully"
                  @change="updateArticle($event)"
                  @close="dialogChange = false"
                />
              </v-dialog>
              <v-text-field
                v-model="form.artikelnummer"
                :label="$tc('COMPONENTS.POSITION.POSITIONARTICLEDIALOG.ARTICLE.TITLE')"
                variant="outlined"
                density="compact"
                :rules="[
                  (v) => !!v || $tc('COMPONENTS.POSITION.POSITIONARTICLEDIALOG.RULES.NOT_EMPTY'),
                  (v) =>
                    /^\d{6}$/.test(v) ||
                    $tc('COMPONENTS.POSITION.POSITIONARTICLEDIALOG.RULES.NO_ARTICLENUMBER'),
                ]"
                @update:model-value="resetValidation"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn variant="outlined" @click="close">
            {{ $tc("COMPONENTS.POSITION.POSITIONARTICLEDIALOG.CANCEL") }}
          </v-btn>
          <v-btn elevation="0" type="submit" class="dark-btn">
            {{
              articleObject
                ? $tc("COMPONENTS.POSITION.POSITIONARTICLEDIALOG.CHANGE")
                : $tc("COMPONENTS.POSITION.POSITIONARTICLEDIALOG.ADD")
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import ChangeArticleDialog from "./ChangeArticleDialog.vue";
import axios from "axios";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  components: {
    ChangeArticleDialog,
  },
    props: {
        position: {
            type: Object as PropType<any>
        },
        articleObject: {
            type: Object as PropType<any>
        }
    },
    data() {
        const articleTypes: any[] = [
                {
                  value: "STANDARD",
                  title: this.$tc("COMPONENTS.POSITION.POSITIONARTICLEDIALOG.POSITION.STANDARD"),
                },
                {
                  value: "ALTERNATIV",
                  title: this.$tc("COMPONENTS.POSITION.POSITIONARTICLEDIALOG.POSITION.ALTERNATIVE"),
                },
                {
                  value: "BEDARF",
                  title: this.$tc("COMPONENTS.POSITION.POSITIONARTICLEDIALOG.POSITION.REQUIREMENT"),
                },
                {
                  value: "EVENTUAL",
                  title: this.$tc("COMPONENTS.POSITION.POSITIONARTICLEDIALOG.POSITION.EVENTUAL"),
                },
              ];
        const form: any = {
                articleType: null,
                artikelnummer: null,
                quantity: null,
              };
        const changeSuccessAlert: any = {
                type: "success",
                message: this.$tc("COMPONENTS.POSITION.POSITIONARTICLEDIALOG.MESSAGE.SUCCESS_CHANGE"),
              };
        const addSuccessAlert: any = {
                type: "success",
                message: this.$tc("COMPONENTS.POSITION.POSITIONARTICLEDIALOG.MESSAGE.SUCCESS_ADD"),
              };

        const localArticleObject: any = this.articleObject;
        const localPosition: any = this.position;

        return {
            dialog: false,
            dialogChange: false,
            submitted: false,
            addSuccessAlert,
            changeSuccessAlert,
            form,
            articleTypes,
            localArticleObject,
            localPosition,
        };
    },
    computed: {
        quotationDetail(): any {
            return this.$store.getters["quotation/quotationDetail"];
        }
    },
    watch: {
        "position": [{
            handler: "positionChanged",
            immediate: true,
        }],
        "articleObject": [{
            handler: "articleObjectChanged",
            immediate: true,
        }]
    },
    mounted() {
        this.resetForm();
    },
    methods: {
        submit(event: any) {
            event.preventDefault();
                const form = this.$refs.myForm as any;

                if (!form.validate()) return;
                // quantity muss ein String sein, damit das Komma durch einen Punkt ersetzt werden kann
                const quantityString = this.form.quantity + "";
                const params = {
                  articleNumber: this.form.artikelnummer,
                  positionId: this.localPosition.id,
                  articleType: this.form.articleType,
                  quantity: quantityString.replaceAll(",", "."),
                };

                this.updateArticleObject();

                if (this.localArticleObject) {
                  axios
                    .post(`/position/article/${this.localArticleObject.id}/update`, null, { params })
                    .then((response) => {
                      if (response.status === 200) {
                        this.$store.commit("activateAlert", this.changeSuccessAlert);
                        this.submitted = false;
                        this.emitResponse(response);
                        this.$emit("change", response.data);
                        this.close();
                        this.$store.dispatch("quotation/loadActionLog", this.quotationDetail.id);
                        //this.$parent.articleChangedSuccessfully(response);          } else {
                        this.handleError();
                      }
                    })
                    .catch(() => {
                      this.handleError();
                    });
                } else {
                  axios
                    .post("/position/article/add", null, { params })
                    .then((response) => {
                      if (response && response.status === 200) {
                        this.$store.commit("activateAlert", this.addSuccessAlert);
                        this.submitted = false;
                        this.resetForm();
                        this.$emit("articleAdded", response.data);
                        this.close();
                        this.$store.dispatch("quotation/loadActionLog", this.quotationDetail.id);
                      }
                    })
                    .catch(() => this.handleError());
                }
        },
        updateArticleObject() {
            //Update Values, otherwise the old values will be shown again, if the Pop up window is opened again
            if (this.localArticleObject && this.form) {
              this.localArticleObject.type = this.form.articleType;
              this.localArticleObject.article.artikelnummer = this.form.artikelnummer;
              this.localArticleObject.quantity = this.form.quantity;
            }
        },
        handleError() {
            this.submitted = false;
        },
        emitResponse(response: any) {
            this.$emit("success", response);
        },
        resetValidation() {
            (this.$refs.myForm as any).resetValidation();
        },
        resetForm() {
            if (this.localArticleObject) {
              this.form.articleType = this.localArticleObject.type;
              this.form.artikelnummer = this.localArticleObject.article.artikelnummer;
              this.form.quantity = this.localArticleObject.quantity;
            } else {
              this.form.articleType = "STANDARD";
              this.form.artikelnummer = null;
              if (this.localPosition && this.localPosition.positionQuantity) {
                this.form.quantity = this.localPosition.positionQuantity
                  .replaceAll(/\.(?=\d{3})/g, "")
                  .replaceAll(".", ",");
              } else {
                this.localPosition.positionQuantity = null;
              }
            }
        },
        articleChangedSuccessfully() {
            console.log("article changed");
        },
        updateArticle(article: any) {
            this.form.artikelnummer = article.artikelnummer;
        },
        close() {
            this.resetForm();
            this.dialog = false;
        },
        positionChanged(newValue: any) {
            this.localPosition = newValue;
        },
        articleObjectChanged(newValue: any) {
            this.localArticleObject = newValue;
        }
    },
})

</script>

<style lang="scss" scoped>
.card1 {
  flex-grow: 1;
}

.button-container {
  flex-grow: 0;
}
</style>
