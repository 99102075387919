export class PlannerView implements PlannerDto {
  kundennummer = '';
  name = '';
  strasse = '';
  plz = '';
  ort = '';

  constructor(plannerDto?: PlannerDto) {
    if (plannerDto) {
      this.kundennummer = plannerDto.kundennummer;
      this.name = plannerDto.name;
      this.strasse = plannerDto.strasse;
      this.plz = plannerDto.plz;
      this.ort = plannerDto.ort;
    }
  }
}

export interface PlannerDto {
  kundennummer: string;
  name: string;
  strasse: string;
  plz: string;
  ort: string;
}