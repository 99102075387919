import { BidderDto } from "./Bidder.model";
import { BillOfQuantityDto, BillOfQuantityView } from "./BillOfQuantity.model";
import { ClientDto, ClientView } from "./Client.model";
import { ContactDto, ContactView } from "./Contact.model";
import { OpportunityDto, OpportunityView } from "./Opportunity.model";
import { PlannerDto, PlannerView } from "./Planner.mode";
import { RecipientDto, RecipientView } from "./Recipient.model";
import { SalesDto, SalesView } from "./Sales.model";

export class MasterdataView {
  client: ClientView = new ClientView();
  recipient: RecipientView = new RecipientView();
  contact: ContactView = new ContactView();
  bidder: BidderDto[] = [];
  planner: PlannerView = new PlannerView();
  opportunity: OpportunityView = new OpportunityView();
  billOfQuantityStammdaten: BillOfQuantityView = new BillOfQuantityView();
  salesArea: SalesView = new SalesView();

  constructor(dto?: MasterdataDto) {
    if (dto) {
      if (dto.client) this.client = new ClientView(dto.client);
      if (dto.recipient) this.recipient = new RecipientView(dto.recipient);
      if (dto.contact) this.contact = new ContactView(dto.contact);
      if (dto.bidder) {
        this.bidder = dto.bidder;
      }
      if (dto.planner) this.planner = new PlannerView(dto.planner);
      if (dto.opportunity) this.opportunity = new OpportunityView(dto.opportunity);
      if (dto.billOfQuantityStammdaten)
        this.billOfQuantityStammdaten = new BillOfQuantityView(dto.billOfQuantityStammdaten);
      if (dto.salesArea) this.salesArea = new SalesView(dto.salesArea);
    }
  }

  setClient(clientDto: ClientDto) {
    this.client = new ClientView(clientDto);
  }
}

export interface MasterdataDto {
  client: ClientDto;
  recipient: RecipientDto;
  contact: ContactDto;
  bidder: BidderDto[];
  planner: PlannerDto;
  opportunity: OpportunityDto;
  billOfQuantityStammdaten: BillOfQuantityDto;
  salesArea: SalesDto;
}
