<template>
  <div class="settings-container">
    <v-container class="keyword-manager-container ml-0 mr-3">
      <v-row class="align-center mb-2">
        <v-col>
          <h2>{{ $tc("COMPONENTS.SETTINGS.KEYWORDMANAGER.MANAGE_KEYWORDS") }}</h2>
        </v-col>
        <v-col cols="auto">
          <v-btn
            @click="addKeywordClicked()"
            class="dark-btn"
          >
            <v-icon start>mdi-plus</v-icon>
            {{ $tc(`COMPONENTS.SETTINGS.KEYWORDMANAGER.ADD`) }}
          </v-btn>
        </v-col>
      </v-row>

      <BoqDataTable
        class="table-container overflow-y-auto"
        :headers="headers"
        :items="keywords"
        v-model:get-config="getConfig"
        key="keyword"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex">
            <ActionButton
              :tooltip="$tc('COMPONENTS.SETTINGS.KEYWORDMANAGER.EDIT')"
              icon="mdi-pencil"
              @buttonClicked="
                dialog = true;
                addMode = false;
                keyword = item;
              "
            />

            <ActionButton
              :tooltip="$tc('COMPONENTS.SETTINGS.KEYWORDMANAGER.DELETE')"
              icon="mdi-delete"
              @buttonClicked="deleteKeyword(item)"
            />
          </div>
        </template>
        <template v-slot:[`item.marketId`]="{ item }">
          {{ getCountryName(item.marketId, marketList) }}
        </template>
      </BoqDataTable>
      <v-dialog v-model="dialog" max-width="400px">
        <KeywordDialog
          v-if="dialog"
          :addMode="addMode"
          :keyword="keyword"
          @buttonClicked="onButtonClicked"
        />
      </v-dialog>
    </v-container>

    <v-container class="product-hierarchy-container ml-0">
      <v-row class="align-center mb-2">
        <v-col>
          <h2>{{ $tc("COMPONENTS.SETTINGS.KEYWORDMANAGER.SELECT_PRODUCTGROUP") }}</h2>
        </v-col>
      </v-row>
      <v-select
        v-model="value"
        :items="productHierarchyLevels"
        @update:model-value="saveProductHierarchy"
        variant="outlined"
        density="compact"
      ></v-select>
    </v-container>
  </div>
</template>

<script lang="ts">
import BoqDataTable from "@/shared/components/data-table.component.vue";
import ActionButton from "@/shared/components/action-button.component.vue";
import KeywordDialog from "@/components/settings/keyword-dialog.component.vue";
import { Keyword } from "@/core/models/keyword/Keyword.model";
import { BoqTableHeader } from "@/core/interfaces/boq-table-header.interface";
import GetConfig from "@/core/services/base/get.config";
import { User } from "@/core/models/user/User.model";
import * as MarketService from "@/core/services/market/market.service";
import { Market } from "@/core/models/market/market.model";
import { defineComponent, ref } from "vue";

export default defineComponent({
  components: {
    BoqDataTable,
    ActionButton,
    KeywordDialog,
  },
    data() {
        const headers: BoqTableHeader[] = [
                {
                  title: this.$tc("COMPONENTS.SETTINGS.KEYWORDMANAGER.KEYWORD"),
                  value: "value",
                  filterable: true,
                },
                {
                  title: this.$tc("COMPONENTS.SETTINGS.KEYWORDMANAGER.COUNTRY"),
                  value: "marketId",
                  filterable: false,
                  type: "select",
                },
                {
                  title: this.$tc("COMPONENTS.SETTINGS.KEYWORDMANAGER.ACTIONS"),
                  value: "actions",
                  filterable: false,
                  width: 110,
                },
              ];
        const value = ref<number | null>(null);
        const productHierarchyLevels: any[] = [
                { value: 1, title: this.$tc("COMPONENTS.SETTINGS.KEYWORDMANAGER.HIERARCHYLEVEL.1") },
                { value: 2, title: this.$tc("COMPONENTS.SETTINGS.KEYWORDMANAGER.HIERARCHYLEVEL.2") },
                { value: 3, title: this.$tc("COMPONENTS.SETTINGS.KEYWORDMANAGER.HIERARCHYLEVEL.3") },
                { value: 4, title: this.$tc("COMPONENTS.SETTINGS.KEYWORDMANAGER.HIERARCHYLEVEL.4") },
                { value: 5, title: this.$tc("COMPONENTS.SETTINGS.KEYWORDMANAGER.HIERARCHYLEVEL.5") },
              ];
        const keyword: Keyword = new Keyword();
        const getConfig: GetConfig = GetConfig.default();

        return {
            getConfig,
            dialog: false,
            addMode: true,
            keyword,
            productHierarchyLevels,
            value,
            headers
        };
    },
    async mounted() {
        if (this.$store.state.settings.keywords.length === 0) {
              await this.loadKeywords();
            }
            await this.loadProductHierarchy();
            this.value = this.productHierarchy;

            this.headers[1].filterable = this.currentUser && this.currentUser.role == "ROLE_ADMIN";
    },
    unmounted() {
        this.$store.dispatch("settings/clearKeywords");
    },
    methods: {
        getCountryName(marketId: number, marketList: Market[]) {
            return MarketService.getCountryName(marketId, marketList);
        },
        loadKeywords() {
            this.$store.dispatch("settings/loadKeywords");
        },
        loadProductHierarchy() {
            return this.$store.dispatch("settings/loadProductHierarchy");
        },
        saveProductHierarchy() {
            this.$store.dispatch("settings/updateProductHierarchy", this.value);
        },
        async filterKeywords() {
            const response = await this.$store.dispatch("settings/filterKeywords", this.getConfig);
            return response;
        },
        async addKeyword(newKeyword: { value: string; marketId?: number }) {
            await this.$store.dispatch("settings/addKeyword", newKeyword);
            await this.filterKeywords();
        },
        async updateKeyword(keyword: Keyword) {
            await this.$store.dispatch("settings/updateKeyword", keyword);
            await this.filterKeywords();
        },
        async deleteKeyword(keyword: Keyword) {
            await this.$store.dispatch("settings/deleteKeyword", keyword);
            await this.filterKeywords();
        },
        onButtonClicked(keyword: Keyword) {
            this.addMode ? this.addKeyword(keyword) : this.updateKeyword(keyword);

                this.addMode = true;

                this.clearKeyword();
                this.close();
        },
        clearKeyword() {
            this.keyword = new Keyword();
        },
        close() {
            this.dialog = false;
        },
        async getConfigChanged() {
            try {
              await this.filterKeywords();
            } catch (err) {
              console.error(err);
            }
        },
        addKeywordClicked() {
            this.dialog = true;
            this.addMode = true;
            this.keyword = new Keyword();
        }
    },
    watch: {
        "getConfig": [{
            handler: "getConfigChanged"
        }]
    },
    computed: {
        keywords(): Keyword[] {
            return this.$store.getters["settings/keywords"];
        },
        productHierarchy(): number | null {
            return this.$store.getters["settings/productHierarchy"];
        },
        currentUser(): User {
            return this.$store.getters["user/currentUser"];
        },
        marketList(): Market[] {
            return this.$store.getters["market/market"];
        }
    }
})

</script>

<style lang="scss" scoped>
.settings-container {
  width: 60%;
  height: 100%;
  display: flex;
}

.keyword-manager-container {
  width: calc(2/3*100%);
}

.product-hierarchy-container {
  width: calc(1/3*100%);
}

.table-container {
  max-height: 75vh;
}
</style>
