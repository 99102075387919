import { ActionTree } from 'vuex';
import { RootState } from '~core/store/state';

export const actions: ActionTree<RootState, RootState> = {
  setMessage(context, payload: string) {
    context.commit('setMessage', payload);
  },
  triggerAlert(context, payload: any) {
    context.commit('activateAlert', payload)
  },
  setLoading(context, isLoading: boolean) {
    context.commit('setLoading', isLoading);
  }
};
