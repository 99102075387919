<template>
  <v-dialog v-model="showImportDialog" width="500">
    <v-form ref="importForm" v-if="showImportDialog" @submit="submit($event)">
      <v-card>
        <v-card-text>
          <v-file-input
            :label="$tc('COMPONENTS.QUOTATION.IMPORTQUOTATIONDIALOG.UPLOAD_FILE')"
            variant="outlined"
            accept=".xls,.xlsx"
            show-size
            density="compact"
            @update:model-value="onFileSelected"
          />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn variant="outlined" @click="showImportDialog = false">Abbrechen</v-btn>
          <v-btn class="dark-btn" elevation="0" type="submit">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script setup lang="ts">
import axios, { AxiosResponse } from "axios";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

const props = defineProps<{
  boqId: number
}>();

const emit = defineEmits<{
  (e: 'success'): void,
}>()

const { t } = useI18n()
const { commit } = useStore()

const selectedFile = ref<File | null>(null);
const submitted = ref(false);
const showImportDialog = ref(false);

const importForm = ref<HTMLFormElement>()

const successAlert: any = {
  type: "success",
  message: t("COMPONENTS.QUOTATION.IMPORTQUOTATIONDIALOG.SUCCESS"),
};

// const errorAlert: any = {
//   type: "error",
//   message: t("COMPONENTS.QUOTATION.IMPORTQUOTATIONDIALOG.ERROR"),
// };

function submit(event: any) {
  event.preventDefault();
  if (selectedFile.value) {
    submitted.value = true;
    const formData = new FormData();
    formData.append("file", selectedFile.value, selectedFile.value.name);
    formData.append("boqId", String(props.boqId));
    axios
      .post("/file-import/excel/new-quotation", formData)
      .then((response) => handleResponse(response))
      .catch((response) => {
        handleError(response);
      });
  }
}

function handleResponse(response: any) {
  if (response.status === 201) {
    commit("activateAlert", successAlert);
    showImportDialog.value = false;
    emit("success");
    clearForm();
  } else {
    handleError(response);
  }
}

function handleError(response: AxiosResponse) {
  submitted.value = false;
  commit("activateAlert", response.data.error);
}

function clearForm() {
  submitted.value = false;
  importForm.value?.reset();
}

function onFileSelected(files: File[]) {
  if (files.length > 0) {
    selectedFile.value = files[0];
  }
}
</script>
