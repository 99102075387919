<template>
  <v-container class="full-height pa-0 ma-0">
    <v-card class="main-card d-flex flex-column full-height">
      <v-card-title v-if="!filterView" class="pdf-card-title">
        <v-row>
          <v-col class="py-0" align-self="center">
            PDF-Viewer
            <ActionButton icon="mdi-refresh" tooltip="Refresh" @button-clicked="loadPdf()" />
            <ActionButton
              icon="mdi-download"
              :tooltip="$tc('COMPONENTS.PDF_VIEWER.DOWNLOAD_PDF')"
              @button-clicked="downloadPdf"
            />
          </v-col>
          <v-col v-if="currentPage !== -1" class="py-0" align-self="center">
            <div class="font-weight-light" size="sm">
              Seite {{ currentPage + 1 }} von {{ numPages }}
            </div>
          </v-col>
          <v-col cols="auto" class="py-0" align-self="center">
            <v-switch v-model="showOverlay" label="Overlay" color="primary" hide-details />
          </v-col>
          <v-col cols="auto" class="py-0" align-self="center">
            <v-switch v-model="showSpecificOverlay" label="Detail" color="primary" hide-details />
          </v-col>
        </v-row>
      </v-card-title>
      <v-responsive
        ref="pdfcontainer"
        class="pdf-container pdf-container-responsive full-height overflow-y-auto"
      >
        <div v-if="!pdfMissing">
          <v-skeleton-loader
            v-if="numPages === 0"
            class="pdf ma-3 elevation-8"
            type="table"
          />
          <v-container
            v-for="page in numPages"
            :key="page"
            class="pdf-container pa-0"
            @click="onPdfClick($event, page)"
          >
            <VuePdf
                v-if="src"
                :id="`pdf-page-${page}`"
                ref="pdfpage"
                :key="page"
                v-intersect="onPdfIntersect"
                class="pdf-page ma-3"
                :src="src"
                :page="page"
            />

            <template v-if="overlayLoaded">
              <div
                v-for="box in overlay.boxes[page - 1]"
                :key="`${box.value}${box.xstart}${box.xend}${box.ystart}${box.yend}`"
              >
                <slot
                  name="scrolling-overlay"
                  :box="box"
                  :make-position-id="makePositionId"
                  :transform="transform"
                  :get-overlay-color="getOverlayColor"
                />
              </div>
            </template>

            <div v-if="showOverlay && overlayLoaded && !filterView">
              <div v-if="intersectingPdfs.includes(`pdf-page-${page}`)">
                <v-tooltip
                  v-for="box in overlay.boxes[page - 1]"
                  :key="`${box.value}${box.xstart}${box.xend}${box.ystart}${box.yend}`"
                  location="bottom"
                >
                  <template #activator="{ props, isActive }">
                      <v-sheet
                        :id="makePositionId(box.value)"
                        class="my-sheet mx-3"
                        :class="{ 'on-hover': isActive }"
                        :elevation="isActive ? 24 : 2"
                        :style="transform(box)"
                        :width="box.xend - box.xstart"
                        :height="box.yend - box.ystart"
                        :color="getOverlayColor(box.type, box.index, box.discarded)"
                        v-bind="props"
                        @click.stop="onClickOverlayBox(box)"
                      />
                  </template>
                  <span class="position-tooltip">
                    <div>
                      {{ box.value }}
                    </div>
                    <div v-if="box.type === 'POSITION_ID'">
                      <br>
                      {{ $tc("COMPONENTS.PDF_VIEWER.POSITIONBEGIN") }}
                      <span v-if="box.correctedValue">{{ box.correctedValue }}</span>
                      <span v-else>{{ box.value }}</span>
                    </div>
                  </span>
                </v-tooltip>
              </div>
            </div>
          </v-container>
        </div>
        <div v-else align="center">
          <v-container class="py-16">
            <v-icon color="primary" size="medium">
              mdi-information
            </v-icon>
            {{ $tc("COMPONENTS.PDF_VIEWER.NO_BOQ") }}.
          </v-container>
        </div>

        <!-- <v-btn class="pdf-download-button ma-4" @click="downloadPdf"
          ><v-icon>mdi-download</v-icon></v-btn
        > -->
      </v-responsive>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import axios from "axios";
import { VuePdf, createLoadingTask } from 'vue3-pdfjs';
import ActionButton from "@/shared/components/action-button.component.vue";
import { ModifyOverlayDto } from "@/core/models/overlay/overlay.model";
import { defineComponent, PropType } from "vue";
import { type VuePdfPropsType } from 'vue3-pdfjs/components/vue-pdf/vue-pdf-props';
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';
import { ref } from "vue";

export default defineComponent({
    components: {
        VuePdf,
        ActionButton,
    },
    props: {
        boqId: {
            type: Number
        },
        quotationId: {
            type: Number
        },
        scrollToElement: {
            type: Object as PropType<any>
        },
        filterView: { default: false,
            type: Boolean
        },
        keywordFilter: { default: () => [],
            type: Array as PropType<string[]>
        },
        reloadPdfTriggered: {
            type: Boolean
        },
        reloadTriggered: {
            type: Boolean
        },
        specificReloadTriggered: {
            type: Boolean
        },
        customBoxData: {
            type: Object as PropType<ModifyOverlayDto>
        },
        showOverlayDefault: { default: () => false,
            type: Boolean
        },
        showSpecificOverlayDefault: { default: () => false,
            type: Boolean
        }
    },
    data() {
        const intersectingPdfs: string[] = [];
        const overlayKeywordMap: Map<string, any[]> = new Map();
        const keywordCountMap: Map<string, number> = new Map();
        let elementGenerator!: Generator;
        const scrollToElements: any[] = [];
        const overlay: any = {
            pages: null,
            boxes: null,
            positionBoxes: null,
        };
        const src = ref<VuePdfPropsType['src'] | null>(null);

        return {
            pdfKey: 1,
            error: false,
            src,
            pdfMissing: false,
            numPages: 0,
            overlayLoaded: false,
            overlayScale: 1,
            overlay,
            dialog: false,
            selectedPositionNumber: "",
            lastScrollToElement: "",
            scrollToElements,
            elementGenerator,
            keywordCountMap,
            overlayKeywordMap,
            intersectingPdfs,
            currentPage: -1,
            showOverlay: this.showOverlayDefault,
            showSpecificOverlay: this.showSpecificOverlayDefault
        };
    },
    emits: {
        'update:scrollToElement' : null,
        'update:reloadPdfTriggered': null,
        onPdfClick: null,
        keywordCount: null,
        addFromOverlay: null,
    },
    watch: {
        "showOverlay": [{
            handler: "onShowOverlayChange"
        }],
        "showSpecificOverlay": [{
            handler: "onShowSpecificOverlayChange"
        }],
        "scrollToElement": [{
            handler: "OnScrollToElementChanged"
        }],
        "reloadPdfTriggered": [{
            handler: "onReloadPdfTriggered"
        }],
        "reloadTriggered": [{
            handler: "onReloadTriggered"
        }],
        "specificReloadTriggered": [{
            handler: "onSpecificReloadTriggered"
        }],
        "keywordFilter": [{
            handler: "OnKeywordFilterChange"
        }]
    },
    mounted() {
        this.elementGenerator = this.makeGeneratorFrom([])
        this.loadPdf();
    },
    methods: {
        makePositionId(value: string) {
            return value ? (value ? `position-${value.replaceAll(".", "")}` : "") : "";
        },
        onClickOverlayBox(box: any) {
            switch (box.type) {
              case "POSITION_BOX":
                this.loadSpecificOverlay(box.page);
                break;
              default:
                this.currentPage = box.page;
                this.$emit("addFromOverlay", box);
                break;
            }
        },
        loadSpecificOverlay(page: any) {
            if (this.quotationId) {
              axios
                .get(`/quotation/${this.quotationId}/createSpecificOverlay`, {
                  params: {
                    page: page,
                  },
                })
                .then((response) => {
                  this.onLoadSpecificOverlayResponse(page, response);
                });
            } else {
              axios({
                method: "post",
                url: `/boq/${this.boqId}/createSpecificOverlay`,
                params: {
                  page: page,
                },
                data: this.customBoxData,
              }).then((response) => {
                this.onLoadSpecificOverlayResponse(page, response);
              });
            }
        },
        onLoadSpecificOverlayResponse(page: any, response: any) {
            this.overlay.boxes[page] = response.data;
        },
        loadOverlay() {
            if (this.quotationId) {
              axios
                .get(`/quotation/${this.quotationId}/createOverlay`, {
                  params: {
                    keywordFilter: this.keywordFilter?.join(","),
                  },
                })
                .then((response) => {
                  this.onLoadOverlayResponse(response);
                })
                .catch((error) => console.log(error));
            } else {
              axios({
                method: "post",
                url: `/boq/${this.boqId}/createOverlay`,
                data: this.customBoxData,
              })
                .then((response) => {
                  this.onLoadOverlayResponse(response);
                })
                .catch((error) => console.log(error));
            }
        },
        onLoadOverlayResponse(response: any) {
            this.overlay = response.data;
            this.overlay.positionBoxes = JSON.parse(JSON.stringify(this.overlay.boxes));
            this.overlayLoaded = true;
            this.overlayScale = this.calculateOverlayScale(this.overlay);

            //Count keywords for filter view
            if (this.filterView) {
              this.keywordCountMap = this.countKeywordsFromOverlay(this.overlay);
              this.overlayKeywordMap = this.mapKeywordsFromOverlay(this.overlay);
              this.$emit("keywordCount", this.keywordCountMap);
            }
        },
        transform(box: any) {
            return `transform-origin: top left; transform: scale(${this.overlayScale}) translateX(${box.xstart}px) translateY(${box.ystart}px);`;
        },
        getOverlayColor(type: string, index: number, discarded: boolean) {
            switch (type) {
              case "POSITION":
              case "POSITION_ID":
              case "POSITION_BOX":
                if (index % 2 === 0) {
                  if (discarded) {
                    return "#000000";
                  } else {
                    return "yellow";
                  }
                } else {
                  if (discarded) {
                    return "#444444";
                  } else {
                    return "blue";
                  }
                }
              case "GENERAL":
                return "grey";
              case "HEADER":
                return "purple";
              case "FOOTER":
                return "orange";
              case "START":
                return "red";
              case "END":
                return "purple";
              case "POSITION_NUMBER":
                return "green";
              case "QUANTITY":
                return "#00008B";
              case "UNIT":
                return "#FF69B4";
              case "ARTICLE_NUMBER":
                return "orange";
              default:
                return "black";
            }
        },
        onPdfIntersect( isIntersecting: boolean, entries: IntersectionObserverEntry[]) {
            Object.values(entries).forEach((value: any) => {
              const index = this.intersectingPdfs.indexOf(value.target.id);
              if (value.isIntersecting) {
                if (index == -1) {
                  this.intersectingPdfs.push(value.target.id);
                  const page = value.target.id.replaceAll("pdf-page-", "") - 1;
                  this.currentPage = page;
                  if (this.showSpecificOverlay) {
                    this.loadSpecificOverlay(page);
                  }
                }
              } else {
                if (index > -1) {
                  const page = value.target.id.replaceAll("pdf-page-", "") - 1;
                  if (this.overlay.boxes) {
                    this.overlay.boxes[page] = this.overlay.positionBoxes[page];
                  }
                  this.intersectingPdfs.splice(index, 1);
                  if (this.intersectingPdfs.length > 0) {
                    const newPage =
                      parseInt(
                        this.intersectingPdfs[this.intersectingPdfs.length - 1].replaceAll("pdf-page-", "")
                      ) - 1;
                    this.currentPage = newPage;
                  }
                }
              }
            });
        },
        onPdfClick(event: any, page: number) {
            const x = Math.round(event.offsetX / this.overlayScale);
                const y = Math.round(event.offsetY / this.overlayScale);

                this.$emit("onPdfClick", {
                  page: page,
                  x: x,
                  y: y,
                });
        },
        scrollTo(item: any) {
            const pdfContainer = (this.$refs.pdfcontainer as any).$el as HTMLElement;
                //Create Array when scroll element changes
                if (this.scrollToElement.positionNumber !== this.lastScrollToElement) {
                  //search elements inside pdfcontainer because there is a second pdf component with possible identical ids for positions
                  this.scrollToElements = this.filterView
                    ? (this.overlayKeywordMap.get(item.positionNumber) as any[])
                    : [item];

                  if (this.scrollToElements === undefined) return;

                  this.elementGenerator = this.makeGeneratorFrom(this.scrollToElements);
                }

                let nextElement: any = this.elementGenerator.next();

                if (nextElement.done === true) {
                  this.elementGenerator = this.makeGeneratorFrom(this.scrollToElements);
                  nextElement = this.elementGenerator.next();
                }

                nextElement = nextElement.value;
                if (nextElement === undefined) return;

                const el: HTMLElement = pdfContainer.querySelector(
                  `[data-page="${nextElement.page}"][data-line="${nextElement.line}"][data-word="${nextElement.word}"]`
                ) as HTMLElement;

                //offset of current page to top of container
                const offsetPageToContainerTop: number = (el.offsetParent as HTMLElement).offsetTop;

                //offset of position id to page
                const offsetPositionIdToPage: number =
                  parseInt(el.getAttribute("data-translate-y") as string) * this.overlayScale;

                pdfContainer.scrollTo({
                  top: offsetPageToContainerTop + offsetPositionIdToPage - 60,
                  behavior: "smooth",
                });
        },
        createArrayOfHtmlElements(container: HTMLElement, elementSelector: string): HTMLElement[] {
            return Array.from(container.querySelectorAll<HTMLElement>(elementSelector));
        },
        makeGeneratorFrom: function*(scrollToElements: any[]): Generator {
            for (const element of scrollToElements) {
              yield element;
            }
        },
        reloadPdf() {
            this.src = null;
            this.numPages = 0;
            this.loadPdf();
        },
        loadPdf() {
            this.src = `${axios.defaults.baseURL}/boq/download?boqId=${this.boqId}`;
            const loadingTask = createLoadingTask(this.src as VuePdfPropsType['src'])
            loadingTask.promise
            .then((pdfFile: PDFDocumentProxy) => {
                this.numPages = pdfFile.numPages;
                this.currentPage = 0;

                //Wait a fraction for the pdf to render
                setTimeout(() => {
                this.loadOverlay();
                }, 250);
            })
            .catch((e: any) => {
                console.log(e);
                this.pdfMissing = true;
            });
        },
        calculateOverlayScale(overlay: any): number {
            const pdfElements = this.$refs["pdfpage"] as any[];

                if (!pdfElements) return 1;

                const pdfElementRef = pdfElements[0].$el as HTMLElement;

                let pdfDomRect: DOMRect = new DOMRect();
                if (pdfElementRef) {
                  pdfDomRect = pdfElementRef.getBoundingClientRect();
                }
                const overlayPages: any = Object.values(overlay.pages);
                return pdfDomRect.width / overlayPages[0].width;
        },
        countKeywordsFromOverlay(overlay: any): Map<string, number> {
            const keywordCountMap: Map<string, number> = new Map();
            //Iterating through object keys
            for (const page in overlay.boxes) {
              //Iterating through array
              for (const element of overlay.boxes[page]) {
                const key: string = element.value.toLowerCase();
                const currentWordCount = keywordCountMap.get(key);
                currentWordCount
                  ? keywordCountMap.set(key, currentWordCount + 1)
                  : keywordCountMap.set(key, 1);
              }
            }
            return keywordCountMap;
        },
        mapKeywordsFromOverlay(overlay: any): Map<string, any[]> {
            const keywords: Map<string, any[]> = new Map();
                for (const page in overlay.boxes) {
                  //Iterating through array
                  for (const element of overlay.boxes[page]) {
                    const key: string = element.value.toLowerCase();
                    const currentValue: any[] = keywords.get(key) as any[];
                    currentValue ? keywords.set(key, [...currentValue, element]) : keywords.set(key, [element]);
                  }
                }

                keywords.forEach((values) => {
                  values.sort((a: any, b: any) => {
                    if (a.page === b.page) {
                      return a.line - b.line;
                    }
                    return a.page - b.page;
                  });
                });

                return keywords;
        },
        downloadPdf() {
            axios({
              method: `get`,
              url: `/boq/${this.boqId}`,
            }).then((getResponse: any) => {
              if (getResponse.status === 200) {
                axios
                  .get(`${axios.defaults.baseURL}/boq/download?boqId=${this.boqId}`, {
                    responseType: "blob",
                  })
                  .then((downloadResponse) => {
                    const blob = new Blob([downloadResponse.data], { type: "application/pdf" });
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = getResponse.data.pdfFileName;
                    link.click();
                    URL.revokeObjectURL(link.href);
                  })
                  .catch(console.error);
              }
            });
        },
        onShowOverlayChange() {
            if (!this.showOverlay) {
              this.showSpecificOverlay = false;
            }
        },
        onShowSpecificOverlayChange() {
            if (this.showSpecificOverlay) {
              this.showOverlay = true;
              this.intersectingPdfs
                .map((e) => parseInt(e.substring(9)))
                .forEach((e) => this.loadSpecificOverlay(e - 1));
            } else {
              this.intersectingPdfs
                .map((e) => parseInt(e.substring(9)))
                .forEach((e) => {
                  this.overlay.boxes[e - 1] = this.overlay.positionBoxes[e - 1];
                });
            }
        },
        OnScrollToElementChanged() {
            if (this.scrollToElement) {
              this.scrollTo(this.scrollToElement);
              this.lastScrollToElement = this.scrollToElement.positionNumber;
              this.$emit("update:scrollToElement", null);
            }
        },
        onReloadPdfTriggered() {
            console.log("onReloadPdfTriggered");
            this.loadPdf();
            this.$emit("update:reloadPdfTriggered", false);
        },
        onReloadTriggered() {
            console.log("onReloadTriggered");
            if (this.showSpecificOverlay) {
              this.intersectingPdfs
                .map((e) => parseInt(e.substring(9)))
                .forEach((e) => this.loadSpecificOverlay(e - 1));
            } else {
              this.loadOverlay();
            }
        },
        onSpecificReloadTriggered() {
            console.log("onSpecificReloadTriggered");
            this.loadSpecificOverlay(this.currentPage);
        },
        OnKeywordFilterChange() {
            this.loadOverlay();
        }
    }
})

</script>

<style lang="scss" scoped>
@use "@/style/styles";

.pdf-container {
  background: styles.$pdf-background;
  position: relative;
}

.pdf-container-responsive {
  flex-grow: 1;
  flex-shrink: 1;
}

::v-deep .pdf-card-title {
  background: white !important;
}

.id-overlay-sheet {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.my-sheet {
  // opacity: 25%; wird beim "npm run build" zu 1%
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
}

.on-hover {
  // opacity: 50%; wird beim "npm run build" zu 1%
  opacity: 0.5;
  transform: scale(1, 1);
}
</style>
