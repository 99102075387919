<template>
  <v-container class="pa-0 ma-0">
    <div class="sticky">
      <!-- <v-row align="center" justify="space-around">
        <v-col>
          <v-btn
            variant="outlined"
            @click="$emit('changeShowPosition')"
            style="border-radius: 5px 0px 0px 5px"
          >
            {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.MASTERDATA.MASTER_DATA") }}
          </v-btn>

          <v-btn class="dark-btn" style="border-radius: 0px 5px 5px 0px">
            {{ $tc("COMPONENTS.POSITION.POSITION.POSITION_DETECTION") }}
          </v-btn>
        </v-col>
      </v-row> -->
      <!-- Dialogs -->
      <v-row align="center" justify="space-around">
        <v-col>
          <v-dialog v-model="dialogNewRootPosition" width="400px">
            <template #activator="{ props: dialogProps }">
              <v-tooltip location="bottom">
                <template #activator="{ props: tooltipProps }">
                    <div class="inline">
                        <v-btn
                            variant="text"
                            color="primary"
                            v-bind="{ ...dialogProps, ...tooltipProps }"
                            class="mr-2"
                            :disabled="selectedPositions.length >= 2 || dialogFastEntry || positionSelected"
                            icon
                            size="large"
                        >
                            <v-icon :size="32">
                                mdi-plus-circle-outline
                            </v-icon>
                        </v-btn>
                    </div>
                </template>
                <span>{{ $tc("COMPONENTS.POSITION.POSITION.ADD_ELEMENT") }}</span>
              </v-tooltip>
            </template>
            <AddPositionDialog
              :quotation-id="quotationId"
              @on-position-added="positionAdded($event, true)"
              @close="dialogNewRootPosition = false"
            />
          </v-dialog>

          <v-dialog
            v-model="dialogFastEntry"
            max-width="1200px"
            transition="dialog-bottom-transition"
            persistent
            no-click-animation
            class="fast-entry-dialog"
            :class="{ 'fast-entry-dialog--active': dialogFastEntry }"
          >
            <template #activator="{ props: dialogProps }">
              <v-tooltip location="bottom">
                <template #activator="{ props: tooltipProps }">
                    <div class="inline">
                        <v-btn
                            variant="text"
                            color="primary"
                            v-bind="{ ...dialogProps, ...tooltipProps }"
                            class="mr-2"
                            :disabled="selectedPositions.length >= 2 || dialogFastEntry || positionSelected"
                            size="large"
                            icon
                            @click="onFastEntryClicked()"
                        >
                            <v-icon :size="32">
                                mdi-application-variable
                            </v-icon>
                      </v-btn>
                    </div>
                </template>
                <span>{{ $tc("COMPONENTS.POSITION.POSITION.FAST_ENTRY") }}</span>
              </v-tooltip>
            </template>
            <FastEntryDialog
                v-if="dialogFastEntry && quotationId"
                :position-type="posType"
                :quotation-id="quotationId"
                :position-id="activePosition.id"
                :ext-ref="getSelectedPositionNumber()"
                @close="closeFastEntry()"
            />
          </v-dialog>

          <v-dialog v-model="showImportDialog" width="500">
            <template #activator="{ props: dialogProps }">
              <v-tooltip location="bottom">
                <template #activator="{ props: tooltipProps }">
                    <div class="inline">
                        <v-btn
                            variant="text"
                            color="primary"
                            :disabled="selectedPositions.length >= 2 || dialogFastEntry || positionSelected"
                            v-bind="{ ...dialogProps, ...tooltipProps }"
                            class="mr-2"
                            size="large"
                            icon
                            @click="showImportDialog = true"
                        >
                            <v-icon :size="32">
                                mdi-download-box-outline
                            </v-icon>
                        </v-btn>
                    </div>
                </template>
                <span> {{ $tc("COMPONENTS.POSITION.IMPORTPOSITION.IMPORTPOSITIONS") }}</span>
              </v-tooltip>
            </template>
            <ImportPositionDialog
              :quotation-id="quotationId"
              :position-id="activePosition.id"
              @close-import-dialog="closeImportDialog"
              @success="onImportPositionDialogSuccess()"
            />
          </v-dialog>

          <v-dialog v-model="dialogPositionFilter" width="70vw">
            <template #activator="{ props: dialogProps }">
              <v-tooltip location="bottom">
                <template #activator="{ props: tooltipProps }">
                    <div class="inline">
                      <v-btn
                        variant="text"
                        color="primary"
                        v-bind="{ ...dialogProps, ...tooltipProps }"
                        class="mr-2"
                        icon
                        size="large"
                        :disabled="selectedPositions.length >= 2 || dialogFastEntry"
                      >
                        <v-icon :size="32">
                          mdi-filter-plus-outline
                        </v-icon>
                      </v-btn>
                    </div>
                </template>
                <span>{{ $tc("COMPONENTS.POSITION.POSITION.FILTER_POSITIONS") }}</span>
              </v-tooltip>
            </template>
            <PositionFilter
              v-if="dialogPositionFilter"
              :boq-id="boqId"
              :quotation-id="quotationId"
              :page-filter="pageFilter"
              @close="closeFilterDialog"
              @save="filterPositions"
            />
          </v-dialog>
        </v-col>
      </v-row>
      <!-- Dialogs -->
    </div>
    <br>
    <!-- todo: höhe dynamisch an screenheight anpassen -->
    <v-responsive class="fill-height pa-0 ma-0">
      <drop class="drop dropFirstPos" @drop="(transferData: any, nativeEvent: any) => moveItemTop(transferData)">
        <template #default="props">
          <div v-if="props.transferData" class="drag">
            <v-container class="pa-1 py-0">
              <v-row class="tree-title py-1" dense>
                <v-col>
                  {{ props.transferData.item.name }}
                </v-col>
              </v-row>
            </v-container>
          </div>
        </template>
      </drop>

      <PositionTree
        :expanded-item-ids="openedItems"
        :selected-item-ids="selectedPositions ?? []"
        :items="positions ?? []"
        :dialog-fast-entry="dialogFastEntry"
        class="py-1"
        :loading-ids="loadingIds"
        @click:add-single="showAddPositionPopup"
        @click:fast-entry="onFastEntryClickedFromTree"
        @click:import-positions="showImportPositions"
        @click:edit="showChangePositionPopup"
        @click:delete="showDeletePositionPopup"
        @click:pdf-scroll="scrollInPdfClicked"
        @click:remove-error="showRemoveValidationErrorPopup"
        @click:prev="goToPrevItem"
        @click:next="goToNextItem"
        @update-position="updatePosition"
        @update:selected-item-ids="updateSelectedItems"
        @drag="dragItem = $event"
        @drop="showDragDropPopup"
        @expand-item="loadChildPositions"
        @close-item="onClosePosition($event)"
        @update:expanded-item-ids="openedItems = $event"
      />

      <v-dialog v-model="showDialogDragDrop" width="600px">
        <PositionDragDropDialog
          :multiple-positions="selectedPositions.length > 0"
          @close="showDialogDragDrop = false"
          @move-child="moveItemInto()"
          @move-same-level="moveItemUnder()"
        />
      </v-dialog>
      <v-dialog v-model="showDialogChangePosition" :width="onChangePopupWidth(activePosition)">
        <ChangePositionDialog
          v-if="showDialogChangePosition"
          :position="activePosition"
          @on-position-changed="positionChanged($event)"
          @close="showDialogChangePosition = false"
        />
      </v-dialog>
      <v-dialog v-model="showDialogRemoveValidation" width="400px">
        <RemoveValidationErrorDialog
          :position-id="activePosition.id"
          :validation-error="selectedValidationError"
          :validation-error-type="selectedValidationErrorType"
          @validation-error-removed="validationErrorRemoved($event)"
          @close="showDialogRemoveValidation = false"
        />
      </v-dialog>
      <v-dialog v-model="showDialogAddPosition" width="400px">
        <AddPositionDialog
          :quotation-id="quotationId"
          :position-id="activePosition.id"
          @on-position-added="positionAdded($event)"
          @close="showDialogAddPosition = false"
        />
      </v-dialog>
      <v-dialog v-model="showDialogDeletePosition" width="600px">
        <DeletePositionDialog
          :position-id="activePosition.id"
          :position-type="activePosition.type"
          :multiple-positions="selectedPositions.length > 1 ? selectedPositions : undefined"
          @position-deleted="positionDeleted($event)"
          @close="showDialogDeletePosition = false"
        />
      </v-dialog>
    </v-responsive>
  </v-container>
</template>

<script lang="ts">
import axios from "axios";
//import PositionContent from "@/components/position/PositionContent.vue";
import PositionFilter from "@/components/position/position-filter.component.vue";
import RemoveValidationErrorDialog from "@/components/position/RemoveValidationErrorDialog.vue";
import AddPositionDialog from "@/components/position/AddPositionDialog.vue";
import DeletePositionDialog from "@/components/position/DeletePositionDialog.vue";
import ChangePositionDialog from "@/components/position/ChangePositionDialog.vue";
import { /*Drag,*/ Drop } from "vue3-drag-drop";
import PositionDragDropDialog from "@/components/position/PositionDragDropDialog.vue";
import ImportPositionDialog from "@/components/position/ImportPositionDialog.vue";
import FastEntryDialog from "./FastEntryDialog.vue";
// import PositionContent from "./PositionContent.vue";
import { defineComponent, PropType } from "vue";
import PositionTree from "@/shared/components/position-tree/PositionTree.vue";
import type { PositionDto } from '@/core/models/Position/Position.model';
import { getPositionValidationErrorColor } from '@/core/helper/position-validation-error'

export default defineComponent({
  components: {
    PositionDragDropDialog,
    // PositionContent,
    RemoveValidationErrorDialog,
    PositionFilter,
    AddPositionDialog,
    FastEntryDialog,
    DeletePositionDialog,
    ChangePositionDialog,
    // Drag,
    Drop,
    ImportPositionDialog,
    PositionTree
},
    props: {
        boqId: {
            type: Number
        },
        quotationId: {
            type: Number
        },
        positionIdsToOpen: {
            type: Object as PropType<any>
        },
        pageFilter: {
            type: Object as PropType<any>
        },
        positionFromOverlay: {
            type: Object as PropType<any>
        }
    },
    data() {
        const modifiedSelectionPosition: any = null;
        const modifiedPosition: any = null;
        const dragItem: any = null;
        const dropItem: any = null;
        const activePosition: any = {};
        const fastEntryRootPosition: any = null;
        const successDragDropAlert: any = {
                type: "success",
                message: this.$tc("COMPONENTS.POSITION.POSITION.BOQ_SUCCESS"),
              };
        const key: any = 0;
        const selectedPositionsCache: any[] = [];
        const selectedPositions: number[] = [];
        const openedItems: number[] = [];
        const loadingIds: number[] = [];

        return {
            CLOSED_ITEM_HEIGHT: 42,
            openedItems,
            loadingIds,
            selectedPositions,
            selectedPositionsCache,
            key,
            successDragDropAlert,
            positionSelected: false,
            dialogNewRootPosition: false,
            dialogFastEntry: false,
            fastEntryRootPosition,
            dialogPositionFilter: false,
            showDialogDragDrop: false,
            showDialogChangePosition: false,
            showDialogRemoveValidation: false,
            showDialogAddPosition: false,
            showDialogDeletePosition: false,
            activePosition,
            selectedValidationError: "",
            selectedValidationErrorIndex: -1,
            selectedValidationErrorType: "",
            dropItem,
            dragItem,
            modifiedPosition,
            modifiedSelectionPosition,
            showFilterToolTip: false,
            showImportDialog: false,
            posType: "",
            showWidePopup: false,
            hideMode: false,
            shiftKeyDown: false
        };
    },
    computed: {
        positions(): PositionDto[] {
            return this.$store.getters["position/positions"];
        },
        positionChildren(): any {
            return this.$store.getters["position/positionChildren"];
        },
    },
    watch: {
        "selectedPositions": [{
            handler: "onSelected"
        }],
        "positionIdsToOpen": [{
            handler: "onPositionIdsToOpenChanged"
        }],
        "positionFromOverlay": [{
            handler: "onPositionFromOverlayChanged"
        }],
    },
    mounted() {
        this.$emit("baseDataChange");
            this.loadData();

            document.onkeydown = (e) => {
              if (e.shiftKey) {
                this.shiftKeyDown = true;
              } else if (e.altKey) {
                //Class of Fast entry dialog window
                const feElement = document.querySelector(".fast-entry-dialog--active") as HTMLDivElement;
                //Class of 4 Buttons in the corner top left
                const btnElements = document.querySelectorAll(".inline");

                if (feElement) {
                  if (e.key == "a" && this.hideMode) {
                    this.hideMode = false;
                    this.$emit("changeHideMode");
                    btnElements.forEach((element: any) => {
                      element.style.cursor = "cursor";
                    });
                    feElement.style.zIndex = "2400";
                  } else if (e.key == "h" && !this.hideMode) {
                    this.hideMode = true;
                    this.$emit("changeHideMode");
                    btnElements.forEach((element: any) => {
                      element.style.cursor = "default";
                    });
                    feElement.style.zIndex = "-2";
                  }
                }
              }
            };

            document.onkeyup = (e) => {
              if (e.key === "Shift") {
                this.shiftKeyDown = false;
              }
            };
    },
    unmounted() {
        this.$store.dispatch("position/clearPositions");
    },
    methods: {
        setPositionForFastEntryDialog(item: any) {
            this.activePosition = item;
            this.fastEntryRootPosition = item;
            this.posType = item.positionType;
        },
        onFastEntryClicked() {
            const position = this.getSelectedPosition();
            if (position) {
              this.setPositionForFastEntryDialog(position);
            }
        },
        onFastEntryClickedFromTree(position: any) {
            this.setPositionForFastEntryDialog(position);
            this.dialogFastEntry = true;
        },
        async showAddSingleElement() {
            const positionTypes = ["POSITION", "TEXT", "POS_EV", "POS_BED", "POS_ALT"];
                if (this.selectedPositions.length >= 2) return false;
                try {
                  for (const id of this.selectedPositions) {
                    const response = await axios({
                      method: "get",
                      url: `/position`,
                      params: {
                        positionId: id,
                      },
                    });
                    if (response.status == 200 && response.data) {
                      this.positionSelected = positionTypes.includes(response.data);
                      return;
                    }
                  }

                  if (this.positionSelected == true) this.positionSelected = false;
                } catch (err) {
                  console.error(err);
                }
        },
        closeImportDialog() {
            this.showImportDialog = false;
        },
        onChangePopupWidth(activePosition: any) {
            let value: boolean;
            if (activePosition && activePosition.label) {
              value = activePosition.positionType == "TEXT" && activePosition.label.length > 78;
            } else {
              value = false;
            }
            return value ? "900px" : "600px";
        },
        onImportPositionDialogSuccess() {
            this.loadData();
            this.$store.dispatch("quotation/loadActionLog", this.quotationId);
            this.$store.dispatch("quotation/updateValidationValue", this.quotationId);
        },
        closeFastEntry() {
            this.posType = "";
            this.fastEntryRootPosition = null;
            this.dialogFastEntry = false;
            this.updateParentObject(this.getSelectedPosition());
            this.$store.dispatch("quotation/loadActionLog", this.quotationId);
            this.$store.dispatch("quotation/updateValidationValue", this.quotationId);
        },
        closeFilterDialog() {
            this.dialogPositionFilter = false;
            this.showFilterToolTip = false;
        },
        getSelectedPositionNumber() {
            const position = this.getSelectedPosition();
            if (position != null) {
              return position.positionNumber;
            } else {
              return null;
            }
        },
        getSelectedPosition() {
            if (this.fastEntryRootPosition != null) {
              return this.fastEntryRootPosition;
            } else {
              if (this.selectedPositions.length == 1) {
                return this.getAndReturnPositionById(this.positions, this.selectedPositions[0]);
              } else {
                return null;
              }
            }
        },
        async updateSelectedItems(selectedItems: any) {
            if (this.selectedPositions.length === selectedItems.length) return;
                if (selectedItems.length > 1) {
                  const currentSelectionId = selectedItems[selectedItems.length - 1];
                  const previousSelectedId = selectedItems[selectedItems.length - 2];
                  this.getPositionById(this.positions, parseInt(currentSelectionId));
                  this.modifiedSelectionPosition = this.modifiedPosition;

                  this.getPositionById(this.positions, parseInt(previousSelectedId));

                  if (!this.shareSameParent(this.modifiedPosition, this.modifiedSelectionPosition)) {
                    this.selectedPositions = [currentSelectionId];
                    await this.showAddSingleElement();
                    return;
                  } else {
                    if (this.shiftKeyDown) {
                      let array;
                      if (this.modifiedPosition.parentId === -1) {
                        array = this.positions;
                      } else {
                        this.getPositionById(this.positions, this.modifiedPosition.parentId);
                        array = this.modifiedPosition.children;
                      }
                      let found = false;
                      for (const pos of array) {
                        if (pos.id === currentSelectionId || pos.id == previousSelectedId) {
                          found = !found;
                        }
                        if (found) {
                          // this.selectedPositions.push(pos.id);
                          if (!selectedItems.find((e: any) => e === pos.id)) {
                            selectedItems.push(pos.id);
                          }
                        }
                      }
                    }
                  }
                }
                this.selectedPositions = selectedItems;
                await this.showAddSingleElement();
        },
        shareSameParent(pos: any, otherpos: any): boolean {
            // if (pos === otherpos) return true;
            // else if (pos === null || otherpos === null) return false;
            // else if (pos.parentId === otherpos.parentId) {
            //   return true;
            // } else
            //   return this.shareSameParent(
            //     this.getAndReturnPositionById(this.positions, pos.parentId),
            //     this.getAndReturnPositionById(this.positions, otherpos.parentId)
            //   );
            if (pos.parentId == otherpos.parentId && (pos.parentId != -1 || otherpos.parentId != -1)) {
              return true;
            } else if (pos.parentId == -1 && otherpos.parentId == -1) {
              return true;
            }
            return false;
        },
        showDragDropPopup(toItem: any/*, transferData: any*/) {
            this.dropItem = toItem;
            // this.dragItem = transferData.item;
            this.showDialogDragDrop = true;
        },
        showChangePositionPopup(item: any) {
            this.activePosition = item;
            this.showDialogChangePosition = true;
        },
        showRemoveValidationErrorPopup(item: PositionDto, index: number) {
            const validationError = item.validationErrors[index];
            this.activePosition = item;
            this.selectedValidationError = validationError;
            this.selectedValidationErrorIndex = index;
            this.selectedValidationErrorType = getPositionValidationErrorColor(validationError);
            this.showDialogRemoveValidation = true;
        },
        showAddPositionPopup(item: any) {
            this.activePosition = item;
            this.showDialogAddPosition = true;
        },
        showDeletePositionPopup(item: any) {
            this.activePosition = item;
            this.showDialogDeletePosition = true;
        },
        showImportPositions(item: any) {
            this.activePosition = item;
            this.showImportDialog = true;
        },
        moveItemTop(transferData: any) {
            axios({
              method: "post",
              url: `/position/${transferData.item.id}/moveBefore`,
              params: {
                nextPositionId: this.positions[0].id,
              },
              data: this.selectedPositions,
            })
              .then((response) => {
                if (response.status === 200) {
                  if (response.data.length > 0) {
                    for (const returnedPosition of response.data) {
                      this.getPositionById(this.positions, parseInt(returnedPosition.id));
                      this.modifiedPosition.validationErrors = returnedPosition
                        ? returnedPosition.validationErrors
                        : [];
                      this.modifiedPosition.parentId = returnedPosition ? returnedPosition.parentId : -1;
                      this.deleteItem(this.positions, parseInt(returnedPosition.id));
                      this.positions.unshift(this.modifiedPosition);
                    }
                    // }
                    // if (this.selectedPositions.length > 0) {
                    //   for (const positionId of this.selectedPositions) {
                    //     this.getPositionById(this.positions, parseInt(positionId));
                    //     const returnedPosition = response.data.find((element: any) => element.id === positionId);
                    //     this.modifiedPosition.validationErrors = returnedPosition ? returnedPosition.validationErrors : [];
                    //     this.modifiedPosition.parentId = returnedPosition ? returnedPosition.parentId : -1;
                    //     this.deleteItem(this.positions, parseInt(positionId));
                    //     this.positions.unshift(this.modifiedPosition);
                    //   }
                  } else {
                    transferData.item.validationErrors = response.data.validationErrors;
                    transferData.item.parentId = response.data.parentId;
                    this.deleteItem(this.positions, transferData.item.id);
                    this.positions.unshift(transferData.item);
                  }
                  this.$store.commit("activateAlert", this.successDragDropAlert);
                  this.selectedPositions = [];
                  this.key++;
                  this.updateValidationValue();
                  this.$store.dispatch("quotation/loadActionLog", this.quotationId);
                }
              })
              .catch((error) => {
                console.log(error);
                // this.$store.commit("activateAlert", this.$store.state.errorAlert);
              });
        },
        moveItemUnder() {
            // const sameItemLevel = (this.getItemLevel(this.positions, this.dropItem.id, 0) == this.getItemLevel(this.positions, this.dragItem.id, 0));
            // this.changeItems(this.positions, this.dropItem.id, this.dragItem, this.dragItem.id, this.dropItem);
            axios({
              method: "post",
              url: `/position/${this.dragItem.id}/moveAfter`,
              params: {
                previousPositionId: this.dropItem.id,
              },
              data: this.selectedPositions,
            })
              .then((response) => {
                if (response.status === 200) {
                  if (response.data.length > 0) {
                    for (const returnedPosition of response.data) {
                      this.getPositionById(this.positions, parseInt(returnedPosition.id));
                      this.modifiedPosition.validationErrors = returnedPosition
                        ? returnedPosition.validationErrors
                        : [];
                      this.modifiedPosition.parentId = returnedPosition ? returnedPosition.parentId : -1;
                      this.deleteItem(this.positions, parseInt(returnedPosition.id));
                      this.placeItemAtNewPosition(this.positions, this.dropItem.id, this.modifiedPosition);
                    }
                    // }
                    // if (this.selectedPositions.length > 0) {
                    //   for (const positionId of this.selectedPositions) {
                    //     this.getPositionById(this.positions, parseInt(positionId));
                    //     const returnedPosition = response.data.find((element: any) => element.id === positionId);
                    //     this.modifiedPosition.validationErrors = returnedPosition ? returnedPosition.validationErrors : [];
                    //     this.modifiedPosition.parentId = returnedPosition ? returnedPosition.parentId : -1;
                    //     this.deleteItem(this.positions, parseInt(positionId));
                    //     this.placeItemAtNewPosition(this.positions, this.dropItem.id, this.modifiedPosition);
                    //   }
                  } else {
                    this.dragItem.validationErrors = response.data.validationErrors;
                    this.dragItem.parentId = response.data.parentId;
                    this.deleteItem(this.positions, this.dragItem.id);
                    this.placeItemAtNewPosition(this.positions, this.dropItem.id, this.dragItem);
                  }
                  this.$store.commit("activateAlert", this.successDragDropAlert);
                  this.selectedPositions = [];
                  this.key++;
                  this.updateValidationValue();
                  this.$store.dispatch("quotation/loadActionLog", this.quotationId);
                }
              })
              .catch((error) => {
                console.log(error);
                // this.$store.commit("activateAlert", this.$store.state.errorAlert);
              });
        },
        moveItemInto() {
            const groupValues = ["GRUPPE", "GRUPPE_ALT", "GRUPPE_EVT", "GRUPPE_BED"];

                if (
                  groupValues.includes(this.dragItem.positionType) &&
                  groupValues.includes(this.dropItem.positionType)
                ) {
                  this.$store.commit(
                    "activateAlert",
                    {
                      type: "error",
                      message: this.$tc("COMPONENTS.POSITION.POSITION.ALTERNATIVE_ERROR"),
                    },
                    { root: true }
                  );
                  return;
                }
                console.log("request!");
                axios({
                  method: "post",
                  url: `/position/${this.dragItem.id}/moveInto`,
                  params: {
                    parentPositionId: this.dropItem.id,
                  },
                  data: this.selectedPositions,
                })
                  .then((response) => {
                    console.log("response!");
                    console.log(response);
                    if (response && response.status === 200) {
                      if (response.data.length > 0) {
                        for (const returnedPosition of response.data) {
                          this.getPositionById(this.positions, parseInt(returnedPosition.id));
                          this.modifiedPosition = returnedPosition;
                          // this.modifiedPosition.positionNumber = returnedPosition.positionNumber;
                          // this.modifiedPosition.validationErrors = returnedPosition
                          //   ? returnedPosition.validationErrors
                          //   : [];
                          // this.modifiedPosition.parentId = returnedPosition ? returnedPosition.parentId : -1;
                          this.deleteItem(this.positions, parseInt(returnedPosition.id));
                          if (!this.dropItem.children) {
                            this.dropItem.children = [];
                          }
                          this.dropItem.children.unshift(this.modifiedPosition);
                        }
                        // }
                        // if (this.selectedPositions.length > 0) {
                        //   for (const positionId of this.selectedPositions) {
                        //     this.getPositionById(this.positions, parseInt(positionId));
                        //     const returnedPosition = response.data.find((element: any) => element.id === positionId);
                        //     this.modifiedPosition.validationErrors = returnedPosition ? returnedPosition.validationErrors : [];
                        //     this.modifiedPosition.parentId = returnedPosition ? returnedPosition.parentId : -1;
                        //     this.deleteItem(this.positions, parseInt(positionId));
                        //     this.dropItem.children.unshift(this.modifiedPosition);
                        //   }
                      } else {
                        this.dragItem.validationErrors = response.data.validationErrors;
                        this.dragItem.parentId = response.data.parentId;
                        this.deleteItem(this.positions, this.dragItem.id);
                        this.dragItem = response.data;
                        if (!this.dropItem.children) {
                          this.dropItem.children = [];
                        }
                        this.dropItem.children.unshift(this.dragItem);
                      }
                      this.$store.commit("activateAlert", this.successDragDropAlert);
                      this.selectedPositions = [];
                      this.key++;
                      this.updateValidationValue();
                      this.$store.dispatch("quotation/loadActionLog", this.quotationId);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    // this.$store.commit("activateAlert", this.$store.state.errorAlert);
                  });
        },
        deleteItem(array: any, id: number) {
            let i = array.length;
            while (i--) {
              if (array[i].id == id) {
                array.splice(i, 1);
                return;
              }
              array[i].children && this.deleteItem(array[i].children, id);
            }
        },
        reloadItem(items: any, id: number) {
            let i = items.length;
            while (i--) {
              if (items[i].id == id) {
                // array.splice(i, 1);
                this.updateParentObject(items[i]);
                return;
              }
              items[i].children && this.reloadItem(items[i].children, id);
            }
        },
        getItemLevel(array: any, id: number, counter: number) {
            let i = array.length;
            counter++;
            while (i--) {
              if (array[i].id == id) {
                return counter;
              }
              array[i].children && this.getItemLevel(array[i].children, id, counter);
            }
        },
        changeItems(array: any, id1: number, item1: any, id2: number, item2: any) {
            let i = array.length;
            while (i--) {
              if (array[i].id == id1) {
                const index2 = this.getSecondIndex(array, id2);
                array.splice(i, 1, item1);
                array.splice(index2, 1, item2);
                return;
              }
              array[i].children && this.changeItems(array[i].children, id1, item1, id2, item2);
            }
        },
        getSecondIndex(array: any, id: number) {
            let i = array.length;
            while (i--) {
              if (array[i].id == id) {
                return i;
              }
            }
        },
        placeItemAtNewPosition(array: any, id: number, item: any) {
            let i = array.length;
            while (i--) {
              if (array[i].id == id) {
                array.splice(i + 1, 0, item);
                return;
              }
              array[i].children && this.placeItemAtNewPosition(array[i].children, id, item);
            }
        },
        getPositionById(positions: any, positionId: number) {
            let i = positions.length;
            while (i--) {
              if (positions[i].id == positionId) {
                this.modifiedPosition = positions[i];
                return positions[i];
              }
              if (positions[i].children) {
                this.getPositionById(positions[i].children, positionId);
              }
            }
        },
        getAndReturnPositionById(positions: any, positionId: number): any {
            if (positions) {
              for (const pos of positions) {
                if (pos.id === positionId) {
                  return pos;
                }
                const childPos = this.getAndReturnPositionById(pos.children, positionId);
                if (childPos) {
                  return childPos;
                }
              }
            }
            return null;
        },
        editPositionName(positionItem: any) {
            console.log(positionItem);
        },
        loadData() {
            this.openedItems = [];
            this.selectedPositions = [];
            this.$store.dispatch("position/loadPositionsToplevel", this.quotationId);
        },
        async updateParentObject(position: any) {
            if (position) {
              await this.$store.dispatch("position/loadPositionChildren", {
                quotationId: this.quotationId,
                positionId: position.id,
              });
              position.children = this.positionChildren ? this.positionChildren : [];
              this.openedItems = [];
              this.selectedPositions = [];
              this.key++;
            } else {
              this.loadData();
            }
        },
        async loadChildPositions(item: PositionDto) {
            this.loadingIds.push(item.id);
            this.selectedPositionsCache = this.selectedPositions;
            return axios({
              method: "get",
              url: `/quotation/${this.quotationId}/positionHierarchy?positionId=${item.id}`,
            }).then((response) => {
              if (response.status === 200) {
                item.children = response.data;
              }
              this.loadingIds = this.loadingIds.filter(id => id !== item.id);
            });
        },
        validationErrorRemoved(progress: string) {
            this.showDialogRemoveValidation = false;
            this.activePosition.validationErrors.splice(this.selectedValidationErrorIndex, 1);
            this.$emit("progressUpdated", progress);
        },
        updateValidationValue() {
            axios.get(`/quotation/${this.quotationId}/validationValue`).then((response) => {
              this.$emit("progressUpdated", response.data);
            });
        },
        positionAdded(addedPosition: any, rootPosition = false) {
            this.showDialogAddPosition = false;
                if (!this.activePosition.children) {
                  this.activePosition.children = [];
                }
                if (rootPosition) {
                  this.loadData();
                } else {
                  this.activePosition.children.push(addedPosition);
                }
                this.key++;
                this.updateValidationValue();

                this.$store.dispatch("quotation/loadActionLog", this.quotationId);
        },
        positionDeleted(parentObject: any) {
            this.showDialogDeletePosition = false;
            if (parentObject) {
              if (
                this.activePosition.children == undefined ||
                (this.activePosition.children.length >= 1 &&
                  this.activePosition.children[0].name != "Content") ||
                this.selectedPositions
              ) {
                this.reloadItem(this.positions, parentObject.id);
              } else {
                this.deleteItem(this.positions, this.activePosition.id);
              }
            } else {
              this.loadData();
            }
            this.selectedPositions = [];
            this.key++;
            this.updateValidationValue();
            this.$store.dispatch("quotation/loadActionLog", this.quotationId);
            this.positionSelected = false;
        },
        positionChanged(changedPosition: any) {
            this.showDialogChangePosition = false;
            this.activePosition.name = changedPosition.name;
            this.activePosition.positionType = changedPosition.positionType;
            this.activePosition.positionLabel = changedPosition.positionLabel;
            this.activePosition.ownRef = changedPosition.ownRef;
            this.activePosition.validationErrors = changedPosition.validationErrors;
            this.updateValidationValue();
            this.loadChildPositions(this.activePosition);
            this.$store.dispatch("quotation/loadActionLog", this.quotationId);
        },
        scrollInPdfClicked(item: any) {
            this.$emit("scrollInPdfClicked", item);
        },
        beforeUnmount() {
            document.onkeydown = null;
            document.onkeyup = null;
        },
        async showNextItem(array: PositionDto[], id: number) {
            let i = array.length;
            while (i--) {
              if (array[i].id == id) {
                let nextItem = array[i];
                if (i !== array.length) {
                  nextItem = array[i + 1];
                }
                await this.loadChildPositions(nextItem);
                this.openedItems = [ ...this.openedItems, nextItem.id ];
                this.scrollInPdfClicked(nextItem);
                return;
              }
              array[i].children && this.showNextItem(array[i].children, id);
            }
        },
        async showPrevItem(array: PositionDto[], id: number) {
            let i = array.length;
            while (i--) {
              if (array[i].id == id) {
                let prevItem = array[i];
                if (i > 0) {
                  prevItem = array[i - 1];
                }
                await this.loadChildPositions(prevItem);
                this.openedItems = [ ...this.openedItems, prevItem.id ];
                this.scrollInPdfClicked(prevItem);
                return;
              }
              array[i].children && this.showPrevItem(array[i].children, id);
            }
        },
        goToPrevItem(item: PositionDto) {
            this.showPrevItem(this.positions, item.id);
            this.openedItems = this.openedItems.filter((element) => element !== item.id);
            this.key++;
            const treeviewContainer = document.getElementsByClassName("quotation__masterdata-wrapper")[0];
            treeviewContainer.scrollTop = treeviewContainer.scrollTop - this.CLOSED_ITEM_HEIGHT;
        },
        goToNextItem(item: PositionDto) {
            this.showNextItem(this.positions, item.id);
            this.openedItems = this.openedItems.filter((element) => element !== item.id);
            this.key++;
            const treeviewContainer = document.getElementsByClassName("quotation__masterdata-wrapper")[0];
            treeviewContainer.scrollTop = treeviewContainer.scrollTop + this.CLOSED_ITEM_HEIGHT;
        },
        async filterPositions(pageFilter: string) {
            await this.$store.dispatch("position/filterPositions", {
                  quotationId: this.quotationId,
                  pageFilter: pageFilter,
                });

                // this.openedItems = [];
                await this.$store.dispatch("position/loadPositionsToplevel", this.quotationId);
                this.key++;
                await this.$store.dispatch("quotation/loadQuotationDetail", this.quotationId);
                this.$emit("reload-pdf");
                this.updateValidationValue();
        },
        updatePosition(changedPosition: any) {
            this.updateValidationErrors(
              this.positions,
              changedPosition.id,
              changedPosition.validationErrors
            );
            this.updateValidationValue();
            this.loadChildPositions(changedPosition);
            this.positionSelected = false;
        },
        updateValidationErrors(array: any, id: number, validationErrors: any[]) {
            let i = array.length;
            while (i--) {
              if (array[i].id == id) {
                array[i].validationErrors = validationErrors;
                return;
              }
              array[i].children && this.updateValidationErrors(array[i].children, id, validationErrors);
            }
        },
        onSelected() {
            if (this.selectedPositionsCache.length !== 0) {
              this.selectedPositionsCache
                .slice()
                .reverse()
                .forEach((e) => this.selectedPositions.unshift(e));
              // this.selectedPositions.unshift(this.selectedPositionsCache);
              this.selectedPositionsCache = [];
            }
        },
        onPositionIdsToOpenChanged() {
            // this.$refs.tree.updateAll(true);
            this.openedItems = this.positionIdsToOpen;
            setTimeout(() => {
              // scroll does not work when node is closed there is no delay
              // TODO $vuetify.goTo existiert nicht mehr. Alternative implementieren!
              // this.$vuetify.goTo("#position-".concat(this.openedItems[0]));
            }, 1);
        },
        onPositionFromOverlayChanged() {
            const newPosition = this.positionFromOverlay.position;
            const prevPosId = this.positionFromOverlay.previousId;
            this.placeItemAtNewPosition(this.positions, prevPosId, newPosition);
        },
        onClosePosition(position: PositionDto) {
          // Entferne die geschlossene Position und rekursiv alle Children aus der Liste der geöffneten und ausgewählten Positionen
          const getAllChildIds: (position: PositionDto) => number[] =
            (position) => ([position.id, ...(position.children ? position.children.flatMap(getAllChildIds) : [])]);

          const allClosedPositionIds: number[] = getAllChildIds(position);
          this.updateSelectedItems(this.selectedPositions.filter(s => !allClosedPositionIds.some(c => c === s)));
          this.openedItems = this.openedItems.filter(o => !allClosedPositionIds.some(c => c === o));
        },
    }
})

</script>

<style lang="scss" scoped>
.select::first-letter {
  visibility: hidden;
}

.card-content-title {
  font-size: 14px;
  padding: 0px;
}
.card-content-text {
  font-size: 12px;
  padding: 0px;
}

.tooltip {
  display: none;
}

hr.v-divider {
  border-color: rgba(242, 243, 245, 255);
}

::v-deep .v-treeview-node--leaf button.v-treeview-node__checkbox {
  display: none;
}

::v-deep .v-treeview-node__root .accent--text {
  color: #333 !important;
  background: yellow;
  width: 16px;
  height: 16px;
  margin: 0 4px 0 10px;
}

.inline {
  display: inline;
}

.sticky {
  position: sticky;
  -webkit-position: sticky;
  top: 64px;
  z-index: 1;
  background: white;
}

div.v-list > div.v-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

div.v-list {
  padding: 0;
}
</style>
