<template>
  <v-container class="customer-selection">
    <v-alert
      v-if="!getConfig.filter || Object.keys(getConfig.filter).length === 0"
      class="mt-3"
      density="compact"
      variant="text"
      type="info">
      {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.SALESAREA_SELECTION") }}
    </v-alert>
    <div class="table-container">
      <BoqDataTable
        ref="dataTable"
        :headers="headers"
        :items="salesAreaList"
        :singleSelect="true"
        :showSelect="true"
        itemKey="kundennummer"
        :loading="loading"
        v-model:get-config="getConfig"
        :debounceTime="0"
        @itemSelected="onItemSelected"
        @enterPressed="loadSalesAreaList"
      >
      </BoqDataTable>
    </div>
    <v-row class="flex-grow-0" justify="start">
      <v-col cols="auto">
        <v-btn
          variant="outlined"
          prepend-icon="mdi-magnify"
          :disabled="loading"
          @click="loadSalesAreaList()"
        >
          {{ $tc('COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CLIENT_SELECETION.SEARCH_AGAIN') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import BoqDataTable from "@/shared/components/data-table.component.vue";
import GetConfig from "@/core/services/base/get.config";
import { headers as HEADERS } from "./headers/salesarea-selection-header";
import { SalesView } from "@/core/models/master-data/Sales.model";
import { BoqTableHeader } from "@/core/interfaces/boq-table-header.interface";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    BoqDataTable,
  },
    data() {
        const errorAlert: any = {
                type: "error",
                message: this.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CLIENT_SELECETION.MESSAGE.ERROR"),
              };
        const noContentAlert: any = {
                type: "info",
                message: this.$tc(
                  "COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.CLIENT_SELECETION.MESSAGE.NO_CONTENT"
                ),
              };
        const headers: BoqTableHeader[] = HEADERS;
        const getConfig: GetConfig = GetConfig.default();

        return {
            getConfig,
            salesAreaSelected: false,
            headers,
            noContentAlert,
            errorAlert
        };
    },
    async mounted() {
        this.getConfig.filter = {
          kundennummer: this.clientId,
        };
        (this.$refs.dataTable as InstanceType<typeof BoqDataTable>).initFiltersFromGetConfig(this.getConfig);
        if (this.clientId) await this.loadSalesAreaList();
    },
    unmounted() {
        this.$store.dispatch("masterdata/clearSalesAreaList");
    },
    methods: {
        loadSalesAreaList() {
            if (this.loading) return;
            this.$store.dispatch("masterdata/loadSalesAreaList", this.getConfig);
        },
        onItemSelected(item: SalesView[]) {
            this.salesAreaSelected = !!item.length;
            this.$emit("itemSelected", item[0], this.salesAreaSelected);
        },
        salesAreaListChanged(salesAreaList: SalesView[]) {
            if (salesAreaList.length !== 0) {
              const salesAreaListItem = salesAreaList.find((e) => e.kundennummer === this.clientId);
              if (salesAreaListItem) {
                (this.$refs.dataTable as InstanceType<typeof BoqDataTable>).selectPreselected(salesAreaListItem);
                this.onItemSelected([salesAreaListItem]);
              }
            }
        }
    },
    props: {
        clientId: { default: "",
            type: String
        }
    },
    watch: {
        "salesAreaList": [{
            handler: "salesAreaListChanged"
        }]
    },
    computed: {
        salesAreaList(): SalesView[] {
            return this.$store.getters["masterdata/salesAreaList"];
        },
        loading(): boolean {
            return this.$store.getters["loading"];
        }
    }
})

</script>

<style lang="scss" scoped>
.customer-selection {
  flex-grow: 1;
  height: 1%;
  display: flex;
  flex-direction: column;
}

.table-container {
  overflow-y: auto;
  flex-grow: 1;
  flex-shrink: 1;
}
</style>
