import { GetterTree } from "vuex";
import { RootState } from "~core/store/state";
import MasterDataState from "./state";

export const getters: GetterTree<MasterDataState, RootState> = {
  masterdata: (state) => state.masterdata,
  masterdataChanged: (state) => state.masterdataChanged,
  clientList: (state) => state.clientList,
  recipientList: (state) => state.recipientList,
  contactList: (state) => state.contactList,
  bidderList: (state) => state.bidderList,
  plannerList: (state) => state.plannerList,
  opportunityList: (state) => state.opportunityList,
  salesAreaList: (state) => state.salesArea,
};
