<template>
  <v-container
    :id="`position-${position.id}`"
    class="px-1 py-0"
  >
    <v-row
      :id="`positiontype-${position.positionType}`"
      class="tree-title py-1"
      dense
    >
      <v-col cols="auto" class="d-flex align-center">
        <v-btn size="x-small" icon variant="text" :loading="loading">
          <v-icon :icon="expanded ? 'mdi-chevron-down' : 'mdi-chevron-right'" size="x-large" @click="$emit('update:expanded')" />
        </v-btn>
        <v-checkbox
          :model-value="selected"
          hide-details
          density="compact"
          @update:model-value="$emit('update:selected', !!$event)"
          class="mx-2 mt-n3"
          style="height: 24px"
        />
      </v-col>
      <v-col
        class="description d-flex align-center"
        cols="*"
        style="padding-top: 6px; padding-bottom: 2px"
      >
        <span class="text-truncate">{{ position.name }}</span>
      </v-col>
      <v-col
        v-for="(validationError, index) in position.validationErrors"
        :key="validationError"
        cols="auto"
        class="d-flex align-center"
      >
        <v-tooltip location="bottom">
          <template #activator="{ props }">
            <div v-bind="props">
              <v-icon
                :color="getPositionValidationErrorColor(validationError)"
                @click="$emit('click:remove-error', index)"
              >
                mdi-alert
              </v-icon>
            </div>
          </template>
          <span>{{ getValidationErrorMessage(validationError) }}</span>
        </v-tooltip>
      </v-col>
      <v-col v-if="!dialogFastEntry && showAddButton" cols="auto" class="d-flex align-center">
        <v-menu>
          <template #activator="{ props }">
            <v-icon v-bind="props">
              mdi-plus
            </v-icon>
            <!-- <v-btn color="primary" class="dark-btn" v-bind="props"> Dropdown </v-btn> -->
          </template>
          <v-list>
            <v-list-item :title="t('COMPONENTS.POSITION.POSITION.ADD_SINGLE')" @click="$emit('click:add-single')" />
            <v-list-item :title="t('COMPONENTS.POSITION.POSITION.FAST_ENTRY')" @click="$emit('click:fast-entry')" />
            <v-list-item :title="t('COMPONENTS.POSITION.POSITION.IMPORT_POSITIONS')" @click="$emit('click:import-positions')" />
          </v-list>
        </v-menu>
      </v-col>
      <v-col v-if="!dialogFastEntry" cols="auto" class="d-flex align-center">
        <v-icon
          class="ml-1"
          @click="$emit('click:edit')"
        >
          mdi-pencil
        </v-icon>
      </v-col>
      <v-col v-if="!dialogFastEntry" cols="auto" class="d-flex align-center">
        <v-icon
          v-if="position.positionNumber"
          @click="$emit('click:pdf-scroll')"
        >
          mdi-target
        </v-icon>
      </v-col>
      <v-col v-if="!dialogFastEntry" cols="auto" class="d-flex align-center">
        <v-icon @click="$emit('click:delete')">
          mdi-delete
        </v-icon>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import type { PositionDto } from '@/core/models/Position/Position.model';
import { useI18n } from 'vue-i18n';
import { getPositionValidationErrorColor } from '@/core/helper/position-validation-error'

defineProps<{
  position: PositionDto;
  dialogFastEntry: boolean;
  showAddButton: boolean;
  expanded: boolean;
  selected: boolean;
  loading?: boolean;
}>()

defineEmits<{
  (e: 'click:add-single'): void,
  (e: 'click:fast-entry'): void,
  (e: 'click:import-positions'): void,
  (e: 'click:remove-error', index: number): void,
  (e: 'click:pdf-scroll'): void,
  (e: 'click:delete'): void,
  (e: 'click:edit'): void,
  (e: 'update:expanded'): void,
  (e: 'update:selected', value: boolean): void,
}>()

const { t } = useI18n();

function getValidationErrorMessage(validationError: string) {
  switch (validationError) {
    case "HIERARCHY":
      return t("COMPONENTS.POSITION.POSITION.MISSMATCH_ID_PARENT");
    case "NO_UNIT":
      return t("COMPONENTS.POSITION.POSITION.NO_UNIT");
    case "NO_QUANTITY":
      return t("COMPONENTS.POSITION.POSITION.NO_QUANTITY");
    case "CONSECUTIVE":
      return t("COMPONENTS.POSITION.POSITION.ID_SMALL_POSITION");
    case "DISTANCE":
      return t("COMPONENTS.POSITION.POSITION.UNORDINARY_DISTANCE");
    case "NO_ID":
      return t("COMPONENTS.POSITION.POSITION.NO_ID");
    case "NO_ARTICLE":
      return t("COMPONENTS.POSITION.POSITION.NO_ARTICLE_NUMBER");
    case "NO_ARTICLE_NUMBER":
      return t("COMPONENTS.POSITION.POSITION.NO_ARTICLE_NUMBER");
    case "NO_ARTICLE_REFERENCE":
      return t("COMPONENTS.POSITION.POSITION.NO_STANDARD_ARTICLE");
    case "NO_GROUP_REFERENCE":
      return t("COMPONENTS.POSITION.POSITION.NO_STANDARD_GROUP");
    default:
      return validationError;
  }
}
</script>

<style lang="scss" scoped>
  
</style>
