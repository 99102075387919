import MasterdataState from "./state";
import { ClientView } from "@/core/models/master-data/Client.model";
import { ContactView } from "@/core/models/master-data/Contact.model";
import { MasterdataView } from "@/core/models/master-data/MasterData.model";
import { BidderView } from "@/core/models/master-data/Bidder.model";
import { PlannerView } from "@/core/models/master-data/Planner.mode";
import { RecipientView } from "@/core/models/master-data/Recipient.model";
import { OpportunityView } from "@/core/models/master-data/Opportunity.model";
import { BillOfQuantityView } from "@/core/models/master-data/BillOfQuantity.model";
import { SalesView } from "@/core/models/master-data/Sales.model";

export default {
  setMasterdata: (state: MasterdataState, value: MasterdataView) => {
    state.masterdata = value;
  },
  setMasterdataChanged: (state: MasterdataState, value: boolean) => {
    state.masterdataChanged = value;
  },
  setClientList: (state: MasterdataState, value: ClientView[]) => {
    state.clientList = value;
  },
  setSalesAreaList: (state: MasterdataState, value: SalesView[]) => {
    state.salesArea = value;
  },
  setRecipientList: (state: MasterdataState, value: RecipientView[]) => {
    state.recipientList = value;
  },
  setContactList: (state: MasterdataState, value: ContactView[]) => {
    state.contactList = value;
  },
  setBidderList: (state: MasterdataState, value: BidderView[]) => {
    state.bidderList = value;
  },
  setPlannerList: (state: MasterdataState, value: PlannerView[]) => {
    state.plannerList = value;
  },
  setOpportunityList: (state: MasterdataState, value: OpportunityView[]) => {
    state.opportunityList = value;
  },
  setClient: (state: MasterdataState, value: ClientView) => {
    state.masterdata.client = value;
  },
  setRecipient: (state: MasterdataState, value: RecipientView) => {
    state.masterdata.recipient = value;
  },
  setContact: (state: MasterdataState, value: ContactView) => {
    state.masterdata.contact = value;
  },
  setSalesArea: (state: MasterdataState, value: SalesView) => {
    state.masterdata.salesArea = value;
  },
  setBidder: (state: MasterdataState, value: BidderView[]) => {
    state.masterdata.bidder = value;
  },
  setPlanner: (state: MasterdataState, value: PlannerView) => {
    state.masterdata.planner = value;
  },
  setOpportunity: (state: MasterdataState, value: OpportunityView) => {
    state.masterdata.opportunity = value;
  },
  setBillOfQuantity: (state: MasterdataState, value: BillOfQuantityView) => {
    state.masterdata.billOfQuantityStammdaten = value;
  },
};
