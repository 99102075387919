<template>
    <VersionHistory />
</template>

<script lang="ts">
import VersionHistory from "./VersionHistory.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    VersionHistory
}
})

</script>
