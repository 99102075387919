import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "font-italic" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "scrollable full-height" }, {
    default: _withCtx(() => [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$tc("COMPONENTS.HISTORY.CURRENT_VERSION")), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.boqVersion), 1),
      _createElementVNode("h1", null, _toDisplayString(_ctx.$tc("COMPONENTS.HISTORY.VERSIONHISTORY")), 1),
      (_ctx.fetchFailed)
        ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: "auto" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_alert, {
                    type: "error",
                    density: "compact"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.HISTORY.LOADING_ERROR")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.fetchFailed)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nestedTags, (nestedMajor) => {
        return (_openBlock(), _createElementBlock("div", {
          key: nestedMajor.value
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(nestedMajor.minors, (nestedMinor) => {
            return (_openBlock(), _createBlock(_component_v_card, {
              key: nestedMinor.value,
              class: "my-6",
              elevation: "10"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => [
                    _createElementVNode("h2", null, _toDisplayString(_ctx.$tc("COMPONENTS.HISTORY.RELEASE")) + " " + _toDisplayString(nestedMajor.value) + "." + _toDisplayString(nestedMinor.value), 1)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(nestedMinor.patches, (nestedPatch) => {
                      return (_openBlock(), _createBlock(_component_v_row, {
                        key: nestedPatch.value
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: "1" }, {
                            default: _withCtx(() => [
                              _createElementVNode("h3", null, [
                                _createTextVNode(_toDisplayString(nestedMajor.value) + "." + _toDisplayString(nestedMinor.value) + "." + _toDisplayString(nestedPatch.value) + " ", 1),
                                _createVNode(_component_v_icon, {
                                  class: "mb-1",
                                  "align-self": "center",
                                  size: "small",
                                  onClick: ($event: any) => (_ctx.openInformationDialog(nestedPatch))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" mdi-information ")
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"])
                              ])
                            ]),
                            _: 2
                          }, 1024),
                          (nestedPatch.data.release != null)
                            ? (_openBlock(), _createBlock(_component_v_col, { key: 0 }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(nestedPatch.data.release.description.split('\r\n\r\n'), (block) => {
                                    return (_openBlock(), _createElementBlock("ul", { key: block }, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(block.split('\n'), (line) => {
                                        return (_openBlock(), _createElementBlock("li", { key: line }, _toDisplayString(line), 1))
                                      }), 128))
                                    ]))
                                  }), 128))
                                ]),
                                _: 2
                              }, 1024))
                            : (_openBlock(), _createBlock(_component_v_col, { key: 1 }, {
                                default: _withCtx(() => [
                                  _createElementVNode("ul", null, [
                                    _createElementVNode("li", _hoisted_2, _toDisplayString(_ctx.$tc("COMPONENTS.HISTORY.NO_DESCRIPTION")), 1)
                                  ])
                                ]),
                                _: 1
                              })),
                          _createVNode(_component_v_col, { cols: "auto" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(new Date(nestedPatch.data.commit.created_at).toLocaleDateString("de-DE")), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]))
      }), 128)),
      (!_ctx.fetchFailed)
        ? (_openBlock(), _createBlock(_component_v_btn, _mergeProps({ key: 2 }, { disabled: _ctx.reachedEnd }, { onClick: _ctx.loadMore }), {
            default: _withCtx(() => [
              (!_ctx.reachedEnd)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$tc("COMPONENTS.HISTORY.LOAD_MORE")), 1))
                : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$tc("COMPONENTS.HISTORY.NO_FURTHER_RELEASES")), 1))
            ]),
            _: 1
          }, 16, ["onClick"]))
        : _createCommentVNode("", true),
      (_ctx.showTagInfo)
        ? (_openBlock(), _createBlock(_component_v_dialog, {
            key: 3,
            modelValue: _ctx.showTagInfo,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showTagInfo) = $event)),
            "max-width": "1000px"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.HISTORY.VERSION")) + " " + _toDisplayString(_ctx.currentTag?.name), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_subtitle, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(new Date(_ctx.currentTag?.commit.created_at).toLocaleString("de-DE")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _createElementVNode("h4", null, _toDisplayString(_ctx.$tc("COMPONENTS.HISTORY.TITLE")), 1),
                      _createElementVNode("p", null, _toDisplayString(_ctx.currentTag?.message), 1),
                      _createElementVNode("h4", null, _toDisplayString(_ctx.$tc("COMPONENTS.HISTORY.AUTHOR")), 1),
                      _createElementVNode("p", null, _toDisplayString(_ctx.currentTag?.commit.author_name) + " (" + _toDisplayString(_ctx.currentTag?.commit.author_email) + ")", 1),
                      (_ctx.currentTag?.release !== null)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createElementVNode("h4", null, _toDisplayString(_ctx.$tc("COMPONENTS.HISTORY.CHANGES")), 1),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentTag?.release.description.split('\r\n\r\n'), (block) => {
                              return (_openBlock(), _createElementBlock("ul", {
                                key: block,
                                class: "mb-5"
                              }, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(block.split('\n'), (line) => {
                                  return (_openBlock(), _createElementBlock("li", { key: line }, _toDisplayString(line), 1))
                                }), 128))
                              ]))
                            }), 128))
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}