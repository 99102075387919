import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, { ref: "myForm" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { class: "text-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.POSITION.POSITIONDRAGDROPDIALOG.MOVE_ELEMENT")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.multiplePositions
            ? _ctx.$tc("COMPONENTS.POSITION.POSITIONDRAGDROPDIALOG.MULTIPLE_SELECTED_ELEMENTS")
            : _ctx.$tc("COMPONENTS.POSITION.POSITIONDRAGDROPDIALOG.SINGLE_SELECTED_ELEMENT")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_divider),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                variant: "outlined",
                onClick: _ctx.close
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.POSITION.POSITIONDRAGDROPDIALOG.CANCEL")), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                class: "dark-btn",
                elevation: "0",
                onClick: _ctx.moveSameLevel
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.POSITION.POSITIONDRAGDROPDIALOG.MOVE_ON_SAME_LEVEL")), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_btn, {
                class: "dark-btn",
                elevation: "0",
                onClick: _ctx.moveChild
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.POSITION.POSITIONDRAGDROPDIALOG.MOVE_AS_CHILD")), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 512))
}