<template>
  <v-app-bar color="primary">
    <div class="d-flex align-center">
      <v-app-bar-nav-icon @click="$emit('click:drawer-icon')" />
      <viega-logo style="width: 58px;" />
    </div>
    <v-spacer />
    <h3 v-if="isTestSystem()" class="ma-3">
      {{ $t("COMPONENTS.NAVIGATIONBAR.TESTSYSTEM") }} <v-icon class="ma-1">
        mdi-wrench
      </v-icon>
    </h3>
    <v-spacer />

    <div style="color: transparent; font-size: 10px">
      {{ boqVersion }}
    </div>
    <v-btn
      v-if="currentUser"
      color="grey-darken-2"
      prepend-icon="mdi-account"
      variant="elevated"
      @click="navigateToProfile"
    >
      <!-- TODO: Profilbild vom eingeloggten Nutzer -->
      {{ $tc('COMPONENTS.NAVIGATIONBAR.PROFIL') }}
    </v-btn>
    <v-btn
      v-if="!currentUser"
      class="ml-2"
      color="grey-darken-2"
      prepend-icon="mdi-login"
      variant="elevated"
      @click="navigateToLogin"
    >
      Login
    </v-btn>
    <v-btn
      v-if="currentUser"
      class="ml-2"
      color="grey-darken-2"
      prepend-icon="mdi-logout"
      variant="elevated"
      @click="logout"
    >
      Logout
    </v-btn>
  </v-app-bar>
</template>

<script lang="ts">
import { User } from "@/core/models/user/User.model";
import { defineComponent } from "vue";
import ViegaLogo from "./ViegaLogo.vue";

export default defineComponent({
    data() {
        return {
            boqVersion: "0.0.0"
        };
    },
    computed: {
        currentUser(): User {
            return this.$store.getters["user/currentUser"];
        }
    },
    mounted() {
        this.boqVersion = process.env.VUE_APP_BOQ_VERSION as string;
    },
    methods: {
        navigateToProfile() {
            this.$router.push({ name: "UserProfile" });
        },
        navigateToLogin() {
            this.$router.push({ name: "Login" });
        },
        logout() {
            this.$store.dispatch("user/setCurrentUser", null);
            this.$store.dispatch("auth/setToken", "");
            if (process.env.NODE_ENV === "production") {
                window.location.href = process.env.VUE_APP_LOGOUT_URL_VIEGA_AD as string;
            }
        },
        isTestSystem() {
            return process.env.VUE_APP_API_URL === "https://viega-boq.projekt.explicatis.com:443/api";
        }
    },
    components: { ViegaLogo }
})

</script>
