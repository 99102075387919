<template>
  <v-form ref="importForm" @submit="submit($event)">
    <v-card :loading="loading">
      <v-card-text>
        <v-file-input
          :label="$tc('COMPONENTS.POSITION.IMPORTPOSITION.UPLOAD_FILE')"
          variant="outlined"
          accept=".xls,.xlsx,.csv"
          show-size
          :multiple="false"
          density="compact"
          @update:model-value="onFileSelected"
          :rules="[(v) => !!v || $tc('COMPONENTS.POSITION.POSITIONARTICLEDIALOG.RULES.NOT_EMPTY')]"
        />
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn variant="outlined" @click="closeImportDialog">
          {{ $tc("COMPONENTS.POSITION.IMPORTPOSITION.CANCEL") }}</v-btn
        >
        <v-btn class="dark-btn" elevation="0" type="submit">
          {{ $tc("COMPONENTS.POSITION.IMPORTPOSITION.SAVE") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script lang="ts">
import axios, { AxiosResponse } from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
    data() {
        const successAlert: any = {
                type: "success",
                message: this.$tc("COMPONENTS.POSITION.IMPORTPOSITION.SUCCESS"),
              };
        const errorAlert: any = {
                type: "error",
                message: this.$tc("COMPONENTS.POSITION.IMPORTPOSITION.ERROR"),
              };
        const selectedFile = ref<File | null>(null);

        return {
            submitted: false,
            selectedFile,
            errorAlert,
            successAlert,
            loading: false
        };
    },
    methods: {
        closeImportDialog() {
            this.$emit("closeImportDialog");
        },
        submit(event: any) {
            event.preventDefault();
                if (this.selectedFile === null) return;
                this.loading = true;
                this.submitted = true;
                const formData = new FormData();
                formData.append("file", this.selectedFile, this.selectedFile.name);
                formData.append("quotationId", String(this.quotationId));
                if (this.positionId) formData.append("positionId", String(this.positionId));

                axios
                  .post("/file-import/excel/to-quotation", formData)
                  .then((response) => {
                    this.handleResponse(response);
                    this.loading = false;
                  })
                  .catch((response) => {
                    try {
                      this.handleError(response);
                    } catch (err) {
                      console.error(err);
                    }
                    this.loading = false;
                  });
        },
        handleResponse(response: any) {
            console.log(response);
            if (response.status === 201) {
              this.$store.commit("activateAlert", this.successAlert);
              this.emitSuccess();
              this.clearForm();
              this.$emit("closeImportDialog");
            } else {
              this.handleError(response);
            }
        },
        handleError(response: AxiosResponse) {
            this.submitted = false;
            this.$store.commit("activateAlert", response.data.error);
        },
        clearForm() {
            this.submitted = false;
            this.importForm.reset();
        },
        onFileSelected(files: File[]) {
          if (files.length > 0) {
            this.selectedFile = files[0];
          }
        },
        emitSuccess(): void {
            this.$emit("success");
        }
    },
    props: {
        quotationId: {
            type: Number
        },
        positionId: {
            type: Number
        }
    },
    computed: {
        importForm() {
            return this.$refs["importForm"] as HTMLFormElement;
        }
    }
})

</script>

<style lang="scss" scoped>
.inline {
  display: inline;
}
</style>
