import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      (_ctx.addMode)
        ? (_openBlock(), _createBlock(_component_v_card_title, { key: 0 }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.SETTINGS.UNITDIALOG.ADD_UNIT")), 1)
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_v_card_title, { key: 1 }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.SETTINGS.UNITDIALOG.CHANGE_UNIT")), 1)
            ]),
            _: 1
          })),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { align: "center" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                ref: "unit-input",
                class: "mr-4",
                modelValue: _ctx.internalUnit.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalUnit.value) = $event)),
                label: _ctx.$tc('COMPONENTS.SETTINGS.UNITDIALOG.UNIT'),
                onKeydown: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('buttonClicked', _ctx.internalUnit)), ["enter"]))
              }, null, 8, ["modelValue", "label"]),
              (_ctx.currentUser.role == 'ROLE_ADMIN')
                ? (_openBlock(), _createBlock(_component_v_select, {
                    key: 0,
                    ref: "unit-input",
                    modelValue: _ctx.internalUnit.marketId,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.internalUnit.marketId) = $event)),
                    items: _ctx.marketList,
                    label: _ctx.$tc('COMPONENTS.SETTINGS.UNITDIALOG.COUNTRY'),
                    "item-value": "id",
                    "item-title": "name",
                    onKeydown: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.$emit('buttonClicked', _ctx.internalUnit)), ["enter"]))
                  }, null, 8, ["modelValue", "items", "label"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, { class: "justify-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('buttonClicked', _ctx.internalUnit))),
            class: "dark-btn"
          }, {
            default: _withCtx(() => [
              (_ctx.addMode)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_v_icon, { start: "" }, {
                      default: _withCtx(() => [
                        _createTextVNode("mdi-plus")
                      ]),
                      _: 1
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$tc("COMPONENTS.SETTINGS.UNITDIALOG.ADD_UNIT")), 1)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_v_icon, { start: "" }, {
                      default: _withCtx(() => [
                        _createTextVNode("mdi-pencil")
                      ]),
                      _: 1
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$tc("COMPONENTS.SETTINGS.UNITDIALOG.CHANGE")), 1)
                  ]))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}