export enum BoxType {
  START = "START",
  END = "END",
  NEW_BOX = "NEW_BOX",
  POSITION_NUMBER = "POSITION_NUMBER",
  NOT_POSITION_NUMBER = "NOT_POSITION_NUMBER",
  QUANTITY = "QUANTITY",
  UNIT = "UNIT",
  ARTICLE_NUMBER = "ARTICLE_NUMBER",
}

export interface ModifyOverlayBoxDto {
  value: string | null;
  boxType: BoxType | null;
  page: number;
  line: number;
  word: number;
  positionNumber: string;
}

export class ModifyOverlayBox implements ModifyOverlayBoxDto {
  value = "";
  boxType = BoxType.POSITION_NUMBER;
  page = -1;
  line = -1;
  word = -1;
  positionNumber = "";

  constructor(value: string, boxType: BoxType, page: number, line: number, word: number) {
    this.value = value;
    this.boxType = boxType;
    this.page = page;
    this.line = line;
    this.word = word;
    this.positionNumber = `${page}-${line}-${word}`;
  }
}

export interface ModifyOverlayDto {
  // data: Map<BoxType, ModifyOverlayBoxDto[]>;
  list: ModifyOverlayBoxDto[];
  noPositionNumbers: boolean;
  positionStructure?: string;
}

export class ModifyOverlay implements ModifyOverlayDto {
  // data: Map<BoxType, ModifyOverlayBoxDto[]>;
  list = [] as ModifyOverlayBoxDto[];
  noPositionNumbers = false;

  constructor() {
    // this.list.push(new ModifyOverlayBox(1, 2, 3));
    // this.data = new Map<BoxType, ModifyOverlayBoxDto[]>();
    // this.data.set(BoxType.POSITION_NUMBER, [] as ModifyOverlayBox[]);
    // this.data.set(BoxType.QUANTITY, [] as ModifyOverlayBox[]);
    // this.data.set(BoxType.UNIT, [] as ModifyOverlayBox[]);
    // this.data.set(BoxType.ARTICLE_NUMBER, [] as ModifyOverlayBox[]);
    // ([
    //   [BoxType.POSITION_NUMBER, [] as ModifyOverlayBox[]],
    //   [BoxType.QUANTITY, [] as ModifyOverlayBox[]],
    //   [BoxType.UNIT, [] as ModifyOverlayBox[]],
    //   [BoxType.ARTICLE_NUMBER, [] as ModifyOverlayBox[]],
    // ]);
  }
}
