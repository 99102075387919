import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "content-title" }
const _hoisted_2 = { class: "text-wrap content-message" }
const _hoisted_3 = { class: "content-title" }
const _hoisted_4 = {
  key: 0,
  class: "text-wrap content-message"
}
const _hoisted_5 = {
  key: 1,
  class: "text-wrap content-message"
}
const _hoisted_6 = { class: "content-title" }
const _hoisted_7 = { class: "text-wrap content-message" }
const _hoisted_8 = { class: "content-title" }
const _hoisted_9 = { class: "text-wrap content-message" }
const _hoisted_10 = { class: "content-title" }
const _hoisted_11 = { class: "positionArticels" }
const _hoisted_12 = { class: "content-title" }
const _hoisted_13 = {
  key: 0,
  class: "editArticle"
}
const _hoisted_14 = { class: "content-title" }
const _hoisted_15 = { class: "text-wrap content-message" }
const _hoisted_16 = { class: "content-title" }
const _hoisted_17 = { class: "text-wrap content-message" }
const _hoisted_18 = { class: "content-title" }
const _hoisted_19 = { class: "text-wrap content-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_responsive = _resolveComponent("v-responsive")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_ArticleDetailDialog = _resolveComponent("ArticleDetailDialog")!
  const _component_PositionArticleDialog = _resolveComponent("PositionArticleDialog")!
  const _component_DeleteArticleDialog = _resolveComponent("DeleteArticleDialog")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createBlock(_component_v_container, {
    class: "position-content",
    "mb-1": "",
    "py-0": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { lg: "6" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$tc("COMPONENTS.POSITION.POSITION_CONTENT.POS_NR")), 1),
                      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.item.position.positionNumber), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { lg: "6" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$tc("COMPONENTS.POSITION.POSITION_CONTENT.ARTICLE.NR")), 1),
                      (_ctx.item.position.positionArticleNumber !== -1)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.item.position.positionArticleNumber), 1))
                        : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$tc("COMPONENTS.POSITION.POSITION_CONTENT.NOT_AVAILABLE")), 1))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { lg: "6" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$tc("COMPONENTS.POSITION.POSITION_CONTENT.QUANTITY")), 1),
                      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.item.position.positionQuantity), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { lg: "6" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$tc("COMPONENTS.POSITION.POSITION_CONTENT.UNIT")), 1),
                      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.item.position.positionUnit), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_container, { class: "px-0 text-wrap" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$tc("COMPONENTS.POSITION.POSITION_CONTENT.POSITION_TEXT")), 1),
                  _createVNode(_component_v_responsive, {
                    class: "content-message overflow-y-auto",
                    "max-height": "300px"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.item.position.positionText), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_11, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.position.articles, (articleObject) => {
          return (_openBlock(), _createBlock(_component_v_row, {
            key: articleObject.id
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$tc("COMPONENTS.POSITION.POSITION_CONTENT.ARTICLE.VIEGA")), 1),
                  _createVNode(_component_ArticleDetailDialog, {
                    article: articleObject.article
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createElementVNode("div", _mergeProps(props, { class: "text-wrap content-message-article" }), _toDisplayString(articleObject.article.artikelnummer ? articleObject.article.artikelnummer : "-"), 17)
                    ]),
                    _: 2
                  }, 1032, ["article"]),
                  (!_ctx.dialogFastEntry)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _createVNode(_component_PositionArticleDialog, {
                          position: _ctx.item.position,
                          "article-object": articleObject,
                          onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeArticle($event)))
                        }, null, 8, ["position", "article-object"]),
                        _createVNode(_component_DeleteArticleDialog, {
                          "position-article-id": articleObject.id,
                          onRemove: ($event: any) => (_ctx.removeArticle($event, articleObject.id))
                        }, null, 8, ["position-article-id", "onRemove"])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$tc("COMPONENTS.POSITION.POSITION_CONTENT.TYPE")), 1),
                  _createElementVNode("div", _hoisted_15, _toDisplayString(articleObject.type ? _ctx.getArticleType(articleObject.type) : "Standard"), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_v_col, { cols: "4" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$tc("COMPONENTS.POSITION.POSITION_CONTENT.QUOTATION_TEXT")), 1),
                  _createElementVNode("div", _hoisted_17, _toDisplayString(articleObject.article.angebotstext ? articleObject.article.angebotstext : "-"), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$tc("COMPONENTS.POSITION.POSITION_CONTENT.QUANTITY")), 1),
                  _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.quantityString(articleObject.quantity)), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                variant: "outlined",
                "prepend-icon": "mdi-chevron-left",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showPreviousItem()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.POSITION.POSITION_CONTENT.BACK")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (!_ctx.dialogFastEntry)
            ? (_openBlock(), _createBlock(_component_v_col, {
                key: 0,
                cols: "auto"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_PositionArticleDialog, {
                    position: _ctx.item.position,
                    onArticleAdded: _cache[2] || (_cache[2] = ($event: any) => (_ctx.addArticle($event)))
                  }, null, 8, ["position"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_v_col, { cols: "auto" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                class: "dark-btn",
                elevation: "0",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showNextItem()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.POSITION.POSITION_CONTENT.NEXT")) + " ", 1),
                  _createVNode(_component_v_icon, {
                    color: "white",
                    end: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" mdi-chevron-right ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}