<template>
  <v-card v-bind="{ loading: submitted, disabled: submitted }" class="text-center">
    <v-card-title text-align-self="center">
      {{
        multiplePositions && multiplePositions.length > 1
          ? $tc("COMPONENTS.POSITION.DELETEPOSITIONDIALOG.DELETE.MULTIPLE_ELEMENTS")
          : $tc("COMPONENTS.POSITION.DELETEPOSITIONDIALOG.DELETE.SINGLE_ELEMENT")
      }}
    </v-card-title>

    <v-card-subtitle>
      <v-icon size="100" color="error">mdi-help-circle</v-icon>
    </v-card-subtitle>

    <v-card-text>
      {{
        multiplePositions && multiplePositions.length > 1
          ? $tc("COMPONENTS.POSITION.DELETEPOSITIONDIALOG.DELETE.SELECTED_ELEMENTS")
          : `${$tc(
              "COMPONENTS.POSITION.DELETEPOSITIONDIALOG.DELETE.SELECTED_TYPE.1"
            )} ${positionType} ${$tc(
              "COMPONENTS.POSITION.DELETEPOSITIONDIALOG.DELETE.SELECTED_TYPE.2"
            )}`
      }}
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-checkbox
        v-if="canHaveChildren()"
        v-model="deleteChildren"
        :label="$tc('COMPONENTS.POSITION.DELETEPOSITIONDIALOG.DELETE.SUBPOSITION')"
      ></v-checkbox>
      <v-spacer></v-spacer>
      <v-btn variant="outlined" @click="cancel()">
        {{ $tc("COMPONENTS.POSITION.DELETEPOSITIONDIALOG.CANCEL") }}
      </v-btn>
      <v-btn class="dark-btn" elevation="0" @click="submit()">
        {{ $tc("COMPONENTS.POSITION.DELETEPOSITIONDIALOG.DELETE.TITLE") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    data() {
        const errorExistingChildrenAlert: any = {
                type: "error",
                message: this.$tc("COMPONENTS.POSITION.DELETEPOSITIONDIALOG.MESSAGE.CHILDREN"),
              };
        const errorNotFoundAlert: any = {
                type: "error",
                message: this.$tc("COMPONENTS.POSITION.DELETEPOSITIONDIALOG.MESSAGE.ERROR"),
              };
        const successAlert: any = {
                type: "success",
                message: this.$tc("COMPONENTS.POSITION.DELETEPOSITIONDIALOG.MESSAGE.SUCCESS"),
              };

        return {
            deleteChildren: false,
            submitted: false,
            successAlert,
            errorNotFoundAlert,
            errorExistingChildrenAlert
        };
    },
    methods: {
        canHaveChildren() {
            switch (this.positionType) {
              case "LOS":
              case "GEWERK":
              case "TITEL":
              case "GRUPPE":
              case "GRUPPE_ALT":
              case "GRUPPE_EVT":
                return true;
              default:
                return false;
            }
        },
        submit() {
            this.submitted = true;

                //Even thoug it is a post request, it deletes the entries
                axios({
                  method: "post",
                  url: `/position/${this.positionId}/delete`,
                  params: { deleteChildren: this.deleteChildren },
                  data: this.multiplePositions ?? [],
                })
                  .then((response) => {
                    switch (response.status) {
                      case 200:
                        this.$store.commit("activateAlert", this.successAlert);
                        this.clearDialog();
                        this.$emit("positionDeleted", response.data);
                        break;
                      default:
                        this.handleError();
                        break;
                    }
                  })
                  .catch((error) => {
                    switch (error.response.status) {
                      case 404: // Not Found
                        this.$store.commit("activateAlert", this.errorNotFoundAlert);
                        this.clearDialog();
                        break;
                      case 409: // Conflict (child-elements exist)
                        this.$store.commit("activateAlert", this.errorExistingChildrenAlert);
                        this.clearDialog();
                        break;
                      default:
                        this.handleError();
                        break;
                    }
                  });
        },
        handleError() {
            this.$store.commit("activateAlert", this.$store.state.errorAlert);
            this.submitted = false;
        },
        clearDialog() {
            this.submitted = false;
        },
        cancel() {
            this.$emit("close");
        }
    },
    props: {
        positionId: {
            type: Object as PropType<any>
        },
        positionType: {
            type: Object as PropType<any>
        },
        multiplePositions: {
            type: Array as PropType<any[]>
        }
    }
})

</script>

<style lang="scss" scoped>
.v-card-title {
  display: block; //weil der Card-Title sonst nicht centered wird
}
</style>
