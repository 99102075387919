import { User, UserDto } from "@/core/models/user/User.model";
import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import store from "@/core/store";
import { i18nGlobalTc } from "@/i18n";

const resource = "user";

export function loadUserList(): Promise<User[]> {
  return axios.get(`/${resource}`).then((response) => {
    return response.data.map((item: UserDto) => new User(item));
  });
}

export async function loadUserDetail(userId: number): Promise<User> {
  const params = {
    userid: userId,
  };
  const response = await axios.get(`/user/${userId}`, { params });
  return response.data;
}

export function updateUser(userId: number, user: User): Promise<any> {
  return axios.put(`/user/${userId}`, user).then((response: AxiosResponse) => {
    if (response.status === 200) {
      store.commit(
        "activateAlert",
        {
          type: "success",
          message: i18nGlobalTc("CORE.SERVICES.USER.USER_SERVICES.USER_CHANGE_SUCCESS"),
        },
        { root: true }
      );
    } else {
      store.commit(
        "activateAlert",
        {
          type: "error",
          message: i18nGlobalTc("CORE.SERVICES.USER.USER_SERVICES.USER_CHANGE_ERROR"),
        },
        { root: true }
      );
    }
    return response;
  });
}

export function updateUserProfile(userId: number, sap: string): Promise<any> {
  const config: AxiosRequestConfig = {};
  config.params = {
    sap: sap,
  };

  return axios.patch(`/user/${userId}`, undefined, config).then((response: AxiosResponse) => {
    if (response.status === 200) {
      store.commit(
        "activateAlert",
        {
          type: "success",
          message: i18nGlobalTc("CORE.SERVICES.USER.USER_SERVICES.USER_CHANGE_SUCCESS"),
        },
        { root: true }
      );
    } else {
      store.commit(
        "activateAlert",
        {
          type: "error",
          message: i18nGlobalTc("CORE.SERVICES.USER.USER_SERVICES.USER_CHANGE_ERROR"),
        },
        { root: true }
      );
    }
    return response;
  });
}

export function createUser(user: User): Promise<any> {
  return axios.post(`/user`, user).then((response: AxiosResponse) => {
    if (response.status === 200) {
      store.commit(
        "activateAlert",
        {
          type: "success",
          message: i18nGlobalTc("CORE.SERVICES.USER.USER_SERVICES.USER_CREATE_SUCCESS"),
        },
        { root: true }
      );
    } else {
      store.commit(
        "activateAlert",
        {
          type: "error",
          message: i18nGlobalTc("CORE.SERVICES.USER.USER_SERVICES.USER_CREATE_ERROR"),
        },
        { root: true }
      );
    }
    return response;
  });
}

export function getCurrentUser(): Promise<User> {
  return axios
    .get(`/${resource}/current`)
    .then((user) => {
      return user.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function deleteUser(userId: string): Promise<AxiosResponse<any>> {
  return axios
    .delete(`/${resource}/${userId}`)
    .then((response) => {
      if (response.status === 200) {
        store.commit(
          "activateAlert",
          {
            type: "success",
            message: i18nGlobalTc("CORE.SERVICES.USER.USER_SERVICES.USER_DELETE_SUCCESS"),
          },
          { root: true }
        );
      }
      return response;
    })
    .catch((response) => {
      store.commit(
        "activateAlert",
        {
          type: "error",
          message: i18nGlobalTc("CORE.SERVICES.USER.USER_SERVICES.USER_DELETE_ERROR"),
        },
        { root: true }
      );
      return response;
    });
}
