import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-440ff971"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_BoqDataTable = _resolveComponent("BoqDataTable")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "customer-selection" }, {
    default: _withCtx(() => [
      (Object.keys({ ..._ctx.getConfig.filter }).length === 0)
        ? (_openBlock(), _createBlock(_component_v_alert, {
            key: 0,
            class: "mt-3",
            density: "compact",
            variant: "text",
            type: "info"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.OPPORTUNITY_SELECTION.USE_FILTER")), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_BoqDataTable, {
          ref: "dataTable",
          headers: _ctx.headers,
          items: _ctx.opportunityList,
          singleSelect: true,
          showSelect: true,
          itemKey: "number",
          loading: _ctx.loading,
          "get-config": _ctx.getConfig,
          "onUpdate:getConfig": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.getConfig) = $event)),
          debounceTime: 0,
          onItemSelected: _ctx.onItemSelected,
          onEnterPressed: _ctx.loadOpportunities
        }, null, 8, ["headers", "items", "loading", "get-config", "onItemSelected", "onEnterPressed"])
      ]),
      _createVNode(_component_v_row, {
        class: "flex-grow-0",
        justify: "start"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "auto" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                variant: "outlined",
                "prepend-icon": "mdi-magnify",
                disabled: _ctx.loading,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loadOpportunities()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$tc('COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.OPPORTUNITY_SELECTION.SEARCH_AGAIN')), 1)
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}