<template>
  <v-data-table
    v-model="selected"
    :calculate-widths="true"
    :disable-pagination="disablePaginate"
    :footer-props="{
      itemsPerPageText: $t('SHARED.COMPONENTS.DATATABLE.ITEMS_PER_PAGE'),
      itemsPerPageAllText: $t('SHARED.COMPONENTS.DATATABLE.ALL'),
    }"
    :headers="headers"
    :filter-keys="(headers.filter(h => h.filterable).map(h => h.key).filter(key => !!key) as string[])"
    :height="height"
    :hide-default-footer="disablePaginate"
    :item-key="itemKey"
    :items="filteredItems"
    return-object
    :loading="loading"
    :show-select="showSelect"
    :select-strategy="singleSelect ? 'single' : undefined"
    class="boq-data-table fill-height"
    disable-sort
    @update:options="$emit('optionsChanged', $event)"
  >
    <template #[`body.prepend`]="{}">
      <tr class="filter-row">
        <td v-if="showSelect" />
        <td v-for="(header, index) in headers" :key="header.value" class="filter-column">
          <v-select
            v-if="header.filterable && header.type == 'select'"
            v-model="filterValues[index]"
            :items="marketList"
            clearable
            item-title="name"
            item-value="id"
            placeholder="Filter"
            density="compact"
            rounded
            hide-details
            variant="solo-filled"
            @update:model-value="debouncedFilterColumn()"
            @keydown.enter="$emit('enterPressed')"
          />
          <v-text-field
            v-if="header.filterable && !header.type"
            v-model="filterValues[index]"
            clearable
            density="compact"
            hide-details
            placeholder="Filter"
            prepend-inner-icon="mdi-magnify"
            rounded
            variant="solo-filled"
            @update:model-value="debouncedFilterColumn()"
            @keydown.enter="$emit('enterPressed')"
          />
          <v-menu
            v-if="header.filterable && header.type === 'date'"
            v-model="dateMenu[index]"
            :close-on-content-click="false"
            transition="scale-transition"
          >
            <template #activator="{ props }">
              <v-text-field
                :model-value="transformToDateString(filterValues[index] as string)"
                clearable
                density="compact"
                hide-details
                placeholder="Filter"
                prepend-inner-icon="mdi-calendar"
                readonly
                rounded
                variant="solo-filled"
                v-bind="props"
                @click:clear="
                  filterValues[index] = '';
                  debouncedFilterColumn();
                "
              />
            </template>
            <v-date-picker
              :model-value="(filterValues[index] as string)"
              color="primary"
              @update:model-value="
                filterValues[index] = $event;
                dateMenu[index] = false;
                debouncedFilterColumn();
              "
            />
          </v-menu>
          <v-menu
            v-if="header.filterable && header.type === 'date-range'"
            v-model="dateMenu[index]"
            :close-on-content-click="false"
            transition="scale-transition"
          >
            <template #activator="{ props }">
              <v-text-field
                :model-value="transformToDateStringFromDateRange(filterValues[index] as string[])"
                clearable
                density="compact"
                variant="solo-filled"
                hide-details
                placeholder="Filter"
                prepend-inner-icon="mdi-calendar"
                readonly
                rounded
                v-bind="props"
                @click:clear="
                  filterValues[index] = [];
                  debouncedFilterColumn();
                "
              />
            </template>
            <v-date-picker
              :model-value="filterValues[index] as string[]"
              color="primary"
              multiple
              @update:model-value="
                filterValues[index] = $event;
                (filterValues[index] as string).length === 2 ? debouncedFilterColumn() : null;
              "
            />
          </v-menu>
        </td>
      </tr>
    </template>

    <!-- pass throug slots -->
    <template v-for="scopedSlotName in Object.keys($slots)" #[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { formatDate } from "@/core/helper/date-time";
import GetConfig from "@/core/services/base/get.config";
import { BoqTableHeader } from "@/core/interfaces/boq-table-header.interface";
import { BidderDto } from "@/core/models/master-data/Bidder.model";
import { Market } from "@/core/models/market/market.model";
import { PartnerObj } from "@/core/models/master-data/Client.model";
import { defineComponent, PropType } from "vue";
import { useDebounceFn } from '@vueuse/core'

type FilterValue = string | string[] | number | boolean | undefined // | null

export default defineComponent({
    props: {
        headers: { default: () => [],
            type: Array as PropType<BoqTableHeader[]>
        },
        filterable: { default: true,
            type: Boolean
        },
        disableSort: { default: false,
            type: Boolean
        },
        disablePaginate: { default: false,
            type: Boolean
        },
        items: {
          default: () => [],
          type: Array as PropType<Array<unknown>>
        },
        singleSelect: { default: false,
            type: Boolean
        },
        showSelect: { default: false,
            type: Boolean
        },
        preselected: { default: null,
            type: Object as PropType<any>
        },
        itemKey: { default: null,
            type: String
        },
        height: { default: null,
            type: Object as PropType<string | number>
        },
        loading: { default: false,
            type: Boolean
        },
        getConfig: { default: GetConfig.default,
            type: Object as PropType<GetConfig>
        },
        debounceTime: { default: 500,
            type: Number
        },
        selectedBidders: { default: () => [],
            type: Array as PropType<BidderDto[]>
        },
        partnerObjects: { default: () => [],
            type: Array as PropType<PartnerObj[]>
        }
    },
    data() {
        const selected: BidderDto[] = this.selectedBidders;
        const dateMenu: boolean[] = [];
        const filterValues: FilterValue[] = [];
        const debouncedFilterColumn = useDebounceFn(() => {}, this.debounceTime);

        return {
            locale: "en",
            filterValues,
            dateMenu,
            selected,
            debouncedFilterColumn,
        };
    },
    computed: {
        filteredItems() {
            if (typeof this.items == "string") {
              return [];
            }
            return this.items;
        },
        marketList(): Market[] {
            return this.$store.getters["market/market"];
        }
    },
    watch: {
        "selected": [{
            handler: "onSelectedChange"
        }],
        "selectedBidders": [{
            handler: "onSelectedBiddersChange"
        }]
    },
    async mounted() {
        this.locale = this.$i18n.locale;
        this.debouncedFilterColumn = useDebounceFn(() => {
            this.filterColumn();
        }, this.debounceTime)
        if (this.preselected) {
          this.selected.push(this.preselected);
        }
    },
    methods: {
        initFiltersFromGetConfig(getConfig: GetConfig) {
            if (getConfig.filter) {
                  Object.keys(getConfig.filter).forEach((key) => {
                    const header = this.headers.find((h) => h.value === key);
                    if (header) {
                      const index = this.headers.findIndex((h) => h === header);
                      if (header.type === "select") {
                        if (getConfig.filter?.[key] === "false") {
                          this.filterValues[index] = false;
                        } else if (getConfig.filter?.[key] === "true") {
                          this.filterValues[index] = true;
                        } else {
                          this.filterValues[index] = parseInt("" + getConfig.filter?.[key]);
                        }
                      } else if (header.type === "date-range") {
                        this.filterValues[index] = [];
                      } else {
                        this.filterValues[index] = getConfig.filter?.[key];
                      }
                    }
                  });
                }

                //execute filter
                this.filterColumn();
        },
        filterColumn() {
            const getConfig = { ...this.getConfig };
                this.headers.forEach((header, index) => {
                  const column = header;
                  let value = this.filterValues[index];

                  if (value !== "" && value !== null && value !== undefined) {
                    if (column.type == "date") value = new Date(value as string).toISOString();
                    else if (column.type == "date-range" && Array.isArray(value)) {
                      if (value.length === 2) {
                        value[0] = new Date(value[0] as string).toISOString();
                        value[1] = new Date(value[1] as string).toISOString();
                      } else {
                        value = [];
                      }
                    } else value = value.toString();

                    if (column.type == "date-range" && Array.isArray(value)) {
                      if (value.length === 2) {
                        getConfig.filter = {
                          ...getConfig.filter,
                          [`${column.value}Start`]: value[0],
                          [`${column.value}End`]: value[1]
                        };
                      } else {
                        if (getConfig.filter) {
                          delete getConfig.filter[`${column.value}Start`];
                          delete getConfig.filter[`${column.value}End`];
                        }
                      }
                    } else if (typeof value === "string") {
                      getConfig.filter = {
                        ...getConfig.filter,
                        [column.value]: value
                      };
                    }
                  } else if (getConfig.filter) {
                    delete getConfig.filter[column.value];
                  }
                });

                this.$emit("update:get-config", getConfig);
        },
        transformToDateString(filterValue: string | Date): string {
            if (filterValue) {
              return formatDate(filterValue);
            }
            return filterValue;
        },
        transformToDateStringFromDateRange(filterValue: string[] | Date[]) {
            if (!filterValue || filterValue?.length === 0) return "";
            let dateStart = "";
            let dateEnd = "";
            if (filterValue.length > 0) {
              dateStart = formatDate(filterValue[0]);
              if (filterValue.length > 1) {
                dateEnd = formatDate(filterValue[1]);
              }
            }
            return [dateStart, dateEnd].join(" - ");
        },
        selectPreselected(object: any) {
            if (object) {
              this.selected.push(object);
            }
        },
        onSelectedChange() {
            this.$emit("itemSelected", this.selected);
        },
        onSelectedBiddersChange() {
            this.selected = this.selectedBidders;
        }
    }
})

</script>

<style lang="scss" scoped>
@use "@/style/styles";
//filter box
.boq-data-table:deep(*) {
  thead {
    background: styles.$primary !important;

    span {
      color: white !important;
    }
  }

  tbody {
    tr:not(.filter-row):hover {
      background-color: #ddd;
    }
  }

  .v-data-table-header {
    th {
      background: styles.$primary !important;
    }
  }

  .v-input {
    min-width: 150px;
    max-width: 300px;
  }
  .v-input, .v-input .v-field {
    font-size: 0.75rem !important;
  }

  .v-field__field {
    padding: 0 4px !important;
    height: 30px !important;
    min-height: 30px !important;

    .v-field__input {
      min-height: 30px;
      height: 30px;
    }
  }

  //icon in filter box
  .v-field__prepend-inner .v-icon,
  .v-field__append-inner .v-icon,
  .v-input__icon--clear .v-icon {
    font-size: 16px;
  }

  .v-data-table__wrapper {
    height: 90%;
    overflow: auto;
  }

  //table header
  .v-data-table-header th {
    padding: 9px 12px !important;
    height: 25px !important;
  }

  .v-text-field {
    margin-top: 0;
  }

  table .filter-row td {
    position: sticky !important;
  }

  .filter-row {
    background: styles.$primary;

    .filter-column {
      padding: 0 10px 10px 10px;
    }
  }

  .v-text-field .v-field__input {
    padding-inline-end: 0 !important;
    padding-inline-start: 0.75rem !important;
  }

  .v-text-field:not(.v-select) .v-field__input {
    padding-inline-start: 0 !important;
  }
}

.boq-disabled {
  color: styles.$secondary;
}

.status-cancelled {
  color: red;
}

// V-select in Filter --> In different stages, the element has different classes
div.v-input.theme--light.v-text-field.v-text-field--is-booted.v-text-field--placeholder.v-select,
div.v-input.v-input--is-label-active.v-input--is-dirty.theme--light.v-text-field.v-text-field--is-booted.v-text-field--placeholder.v-select,
div.v-input.v-input--is-label-active.v-input--is-dirty.v-input--is-focused.theme--light.v-text-field.v-text-field--is-booted.v-text-field--placeholder.v-select.primary--text {
  padding-top: 22px;
}
</style>
