import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-center" }
const _hoisted_2 = {
  key: 0,
  class: "ma-3"
}
const _hoisted_3 = { style: {"color":"transparent","font-size":"10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_viega_logo = _resolveComponent("viega-logo")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!

  return (_openBlock(), _createBlock(_component_v_app_bar, { color: "primary" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_app_bar_nav_icon, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click:drawer-icon')))
        }),
        _createVNode(_component_viega_logo, { style: {"width":"58px"} })
      ]),
      _createVNode(_component_v_spacer),
      (_ctx.isTestSystem())
        ? (_openBlock(), _createElementBlock("h3", _hoisted_2, [
            _createTextVNode(_toDisplayString(_ctx.$t("COMPONENTS.NAVIGATIONBAR.TESTSYSTEM")) + " ", 1),
            _createVNode(_component_v_icon, { class: "ma-1" }, {
              default: _withCtx(() => [
                _createTextVNode(" mdi-wrench ")
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_v_spacer),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.boqVersion), 1),
      (_ctx.currentUser)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 1,
            color: "grey-darken-2",
            "prepend-icon": "mdi-account",
            variant: "elevated",
            onClick: _ctx.navigateToProfile
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$tc('COMPONENTS.NAVIGATIONBAR.PROFIL')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true),
      (!_ctx.currentUser)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 2,
            class: "ml-2",
            color: "grey-darken-2",
            "prepend-icon": "mdi-login",
            variant: "elevated",
            onClick: _ctx.navigateToLogin
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Login ")
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true),
      (_ctx.currentUser)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 3,
            class: "ml-2",
            color: "grey-darken-2",
            "prepend-icon": "mdi-logout",
            variant: "elevated",
            onClick: _ctx.logout
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Logout ")
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}