<template>
  <v-dialog v-model="dialog">
    <template #activator="slotData">
      <slot name="activator" v-bind="slotData" />
    </template>
    <v-stepper v-if="dialog" v-model="stepper" class="stepper-container">
      <v-stepper-header>
        <v-stepper-item :value="1">
          {{ stepOneTitle }}
        </v-stepper-item>

        <v-divider v-if="showStepper && amountOfSteps > 1" />

        <v-stepper-item v-if="showStepper && amountOfSteps === 3" :value="2">
          {{ stepTwoTitle }}
        </v-stepper-item>

        <v-stepper-item v-if="showStepper && amountOfSteps !== 3" :value="2">
          {{
            $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.SELECTION_DIALOG.CONTACT_PERSON")
          }}
        </v-stepper-item>

        <v-divider v-if="showStepper && amountOfSteps === 3" />

        <v-stepper-item v-if="showStepper && amountOfSteps === 3" :value="3">
          {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.SELECTION_DIALOG.CONTACT_PERSON") }}
        </v-stepper-item>

        <v-spacer />
        <v-btn class="align-self-center mr-3" icon="mdi-close" color="black" @click="close" />
      </v-stepper-header>
      <v-stepper-window class="flex-grow-1">
        <v-stepper-window-item class="selection-container" :value="1">
          <slot name="stepOne" :on-item-selected="onItemOneSelected" :stepper="stepper" />
          <v-divider />
          <v-row class="flex-grow-0" justify="space-between">
            <v-spacer />
            <v-col cols="auto">
              <v-btn
                v-if="showStepper"
                :disabled="!itemOneSelected"
                class="dark-btn"
                @click="stepper = stepper + 1"
              >
                {{ $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.SELECTION_DIALOG.NEXT") }}
              </v-btn>
              <v-btn v-if="!showStepper || oneStepper" class="dark-btn" @click="submit()">
                {{
                  $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.SELECTION_DIALOG.COMPLETE")
                }}
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-window-item>
        <v-stepper-window-item
          v-if="showStepper && amountOfSteps === 2"
          class="selection-container"
          :value="2"
        >
          <slot name="stepTwo" :on-item-selected="onItemTwoSelected" :stepper="stepper" />
          <v-divider />
          <v-row class="flex-grow-0" justify="space-between">
            <v-col cols="auto">
              <v-btn class="dark-btn" @click="stepper = stepper - 1">
                {{
                  $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.SELECTION_DIALOG.BACK")
                }}
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn :disabled="!itemTwoSelected && amountOfSteps !== 2" class="dark-btn" @click="submit()">
                {{
                  $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.SELECTION_DIALOG.COMPLETE")
                }}
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-window-item>
        <v-stepper-window-item
          v-if="showStepper && amountOfSteps === 3"
          class="selection-container"
          :value="2"
        >
          <slot name="stepSales" :on-item-selected="onItemSalesSelected" :stepper="stepper" />
          <v-divider />
          <v-row class="flex-grow-0" justify="space-between">
            <v-col cols="auto">
              <v-btn class="dark-btn" @click="stepper = stepper - 1">
                {{
                  $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.SELECTION_DIALOG.BACK")
                }}
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn :disabled="!itemSalesSelected" class="dark-btn" @click="stepper = stepper + 1">
                {{
                  $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.SELECTION_DIALOG.NEXT")
                }}
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-window-item>
        <v-stepper-window-item
          v-if="showStepper && amountOfSteps === 3"
          class="selection-container"
          :value="3"
        >
          <slot name="stepThree" :on-item-selected="onItemThreeSelected" :stepper="stepper" />
          <v-divider />
          <v-row class="flex-grow-0" justify="space-between">
            <v-col cols="auto">
              <v-btn class="dark-btn" @click="stepper = stepper - 1">
                {{
                  $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.SELECTION_DIALOG.BACK")
                }}
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn class="dark-btn" @click="submit()">
                {{
                  $tc("COMPONENTS.MASTER_DATA.CUSTOMER_SELECTION.SELECTION_DIALOG.COMPLETE")
                }}
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-window-item>
      </v-stepper-window>
    </v-stepper>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        showStepper: { default: true,
            type: Boolean
        },
        stepOneTitle: { default: "",
            type: String
        },
        stepTwoTitle: { default: "",
            type: String
        },
        amountOfSteps: { default: 2,
            type: Number
        },
        selectedBidder: { default: () => [],
            type: Array as PropType<Array<unknown>>
        },
        plannerMode: { default: false,
            type: Boolean
        },
        oneStepper: { default: false,
            type: Boolean
        }
    },
    data() {
        return {
            itemOneSelected: false,
            itemTwoSelected: false,
            itemSalesSelected: false,
            itemThreeSelected: false,
            dialog: false,
            stepper: 1,
            loading: false
        };
    },
    watch: {
        "dialog": [{
            handler: "onDialogChange"
        }]
    },
    methods: {
        submit() {
            this.$emit("submit");
            this.close();
        },
        elementSelected() {
            return this.plannerMode ? !this.itemOneSelected : !(this.selectedBidder as any).length;
        },
        close() {
            this.dialog = false;
        },
        onItemOneSelected(item: never) {
            this.itemOneSelected = !!item;
            this.$emit("itemOneSelected", item);
        },
        onItemTwoSelected(item: never) {
            this.itemTwoSelected = !!item;
            this.$emit("itemTwoSelected", item);
        },
        onItemSalesSelected(item: never) {
            this.itemSalesSelected = !!item;
            this.$emit("salesItemSelected", item);
        },
        onItemThreeSelected(item: never) {
            this.itemThreeSelected = !!item;
            this.$emit("itemThreeSelected", item);
        },
        onDialogChange() {
            this.stepper = 1;
        }
    }
})

</script>

<style lang="scss" scoped>
:deep(.stepper-container) {
  height: 90vh;
  display: flex;
  flex-direction: column;

  .v-stepper-window {
    height: 100%;
  }
  .v-stepper-window-item {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .selection-container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .v-alert {
    flex: none;
  }
}
</style>
